var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { class: _vm.classStr, on: { touchstart: _vm.increment } },
    [_c("i", { staticClass: "fa-solid fa-circle-plus fa-2x" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }