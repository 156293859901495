作品画像を表示させつつ、statusによって色を変化させる
<template>
  <a :href="path" :class="statusClass">
    <picture>
      <source type="image/webp" :srcset="assetImg.toWebp().toUrl()+' 1x,'+assetImg.toRetina().toWebp().toUrl()+' 2x'">
      <source :srcset="assetImg.toJpeg().toUrl()+' 1x, '+assetImg.toRetina().toJpeg().toUrl()+' 2x'">
      <img :src="assetImg.toJpeg().toUrl()" :alt="title" :width="width" :height="height">
    </picture>
  </a>
</template>

<script>
import AssetImage from "../../../../js/common/modules/AssetImage"
import {getUserStatusMapByWorkType} from "../../../utils/list"

export default {
  props: {
    title: String, // 作品名
    workType: String, // anime or manga
    image: String, // 画像path
    path: String, // 詳細ページへのpath
    statusId: Number, // listについてのユーザステータス
    width: { // 画像の横幅
      type: Number,
      default: 40,
    },
    height: { // 画像の高さ
      type: Number,
      default: 40,
    },
  },
  computed: {
    assetImg() {
      return new AssetImage({path: this.image, width: this.width, height: this.height})
    },
    statusClass() {
      const statusMap = getUserStatusMapByWorkType(this.workType)
      return statusMap[this.statusId].key
    },
  },
  methods: {
    editListItem() {
      this.$emit("edit-list-item")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/variables";

a {
  color: $mal-blue;
  text-decoration: none;
  font-weight: 400;
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 4px;
    height: 40px;
    content: '';
  }

  &.watching:before {
    background-color: $list-status-watching;
  }
  &.reading:before {
    background-color: $list-status-reading;
  }
  &.completed:before {
    background-color: $list-status-completed;
  }
  &.on-hold:before {
    background-color: $list-status-on-hold;
  }
  &.dropped:before {
    background-color: $list-status-dropped;
  }
  &.plan-to-watch:before {
    background-color: $list-status-plan-to-watch;
  }
  &.plan-to-read:before {
    background-color: $list-status-plan-to-read;
  }

  picture:active {
    opacity: 0.5;
  }
}
</style>
