score更新用のselect box
<template>
  <div class="select-outer">
    <select @change="updateScore($event.target.value)" :value="score" v-if="isOwner" class="select">
      <option value="0">Select</option>
      <option v-for="score in scoreList" :value="score.val">
        {{ score.val }} - {{ score.str }}
      </option>
    </select>
    <div v-if="!isOwner">
      {{ currentStatus }}
    </div>
  </div>
</template>

<script>
const _ = window._
import {ScoreList} from "../../../utils/list"

export default {
  data() {
    return {scoreList: ScoreList}
  },
  props: {
    score: Number,
    isOwner: Boolean, // アクセスした人がlistのOwnerかどうか
  },
  computed: {
    currentStatus() {
      if (this.score > 0) {
        const current = _.find(ScoreList, {val: this.score})
        return `${current.val} - ${current.str}`
      }
    },
    currentStatusLabel() {
      if (this.score > 0) {
        const current = _.find(ScoreList, {val: this.score})
        return ` - ${current.str}`
      }
    },
  },
  methods: {
    updateScore(val) {
      this.$emit("change", parseInt(val))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";

.select {
  display: inline-block;
  background-color: inherit;
  border: 0 solid #979797;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  @include vendor-prefixes(appearance, none);
  @include vendor-prefixes(border-radius, 0);
  color: #2e51a2;
}
.select-outer {
  display: inline-block;
}
</style>
