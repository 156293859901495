// JavaScript Document
const $=require("jquery");

$(function () {

  if ($(".contents-video-embed")[0]) {
    $(".js-btn-open-information").on("click", function() {
      const id = $(this).data("id");
      $(this).toggleClass("on");
      $(`.js-${id}-block`).toggle();
    });
  }

});
