<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-container">
        <div class="modal-header" @touchmove.prevent @touchmove.stop>
          <div class="modal-header-title">
            Confirm
            <a class="modal-close" @click="cancel">close</a>
          </div>
        </div>
        <div class="modal-list" @touchmove.stop>
          <div class="modal-list-inner">
            <div class="modal-list-item" v-for="item in items">
              <div>
                <img :src="coverImageUrl(item.coverImageBaseUrl)" width="46" height="auto" class="modal-list-cover margin-r10">
              </div>
              <div class="modal-list-info">
                <div class="modal-list-title">{{ item.title }}</div>
                <div class="modal-list-volume"
                     :class="{iconNew: !item.isPossessed && item.isNew}"
                >
                  {{ item.numberingText }}
                </div>
                <div class="modal-list-price">
                  <template v-if="item.salePrice">
                    <span class="is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                    Sale <span class="modal-price-sale">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                  </template>
                  <template v-else>
                    {{ currency.formatSymbolAndPrice(item.price) }}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" @touchmove.prevent @touchmove.stop>
          <div class="modal-price_wrap">
            <ul>
              <li><div>Price</div>{{ currency.formatSymbolAndPrice(totalPriceWithoutTax) }}</li>
              <li><div>Tax</div>{{ currency.formatSymbolAndPrice(totalTax) }}</li>
              <li class="modal-price"><div>Total</div>{{ currency.symbol }}<span>{{ currency.formatPrice(totalPrice) }}</span>{{ currency.name }}</li>
            </ul>

            <div class="modal-error" v-show="error">{{ error }}</div>
          </div>
          <div class="modal-table">
            <template v-if="!isRequesting">
              <div style="position: absolute;left: 12px;">
              </div>
              <div><a class="modal-cancel-button margin-l10" @click="cancel">Cancel</a></div>
              <div><a class="modal-confirm-button margin-l10" @click="$emit('confirm')">Confirm Purchase</a></div>
            </template>
            <template v-else>
              <div class="modal-button-loading"></div>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  $text-price-color:#C0392B;
  $text-volume-color:#7B7D82;
  $button-payment-color:#ff9600;
  $button-cancel-color:#2E51A2;
  $button-disable-color:#9B9B9B;
  $border-color:#ebebeb;
  $border-light-color:#e5e5e5;
  $border-cover-color:#E1E7F5;
  $header-height:147px;
  $modal-side-padding:10px;
  img {
    vertical-align: bottom;
  }
  .modal-container {
    font-family: Verdana,Arial;
    -webkit-text-size-adjust: 100%;
    position: fixed;
    z-index: 9998;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    top: 0;
    left: 0;
    /* ipone5でなぜかurlバーと下のメニューを高さに含めてしまうのでjavascriptで対応→結局だめ */
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: transform .5s ease, opacity .3s ease;
    will-change: transform, opacity;
    z-index: 9999;
  }

  .modal-header {
    display: block;
    border-bottom: 1px solid $border-light-color;
  }
  .modal-header-title {
    text-align: center;
    padding: 0 42px;
    background-color: #EFF4FA;
    color: #2E51A2;
    font-size: 16px;
    line-height: 32px;
  }
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url(/images/manga_store/sp/modal_close_gray@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    text-indent: -999999px;
  }
  .modal-footer {
    display: block;
    padding: 10px $modal-side-padding 40px;
    border-top: 1px solid $border-light-color;
  }
  .modal-button-loading {
    display: block;
    width: calc(100% - 120px);
    height: 34px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }
  .modal-edit-payment-method {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #ebebeb;
    padding: 8px 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    text-indent: 0;
    text-align: center;
    &:active {
      background-color: #E1E7F5;
    }
  }
  .modal-price_wrap {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6rem;
    ul {
      display: table;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    li {
      display: table-row;
      & > div {
        display: table-cell;
        padding-bottom: 4px;
        &:first-child {
          width: 46px;
        }
      }
      &:last-child {
        & > div {
          padding-bottom: 10px;
        }
      }
      div {
        font-weight: 300;
      }
    }
  }
  .modal-price {
    color: $text-price-color;
    font-weight: 700;
    font-size: 14px;
    > span {
      font-size: 18px;
    }
  }
  .modal-table {
    display: -webkit-box;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-end;
  }
  %button {
    font-weight: 700;
    font-size: 14px;
    min-width: 70px;
    padding: 7px 8px;
    display: block;
    box-sizing: border-box;
    text-align: center;
    line-height: 16px;
    border-radius: 3px;
    white-space: nowrap;
    &.is-disable {
      cursor: auto;
      color: $button-disable-color;
      border: 2px solid $button-disable-color;
    }
  }
  .modal-cancel-button {
    color: $button-cancel-color;
    border: 2px solid $button-cancel-color;
    @extend %button;
    &:active {
      background-color: rgba(#2E51A2, 0.2);
    }
  }
  .modal-confirm-button {
    color: $button-payment-color;
    border: 2px solid $button-payment-color;
    @extend %button;
    &:active {
      background-color: rgba(#ff9600, 0.2);
    }
  }
  .modal-taxamo {
    background: url(/images/manga_store/taxamo_logo@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    display: inline-block;
    width: 60px;
    height: 10px;
    margin-top: 10px;
    text-indent: -9999px;
    overflow: hidden;
  }

  .modal-list {
    -webkit-box-flex: 1;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .modal-list-inner {
    position: relative;
  }
  .modal-list-item {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px $modal-side-padding;
    box-sizing: border-box;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    border-bottom: 1px solid $border-light-color;
    &:last-child {
      border-bottom: none;
    }
    &.is-hold {
      color: #bdbdbd;
    }
  }
  .modal-list-cover {
    box-sizing: border-box;
    border: 1px solid  $border-cover-color;
  }
  .modal-list-cover-grayscale {
    width: 46px;
  }
  .modal-list-info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    text-align: left;
  }
  .modal-list-title {
    font-size: 14px;
    font-weight: 300;
    word-wrap: break-word;
    min-width: 100%;
  }
  .modal-list-volume {
    padding-top: 4px;
    color: $text-volume-color;
    font-size: 12px;
    font-weight: 300;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    box-flex: 1;
    &.iconNew {
      position: relative;
      padding-left: 36px;
      &:after {
        position: absolute;
        top: 2px;
        left: 0;
        background-color: #c0392b;
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        color: #fff;
        font-size: 10px;
        font-weight: 300;
        line-height: 1.0rem;
        content: "New";
      }
    }
    .is-hold &{
      color: #bdbdbd;
    }
  }
  .modal-list-price {
    font-size: 14px;
    color: $text-price-color;
    .is-sale {
      color: #7B7D82;
      font-size: 11px;
      font-weight: 300;
      text-decoration: line-through;
    }
    .modal-price-sale {
      font-weight: 700;
    }
  }
  .margin-l10 {
    margin-left: 10px;
  }
  .margin-r10 {
    margin-right: 10px;
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter.modal-container,
  .modal-leave-active.modal-container {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
  }
  .modal-error {
    display: block;
    margin-bottom: 10px;
    text-align: right;
    color: #ff6a86;
  }
</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      title: String,
      items: Array,
      currency: Object,
      totalPrice: Number,
      error: String,
      isRequesting: Boolean,
      paymentMethod: String,
      totalPriceWithoutTax: Number,
      totalTax: Number,
      isDelegated: Boolean
    },
    data() {
      // TODO(SYS): Tax 表示
      return {
        tax: 0.99,
      }
    },
    methods: {
      cancel() {
        this.$emit('cancel')
      },
      coverImageUrl(coverImageBaseUrl) {
        return generateCoverImageUrl(coverImageBaseUrl)
      },
    },
  }
</script>
