var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "user-status" }, [
    _c(
      "li",
      { staticClass: "status" },
      [
        _c("user-status", {
          attrs: {
            "status-id": _vm.userStatusId,
            "work-type": _vm.workType,
            "is-owner": _vm.isOwner
          },
          on: { change: _vm.updateUserStatus }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "li",
      { staticClass: "score", class: { scored: _vm.isOwner || _vm.score } },
      [
        _c("i", { staticClass: "fa-regular fa-star mr4" }),
        _c("user-score", {
          attrs: { score: _vm.score, "is-owner": _vm.isOwner },
          on: { change: _vm.updateScore }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "li",
      { staticClass: "increment" },
      [
        _vm.canIncrement
          ? [
              _vm.workType === "anime"
                ? _c("increment", {
                    attrs: { unit: "ep" },
                    on: { increment: _vm.incrementEpisode }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.workType === "manga"
                ? _c("increment", {
                    attrs: { unit: "vol" },
                    on: { increment: _vm.incrementVolume }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.workType === "manga"
                ? _c("increment", {
                    attrs: { unit: "chp" },
                    on: { increment: _vm.incrementChapter }
                  })
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }