const $ = window.$;

import Modal from "../../common/modules/modal";
import GtmJsEvent from "../../common/modules/gtm/jsEvent";

// プロフィールカードのデザインがPC/SPで異なるためいったん別々に実装する
// あとから共通化するのが望ましい
$(function () {
  const CARD_HTML = `<div class="mal-profile-card">
    <div class="mal-profile-card-container">
      <div class="signature"></div>
      <div class="main">
        <div class="avatar">
          <a class="link ga-click" data-ga-click-type="profilecard-icon"><img class="avatar-icon" src="/images/kaomoji_mal_white.png" /></a>
          <div class="title"></div>
        </div>
        <div class="content">
          <div class="user">
            <div class="item">
              <div class="username"><a class="link ga-click" data-ga-click-type="profilecard-name"></a></div>
              <div class="registered"></div>
              <div class="posts"></div>
            </div>
            <div class="item">
              <div class="button"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`;

  jQuery.fn.extend({
    profileCard: function () {
      const $target = $(this);
      const username = $target.attr('rel');
      if (!username) return;

      // GA4インプレッションイベントを送信する
      GtmJsEvent.impression('forum-profilecard', `user:${username}`);

      console.log(`Showing profile card for ${username}.`);
      const $exists = $(`div.mal-profile-card[rel=${username}]`);
      // プロフィールカードが読込済の場合は表示して終了
      if ($exists.length > 0) {
        return $exists.showProfileCard($target);
      }
      // プロフィールカードを新規読込
      $.ajax({
        type: 'GET',
        url: `/profile/${username}/cardinfo`,
        cache: false,
        dataType: 'json',
        timeout: 10000,
        success : function (data) {
          console.log(data);
          if (!data.result) {
            console.log('Error occured.', data.error.message, data.error.code);
            return;
          }
          const $card = $(CARD_HTML);
          $card.attr('data-id', data.id);
          $card.attr('rel', data.name);
          $('.avatar .link', $card).attr('href', data.url);
          $('.content .username .link', $card).attr('href', data.url);
          $('.content .username .link', $card).text(data.name);
          $('.content .registered', $card).text(data.registered_at);
          if (data.avatar) {
            $('.main .avatar .avatar-icon', $card).attr('src', data.avatar);
          }
          if (data.sectitle) {
            $('.main .avatar .title', $card).append($(`<div class="mal-badge mal-bg-${data.secstr}"></div>`).text(data.sectitle));
          }
          if (data.title) {
            $('.main .avatar .title', $card).append($(`<div class="mal-badge"></div>`).text(data.title));
          }
          if (data.posts) {
            $('.main .content .user .posts', $card).text(data.posts.toLocaleString());
          } else {
            $('.main .content .user .posts', $card).remove();
          }
          if (data.self) {
            $('.main .content .user .item .button', $card).append(`<a href="/editprofile.php?go=forumoptions" class="mal-btn primary ga-click" data-ga-click-type="profilecard-edit-profile">Edit Profile</a>`);
          } else {
            if (data.friend_status == 'friend') {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Friend</a>`);
            } else if (data.friend_status == 'pending') {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Pending Approval</a>`);
            } else if (data.friend_request != 1) {
              $('.main .content .user .item .button', $card).append(`<a class="mal-btn secondary outline disabled mal-btn-friend-request">Add Friend</a>`);
            } else {
              $('.main .content .user .item .button', $card).append(`<a href="/myfriends.php?go=add&id=${data.id}" class="mal-btn primary mal-btn-friend-request ga-click" data-ga-click-type="profilecard-add-friend">Add Friend</a>`);
            }
          }
          $card.appendTo('body');
          $card.showProfileCard($target);
        },
      });
    },
    showProfileCard: function ($element) {
      const $card = $(this);
      $card.addClass('show');
      $('.mal-profile-card-backdrop').addClass('show');
      return $card;
    },
  });

  $('body').on('click', '.js-mal-profile-popup', function (event) {
    const $target = $(this);
    $target.profileCard();
    return false;
  });
  $('body').on('click', '.js-mal-friend-request', function (event) {
    const $target = $(this);
    // 画面を閉じる
    $('.mal-profile-card.show').removeClass('show');
    $('.mal-profile-card-backdrop').removeClass('show');
    // フレンドリクエストを表示する
    Modal.generate();
    Modal.buildFriendRequestDialog($target.data('id'), $target.data('name'));
    Modal.show();
  });
  $('body').on('click', '.mal-profile-card-backdrop', function (event) {
    $('.mal-profile-card.show').removeClass('show');
    $('.mal-profile-card-backdrop').removeClass('show');
  });
  // バックドロップをあらかじめ準備する
  $('body').append('<div class="mal-profile-card-backdrop"></div>');
});
