// JavaScript Document
import $ from "jquery"
import _ from "lodash"
import Cookies from "js-cookie";
import Modal from "../../../common/modules/modal";
import List from "../../../common/modules/ownlist";
import PlusOne from "../../../common/modules/plusone";
const localStorage = require("../../../common/modules/localStorage");
$(function() {
  $(".related-entries .hide-entry").hide();

  $(".js-toggle-related-entries").on("click", function () {
    if ($(this).html().indexOf("More") > -1) {
      $(this).html("Less related entries");
      $(this).addClass('open');
      $(".related-entries .hide-entry").show();
    } else {
      $(this).html("More related entries");
      $(this).removeClass('open');
      $(".related-entries .hide-entry").hide();
    }
  });

  // Notify on Air登録
  const notifyAnimeEntry = function (animeId, info, options = {}) {
    options = _.assign({
      onSuccess() {},
      onFail() {},
    }, options);

    $.post("/ownlist/anime/add.json", JSON.stringify({
      anime_id: parseInt(animeId),
      status: parseInt(info.status),
    }), function (data) {
      options.onSuccess(data);
    }).fail((xhr) => {
      options.onFail(xhr.responseJSON.errors[0].message);
    });
  }

  // Notify on Air登録ボタン押下時の処理
  $("#js-notify-button").on("click", function() {

    const animeId  = $("#myinfo_anime_id").val();
    const status   = 6; // plan_to_watch

    notifyAnimeEntry(animeId, {
      status  : status,
    }, {
      onSuccess() {
        $("#notify-msg-frame").show();
        $("#js-notify-button").hide();
        $(".status-unit .js-btn-dialog-add").text("Plan to Watch");
        $(".status-unit .js-btn-dialog-add").attr(status);
      }, onFail(error) {
        $("#notify-block").text(error);
      },
    });
  })

  // Watch Episodeボタンの処理
  $(".js-broadcast-button").on("click", function() {
    if ($(this).data('raw') === undefined ||
      $(this).data('raw') == '') {
      return;
    }
    Modal.generate();
    Modal.buildBroadcastDialog(
      $(this).data('title'),
      $(this).data('subtitle'),
      $(this).data('raw')
    );
    Modal.show();
  });

  // genre sort
  if ($(".js-filter-sort")[0]) {
    const $filter_sort = $(".js-filter-sort")
    const genre_cookie = Cookies.get("search_sort_anime")

    if (genre_cookie) {
      $filter_sort.val(genre_cookie)
    } else {
      $filter_sort.val("members")
    }

    $filter_sort.on("change", function() {
      const id = $(this).val()
      Cookies.set("search_sort_anime", id)
      // remove query
      location.href = location.href.replace(/\?.*$/, "")
    })
  }

  // ---------------------------------
  // 20220127 sort button
  // ---------------------------------
  if ($(".js-navi-company")[0]) {
    // tab select
    $(".js-btn-anime-type").on("change", function() {
      localStorage.setItem('companyAnimeTab', $(this).val());
      reflectsAnime($(this).val());
    });

    // sort button
    $(".js-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      $(".js-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      localStorage.setItem('companyAnimeSort', $(this).attr("id"));
      sortAnime($(this).attr("id"));
    });

    // sort anime
    const sortAnime = function(method) {
      $(".js-seasonal-anime").sort(blockSorter[method]).each(function() {
        $(".js-seasonal-anime-append").append(this);
      });
    }
    const blockSorter = {
      members: function(a, b) {
        const aMembers = a.querySelector('.js-members').innerHTML;
        const bMembers = b.querySelector('.js-members').innerHTML;
        return bMembers - aMembers;
      },
      score: function(a, b) {
        const aScore = a.querySelector('.js-score').innerHTML;
        const bScore = b.querySelector('.js-score').innerHTML;
        return bScore - aScore;
      },
      start_date: function(a, b) {
        const aDate = a.querySelector('.js-start_date').innerHTML;
        const bDate = b.querySelector('.js-start_date').innerHTML;
        return bDate - aDate;
      },
      title: function(a, b) {
        const aTitle = a.querySelector('.js-title').innerHTML;
        const bTitle = b.querySelector('.js-title').innerHTML;
        return (aTitle > bTitle) ? 1 : (aTitle < bTitle) ? -1 : 0
      }
    };

    const companyAnimeSort = {
      members    : "Members",
      score      : "Score",
      start_date : "Newest",
      title      : "Title"
    };
    let sort = localStorage["companyAnimeSort"] || "members";
    $(".js-sort-order-block.js-sort .js-btn-sort-order#" + sort).addClass("selected");
    $(".js-text-sorted-by").text(companyAnimeSort[sort]);
    sortAnime(sort);

    const selectTab = localStorage["companyAnimeTab"] ? localStorage["companyAnimeTab"] : 'all';
    const btnTab = $(".js-btn-anime-type");
    btnTab.val() === selectTab;
    const reflectsAnime = function(key) {
      $(".js-categories-seasonal .js-seasonal-anime").show();
      // tab select
      const $anime_type = $(".js-btn-anime-type")
      if ($anime_type.val() !== "all") {
        $(".js-btn-anime-type option").each(function() {
          const tab = $(this).val();
          if (tab !== key) {
            if (tab !== "all") {
              if (tab === "0") { // Other(Unknown, Special, Music, CM, PV, TV Special)
                $(".js-anime-type-0").hide();
                $(".js-anime-type-4").hide();
                $(".js-anime-type-6").hide();
                $(".js-anime-type-7").hide();
                $(".js-anime-type-8").hide();
                $(".js-anime-type-9").hide();
              } else {
                $(`.js-anime-type-${tab}`).hide();
              }
            }
          }
        });
      }
    }
    reflectsAnime("all");
  }

  // ---------------------------------
  // ---------------------------------

  // お気に入り追加/削除ボタン
  if ($(".js-favorite-button")[0]) {
    $(".js-favorite-button").on("click", function(e) {
      e.preventDefault()
      const $btn = $(this)
      const type = $btn.data("type")
      let isFavorite = $btn.attr("data-isFavorite")
      const id   = $btn.data("id")

      const $sliderBtn = $btn.parent(".js-ajax-loading")
      const $favUsers  = $sliderBtn.find(".js-favorite-users")
      const fav = parseInt($favUsers.attr("data-users"))

      const method = isFavorite === "true" ? "DELETE": "POST"

      $sliderBtn.addClass("btn-ajax-loading")

      $.ajax({
        url: `/favorite/${type}/${id}.json`,
        method: method
      })
      .done(() => {
        isFavorite = (method === "POST") ? true : false
        $btn.attr("data-isFavorite", isFavorite)
        if ($favUsers) {
          const new_fav      = (method === "POST") ? fav + 1 : fav - 1
          const new_fav_text = (new_fav > 1) ? "users" : "user"
          $favUsers.attr("data-users",new_fav).text(new_fav.toLocaleString() + new_fav_text)
        }
      })
      .fail((xhr) => {

        const errorDialog = function(msg) {
          // error msg
          const docH = $(document).height()
          const sclV = $(window).scrollTop()
          $("#dialog_overlay").css({height:docH,display:"block"}).hide().fadeIn("fast",function() {
              const dialogH = $("#dialog_pop").height()
              const topPosV = ( dialogH < 150 ) ? 130+sclV : 40+sclV
              $("#dialog_pop").removeClass("dialog_edit_list")
              $("#dialog_pop").css({"top":`${topPosV}px`,"margin-top":"inherit","display":"block"}).show()
          })

          $(window).on("touchmove.noScroll", function(e) {
              e.preventDefault()
          })

          const template = _.template($("#dialog_error_template").text())
          $("#dialog_pop").append(template({
              error_message: msg ? _.template('<div class="fn-black fw-n"><%= msg %></div>')({msg: msg}) : `<div class="fn-black fw-n">Unknown error occured</div>`
          }))

          //close BTN function
          $(".js-btn-close-dialog").on("click",function(){
            $("#loading .circleAnim").removeClass("circleAnim2")
            $("#loading").hide()
            $("#fieldcontain").remove()//remove underscore.js template
            $("#dialog_pop").empty()
            $(window).off(".noScroll")
            $("#dialog_overlay").css("display","none")
            return false
          })
        }

        const json = xhr.responseJSON
        let msg = ""
        switch (xhr.status) {
          case 400: // 最大favorite数を越えた場合
            if (json.is_supporter) {
              msg = `Only a maximum of ${json.max_favs} favorites allowed.`
            } else {
              msg = `Only a maximum of ${json.max_favs} favorites allowed. Become a <a href="${json.url}" class="link-mal-supporter td-u">MAL Supporter</a> to increase it to twice!`
            }
            errorDialog(msg)
            break
          case 401: // loginしていない場合login画面に飛ばす
            window.location.href = json.redirect
            break
          default:
            msg = (json === null)? "Unknown error occured": json.errors[0].message
            errorDialog(msg)
        }
      })
      .always(() => {
        $sliderBtn.removeClass("btn-ajax-loading")
      })
    })
  }

  // ---------------------------------
  // お気に入り追加/削除ボタン(旧スタイル)
  // ---------------------------------
  if ($(".js-favorite-button-rect")[0]) {
    $(".js-favorite-button-rect").on("click", function (e) {
      e.preventDefault()
      const $btn = $(this)
      const $favUsers  = $(".js-favorite-users:first")
      let isFavorite = $btn.attr("data-isFavorite")

      const type = $btn.data("type")
      const id   = $btn.data("id")
      const fav = parseInt($favUsers.text().split(',').join('').trim())

      const method = isFavorite === "true" ? "DELETE": "POST"

      $btn.addClass("loading")
      $.ajax({
        url: `/favorite/${type}/${id}.json`,
        method: method
      })
      .done(() => {
        isFavorite = (method === "POST") ? true : false
        const new_text = (method === "POST") ? "Remove from Favorites" : "Add to Favorites"
        $btn.attr("data-isFavorite", isFavorite).text(new_text)
        if ($favUsers) {
          const new_fav = (method === "POST") ? fav + 1 : fav - 1
          $favUsers.text(new_fav.toLocaleString())
        }
      })
      .fail((xhr) => {

        const errorDialog = function(msg) {
          // error msg
          const docH = $(document).height()
          const sclV = $(window).scrollTop()
          $("#dialog_overlay").css({height:docH,display:"block"}).hide().fadeIn("fast",function() {
              const dialogH = $("#dialog_pop").height()
              const topPosV = ( dialogH < 150 ) ? 130+sclV : 40+sclV
              $("#dialog_pop").removeClass("dialog_edit_list")
              $("#dialog_pop").css({"top":`${topPosV}px`,"margin-top":"inherit","display":"block"}).show()
          })

          $(window).on("touchmove.noScroll", function(e) {
              e.preventDefault()
          })

          const template = _.template($("#dialog_error_template").text())
          $("#dialog_pop").append(template({
              error_message: msg ? _.template('<div class="fn-black fw-n"><%= msg %></div>')({msg: msg}) : `<div class="fn-black fw-n">Unknown error occured</div>`
          }))

          //close BTN function
          $(".js-btn-close-dialog").on("click",function(){
            $("#loading .circleAnim").removeClass("circleAnim2")
            $("#loading").hide()
            $("#fieldcontain").remove()//remove underscore.js template
            $("#dialog_pop").empty()
            $(window).off(".noScroll")
            $("#dialog_overlay").css("display","none")
            return false
          })
        }

        const json = xhr.responseJSON
        let msg = ""
        switch (xhr.status) {
          case 400: // 最大favorite数を越えた場合
            if (json.is_supporter) {
              msg = `Only a maximum of ${json.max_favs} favorites allowed.`
            } else {
              msg = `Only a maximum of ${json.max_favs} favorites allowed. Become a <a href="${json.url}" class="link-mal-supporter td-u">MAL Supporter</a> to increase it to twice!`
            }
            errorDialog(msg)
            break
          case 401: // loginしていない場合login画面に飛ばす
            window.location.href = json.redirect
            break
          default:
            msg = (json === null)? "Unknown error occured": json.errors[0].message
            errorDialog(msg)
        }
      })
      .always(() => {
        $btn.removeClass("loading")
      })
    })
  }

  // ---------------------------------
  // お気に入り追加/削除ボタン(新スタイル for detail page)
  // ---------------------------------
  if ($(".js-favorite-button-detail")[0]) {
    $(".js-favorite-button-detail").on("click", function (e) {
      e.preventDefault()
      const $btn = $(this)
      let isFavorite = $btn.attr("data-isFavorite")

      const type = $btn.data("type")
      const id   = $btn.data("id")

      const method = isFavorite === "true" ? "DELETE": "POST"

      $btn.addClass("loading")
      $.ajax({
        url: `/favorite/${type}/${id}.json`,
        method: method
      })
      .done(() => {
        isFavorite = (method === "POST") ? true : false
        $btn.attr("data-isFavorite", isFavorite)
      })
      .fail((xhr) => {

        const errorDialog = function(msg) {
          // error msg
          const docH = $(document).height()
          const sclV = $(window).scrollTop()
          $("#dialog_overlay").css({height:docH,display:"block"}).hide().fadeIn("fast",function() {
              const dialogH = $("#dialog_pop").height()
              const topPosV = ( dialogH < 150 ) ? 130+sclV : 40+sclV
              $("#dialog_pop").removeClass("dialog_edit_list")
              $("#dialog_pop").css({"top":`${topPosV}px`,"margin-top":"inherit","display":"block"}).show()
          })

          $(window).on("touchmove.noScroll", function(e) {
              e.preventDefault()
          })

          const template = _.template($("#dialog_error_template").text())
          $("#dialog_pop").append(template({
              error_message: msg ? _.template('<div class="fn-black fw-n"><%= msg %></div>')({msg: msg}) : `<div class="fn-black fw-n">Unknown error occured</div>`
          }))

          //close BTN function
          $(".js-btn-close-dialog").on("click",function() {
            $("#loading .circleAnim").removeClass("circleAnim2")
            $("#loading").hide()
            $("#fieldcontain").remove()//remove underscore.js template
            $("#dialog_pop").empty()
            $(window).off(".noScroll")
            $("#dialog_overlay").css("display","none")
            return false
          })
        }

        const json = xhr.responseJSON
        let msg = ""
        switch (xhr.status) {
          case 400: // 最大favorite数を越えた場合
            if (json.is_supporter) {
              msg = `Only a maximum of ${json.max_favs} favorites allowed.`
            } else {
              msg = `Only a maximum of ${json.max_favs} favorites allowed. Become a <a href="${json.url}" class="link-mal-supporter td-u">MAL Supporter</a> to increase it to twice!`
            }
            errorDialog(msg)
            break
          case 401: // loginしていない場合login画面に飛ばす
            window.location.href = json.redirect
            break
          default:
            msg = (json === null)? "Unknown error occured": json.errors[0].message
            errorDialog(msg)
        }
      })
      .always(() => {
        $btn.removeClass("loading")
      })
    })
  }
  // ---------------------------------
  // episode detail - Status

  if ($(".js-user-status-block")[0]) {
    // My info追加
    const myinfoAddtolist = function() {
      if ($(".js-user-status-block").data("type") === "manga") {
        // manga add
        const mangaId = $(".js-user-status-block #myinfo_anime_id").val();
        const score   = $(".js-user-status-block #myinfo_score").val();
        const status  = $(".js-user-status-block #myinfo_status").val() || $(".js-user-status-block #myinfo_status").data("value");
        const chapters = $(".js-user-status-block #myinfo_chapters").val();
        const volumes  = $(".js-user-status-block #myinfo_volumes").val();

        $(".js-user-status-block").addClass("on");
        $(".js-user-status-block i.fa-spinner").show();
        $(".js-myinfo-error").html("").hide();

        List.addMangaEntry(mangaId, {
          status  : status,
          score   : score,
          chapters: chapters,
          volumes : volumes,
          side    : "sp",
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-user-status-block .status .js-form-user-status-select").prop("disabled", false).show();
          },
          onFail(error) {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-myinfo-error").text(error).show();
          },
        });
      } else {
        // anime add
        const animeId  = $("#myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val() || $(".js-user-status-block #myinfo_status").data("value");
        const episodes = $(".js-user-status-block #myinfo_watchedeps").val();

        $(".js-user-status-block").addClass("on");
        $(".js-user-status-block i.fa-spinner").show();
        $(".js-myinfo-error").html("").hide();

        List.addAnimeEntry(animeId, {
          status  : status,
          score   : score,
          episodes: episodes,
          side    : "sp",
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-user-status-block .status .js-form-user-status-select").prop("disabled", false).show();

            // 左カラムの成功表示
            renderSuucessLeftColumn(animeId);
          },
          onFail(error) {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-myinfo-error").text(error).show();
          },
        });
      }
    }
    // My info更新
    const myinfoUpdateInfo = function(reason) {
      if ($(".js-user-status-block").data("type") === "manga") {
        // manga update
        const mangaId  = $(".js-user-status-block #myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val();
        const chapters = $(".js-user-status-block #myinfo_chapters").val();
        const volumes  = $(".js-user-status-block #myinfo_volumes").val();

        $(".js-user-status-block").addClass("on");
        $(".js-user-status-block i.fa-spinner").show();
        $(".js-myinfo-error").html("").hide();

        List.updateMangaEntry(mangaId, {
          status  : status,
          score   : score,
          chapters: chapters,
          volumes : volumes,
          side    : "sp",
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            // 左カラムと同期
            $(".js-manga-addtolist-block #myinfo_status").val(status);
            $(".js-manga-addtolist-block #myinfo_score").val(score);
            $(".js-manga-addtolist-block #myinfo_chapters").val(chapters);
            $(".js-manga-addtolist-block #myinfo_volumes").val(volumes);
            // +1ダイアログ表示
            if (reason == 'episode') {
              PlusOne.showMangaVoteDialog(mangaId, chapters);
            }
          },
          onFail() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-myinfo-error").innerHTML = "Failed to Update";
          },
        });
      } else {
        // anime update
        const animeId  = $(".js-user-status-block #myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val();
        const episodes = $(".js-user-status-block #myinfo_watchedeps").val();

        $(".js-user-status-block").addClass("on");
        $(".js-user-status-block i.fa-spinner").show();
        $(".js-myinfo-error").html("").hide();

        List.updateAnimeEntry(animeId, {
          status  : status,
          score   : score,
          episodes: episodes,
          side    : "sp",
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            // 左カラムと同期
            $(".js-anime-addtolist-block #myinfo_status").val(status);
            $(".js-anime-addtolist-block #myinfo_score").val(score);
            $(".js-anime-addtolist-block #myinfo_watchedeps").val(episodes);
            // +1ダイアログ表示
            if (reason == 'episode') {
              PlusOne.showAnimeVoteDialog(animeId, episodes);
            }
          },
          onFail() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block i.fa-spinner").hide();
            $(".js-myinfo-error").innerHTML = "Failed to Update";
          },
        });
      }
    }

    const check3episodes = function(watchedeps) {
      if ($(".js-user-status-block").data("type") === "manga") return;
      const $review_msg  = $('#review_message');
      const $onaired_episode = $('#onaired_episode');
      const $media_type  = $('#myinfo_media_type');
      if ($media_type.val() == 1) {
        if ($onaired_episode.length && $onaired_episode.data("type") === "broadcast") {
          if (watchedeps < 3) {
            $onaired_episode[0].style.display = 'none';
          } else {
            $onaired_episode[0].style.display = 'block';
          }
        }
        if ($review_msg.length) {
          if (watchedeps < 3 || watchedeps == null) {
            $review_msg[0].style.display = 'block';
          } else {
            $review_msg[0].style.display = 'none';
          }
        }
      } else { // TV以外
        if ($review_msg.length) {
          if (watchedeps < 1 || watchedeps == null) {
            $review_msg[0].style.display = 'block';
          } else {
            $review_msg[0].style.display = 'none';
          }
        }
      }
    }
    window['check3episodes'] = check3episodes;

    const checkUserStatus = function () {
      const $status = $(".js-user-status-block .status .js-form-user-status");
      const text    = $status.find("option:selected").text().toLowerCase().replace(/\s+/g, "");
      return text;
    }

    const pushUserStatus = function (reason) {
      const $formUserStatus = $(".js-user-status-block .status .js-form-user-status");
      if ($formUserStatus.hasClass("myinfo_addtolist")) {
        myinfoAddtolist();
        $formUserStatus.removeClass("myinfo_addtolist").addClass("myinfo_updateInfo");
      } else if ($formUserStatus.hasClass("myinfo_updateInfo")) {
        myinfoUpdateInfo(reason);
      }
    }

    // status color
    const setUserStatusColor = function () {
      const statusText = checkUserStatus();
      $(".js-user-status-block .status .js-form-user-status").attr("data-class", statusText);
    }

    const checkUserStatusCompleted = function () {
      const $userEpisode = $(".js-user-status-block .form-user-episode");

      if ($(".js-user-status-block").data("type") === "manga") {
        //manga
        const volTotal = parseInt($(".js-user-status-block #totalVols").data("num"));
        const volRead  = parseInt($(".js-user-episode-seen#myinfo_volumes").val() || 0);
        if ("completed" === checkUserStatus() && volTotal <= volRead && volTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }

        const chapTotal = parseInt($(".js-user-status-block #totalChaps").data("num"));
        const chapRead  = parseInt($(".js-user-episode-seen#myinfo_chapters").val() || 0);
        if ("completed" === checkUserStatus() && chapTotal <= chapRead && chapTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }

      } else {
        //anime
        const epsTotal = parseInt($(".js-user-status-block #curEps").data("num"));
        const epsSeen  = parseInt($(".js-user-episode-seen#myinfo_watchedeps").val() || 0);
        if ("completed" === checkUserStatus() && epsTotal <= epsSeen && epsTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }
      }
    }
    checkUserStatusCompleted();

    // status
    if ($(".js-user-status-block .status .js-form-user-status")[0]) {
      setUserStatusColor();// onload

      $(".js-user-status-block .status .js-form-user-status").on("change", function () {
        const statusText = checkUserStatus();
        $(".js-user-status-block .status .js-form-user-status").attr("data-class", statusText);

        if ($(".js-user-status-block").data("type") === "manga") {
          // manga
          const volTotal = parseInt($(".js-user-status-block #totalVols").data("num"));
          // fill Eps num if status is completed
          if (statusText === "completed" && volTotal) {
            $(".js-user-episode-seen#myinfo_volumes").val(volTotal).change();
          }

          const chapTotal = parseInt($(".js-user-status-block #totalChaps").data("num"));
          // fill Eps num if status is completed
          if (statusText === "completed" && chapTotal) {
            $(".js-user-episode-seen#myinfo_chapters").val(chapTotal).change();
          }
        } else {
          //anime
          const epsTotal = parseInt($(".js-user-status-block #curEps").data("num"));
          // fill Eps num if status is completed
          if (statusText === "completed" && epsTotal) {
            $(".js-user-episode-seen#myinfo_watchedeps").val(epsTotal).change();
            check3episodes(epsTotal);
          }
        }

        checkUserStatusCompleted();
        setUserStatusColor();
        pushUserStatus();
      });
    }

    // Score
    if ($(".js-user-status-block .js-form-user-score")[0]) {
      $(".js-user-status-block .js-form-user-score").on("change", function () {
        pushUserStatus();
      });
    }
    // Score for free manga
    if ($(".js-user-status-block .js-form-user-score-1")[0]) {
      $(".js-user-status-block .js-form-user-score-1").on("change", function () {
        pushUserStatus();
        $(".btn-user-status-add-list").remove();
      });
    }

    // Episode Seen
    if ($(".js-user-status-block .js-btn-count")[0]) {
      const $userEpisode = $(".js-user-status-block .form-user-episode");
      if (!$userEpisode.hasClass("disabled")) {
        const countUpDown = function (obj, btnID, totalID) {
          const $myinfoWatchedeps = $(`.js-user-status-block #${btnID}`);
          const nepsseen           = parseInt($myinfoWatchedeps.val() || 0);
          let curEps             = $(`.js-user-status-block #${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;
          if (!$userEpisode.hasClass("completed") || curEps === 0) {
            const countUpEpsNum = function (num) {
              $myinfoWatchedeps.val(nepsseen + num).trigger("change");
              pushUserStatus('episode');
              check3episodes(nepsseen + num);
            }
            if (obj.hasClass("increase")) {
              if (curEps === 0 || nepsseen < curEps) {
                countUpEpsNum(1);
              }
            }
          }
        }

        //button
        $(".js-btn-count").on("click", function () {
          // Clear preparing plus one dialog
          PlusOne.clearVoteDialog();
          if ($(this).hasClass("volume")) {
            //manga volume
            countUpDown($(this), "myinfo_volumes", "totalVols");
          } else if ($(this).hasClass("chapter")) {
            //manga chapter
            countUpDown($(this), "myinfo_chapters", "totalChaps");
          } else {
            //anime
            countUpDown($(this), "myinfo_watchedeps", "curEps");
          }
        });


        // score input
        const inputEpsNum = function (str, totalID) {
          let curEps = $(".js-user-episode-seen").next(`#${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;
          if (str.match(/^[-]?[0-9]+(¥.[0-9]+)?$/)) {
            if (parseInt(str) <= parseInt(curEps) || curEps === 0) {
              pushUserStatus('episode');
              $(".js-user-episode-seen").blur();
            }
          }
        }

        $(".js-user-episode-seen").on("focus", function () {
          $(this).attr("data-eps", $(this).val());
        });

        // もしepisodeは空の対策
        $(".js-user-episode-seen").on("blur", function () {
          if($(this).val() == '') {
            $(this).val(0).trigger("change");
            $(this).attr("data-eps", 0);
          }
        });

        $(".js-user-episode-seen").on("keyup", function (e) {
          if (!$userEpisode.hasClass("completed")) {
            const keycode = e.keyCode;
            switch (keycode) {
              case 27://esc
                const originNum = $(this).attr("data-eps");
                $(this).val(originNum).blur();
                break;
              case 8://backspace
                break;
              default:
                const str     = $(this).val();
                const totalID = $(this).next("span").attr("id");
                setTimeout(function () {
                  inputEpsNum(str, totalID);
                }, 500);
                break;
            }
          }
        });
      }
    }
  }

  // ---------------------------------
  // Bottom Menu
  // ---------------------------------
  if($("#bottom-menu")[0]) {
    let bottomMenuHeight = $("#bottom-menu").height();
    $("footer").css("padding-bottom", bottomMenuHeight);

    // Bottom広告(TLA)の有無
    if($(".anchor_image")[0]) {
      // Bottom Menuの高さ調整
      const anchorImageHeight = $(".anchor_image").height();
      $("#bottom-menu").css("bottom", anchorImageHeight);
      // bottomMenuの高さにBottom広告の高さを足しておく
      bottomMenuHeight += anchorImageHeight;
    }

    // scrollerの高さ調整
    $("#top_scroller").css({"bottom": `+=${bottomMenuHeight}`});

    $(window).on('load', function() {
      // Privacyボタンの高さ調整（GDPR対象国の場合表示される）
      if($(".qc-cmp2-persistent-link")[0]) {
        $(".qc-cmp2-persistent-link").css("bottom", bottomMenuHeight);
      }
      // Taboola広告が表示されている場合の高さ調整
      if($("._cm-os-slider")[0]) {
        $("._cm-os-slider").css({"cssText": "margin-bottom: 66px !important;"});
      }

      let adjustmentHeight = $("body").height() - $("#wrapper").height();
      if (adjustmentHeight) {
        bottomMenuHeight -= adjustmentHeight;
        $("footer").css("padding-bottom", bottomMenuHeight);
      }
    });
  }

  // Episode list
  if ($(".js-episode-vote-button")[0]) {
    $(".js-episode-vote-button").on("click", function() {
      Modal.generate();
      Modal.buildVoteDialog(
        $(this).data('title-id'),
        $(this).data('episode-num'),
        $('.title-name').text(),
        $(this).data('topic-id'),
        1,
        false
      );
      Modal.show();
    })
  }
})
