var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "modal-content",
            style: {
              height: _vm.browserInnerHeight
                ? _vm.browserInnerHeight + "px"
                : "100%"
            }
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.hide } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _vm.isPreview
                ? _c("div", [
                    _c("div", { staticClass: "modal-container" }, [
                      _c("p", { staticClass: "modal-head" }, [
                        _vm._v("Purchase this manga to continue reading")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-landscape-table" }, [
                        _c("div", { staticClass: "modal-landscape-left" }, [
                          _c("div", {
                            staticClass: "modal-cover",
                            class: [
                              {
                                iconNew:
                                  !_vm.nextBook.isPossessed &&
                                  _vm.nextBook.isNew
                              },
                              _vm.coverImageSize
                            ],
                            style: {
                              backgroundImage: "url(" + _vm.coverImageUrl + ")"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "modal-title",
                              class: _vm.coverImageSize
                            },
                            [_vm._v(_vm._s(_vm.nextBook.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.nextBook.isPossessed,
                                  expression: "!nextBook.isPossessed"
                                }
                              ],
                              staticClass: "modal-volume"
                            },
                            [_vm._v(_vm._s(_vm.nextBook.numberingText))]
                          ),
                          _vm._v(" "),
                          !_vm.nextBook.isPossessed &&
                          _vm.nextBook.salePrice >= 0
                            ? _c("p", { staticClass: "modal-price-wrap" }, [
                                _c(
                                  "span",
                                  { staticClass: "modal-price is-sale" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currency.formatSymbolAndPrice(
                                          _vm.nextBook.price
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "modal-price-sale" },
                                  [
                                    _vm._v(
                                      "Sale " +
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            _vm.nextBook.salePrice
                                          )
                                        )
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.nextBook.isPossessed &&
                          !(_vm.nextBook.salePrice >= 0)
                            ? _c("p", { staticClass: "modal-price-wrap" }, [
                                _c("span", { staticClass: "modal-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currency.formatSymbolAndPrice(
                                        _vm.nextBook.price
                                      )
                                    )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-landscape-right" }, [
                          _vm.nextBook.isPossessed || _vm.nextBook.isFree
                            ? _c(
                                "a",
                                {
                                  staticClass: "modal-button-read",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("read")
                                    }
                                  }
                                },
                                [_vm._v("Read")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.nextBook.isPossessed && !_vm.nextBook.isFree
                            ? _c(
                                "a",
                                {
                                  staticClass: "modal-button-back-store",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("backstore")
                                    }
                                  }
                                },
                                [_vm._v("Buy on Store")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "modal-button-back-manga",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("backmanga")
                                }
                              }
                            },
                            [_vm._v("Back to Manga page")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        { staticClass: "modal-close", on: { click: _vm.hide } },
                        [_vm._v("Close")]
                      )
                    ])
                  ])
                : _vm.isFree
                ? _c("div", [
                    _vm.customFinish.useFreeManga && _vm.customFinish.productId
                      ? _c("div", [
                          _c("div", { staticClass: "modal-container" }, [
                            _c("p", { staticClass: "modal-head" }, [
                              _vm._v("Tap the read button for "),
                              _c("br"),
                              _vm._v("next chapter")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { padding: "3%" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    float: "left",
                                    "margin-right": "2%",
                                    width: "50%"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.nextBookCoverImageUrl,
                                      width: "98%"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "13px",
                                    overflow: "hidden"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "modal-chapter" }, [
                                    _vm._v(_vm._s(_vm.nextBook.numberingText))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "1px",
                                    overflow: "hidden"
                                  }
                                },
                                [
                                  _c("div", [
                                    _vm.nextBook.onSaleAt <=
                                      _vm.currentTimestamp &&
                                    (_vm.nextBook.closeSaleAt >=
                                      _vm.currentTimestamp ||
                                      _vm.nextBook.closeSaleAt == 0)
                                      ? _c("div", [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "modal-button-read-special-small",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit("read")
                                                }
                                              }
                                            },
                                            [_vm._v("Read")]
                                          )
                                        ])
                                      : _vm.nextBook.onSaleAt > 0 &&
                                        _vm.nextBook.onSaleAt >
                                          _vm.currentTimestamp
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#9b9b9b",
                                                "font-size": "12px"
                                              }
                                            },
                                            [_vm._v("coming soon")]
                                          )
                                        ])
                                      : _vm.nextBook.closeSaleAt > 0 &&
                                        _vm.nextBook.closeSaleAt <
                                          _vm.currentTimestamp
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#9b9b9b",
                                                "font-size": "12px"
                                              }
                                            },
                                            [_vm._v("not longer free")]
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.isAdShown
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fb-page",
                                      staticStyle: { "text-align": "center" }
                                    },
                                    [
                                      _c("iframe", {
                                        staticStyle: {
                                          border: "none",
                                          overflow: "hidden"
                                        },
                                        attrs: {
                                          src:
                                            "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=300&height=200&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId",
                                          width: "300",
                                          height: "200",
                                          scrolling: "no",
                                          frameborder: "0",
                                          allowTransparency: "true"
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fb-page",
                                      staticStyle: { "text-align": "center" }
                                    },
                                    [
                                      _c("iframe", {
                                        staticStyle: {
                                          border: "none",
                                          overflow: "hidden"
                                        },
                                        attrs: {
                                          src:
                                            "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=300&height=200&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId",
                                          width: "300",
                                          height: "200",
                                          scrolling: "no",
                                          frameborder: "0",
                                          allowTransparency: "true"
                                        }
                                      })
                                    ]
                                  )
                                ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "modal-close",
                                on: { click: _vm.hide }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                      : _c("div", [
                          _vm.customFinish.url
                            ? _c("div", [
                                _c("div", { staticClass: "modal-container" }, [
                                  _c(
                                    "div",
                                    { staticClass: "iframe-container" },
                                    [
                                      _c("iframe", {
                                        attrs: {
                                          frameborder: "0",
                                          scrolling: _vm.customFinish.scroll
                                            ? "auto"
                                            : "no",
                                          src: _vm.customFinish.url
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "modal-close",
                                      on: { click: _vm.hide }
                                    },
                                    [_vm._v("Close")]
                                  )
                                ])
                              ])
                            : _vm.customFinish.productId
                            ? _c("div", [
                                _c("div", { staticClass: "modal-container" }, [
                                  _c("p", { staticClass: "modal-head" }, [
                                    _vm._v(
                                      "Purchase this manga to continue reading"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "modal-landscape-table" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "modal-landscape-left" },
                                        [
                                          _c("div", {
                                            staticClass: "modal-cover",
                                            class: [
                                              {
                                                iconNew:
                                                  !_vm.nextBook.isPossessed &&
                                                  _vm.nextBook.isNew
                                              },
                                              _vm.coverImageSize
                                            ],
                                            style: {
                                              backgroundImage:
                                                "url(" +
                                                _vm.nextBookCoverImageUrl +
                                                ")"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "modal-title",
                                              class: _vm.coverImageSize
                                            },
                                            [_vm._v(_vm._s(_vm.nextBook.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.nextBook
                                                    .isPossessed,
                                                  expression:
                                                    "!nextBook.isPossessed"
                                                }
                                              ],
                                              staticClass: "modal-volume"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.nextBook.numberingText
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.nextBook.isPossessed &&
                                          _vm.nextBook.salePrice >= 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "modal-price-wrap"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "modal-price is-sale"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currency.formatSymbolAndPrice(
                                                            _vm.nextBook.price
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "modal-price-sale"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Sale " +
                                                          _vm._s(
                                                            _vm.currency.formatSymbolAndPrice(
                                                              _vm.nextBook
                                                                .salePrice
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.nextBook.isPossessed &&
                                          !(_vm.nextBook.salePrice >= 0)
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "modal-price-wrap"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "modal-price"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currency.formatSymbolAndPrice(
                                                            _vm.nextBook.price
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "modal-landscape-right"
                                        },
                                        [
                                          _vm.nextBook.isPossessed ||
                                          _vm.nextBook.isFree
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "modal-button-read",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit("read")
                                                    }
                                                  }
                                                },
                                                [_vm._v("Read")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.nextBook.isPossessed &&
                                          !_vm.nextBook.isFree
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "modal-button-back-store",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "backstore"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Buy on Store")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "modal-button-back-manga",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit("backmanga")
                                                }
                                              }
                                            },
                                            [_vm._v("Back to Manga page")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "modal-close",
                                      on: { click: _vm.hide }
                                    },
                                    [_vm._v("Close")]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                  ])
                : _c("div", [
                    _vm.nextBook
                      ? _c("div", [
                          _c("div", { staticClass: "modal-container" }, [
                            _c("p", { staticClass: "modal-head" }, [
                              _vm._v(
                                _vm._s(_vm.book.numberingText) + " Complete"
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "modal-landscape-table" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modal-landscape-left" },
                                  [
                                    _c("div", {
                                      staticClass: "modal-cover",
                                      class: [
                                        {
                                          iconNew:
                                            !_vm.nextBook.isPossessed &&
                                            _vm.nextBook.isNew
                                        },
                                        _vm.coverImageSize
                                      ],
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          _vm.nextBookCoverImageUrl +
                                          ")"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "modal-title",
                                        class: _vm.coverImageSize
                                      },
                                      [_vm._v(_vm._s(_vm.nextBook.title))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.nextBook.isPossessed,
                                            expression: "!nextBook.isPossessed"
                                          }
                                        ],
                                        staticClass: "modal-volume"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.nextBook.numberingText)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.nextBook.isPossessed &&
                                    _vm.nextBook.salePrice >= 0 &&
                                    !_vm.banned.isBanned
                                      ? _c(
                                          "p",
                                          { staticClass: "modal-price-wrap" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "modal-price is-sale"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.currency.formatSymbolAndPrice(
                                                      _vm.nextBook.price
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "modal-price-sale"
                                              },
                                              [
                                                _vm._v(
                                                  "Sale " +
                                                    _vm._s(
                                                      _vm.currency.formatSymbolAndPrice(
                                                        _vm.nextBook.salePrice
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.nextBook.isPossessed &&
                                    !(_vm.nextBook.salePrice >= 0) &&
                                    !_vm.banned.isBanned
                                      ? _c(
                                          "p",
                                          { staticClass: "modal-price-wrap" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "modal-price" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.currency.formatSymbolAndPrice(
                                                      _vm.nextBook.price
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "modal-landscape-right" },
                                  [
                                    _vm.nextBook.isPossessed ||
                                    _vm.nextBook.isFree
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "modal-button-read",
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("read")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Read " +
                                                _vm._s(
                                                  _vm.nextBook.numberingText
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.nextBook.isPossessed &&
                                    !_vm.banned.isBanned &&
                                    !_vm.nextBook.isFree
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "modal-button-buy",
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("purchase")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Buy " +
                                                _vm._s(
                                                  _vm.nextBook.numberingText
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.nextBook.isPossessed &&
                                    _vm.nextBook.isPreviewable &&
                                    !_vm.banned.isBanned &&
                                    !_vm.nextBook.isFree
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "modal-button-trial",
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("preview")
                                              }
                                            }
                                          },
                                          [_vm._v("Preview")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.nextBook.isPossessed &&
                                    _vm.banned.isBanned &&
                                    !_vm.nextBook.isFree
                                      ? _c(
                                          "div",
                                          { staticClass: "modal-ban" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "modal-text-ban" },
                                              [
                                                _vm._v(
                                                  "This account has been restricted from making purchases."
                                                )
                                              ]
                                            ),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "modal-button-ban",
                                                attrs: {
                                                  href: _vm.banned.storeUrl
                                                }
                                              },
                                              [_vm._v("More about your ban")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "modal-close",
                                on: { click: _vm.hide }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "modal-container" }, [
                            _c("p", { staticClass: "modal-head" }, [
                              _vm._v(
                                _vm._s(_vm.book.numberingText) + " Complete"
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "modal-cover",
                              class: _vm.coverImageSize,
                              style: {
                                backgroundImage:
                                  "url(" + _vm.coverImageUrl + ")"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "modal-button-submit",
                                attrs: { href: _vm.book.storeUrl }
                              },
                              [_vm._v("Manga Store")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "modal-close",
                                on: { click: _vm.hide }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                  ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }