listの進捗状況を表示する
<template>
  <div class="info">
    <div class="title">{{ title }}</div>
    <div class="status">
      <span class="progress">
        {{ head }}:
        <span class="num">{{ completed }}</span>
      </span>
      <span class="score score-label" :class="'score-' + (score > 0 ? score : 'na')">
        <i class="fa-regular fa-star mr4"></i>{{ score }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String, // 作品名
    head: String, // 見出し
    completed: Number, // 視聴済み・既読の数
    score: Number,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
@import "../../../../css/variables";

.info {
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  @include text-shadow(#000000 1px 1px 0);
  .title {
    font-size: 1.2rem;
    font-weight: 700;
    display: inline-block;
    overflow: hidden;
    width: calc(100% - 1px);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .status {
    font-size: 1rem;
    .progress {
      float: left;
    }
    .score {
      position: relative;
      display: inline-block;
      float: right;
    }
  }
}
</style>
