<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-container">
        <div class="modal-header" @touchmove.prevent @touchmove.stop>
          <div class="modal-header-title">
            Buy Series
            <a class="modal-close" @click="cancel">close</a>
          </div>
          <div class="modal-checkbox-wrap">
            <div class="checkbox-check-all-button" @click="toggleAll">
              <span class="checkbox-wrap-text" v-show="checkedIdsNum === 0">Check all</span>
              <span class="checkbox-wrap-text" v-show="checkedIdsNum > 0">Uncheck all</span>
              <div class="checkbox-wrap checkbox-s-wrap">
                <span class="checkbox margin-l10" :class="checkAllClass"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-list" @touchmove.stop>
          <div class="modal-list-inner">
            <div class="modal-list-item" :class="{'is-hold': item.isPossessed }" v-for="item in items">
              <div>
                <img width="46" height="auto" class="modal-list-cover margin-r10"
                    :src="coverImageUrl(item.coverImageBaseUrl)"
                    :class="{'disabled': item.isPossessed}">
              </div>
              <div class="modal-list-info">
                <div class="modal-list-title">{{ item.title }}</div>
                <div class="modal-list-volume"
                     :class="{iconNew: !item.isPossessed && item.isNew}"
                >
                  {{ item.numberingText }}
                </div>
                <div class="modal-list-price" v-show="!item.isPossessed">
                  <template v-if="item.salePrice >= 0">
                    <span class="is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                    Sale <span class="modal-price-sale">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                  </template>
                  <template v-else>
                    {{ currency.formatSymbolAndPrice(item.price) }}
                  </template>
                </div>
              </div>
              <div>
                <div class="checkbox-wrap margin-l10">
                  <input type="checkbox" :disabled="item.isPossessed || item.isFree" v-model="checkedIds" :value="item.id" class="volumeCheckbox">
                  <span class="checkbox"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" @touchmove.prevent @touchmove.stop>
          <div class="modal-table">
            <div class="modal-price">
            <span>{{ currency.formatSymbolAndPrice(totalPriceWithoutTax) }}</span>{{ currency.name }}
            <br><span class="tax">(Excl. Tax)</span></div>
            <div><a class="modal-cancel-button margin-l10" @click="cancel">Cancel</a></div>
            <div><a class="modal-buy-button margin-l10" :class="{'is-disable' : checkedIdsNum === 0 }" @click="proceed">Buy</a></div>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<style lang="scss" scoped>
  $text-price-color:#C0392B;
  $text-volume-color:#7B7D82;
  $button-payment-color:#ff9600;
  $button-cancel-color:#2E51A2;
  $button-disable-color:#9B9B9B;
  $border-color:#ebebeb;
  $border-light-color:#e5e5e5;
  $border-cover-color:#E1E7F5;
  $header-height:147px;
  $modal-side-padding:10px;

  img {
    vertical-align: bottom;
  }
  .modal-container {
    font-family: Verdana,Arial;
    -webkit-text-size-adjust: 100%;
    position: fixed;
    z-index: 9998;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    top: 0;
    left: 0;
    /* ipone5でなぜかurlバーと下のメニューを高さに含めてしまうのでjavascriptで対応→結局だめ */
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: transform .5s ease, opacity .3s ease;
    will-change: transform, opacity;
    z-index: 9999;
  }

  .modal-header {
    display: block;
    border-bottom: 1px solid $border-light-color;
  }
  .modal-header-title {
    text-align: center;
    padding: 0 42px;
    background-color: #EFF4FA;
    color: #2E51A2;
    font-size: 16px;
    line-height: 32px;
  }
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url(/images/manga_store/sp/modal_close_gray@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    text-indent: -999999px;
  }
  .modal-footer {
    display: block;
    border-top: 1px solid $border-light-color;
  }
  .modal-price {
    font-weight: bold;
    font-size: 14px;
    -webkit-box-flex: 1;
    flex: 1;
    > span {
    font-size: 18px;
    }
    .tax {
      font-size: 10px;
      font-weight: 300;
    }
  }
  .modal-table {
    display: -webkit-box;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px $modal-side-padding;
    width: 100%;
    box-sizing: border-box;
  }
  %button {
    font-weight: bold;
    font-size: 14px;
    width: 67px;
    padding: 7px 0;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 16px;
    border-radius: 3px;
    &.is-disable{
      cursor: auto;
      color: $button-disable-color;
      border: 2px solid $button-disable-color;
    }
  }
  .modal-cancel-button {
    color: $button-cancel-color;
    border: 2px solid $button-cancel-color;
    @extend %button;
    &:active {
      background-color: rgba(#2E51A2, 0.2);
    }
  }
  .modal-buy-button {
    color: $button-payment-color;
    border: 2px solid $button-payment-color;
    @extend %button;
    &:active {
      background-color: rgba(#ff9600, 0.2);
    }
  }
  .modal-checkbox-wrap {
    line-height: 18px;
    height: 29px;
    padding-bottom: 1px;
    display: block;
    padding: 5px 0 0 0;
    text-align: center;
    border-top: 1px solid $border-color;
    label {
      font-size: 12px;
    }
    .checkbox-wrap-text {
      font-size: 14px;
      display: inline-block;
    }
  }
  .checkbox-check-all-button {
    display: inline-block;
  }
  .modal-list-inner {
    position: relative;
  }
  .modal-list {
    -webkit-box-flex: 1;
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .modal-list-item {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px $modal-side-padding;
    box-sizing: border-box;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    border-bottom: 1px solid $border-light-color;
    &:last-child{
      border-bottom: none;
    }
    &.is-hold {
      color: #bdbdbd;
    }
  }
  .modal-list-cover {
    box-sizing: border-box;
    border: 1px solid  $border-cover-color;
    &.disabled {
      opacity: .5;
    }
  }
  .modal-list-cover-grayscale {
    width: 46px;
  }
  .modal-list-info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    text-align: left;
  }
  .modal-list-title {
    font-size: 14px;
    font-weight: 300;
    min-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .modal-list-volume {
    padding-top: 4px;
    color: $text-volume-color;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 4px;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    box-flex: 1;
    &.iconNew {
      position: relative;
      padding-left: 36px;
      &:after {
        position: absolute;
        top: 2px;
        left: 0;
        background-color: #c0392b;
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        color: #fff;
        font-size: 10px;
        font-weight: 300;
        line-height: 1.0rem;
        content: "New";
      }
    }
    .is-hold &{
      color: #bdbdbd;
    }
  }
  .modal-list-price {
    font-size: 14px;
    color: $text-price-color;
    .is-sale {
      color: #7B7D82;
      font-size: 11px;
      font-weight: 300;
      text-decoration: line-through;
    }
    .modal-price-sale {
      font-weight: 700;
    }
  }
  .checkbox-wrap {
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    &.checkbox-s-wrap {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      margin-bottom: 5px;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    z-index: 1;
    cursor: pointer;
  }
  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    display: block;
    vertical-align: middle;
    background-size: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &.is-checked,
    input[type="checkbox"]:checked + & {
      background-image: url(/images/manga_store/pc/checkbox_on@2x.png?v=161115001);
    }
    &.is-mixed {
      background-image: url(/images/manga_store/pc/checkbox_mixed@2x.png?v=161115001);
    }
    input[type="checkbox"]:disabled + & {
      background-image: none;
      background-color: rgba(235,235,235,0.3);
      border: 1px solid #ebebeb;
      border-radius: 2px;
    }
  }
  .margin-l10 {
    margin-left: 10px;
  }
  .margin-r10 {
    margin-right: 10px;
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter.modal-container,
  .modal-leave-active.modal-container {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
  }

</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      title: String,
      items: Array,
      currency: Object,
      purchasableItems: Array,
      checkedItemIds: Array,
      totalPriceWithoutTax: Number,
      isPurchased: Boolean,
    },
    data() {
      return {
        checkedIds: this.checkedItemIds,
      }
    },
    mounted() {
      /** webkit-overflow-scrolling がios safariにて挙動がいまいちであるための対応**/
      $(window).scroll(e => {
        $(".modal-list-inner").css("top", `- ${$(e.currentTarget).scrollTop()}px`);
        e.preventDefault()
      })
    },
    watch: {
      checkedIds() {
        this.$emit('updateCheckedItemIds', this.checkedIds)
      }
    },
    computed: {
      checkAllClass() {
        return {
          "is-mixed": this.checkedIds.length > 0 && this.checkedIds.length < this.purchasableItems.length,
          "is-checked": this.checkedIds.length === this.purchasableItems.length
        }
      },
      checkedIdsNum() {
        if (this.isPurchased) {
          this.checkedIds = this.checkedItemIds
          this.$emit('updateIsPurchased', false)
        }
        return this.checkedIds.length
      }
    },
    methods: {
      toggleAll() {
        if (this.checkedIds.length === 0) {
          this.checkedIds = this.purchasableItems.map(i => i.id)
        } else {
          this.checkedIds = []
        }
      },
      proceed() {
        if (this.checkedIds.length > 0) {
          this.$emit('proceed')
        }
      },
      cancel() {
        this.$emit('cancel')
      },
      coverImageUrl(coverImageBaseUrl) {
        return generateCoverImageUrl(coverImageBaseUrl)
      },
    },
  }
</script>
