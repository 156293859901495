import $ from "jquery"
import MAL from "../../common/namespace"

let isAlreadyRendered = false

export default class Interstitial {
  constructor(slotCode, rating = "gray") {
    this.slotCode = slotCode
    this.rating = rating
  }

  renderAd() {
    if (
      !("googletag" in window)
      ||
      isAlreadyRendered === true // 一度同じページで表示していたらださない
    ) {
      return
    }

    if (!$(`#${this.slotCode}`).exists()) {
      const adContainer = $("<div></div>", {id:this.slotCode, class:"_unit"})
      $("body").append(adContainer)
    }

    const googletag = window.googletag
    googletag.pubads().disableInitialLoad()
    const self = this
    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(`/${MAL.DFP_NETWORK_CODE}/${self.slotCode}`, [1,1], self.slotCode)
      if (self.rating !== null) {
        slot.setTargeting("adult", self.rating)
      }
      slot.addService(googletag.pubads())

      googletag.display(self.slotCode)
      googletag.pubads().addEventListener("slotRenderEnded", (event) => {
        if (event.isEmpty) {
          return // 在庫がない
        }
        const unitId = event.slot.getSlotElementId();
        if (unitId === self.slotCode) {
          isAlreadyRendered = true // 広告を表示したらフラグをたてる
        }
      })
      googletag.pubads().refresh([slot])
    })
  }
}
