
export default {
  purchasableItems(state) {
    return state.items.filter(i => !i.isPossessed && !i.isFree)
  },

  checkedItems(state) {
    return state.items.filter(i => state.checkedItemIds.includes(i.id))
  },

  totalPrice(state, getters) {
    return getters.checkedItems
      .map(i => (i.totalAmount))
      .reduce((prev, cur) => prev + cur, 0)
  },

  totalPriceWithoutTax(state, getters) {
    return getters.checkedItems
      .map(i => (i.salePrice >= 0 ? i.salePrice : i.price))
      .reduce((prev, cur) => prev + cur, 0)
  },

  totalTax(state, getters) {
    return getters.checkedItems
      .map(i => (i.tax))
      .reduce((prev, cur) => prev + cur, 0)
  },
}
