const $ = window.$;
import _ from "lodash";

$(function() {
  jQuery.fn.extend({
    // パネルを開く
    openMalPanel: function () {
      const $panel = $(this);
      const rel = $panel.attr('rel');
      const $button = $(`.js-adapted-switch-button[rel=${rel}]`);
      if ($panel.hasClass('show')) return;
      $('.js-adapted-switch-button').removeClass('primary noborder pressed');
      $('.js-adapted-switch-button').addClass('secondary');
      $('.adapted-navi-panel').removeClass('show').slideUp();
      $button.removeClass('secondary');
      $button.addClass('primary noborder pressed');
      $panel.addClass('show').slideDown();
    },
    // パネルを閉じる
    closeMalPanel: function () {
      const $panel = $(this);
      const rel = $panel.attr('rel');
      const $button = $(`.js-adapted-switch-button[rel=${rel}]`);
      if (!$panel.hasClass('show')) return;
      $button.removeClass('primary noborder pressed');
      $button.addClass('secondary');
      $button.find('i.caret').removeClass('fa-caret-up');
      $button.find('i.caret').addClass('fa-caret-down');
      $panel.removeClass('show').slideUp();
    },
    // 選択ボックスの表示状態を文字列で返却する
    getMalSelectStatus: function() {
      const $button = $(this);
      if ($button.hasClass('on')) {
        return 'on';
      }
      if ($button.hasClass('selected')) {
        return 'selected';
      }
      if ($button.hasClass('crossed')) {
        return 'crossed';
      }
      return '';
    }
  });

  // パネル - 開閉ボタン
  $('.js-adapted-switch-button').on('click', function() {
    const $button = $(this);
    const rel = $button.attr('rel');
    const $panel = $(`.adapted-navi-panel[rel=${rel}]`);
    if ($panel.hasClass('show')) {
      $panel.closeMalPanel();
    }
    else {
      $panel.openMalPanel();
    }
  });
  // パネル - 変更適用ボタン
  $('.js-adapted-panel-apply').on('click', function() {
    const $button = $(this);
    const $panel = $button.closest('.adapted-navi-panel');
    if ($panel.attr('rel') == 'filter') {
      applyAdaptedFilterConfig();
    }
    if ($panel.attr('rel') == 'sort') {
      applyAdaptedSortConfig();
    }
    $panel.closeMalPanel();
  });
  // パネル - キャンセルボタン
  $('.js-adapted-panel-cancel').on('click', function() {
    const $button = $(this);
    const $panel = $button.closest('.adapted-navi-panel');
    // パネル内の選択式ボタンをデフォルト状態に戻す
    $('.js-btn-sort-check, .js-btn-sort-radio', $panel).each(function(index, data){
      const $data = $(data);
      const $icon = $data.find('i');
      // ON状態に戻す
      if ($data.hasClass('default-on')) {
        $data.addClass('primary noborder pressed on');
        $data.removeClass('secondary');
        $icon.addClass('fas fa-circle-check mr4');
      }
      // OFF状態に戻す
      else {
        $data.removeClass('primary noborder pressed on');
        $data.addClass('secondary');
        $icon.removeClass('fas fa-circle-check mr4');
      }
    });

    $panel.closeMalPanel();
  });
  // チェックボックスの切替処理
  $('.js-btn-sort-check').on('click', function() {
    const $button = $(this);
    const $icon = $button.find('i');
    if ($button.attr('rel') === 'All') {
      const $buttons = $button.closest('.navi-toolbar-group').find('.js-btn-sort-check');
      if ($button.hasClass('on')) {
        $buttons.removeClass('primary noborder pressed on');
        $buttons.addClass('secondary');
        $buttons.find('i').removeClass('fas fa-circle-check mr4');
      }
      else {
        $buttons.addClass('primary noborder pressed on');
        $buttons.removeClass('secondary');
        $buttons.find('i').addClass('fas fa-circle-check mr4');
      }
    }
    else {
      const $all = $button.closest('.navi-toolbar-group').find('.js-btn-sort-check[rel=All]');
      if ($button.hasClass('on')) {
        $all.removeClass('primary noborder pressed on');
        $all.addClass('secondary');
        $all.find('i').removeClass('fas fa-circle-check mr4');
        $button.removeClass('primary noborder pressed on');
        $button.addClass('secondary');
        $icon.removeClass('fas fa-circle-check mr4');
      }
      else {
        $button.addClass('primary noborder pressed on');
        $button.removeClass('secondary');
        $icon.addClass('fas fa-circle-check mr4');

        const $buttons = $button.closest('.navi-toolbar-group').find('.js-btn-sort-check');
        let noselect = 0;
        $buttons.each(function(index, data){
          const $data = $(data);
          if ($data.attr('rel') != 'All' && !$data.hasClass('on')) {
            noselect++;
          }
        });
        if (noselect == 0) {
          $all.addClass('primary noborder pressed on');
          $all.removeClass('secondary');
          $all.find('i').addClass('fas fa-circle-check mr4');
        }
        else {
          $all.removeClass('primary noborder pressed on');
          $all.addClass('secondary');
          $all.find('i').removeClass('fas fa-circle-check mr4');
        }
      }
    }
  });
  // 除外状態つきチェックボックスの切替処理
  $('.js-btn-sort-crossed').on('click', function() {
    const $button = $(this);
    const $icon = $button.find('i');
    if ($button.hasClass('on')) {
      $button.removeClass('danger noborder pressed on');
      $button.addClass('secondary');
      $icon.removeClass('fas fa-circle-xmark mr4');
    }
    else {
      $button.addClass('danger noborder pressed on');
      $button.removeClass('secondary');
      $icon.addClass('fas fa-circle-xmark mr4');
    }
  });
  // ラジオボタンの切替処理
  $('.js-btn-sort-radio').on('click', function() {
    const $button = $(this);
    const $buttons = $button.closest('.mal-btn-toolbar').find('.js-btn-sort-radio');
    const $icon = $button.find('i');
    if (!$button.hasClass('on')) {
      $buttons.removeClass('primary noborder pressed on');
      $buttons.addClass('secondary');
      $buttons.find('i').removeClass('fas fa-circle-check mr4');
      $button.addClass('primary noborder pressed on');
      $button.removeClass('secondary');
      $icon.addClass('fas fa-circle-check mr4');
    }
  });
  // プルダウン色変更
  const changePulldownColor = function() {
    const $button = $('.js-adapted-switch-button[rel=filter]');
    const filtered = (
      $('.js-btn-sort-finished').hasClass('on') ||
      !$('.js-btn-sort-mangalist[rel=All]').hasClass('on') ||
      !$('.js-btn-sort-animelist[rel=All]').hasClass('on')
    );
    if(filtered) {
      $button.addClass('filtered');
    } else {
      $button.removeClass('filtered');
    }
  }

  // Ajax - フィルタ設定を保存する
  const applyAdaptedFilterConfig = function() {
    $('.ajax-loader-wrapper').addClass('show');
    let data = {};
    data.filter = {};
    data.filter.finished = $('.js-btn-sort-finished').getMalSelectStatus();
    data.genre_filter = {check_ids:[], hide_ids:[]};
    data.myanimelist_filter = {check_ids: []};
    data.mymangalist_filter = {check_ids: []};

    // SPでは常にR18/Kidsを無効にする
    data.genre_filter.hide_ids.push(12);
    data.genre_filter.hide_ids.push(49);
    data.genre_filter.hide_ids.push(15);
    if ($('.js-btn-sort-animelist').length > 0) {
      $('.js-btn-sort-animelist.on').each(function(){
        data.myanimelist_filter.check_ids.push($(this).attr('rel'));
      });
    }
    if ($('.js-btn-sort-mangalist').length > 0) {
      $('.js-btn-sort-mangalist.on').each(function(){
        data.mymangalist_filter.check_ids.push($(this).attr('rel'));
      });
    }

    $.ajax({
      url: "/manga/adapted/config/sp_filter.json",
      type: "PUT",
      dataType: "html",
      data: JSON.stringify(data),
      timeout: 10000,
      success: function() {
        reloadAdaptedPage();
      },
      error: function(jqXHR) {
        $('.ajax-loader-wrapper').removeClass('show');
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        alert(errorMessages[0].message);
      },
    });
  }

  // Ajax - ソート設定を保存する
  const applyAdaptedSortConfig = function() {
    let data = {
      sort_by: $('.js-btn-sort-key.on').attr('rel'),
    };
    $('.ajax-loader-wrapper').addClass('show');

    $.ajax({
      url: "/manga/adapted/config/sort.json",
      type: "PUT",
      dataType: "html",
      data: JSON.stringify(data),
      timeout: 10000,
      success: function() {
        reloadAdaptedPage();
      },
      error: function(jqXHR) {
        $('.ajax-loader-wrapper').removeClass('show');
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        alert(errorMessages[0].message);
      },
    });
  }

  // 各種フィルター変更を伴う際のページリロード処理
  const reloadAdaptedPage = function() {
    let url = new URL(location.href);
    // 現在1ページ目の場合はリロード
    if (url.searchParams.get('page') <= 1) {
      window.location.reload();
      return;
    }
    // 現在2ページ目以降の場合は1ページ目に遷移する
    url.searchParams.delete('page');
    location.href = url;
  }

  // on load page
  if ($('body.sp.adapted')[0]) {
    // プルダウン色変更
    changePulldownColor();
  }
});
