// JavaScript Document
import $ from "jquery"

require("../../../common/pages/forum/forum")

const touchEventName = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) ? 'touchend' : 'click';

$(() => {
  jQuery.fn.extend({
    resizeTextarea: function () {
      const $textarea = $(this);
      let height = $textarea.height();
      let scheight = $textarea.get(0).scrollHeight;
      if (height < 80 || $textarea.val() == '') {
        height = 80;
      }
      $textarea.css('height', `${height}px !important`);
      $textarea.css('height', `${scheight}px !important`);
    },
  });

  // Topic一覧でのIgnoreボタン
  $(".js-ignore-topic-button").on("click", function() {
    const topicId = $(this).data("id")

    $.ajax({
      type: "POST",
      url: `/forum/${topicId}/toggle-ignore`,
      cache: false,
      data: {},
      dataType: "json",
      timeout: 10000,
      success : function (data) {
        if (data.error) {
          alert(data.error.message);
          return;
        }
        $(`#topic${topicId}`).toggle();
      },
    });
  })

  // 多重送信防止
  $('.forum-post-message').on('submit', function (event) {
    const $this = $(this)
    if ($this.data('submitted') === true) {
      event.preventDefault()
      return
    }
    $this.data('submitted', true)
  });

  $('table.poll-table').on('click', '.js-poll-option-add', function () {
    const table = $('table.poll-table');
    if (table.length == 0) return;
    const row = $('<tr class="removable"></tr>');
    const option_length = $('table.poll-table').find('tr').length;
    $(`<td>Option ${option_length}</td>`).appendTo(row);
    $('<td><input type="text" name="option_texts[]" class="inputtext" value=""></td>').appendTo(row);
    $('<td><button type="button" class="mal-btn secondary outline noborder js-poll-option-del"><i class="fa-solid fa-minus"></i></button></td>').appendTo(row);
    row.appendTo(table);
  });

  $('table.poll-table').on('click', '.js-poll-option-del', function () {
    $(this).parents('tr').remove();
  });

  $('textarea.topic-reply-text').on('focus keydown', function() {
    $(this).resizeTextarea();
  });

  // adjust combination image (for forum)
  $(".signature-image").each(function() {
    if ($(this).find("img").length <= 1) return;
    $(this).children("div").width("886").css({"text-align":"left"});
    $(this).css({"cssText": "line-height: 60% !important;", "text-align": "left"});
  });

  // Christmas Layout
  if ($(".sp.forums.christmas").exists()) {
    let timer;
    let commentId;
    let prevScroll = 0;

    $(window).scroll(function() {
      clearTimeout(timer);

      timer = setTimeout(function() {
        let nowScroll  = $(this).scrollTop();
        let scrollDiff = prevScroll - nowScroll;
        prevScroll     = nowScroll;

        $(".message").each(function(index, element) {
          commentId = $(element).attr('id');
          const rect = element.getBoundingClientRect();
          const isInView = 0 < rect.top && rect.bottom < window.innerHeight;

          if(isInView && (scrollDiff < -100 || scrollDiff > 100)) {
            const $faGift = $(`#${commentId}`).find(".fas.fa-gift");
            let iconPath = "/images/event/2022_christmas/icn_gift_forum.gif?v=" + Date.now();
            $faGift.css("visibility", "hidden");
            $faGift.before('<i class="christmas-icn-gift-forum"></i>');
            $(`#${commentId}`).find(".christmas-icn-gift-forum").css({
              "position": "absolute",
              "background-image": `url("${iconPath}")`,
              "background-position": "left top",
              "background-repeat": "no-repeat",
              "background-size": "34px auto",
              "display": "inline",
              "width": "34px",
              "height": "20px",
              "content": "''",
              "margin-top": "-4.5px",
              "margin-left": "-11px",
            });
            setTimeout(function() {
              $faGift.css("visibility", "visible");
              $faGift.siblings('.christmas-icn-gift-forum').remove();
            }, 5000);
            return false;
          }
        })
      }, 100);
    });
  }
});
