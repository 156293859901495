var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass: "tutorial-content",
            style: {
              height: _vm.browserInnerHeight
                ? _vm.browserInnerHeight + "px"
                : "100%"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "tutorial-mask",
                style: {
                  height: _vm.browserInnerHeight
                    ? _vm.browserInnerHeight + "px"
                    : "100%"
                },
                on: { click: _vm.maskClick }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isShowHowtoRead,
                        expression: "!isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-mask-header"
                  },
                  [
                    _c("div", { staticClass: "tutorial-mask-header-text" }, [
                      _vm._v("Tool bar")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tutorial-mask-gray" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isShowHowtoRead,
                        expression: "!isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-mask-footer"
                  },
                  [
                    _c("div", { staticClass: "tutorial-mask-footer-text" }, [
                      _vm._v("Tool bar")
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tutorial-wrapper" }, [
              _c("div", { staticClass: "tutorial-container" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isShowHowtoRead,
                        expression: "!isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-howto-use"
                  },
                  [
                    _c("p", [
                      _vm._v("Tap the centre of the screen "),
                      _c("br"),
                      _vm._v("to show the toolbar")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "button-howto-read",
                          on: { click: _vm.showHowtoRead }
                        },
                        [_vm._v("How to Read")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowHowtoRead,
                        expression: "isShowHowtoRead"
                      }
                    ],
                    staticClass: "tutorial-howto-read"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "Unlike English comics, Japanese manga is read right to left, top to bottom. Pages are also turned in the opposite direction."
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "button-howto-use",
                          on: { click: _vm.hideHowtoRead }
                        },
                        [_vm._v("How to Use")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button-tutorial-close",
                    on: { click: _vm.hide }
                  },
                  [_vm._v("Got it!")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }