<template>
  <button @click="click">
    <slot></slot>
  </button>
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit("click")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
button {
  background-color: #4a90e2;
  display: block;
  min-width: 120px;
  margin: 0 auto;
  padding: 8px 12px;
  font-size: 1.6rem;
  color: #fff;
  @include vendor-prefixes(border-radius, 4px);
}
</style>
