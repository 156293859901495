<template>
  <a :href="previewUrl" target="_blank">Preview</a>
</template>

<style lang="scss" scoped>
  a {
    display: block;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #2e51a2;
    border-radius: 2px;
    font-weight: 700;
    text-align: center;
    text-decoration: none !important;
    font-size: 16px;
    background-color: #ffffff;
    display: inline-block;
    font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;    
    min-width: 92px;
    &:hover {
      text-decoration: none !important;
      background-color: rgba(#2e51a2, 0.2);
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      previewUrl: String,
    }
  }
</script>
