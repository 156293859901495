<template>
  <div class="root">
    <transition name="modal">
      <div
        v-show="show"
        class="modal-content"
        :style="{height: ( browserInnerHeight ? browserInnerHeight + 'px' : '100%' ) }">
        <div class="modal-mask" @click="hide"></div>
        <div class="modal-wrapper">
          <div v-if="isPreview">
            <div class="modal-container">
              <p class="modal-head">Purchase this manga to continue reading</p>
              <div class="modal-landscape-table">
                <div class="modal-landscape-left">
                  <div class="modal-cover"
                    :style="{backgroundImage: `url(${coverImageUrl})`}"
                    :class="[{iconNew: !nextBook.isPossessed && nextBook.isNew}, coverImageSize]"
                  ></div>
                  <p class="modal-title"
                    :class="coverImageSize"
                  >{{ nextBook.title }}</p>
                  <p v-show="!nextBook.isPossessed" class="modal-volume">{{ nextBook.numberingText }}</p>

                  <p v-if="!nextBook.isPossessed && nextBook.salePrice >= 0" class="modal-price-wrap">
                    <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                    <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                  </p>
                  <p v-if="!nextBook.isPossessed && !(nextBook.salePrice >= 0)" class="modal-price-wrap">
                    <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                  </p>
                </div>
                <div class="modal-landscape-right">
                  <a v-if="nextBook.isPossessed || nextBook.isFree" class="modal-button-read" @click="$emit('read')">Read</a>
                  <a v-if="!nextBook.isPossessed && !nextBook.isFree" class="modal-button-back-store" @click="$emit('backstore')">Buy on Store</a>
                  <a class="modal-button-back-manga" @click="$emit('backmanga')">Back to Manga page</a>
                </div>
              </div>
              <a class="modal-close" @click="hide">Close</a>
            </div>
          </div>
          <div v-else-if="isFree">
            <div v-if="customFinish.useFreeManga && customFinish.productId">
              <div class="modal-container">
                <p class="modal-head">Tap the read button for <br />next chapter</p>
                <div style="padding:3%;">
                    <div style="float:left;margin-right:2%;width:50%;">
                        <img :src="nextBookCoverImageUrl" width="98%">
                    </div>
                    <div style="padding-top:13px;overflow: hidden;"> 
                        <span class="modal-chapter">{{ nextBook.numberingText }}</span>
                    </div>
                    <div style="padding-top:1px;overflow: hidden;"> 
                        <div>
                          <div v-if="nextBook.onSaleAt <= currentTimestamp && (nextBook.closeSaleAt >=currentTimestamp || nextBook.closeSaleAt == 0) ">
                            <a class="modal-button-read-special-small" @click="$emit('read')">Read</a>
                          </div>
                          <div v-else-if="nextBook.onSaleAt > 0 && nextBook.onSaleAt > currentTimestamp">
                            <span style="color:#9b9b9b;font-size:12px;">coming soon</span>
                          </div>
                          <div v-else-if="nextBook.closeSaleAt > 0 && nextBook.closeSaleAt < currentTimestamp">
                            <span style="color:#9b9b9b;font-size:12px;">not longer free</span>
                          </div>
                        </div>
                    </div>
                </div>
                <div v-if="isAdShown">
                    <div class="fb-page" style="text-align:center;">
                      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=300&height=200&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" width="300" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
                    </div>
                </div>
                <div v-else>
                    <div class="fb-page" style="text-align:center;">
                      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOfficialMyAnimeList&tabs&width=300&height=200&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId" width="300" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
                    </div>
                </div>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
            <div v-else>
                <div v-if="customFinish.url">
                  <div class="modal-container">
                    <div class="iframe-container">
                      <iframe frameborder="0" :scrolling="customFinish.scroll ? 'auto' : 'no'" :src="customFinish.url"></iframe>
                    </div>
                    <a class="modal-close" @click="hide">Close</a>
                  </div>
                </div>
                <div v-else-if="customFinish.productId">
                  <div class="modal-container">
                    <p class="modal-head">Purchase this manga to continue reading</p>
                    <div class="modal-landscape-table">
                      <div class="modal-landscape-left">
                        <div class="modal-cover"
                             :style="{backgroundImage: `url(${nextBookCoverImageUrl})`}"
                             :class="[{iconNew: !nextBook.isPossessed && nextBook.isNew}, coverImageSize]"
                        ></div>
                        <p class="modal-title"
                           :class="coverImageSize"
                        >{{ nextBook.title }}</p>
                        <p v-show="!nextBook.isPossessed" class="modal-volume">{{ nextBook.numberingText }}</p>

                        <p v-if="!nextBook.isPossessed && nextBook.salePrice >= 0" class="modal-price-wrap">
                          <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                          <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                        </p>
                        <p v-if="!nextBook.isPossessed && !(nextBook.salePrice >= 0)" class="modal-price-wrap">
                          <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                        </p>
                      </div>
                      <div class="modal-landscape-right">
                        <a v-if="nextBook.isPossessed || nextBook.isFree" class="modal-button-read" @click="$emit('read')">Read</a>
                        <a v-if="!nextBook.isPossessed && !nextBook.isFree" class="modal-button-back-store" @click="$emit('backstore')">Buy on Store</a>
                        <a class="modal-button-back-manga" @click="$emit('backmanga')">Back to Manga page</a>
                      </div>
                    </div>
                    <a class="modal-close" @click="hide">Close</a>
                  </div>
                </div>
            </div>
          </div>
          <div v-else>
            <div v-if="nextBook">
              <div class="modal-container">
                <p class="modal-head">{{ book.numberingText }} Complete</p>
                <div class="modal-landscape-table">
                  <div class="modal-landscape-left">
                    <div class="modal-cover"
                      :style="{backgroundImage: `url(${nextBookCoverImageUrl})`}"
                      :class="[{iconNew: !nextBook.isPossessed && nextBook.isNew}, coverImageSize]"
                    ></div>
                    <p class="modal-title"
                      :class="coverImageSize"
                    >{{ nextBook.title }}</p>
                    <p v-show="!nextBook.isPossessed" class="modal-volume">{{ nextBook.numberingText }}</p>

                    <p v-if="!nextBook.isPossessed && nextBook.salePrice >= 0 && !banned.isBanned" class="modal-price-wrap">
                      <span class="modal-price is-sale">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                      <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(nextBook.salePrice) }}</span>
                    </p>
                    <p v-if="!nextBook.isPossessed && !(nextBook.salePrice >= 0) && !banned.isBanned" class="modal-price-wrap">
                      <span class="modal-price">{{ currency.formatSymbolAndPrice(nextBook.price) }}</span>
                    </p>
                  </div>
                  <div class="modal-landscape-right">
                    <a v-if="nextBook.isPossessed || nextBook.isFree"
                       class="modal-button-read" @click="$emit('read')">Read {{ nextBook.numberingText }}</a>
                    <a v-if="!nextBook.isPossessed && !banned.isBanned && !nextBook.isFree"
                       class="modal-button-buy" @click="$emit('purchase')">Buy {{ nextBook.numberingText }}</a>
                    <a v-if="!nextBook.isPossessed && nextBook.isPreviewable && !banned.isBanned && !nextBook.isFree"
                       class="modal-button-trial" @click="$emit('preview')">Preview</a>
                    <div v-if="!nextBook.isPossessed && banned.isBanned && !nextBook.isFree" class="modal-ban">
                      <span class="modal-text-ban">This account has been restricted from making purchases.</span><br>
                      <a class="modal-button-ban" :href="banned.storeUrl">More about your ban</a>
                    </div>
                  </div>
                </div>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
            <div v-else>
              <div class="modal-container">
                <p class="modal-head">{{ book.numberingText }} Complete</p>
                <div class="modal-cover"
                  :style="{backgroundImage: `url(${coverImageUrl})`}"
                  :class="coverImageSize"
                ></div>
                <a class="modal-button-submit" :href="book.storeUrl">Manga Store</a>
                <a class="modal-close" @click="hide">Close</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
    -webkit-text-size-adjust: none;
  }
  .modal-mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    position: relative;
    width: 90%;
    max-width: 340px;
    margin: 0 auto;
    padding: 20px 20px 30px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-close{
    position: absolute;
    top: - 20px;
    right: - 35px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(/images/manga_store/viewer/sp/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }
  .modal-head {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 1.0rem;
    margin: 0 0 12px;
  }
  .modal-cover {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    display: block;
    width: 90px;
    height: 135px;
    margin: 0 auto;
    box-sizing: border-box;
    &.iconNew:after {
      position: absolute;
      top: 1px;
      left: 1px;
      background-image: url('/images/manga_store/icon_new.png');
      background-size: 47px 47px;
      background-position: left top;
      background-repeat: no-repeat;
      display: inline-blockl;
      width: 47px;
      height: 47px;
      content: "";
    }
    &.small {
      width: 45px;
      height: 67px;
      &.iconNew:after {
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
      }
    }
    &.medium {
      width: 56px;
      height: 84px;
      &.iconNew:after {
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
      }
    }
    &.large {
      width: 97px;
      height: 145px;
    }

  }
  .modal-title {
    width: 100%;
    display: block;
    margin: 8px auto 0;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
  }
  .modal-volume {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;
  }
  .modal-price-wrap {
    margin: 4px auto 0;
    font-size: 16px;
    text-align: center;
    color: #C0392B;
    font-weight: 700;
  }
  .modal-price {
    &.is-sale {
      color: #323232;
      font-size: 14px;
      font-weight: 300;
      text-decoration: line-through;
    }
  }
  .modal-button-submit,
  .modal-button-read {
    display: block;
    width: 164px;
    height: 40px;
    margin: 15px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2e51a2;
    color: #2e51a2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
    &:active {
      background-color: rgba(#2e51a2, 0.2);
    }
  }
  .modal-button-buy {
    display: block;
    width: 164px;
    height: 40px;
    margin: 8px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #FF9600;
    color: #FF9600;
    border-radius: 3px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    &:active {
      background-color: rgba(#ff9600, 0.2);
    }
  }
  .modal-button-back-store,
  .modal-button-back-manga {
    display: block;
    width: 200px;
    margin: 8px auto 0;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 3px;
    text-align: center;
    font-weight: 700;
    padding: 10px 12px;
  }
  .modal-button-back-store {
    background-color: #ee8e05;
    &:active {
      background-color: rgba(#ee8e05, 0.8);
    }
  }
  .modal-button-back-manga {
    background-color: #2e51a2;
    &:active {
      background-color: rgba(#2e51a2, 0.8);
    }
  }
  .modal-button-trial {
    display: block;
    width: 164px;
    height: 40px;
    margin: 15px auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2E51A2;
    color: #2E51A2;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    &:active {
      background-color: rgba(#2e51a2, 0.2);
    }
  }

  .modal-text-ban {
    display: inline-block;
    color: #ff6a86;
    text-indent: 0;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    line-height: 1.2rem;
  }
  .modal-button-ban {
    display: block;
    width: 164px;
    height: 40px;
    margin: 0 auto 0;
    background-color: #fff;
    box-sizing: border-box;
    border: 2px solid #2E51A2;
    color: #2E51A2;
    font-size: 14px;
    border-radius: 3px;
    line-height: 36px;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
  }


  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .iframe-container{
    width: 100%;
    height: 229px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  iframe{
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }

  @media only screen and (max-width : 320px) and (max-height : 480px) {
    .modal-container {
      padding: 10px;
    }
    .modal-head{
      font-size: 12px;
      margin-bottom: 10px;
    }
    .modal-title{
      font-size: 12px;
      margin-top: 8px;
    }
    .modal-volume {
      font-size: 12px;
      margin: 4px auto;
    }
    .modal-price{
      font-size: 12px;
      margin: 4px auto;
    }
  }
  @media only screen and (orientation : landscape) {
    .modal-head{
      font-size: 12px;
      margin: 0 0 5px;
    }
    .modal-container {
      max-width: 400px;
      padding: 10px;
    }
    .modal-landscape-table{
      display: table;
    }
    .modal-landscape-left{
      display: table-cell;
      width: 55%;
      vertical-align: top;
      padding-top: 0;
    }
    .modal-landscape-right{
      display: table-cell;
      vertical-align: top;
    }
    .modal-head{
      font-size: 12px;
      margin-bottom: 10px;
    }
    .modal-title{
      font-size: 12px;
      margin-top: 4px;
      &.small,
      &.medium {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .modal-volume{
      font-size: 12px;
      margin: 4px auto 0;
    }
    .modal-price {
      font-size: 12px;
      margin: 4px auto 0;
    }
    .modal-text-ban {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 1.0rem;
    }
    .iframe-container{
      height: 161px;
    }

  }
  .modal-chapter {
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    font-weight: bold;
  }
  .modal-button-read-special-small {
    display: block;
    width: 90px;
    height: 32px;
    margin: 5px auto 0;
    background-color: #5675C2;
    color: #fff;
    border: 2px solid #5675C2;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: rgba(#2e51a2, 0.2);
    }
    float:left;
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import {generateCoverImageUrl} from "../../../../../components/manga_store/common/modules/book"
  import * as consts from "../../common/constants"

  export default {
    props: {
      show: Boolean,
      book: Object,
      nextBook: Object,
      currency: Object,
      isPreview: Boolean,
      isFree: Boolean,
      isAdShown: Boolean,
      currentTimestamp: String,
      customFinish: Object,
      browserInnerHeight: Number,
      banned: Object,
    },
    computed: {
      coverImageUrl() {
        return generateCoverImageUrl(this.book.coverImageBaseUrl)
      },
      nextBookCoverImageUrl() {
        return generateCoverImageUrl(this.nextBook.coverImageBaseUrl)
      },
      coverImageSize() {
        return this.browserInnerHeight < 300 ? "small" : ( this.browserInnerHeight < 400 ? "medium" : "large" )
      }
    },
    watch: {
      show() {
        if (this.show) {
          this.$store.dispatch(types.LOG, {
            type: consts.LOG_TYPE_FINISH_MODAL,
            payload: {
              preview: this.isPreview,
              next: !!this.nextBook,
              possess: this.nextBook ? !!this.nextBook.isPossessed : false,
            }
          })
        }
      }
    },
    methods: {
      hide() {
        this.$store.commit(types.HIDE_FINISH_MODAL)
      },
    },
  }
</script>
