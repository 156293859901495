require("blockadblock")
import Log from "../../common/modules/log"
import $ from "jquery"

function log(unitCount) {
  Log.actionLog("adblock", {uc: unitCount})
}

window.blockAdBlock.on(true, () => {
  const $units = $("div._unit")
  // Adblock使用ユーザ数を出すためのログ
  log($units.length)
})
