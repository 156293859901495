作品情報を表示する
<template>
  <div class="list-item">
    <div class="image">
      <status-colored-image :title="title" :work-type="workType" :image="image" :path="path" :statusId="userStatusId">
      </status-colored-image>
    </div>
    <div class="info">
      <div class="edit-icon" v-if="isOwner">
        <edit-icon @edit-list-item="showModal"></edit-icon>
      </div>
      <a :href="path" class="title">{{ title }}</a>
      <div class="stats">
        <progress-stats v-if="workType === 'anime'" head="Episodes" :total="totalEpisodes" :completed="watchedEpisodes"></progress-stats>
        <progress-stats v-if="workType === 'manga'" head="Volumes" :total="totalVolumes" :completed="readVolumes"></progress-stats>
        <progress-stats v-if="workType === 'manga'" head="Chapters" :total="totalChapters" :completed="readChapters"></progress-stats>
        <span v-if="reDoing" class="re-doing">
          {{ doing }}
        </span>
        <span v-if="status" class="status">
          {{ status }}
        </span>
      </div>
    </div>
  </div><!-- .list-item -->
</template>

<script>
import {AnimeStatusMap, MangaStatusMap} from "../../../utils/list"
import StatusColoredImage from "../atoms/StatusColoredImage.vue"
import EditIcon from "../atoms/EditIcon.vue"
import ProgressStats from "../atoms/ProgressStats.vue"

export default {
  props: {
    id: Number, // anime or manga id
    path: String, // 作品詳細へのpth
    workType: String, // anime or manga
    userStatusId: Number, // listについてのユーザのstatus
    statusId: Number, // 作品のstatus
    score: Number,
    title: String, // 作品名
    image: String, // 作品画像へのpath
    watchedEpisodes: Number, // 視聴済みepisode数
    totalEpisodes: Number, // 作品の総episode数
    readVolumes: Number, // 既読巻数
    totalVolumes: Number, // 作品の総巻数
    readChapters: Number, // 既読話数
    totalChapters: Number, // 作品の総話数
    reDoing: Boolean, // re-watching or re-readingかどうか
    isOwner: Boolean, // アクセスした人がlistのOwnerかどうか
  },
  computed: {
    doing() {
      return this.workType === "anime" ? "Re-watching" : "Re-reading"
    },
    status() {
      if (this.statusId !== 3) {
        return
      }
      // not yet aired or published
      return this.workType === "anime" ? AnimeStatusMap[this.statusId] : MangaStatusMap[this.statusId]
    },
  },
  methods: {
    showModal() {
      this.$emit("edit-list-item")
    },
  },
  components: {
    StatusColoredImage, EditIcon, ProgressStats,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
.list-item {
  width: calc(100% - 8px - 8px);
  margin: 0 auto;
  padding-bottom: 4px;
  display: table;

  .image {
    width: 50px;
    display: table-cell;
    vertical-align: top;
  }

  .info {
    display: table-cell;
    vertical-align: top;

    .edit-icon {
      float: right;
      display: inline-block;
    }

    .title {
      font-weight: 700;
      font-size: 1.4rem;
    }

    .stats {
      font-size: 1.2rem;
      color: #7A7A7A;
      padding-top: 4px;

      .re-doing, .status {
        background-color: #ebebeb;
        display: inline-block;
        font-size: 1.2rem;
        color: #7a7a7a;
        padding: 2px 6px;
        margin {
          top: 4px;
          right: 8px;
        }
        @include vendor-prefixes(border-radius, 12px);
      }
    }
  }
}
</style>
