const $=require("jquery");
import _ from "lodash"
import Cookies from "js-cookie"
const Log = require("../../common/modules/log");

$(document).ready(function () {

  // for ":active"
  document.addEventListener("touchstart", function() {}, false);

  // get size
  const getDimention = function () {
    const data = {
      docW : $(document).width(),
      docH : $(document).height(),
      winW : $(window).width(),
      winH : $(window).height(),
      sclV : $(window).scrollTop()
    };
    return data;
  };

  // getting user agent
  // あとでmodule化する
  const ua = {};
  ua.data = window.navigator.userAgent.toLowerCase();

  ua.iPhone = ua.data.indexOf("iphone") >= 0;
  ua.iPod = ua.data.indexOf("ipod") >= 0;
  ua.iPad = ua.data.indexOf("ipad") >= 0;
  ua.iOS = (ua.iPhone || ua.iPod || ua.iPad);
  ua.android = ua.data.indexOf("android") >= 0;
  ua.tablet = (ua.iPad || (ua.android && ua.data.indexOf("mobile") < 0));

  if (ua.iOS) {
    ua.vAry = /(os)\s([0-9]{1,})([\_0-9]{1,})/.exec(ua.data);
    if (ua.vAry) {
      ua.version = parseInt(ua.vAry[2], 10);
    }
  }
  if (ua.android) {
    ua.vAry = /(android)\s([0-9]{1,})([\.0-9]{1,})/.exec(ua.data);
    if (ua.vAry) {
      ua.version = parseInt(ua.vAry[2], 10);
    }
  }
  if (ua.data.indexOf("firefox") >= 0) {
    // firefox
    ua.browser = "firefox";
  } else if (ua.data.indexOf("opera") >= 0) {
    // opera
    ua.browser = "opera";
  } else if (ua.data.indexOf("chrome") >= 0) {
    // chrome
    ua.browser = "chrome";
  } else if (ua.data.indexOf("android") >= 0) {
    // android default
    ua.browser = "android";
  }

  // dropdown menu for jump to select
  $(".js-dropdown-jump select").on("change", function() {
    const url = $(this).val();
    const $option = $("option:selected", this);
    if ($option.hasClass('newtab')) {
      window.open(url);
      return;      
    }
    location.href = url;
  });
  //　sub menuを出すボタン
  $(".js-btn-submenu").on("click", function() {
    $(this).toggleClass("on");
    $(".js-sub-menu").toggle();
  });

  $("input[type=\"text\"]").on("focus", function() {
    const id = $(this).attr("id");
    if ( id !== "topSearchText" ) {
      $("#header").css({position:"absolute"});
    }
  }).on("blur", function() {
    $("#header").css({position:"fixed"});
  });
  $("input[type=\"password\"]").on("focus", function() {
    $("#header").css({position:"absolute"});
  }).on("blur", function() {
    $("#header").css({position:"fixed"});
  });

  // close Dialog
  const closeDialog = function () {
    $("#loading .circleAnim").removeClass("circleAnim2");
    $("#loading").hide();
    $("#fieldcontain").remove();// remove lodash.js template
    $("#dialog_pop").empty();
    $(window).off(".noScroll");
    $("#dialog_overlay").css("display", "none");
    return false;
  }

  // make link in link (Link in a tag)
  $(".btn-inner-url").on("click", function() {
    const URL = $(this).data("url");
    if (URL) {
      window.location.href = URL;
    }
    return false;
  });

  // Youtube
  function resize_youtube() {
    const aspect = 0.835; // 355/425;
    $("iframe.movie.youtube").each(function() {
      const $el = $(this);
      const w = $el.parent().width();
      let orginW = $el.attr("width");
      let orginH = $el.attr("height");
      if (!$el.data("json")) {
        $el.data("json",{width: orginW, height: orginH});
      } else {
        orginW = $el.data("json").width;
        orginH = $el.data("json").height;
      }
      let h = 0;
      orginW = parseInt(orginW);
      orginH = parseInt(orginH);
      if (!(orginW == 425 && orginH == 355)) {
        h = Math.floor(w * orginH / orginW);
      } else {
        h = Math.floor(w * aspect);
      }
      if (orginW < w) {
        $el.attr({width: orginW, height: orginH});
      } else {
        $el.attr({width: w, height: h});
      }
    });
  }
  // YouTube movies exist in forum, etc.
  resize_youtube();

  // newsImgSizeChange
  const newsImgSizeChange = function() {
    const imgW = $(".news.news-detail .news-content-body").width();
    let winW = $(window).width();
    const winH = $(window).height();
    winW = (winW < winH) ? winW : winH;
    const halfSize = Math.floor(winW / 2);

    $.each($(".news-detail .news-content-body .userimg"), function () {
      const img = new Image();
      img.src = $(this).attr("src");
      if (img.width < halfSize) {
        $(this).css({width:img.width});
        if ($(this).hasClass("img-a-l")) {
          $(this).css({"float":"left","padding-right":"8px"});
        } else if ($(this).hasClass("img-a-r")) {
          $(this).css({"float":"right","padding-left":"8px"});
        }
      } else {
        $(".news.news-detail .news-content-body .userimg").width(imgW);
      }
    });
  }
  if ($(".news.news-detail .news-content-body")[0]) {
    newsImgSizeChange();
    $(window).scroll(function() {
      $.each($(".news-detail .news-content-body .userimg"), function() {
        if ($(this).width() === 0) {
          newsImgSizeChange();
        }
      });
    });
  }
  // orient action change
  // android chrome: orientationchange event -> (resize window) -> resize event
  // iOS 8: (resize window) -> resize event -> orientationchange event
  // Must set interval for Android
  $(window).on("resize orientationchange", function(e) {
    if (e.type == "orientationchange") {
      if ($(".news.news-detail .news-content-body")[0]) {
        const ci_newsImgSizeChange = setTimeout(function() {
          newsImgSizeChange();
          clearInterval(ci_newsImgSizeChange);
        },500);
      }
      if ($(".js-detail-video")[0]) {
        const ci_pvImgSizeChange = setTimeout(function() {
          resize_youtube();
          clearInterval(ci_pvImgSizeChange);
        },500);
      }
    }
  });

  // back to previous page from search result
  const ref = document.referrer;
  $("#search-box .btn-close-back").on("click", function() {
    window.location.href = ref;
  });
  $(".header .btn-close-back").on("click", function() {
    window.location.href = ref;
  });

  // Simply validate and feedback
  // for browsers not supporting html5 form attributes.
  $("#searchBar").on("submit", function(e) {
    const $text = $(this).find("#topSearchText");
    if ($text.val().length < (+$text.attr("minlength"))) {
      e.preventDefault();
      $text.css("background-color", "#fae3e3");
      $text.on("touchstart", function() {
        $text.css("background-color", "#fff");
      });
    }
  });

  // -----------------
  // expand Read More
  // -----------------
  // add Readmore button
  const add_readmore = () => {
    $(".trunc-text").each(function() {
      const truncH      = $(this).height()
      const truncInnerH = $(this).children(".trunc-inner").height()
      const id = $(this).attr("id")

      if (truncH === 0 || $(this).data("didSetup")) {
        return
      }

      if (truncH + 50 < truncInnerH) {
        $(this).addClass("more")
        const btnHTML = _.template(`<div class="ar mr12"><span class="btn-read-more js-btn-read-more btn-t1 di-ib fn-blue1 fw-b fs16 lh20 ga-click" data-ga-click-type="read-more" data-id="<%- id %>">Read More</span></div>`)({id: id})
        $(this).after(btnHTML)

        // open close block
        $(`.js-btn-read-more[data-id=${id}]`).on("click",function () {
          const tID = $(this).data("id")
          const rText = $(`.${tID}`)

          const init_truncH  = $(`#${tID}`).height()
          const truncH       = $(`#${tID} .trunc-inner`).height()

          $(`#${tID}`).toggleClass("more")

          if ( $(this).hasClass("open") ) {
            $(this).removeClass("open").text("Read More")
            $(`#${tID}`).toggleClass("open").css({"max-height": `${$(this).data("height")}px`})
            $(this).data("ga-click-type", "read-more")
          }else{
            $(this).attr("data-height",init_truncH)
            $(`#${tID}`).toggleClass("open").css({"max-height": `${truncH}px`})
            $(this).addClass("open").text("Close")
            $(this).data("ga-click-type", "read-more-close")
          }

          //resize ovrelay object over Contents
          const chkClass = $(`#${tID}`).hasClass("open")
          const posNeg = (chkClass)? 1 : -1
          const overLayerHeight = $(".content-over").height()
          $(`#${tID}`).on("resize",function() {
            const contentH = posNeg*(truncH-140) + overLayerHeight
            $(".content-over").css({height:`${contentH}px`})
          })
        })
      } else {
        $(this).css({"max-height": "inherit"})
      }
      $(this).data("didSetup", true)
    })
  }

  if (document.querySelectorAll(".trunc-text").length > 0) {
    add_readmore()
  }


  // show password in login form
  if ($(".page_password_login #show-password-sp")[0]) {
    $(".page_password_login #show-password-sp").on("click", function() {
      const $password    = $(".page_password_login #password");
      const password_str = $password.val();
      const type = ($password.attr("type") === "text") ? "password" : "text";
      const $new_password = $("<input>");
      $new_password.attr("type", type);
      $new_password.attr("value", password_str);
      _.each(["id", "class", "name", "size", "maxlength", "tabindex", "autocomplete", "placeholder"], function (value, key) {
        const attr = $password.attr(value);
        if (attr) {
          $new_password.attr(value, $password.attr(value));
        }
      });
      $password.before($new_password);
      $password.remove();
      $(this).toggleClass("checked");
    });
  }

  // checkbox for cookie in login
  if ($(".label-checkbox")[0]) {
    $(".label-checkbox").on("click", function() {
      let cookie_val = parseInt($("input#cookie").val());
      cookie_val = (cookie_val === 0) ? 1 : 0;
      $(".label-checkbox").toggleClass("on");
      $("input#cookie").val(cookie_val);
    });
  }

  // checkbox in login (general)
  if ($(".icon-label-checkbox")[0]) {
    $(".icon-label-checkbox").on("click", function() {
      const labelID = $(this).data("label");
      $(this).toggleClass("on");
      const labelValue = ($(this).hasClass("on")) ? 1 : 0;
      $(`input#${labelID}`).val(labelValue);
    });
  }

  // anime detail open/close button
  if ($(".js-btn-toggle-detail")[0]) {
    $(".js-btn-toggle-detail").on("click", function() {
      const cID = $(this).data("id");
      $(`#${cID}`).slideToggle();
      $(this).toggleClass("open");
    });
  }

  //diaable keyboard in dialog with textarea
  if ($(".report-dialog")[0]) {
    $(".dialog-btn-close").on("touchstart", function() {
      $(".dialog-textarea").prop("readonly",true);
    });
    $(".dialog-textarea").on("touchstart", function() {
      $(this).prop("readonly",false);
    });
  }

  // input comment
  if ($(".comment-textarea")[0]) {
    if (ua.android) {
      $(".btn-submit").on("touchstart", function() {
        $(".comment-textarea textarea").prop("readonly", true);
      });
      $(".comment-textarea textarea").on("touchstart", function() {
        $(this).prop("readonly", false);
      });
    }
  }

  //helpful button in forum
  if ( $(".btn-helpful")[0]) {
    const checkHelpful = function(num,id) {
      const helpfulNum = $(`#${id} .current-helpful-number`);
      const cNum = helpfulNum.text();
      //write something for submit something to DB
      if (num > 0) {
        $(`#${id} .current-helpful-number`).text(parseInt(cNum) + parseInt(num)).addClass("fn-grey2");
      }
      $(`.bn-block-${id} span`).off("click").removeClass("btn-helpful");
    }

    $(".btn-helpful").on("click",function() {
      const addHelpfulNum = $(this).data("helpful");
      const addHelpfulID = $(this).data("helpful-id");
      $(this).addClass("fn-grey2");
      checkHelpful(addHelpfulNum,addHelpfulID);
    });
  }

  // hide header
  const SITE_HEADER = $("#header");
  $( "body" ).delegate( "textarea", "focus", function() {
    SITE_HEADER.css({position:"absolute"});
  });
  $( "body" ).delegate( "textarea", "blur", function() {
    SITE_HEADER.css({position:"fixed"});
  });
  // show header
  $(window).scroll(function() {
    if(SITE_HEADER.css("display") == "none") {
      $(window).on({
        touchmove: function() {
          if ($(this).scrollTop() < 45) {
            $("textarea").blur();
            $("#header").css({position:"fixed"});
          }
        },
      });
    } else {
      $(window).off("touchstart touchmove touchend");
    }
  });


  // overlay_dialog(黒背景)
  const overlay_dialog = function(type, param) {
    if (param) {
      var addClass = param.class;
    }
    const dimention = getDimention();
    const docH = dimention.docH;
    const sclV = dimention.sclV;

    const $dialog_overlay = $("#dialog_overlay");
    $dialog_overlay.css({height: docH, display: "block", opacity: 0.8}).addClass(addClass).hide().fadeIn("fast", function() {
      const dlgH = $("#dialog_pop").height();
      $("#dialog_pop").removeClass("dialog_edit_list");
      const class_edit_list = (type == "add") ? "dialog_edit_list" : "";
      if (type != "search") {
        if ($("#dialog_pop #fieldcontain").hasClass("posMiddleCenter")) {
          const marginTopVal = Math.floor(-1 * (dlgH / 2));
          $("#dialog_pop").css({"position": "fixed", "top": "50%", "margin-top": marginTopVal, "display": "block"}).addClass(class_edit_list).show();
        } else {
          const topPosV = (dlgH < 150) ? 130 + sclV : 40 + sclV;
          $("#dialog_pop").css({"top": `${topPosV}px`, "margin-top": "inherit", "display": "block"}).addClass(class_edit_list).show();
        }
        if (type != "ad_dialog") {
          $dialog_overlay.on("click", function() {
            closeDialog();
            if (type == "easy2add") {
              getTmpList(function(tmp_list) {
                if (tmp_list.length > 0) {
                  let url = new URL(location.href);
                  url.searchParams.set('tmp_list','2');
                  location.href = url;
                }
              });
            }
          });
        }
      }
    });

    $(window).on("touchmove.noScroll", function(e) {
      e.preventDefault();
    });
  }

  // function for showing software keyboard
  $("input[type=text], input[type=password], textarea").on("blur", function() {
    window.scrollBy(0, 1);
    setTimeout(function() {
        window.scrollBy(0, -1);
    }, 100);
  });

  // zoom youtube
  if ($(".js-fancybox-video")[0]) {
    $(".js-fancybox-video").on("click", function(e) {
      e.preventDefault();
      const $obj = $(this);
      let id = $obj.data("id");
      if (!id) {
        const embedUrl = $("<a>", {href: $(this).attr("href")})[0];
        id = embedUrl.pathname.replace("/embed/", "");
      }
      const dimention = getDimention();
      const docH = dimention.docH;
      const winW = dimention.winW;
      const dialogObj = $("#dialog_overlay");

      dialogObj.css({height: docH, display: "block", opacity: 0.8}).hide().fadeIn("fast", function() {
        const dlgW = $("#dialog_pop").width();
        const dlgH = $("#dialog_pop").height();
        const marginTopVal = -1 * (Math.floor(dlgH / 2));

        $("#dialog_pop").css({
          "position": "fixed",
          "top": "50%",
          "left": "50%",
          "margin-top": marginTopVal,
          "margin-left": -1 * (Math.floor(winW / 2)),
          "display": "block",
          "width": "100%"
        }).show();

        dialogObj.on("touchend click", function() {
          closeDialog();
          return false;
        });
      });

      dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
        e.preventDefault();
      });

      $(window).on("touchmove.noScroll", function(e) {
        e.preventDefault();
      });

      const sizeJSON = $obj.data("json");
      const orginW = (sizeJSON) ? parseInt(sizeJSON.width) : 560;
      const orginH = (sizeJSON) ? parseInt(sizeJSON.height) : 315;
      let w = winW;
      let h = Math.floor(w * orginH / orginW);
      if (orginW < w) {
        w = orginW;
        h = orginH;
      }

      const template = _.template($("#option_template_youtube").text());
      $("#dialog_pop").append(template({id: id, w: w, h: h}))

      $("#dialog_pop").on("touchend", function() {
        $("#dialog-youtube-pop").remove();
        $(this).css("display", "none");
        $("#dialog_overlay").css("display", "none");
        $(window).off(".noScroll");
        $(this).off("touchend");
        return false;
      });
      $("#dialog_overlay").on("touchend", function() {
        $("#dialog-youtube-pop").remove();
        $("#dialog_pop").css("display", "none");
        $(this).css("display", "none");
        $(window).off(".noScroll");
        $(this).off("touchend");
        return false;
      });

      // for analyze(don't delete)
      Log.actionLog("promotion_video", {
        id:       $obj.data("video-id"),
        anime_id: $obj.data("anime-id"),
        title:    $obj.data("title")
      });

      return false;
    });
  }


  //zoom thumbs(single)
  $(".thumbs-zoom").on("click", function(e) {
    e.preventDefault();
    overlay_dialog();

    const template_data = {
      images  : $(this).data("image"),
      title   : $(this).text(),
      is_upload : $(this).data('is-upload') != undefined ? true : false,
      is_me : $(this).data('is-me') != undefined ? true : false
    };
    const template = _.template($("#option_template_img").text());
    $("#dialog_pop").append(template(template_data));

    $("#dialog_pop").on("click", function (e) {
      if ($(e.target).attr("id") == 'dialog_pop') {
        e.preventDefault();
        closeDialog();
      }
    });

    $("#dialog_overlay").on("click", function(e) {
      e.preventDefault();
      closeDialog();
    });

    $(".js-btn-upload-btn").on("click", function () {
      $("#select-file").click();
    });

    $("#select-file").change(function () {
      const fd = new FormData();
      let notify_message = "";
      let is_err = false;
      fd.append("file", $(this).prop("files")[0]);
      $.ajax({
        url        : "/account/profile/image.json",
        type       : "POST",
        data       : fd,
        dataType   : "json",
        processData: false,
        contentType: false,
      })
        .done(function(data) {
          notify_message = "It may take up to 10 minutes for your new picture to show, please be patient.";
        })
        .fail(function(data) {
          notify_message = $.parseJSON(data.responseText).errors[0].message;
          is_err = true;
        })
        .always(function() {
          $(".notify-message").remove();
          $("#content").prepend("<div class='notify-message fs12 pt12 pr12 pb12 pl12'></div>");
          $(".notify-message").text(notify_message);

          if (is_err) {
            $(".notify-message").addClass("fn-red1");
          }

          e.preventDefault();
          closeDialog();
        });
    });

    //close
    $(".js-btn-close-dialog").on("click", function() {
      e.preventDefault();
      closeDialog();
    });
  });

  // ------------------------
  // Open Dialog
  // ------------------------
  $(".js-btn-dialog-pop").on("click", function() {
    // lodashTemplate(put json data in data attribute)
    const $this = $(this);
    const tmplParam = $this.data("param");// except add/edit button
    const thisBtnText = $this.text();
    let tmplName = tmplParam.dialog;

    if(tmplParam.dialog == "add" || tmplParam.dialog == "edit") {
      tmplName = "add";
    }
    // open dialogue
    overlay_dialog(tmplName,null);

    // load lodashTemplate
    const template = _.template($(`#${tmplName}_template`).text());

    if (tmplName === "add") {
      // getStatus();// for add/edit
    } else {
      $("#dialog_pop").append(template({param:tmplParam,chkParam:thisBtnText}));
      //close BTN function
      $(".js-btn-close-dialog").on("touchend", function() {
        closeDialog();
        return false;
      });
    }

    return false;
  });

  if ($('body.page-rwd').length == 0) {
    //delete attribute in OnClick
    $(".spoiler .button").removeAttr("onClick").css({display:"inline-block"});
    //delete useless input button
    $(".spoiler_content input.button").remove();
    //click event
    $(".spoiler .button").off("click").on("click",function(){
      $(this).siblings(".spoiler_content").toggle();
      $(this).removeClass('hide_button');

      const showName = $(this).data("showname") || "Show spoiler";
      const hideName = $(this).data("hidename") || "Hide spoiler";
      let btnText = showName;

      if( $(this).siblings(".spoiler_content").css("display") != "none" ){
        btnText = hideName;
        if( $(this).parents("div.trunc-text")[0] ){
          $(this).parents("div.trunc-text").css("max-height", "none");
        }
        $(this).addClass('hide_button');
      }
      $(this).val(btnText);
    });
  }


  //status-info-box
  if ($(".status-info-box")[0]) {
    const thisBlock = $(".status-info-box");
    const icon = $(".status-info-box .icon-social");
    const thisBlockW = thisBlock.width();
    const iconNum = icon.length;
    const iconW = iconNum*icon.width();
    const margin = Math.floor((thisBlockW - iconW) / (iconNum - 1));
    icon.css({"margin-right":margin});
  }

  if ($(".btn-view_more-show")[0]) {
    $(".btn-view_more-show").on("click",function(){
      const objID = $(this).data("id");
      $(`#${objID}`).show();
      $(this).hide();
    });
  }

  //button for detail link in review block
  $(".box-unit1-btn .data").on("touchstart", function(){
    $(this).parent(".box-unit1-btn").parent(".box-unit1").addClass("on");
  }).on("touchend", function(){
    $(this).parent(".box-unit1-btn").parent(".box-unit1").removeClass("on");
  });

  $(".btn-detail-review").on("touchstart", function(){
    $(this).children(".btn-inner-details").children("span").addClass("bg-blue");
    $(this).children(".icon-thumb").css({opacity:".8"});
  }).on("touchend", function(){
    $(this).children(".icon-thumb").css({opacity:"1"});
    $(this).children(".btn-inner-details").children("span").removeClass("bg-blue");
  });


  //error 404
  $(".error404 .error_image").on("touchstart", function(){
    $(this).children(".btn-home").addClass("on");
  }).on("touchend", function(){
    $(this).children(".btn-home").removeClass("on");
  });


  // show popup for information in anime/manga detail page
  if ($(".js-detail-status-information-popup")[0]) {
    $(".js-detail-status-information-popup").on("click", function(e) {
      e.preventDefault();
      overlay_dialog();
      const template = _.template($("#dialog_detail_info").text());

      $("#dialog_pop").append(template({
          detail_info: $(".js-detail-information").html()
      })).hide().fadeIn("fast");

      $("#dialog_pop").promise().done(function() {
        const $information = $(".detail-dialog-content").find(".information");
        const winH      = window.innerHeight;
        const infoH     = winH - 220;
        const infoDataH = $information.find(".table-list").height();

        if (infoH < infoDataH) {
          $information.height(infoH);
          $information.on("touchmove", function() {
            $(window).off('.noScroll');
          });
          $information.on("touchend", function() {
            $(window).on("touchmove.noScroll", function(e) {
                e.preventDefault();
            });
          });
        }
      });

      // close BTN function
      $(".js-btn-close-dialog").on("touchend",function(){
          closeDialog();
          return false;
      });

    });
  }

  // show Tmp list in anime/manga detail page
  const showTmpList = function() {
    if ($("#fieldcontain")[0]) {
      return false;
    }
    overlay_dialog();

    const template = _.template($("#dialog_tmp_list").text());
    $("#dialog_pop").append(template({
      detail_info: $(".js-detail-tmp-list").html()
    })).hide().fadeIn("fast");
    $("#fieldcontain").css("border-radius", "12px");

    // control background scrolling
    $(".js-tmp-list-dialog").on("touchmove", function () {
      $("body").css('overflow-y', 'hidden');
    });

    // height adjustment
    const tmpListH =  $(".js-tmp-list-dialog").height();
    const winH     = window.innerHeight;
    const tmpH     = winH - 60;
    if (tmpH < tmpListH) {
      $(".js-tmp-list-dialog").css({"height":tmpH, "overflow":"auto"});
    }


    $("#dialog_overlay").on('touchstart', function(){
      $("body").css('overflow-y','');
    });
    // close BTN function
    $(".js-btn-close-tmp-list").on("touchend",function(){
      $("body").css('overflow-y','');
      closeDialog();
      return false;
    });

    if ($('.js-tmp-list-more-num')[0]) {
      $('.js-tmp-list-more-num').on('click', function () {
        $('.tmp-list-more').css('display', 'flex');
        $('.js-tmp-list-more-num').hide();
        $('.js-tmp-list-hide-more-anime').show();
      });
    }
    if ($('.js-tmp-list-hide-more-anime')[0]) {
      $('.js-tmp-list-hide-more-anime').on('click', function () {
        $('.tmp-list-more').hide();
        $('.js-tmp-list-hide-more-anime').hide();
        $('.js-tmp-list-more-num').show();
      });
    }
  }

  // show Tmp list (Click Tmp list button)
  if ($(".js-detail-status-tmp-list-popup")[0]) {
    $(".js-detail-status-tmp-list-popup").on("click", function(e) {
      e.preventDefault();
      showTmpList();
    });
  }

  // show Tmp list (Automatic display after adding to Tmp list)
  $(window).on('load', function() {
    if ($("#bottom-menu")[0]) {
      const urlParams = location.search.match(/[\?|&]tmp_list/);
      if(urlParams) {
        showTmpList();
      } else {
        const topanime = location.pathname.match(/^\/topanime.php/);
        const seasonal = location.pathname.match(/^^\/anime\/season/);
        let page_title = '';
        if (topanime) {
          page_title = 'top_page';
        } else if (seasonal) {
          page_title = 'seasonal';
        }

        if (page_title !== '') {
          // 表示抑止
          const disp = page_title == 'seasonal' ? 'easy2add_disp_seasonal' : 'easy2add_disp_top';
          Cookies.get('easy2add_disp_title')
          if (Cookies.get(disp) !== 'on') {
            const exp = 7;
            Cookies.set(disp, 'on', { expires : exp });

            const docH = $(document).height() - 200 - 579;
            const dispH = docH / 2;
            $(window).scroll(function () {
              if ($('#dialog_pop').css('display') == 'none' && $(this).scrollTop() > dispH) {
                showEasy2addList(page_title, null);
              }
            });
          }

        }
      }
    }
  });

  const getTmpList = function (callback) {
    // APIから取得
    const api_url = '/anime/easy2add/get_tmp_list';
    $.ajax({
      url: api_url,
      type: "GET",
      data    : {},
      dataType: "json",
      success : function (data) {
          if (data.message == "OK") {
            callback(data.result);
          } else {
            callback([]);
          }
      },
      error : function (err) {
          callback([]);
      }
    });
  };

  const setTmpList = function (item, callback) {
    // API送信
    const api_url = '/anime/easy2add/set_tmp_list';
    $.ajax({
      url: api_url,
      type: "GET",
      data    : item,
      dataType: "json",
      success : function (data) {
          callback('title_page', item.id);
      },
      error : function (err) {
          // console.log("%o", err);
      }
    });
  };

  // Completed でリスト登録
  $("#js-btn-add-to-tmp-list-completed").on('click', function(){
    const animeId = Number($(this).val());
    const changeBtnFunc = () => {
      $('.bottom-menu-btn-block').html(
        '<a href="/register.php" class="bottom-menu-btn btn-completed">Completed</a>'
      );
    }
    addAnimeTempList(animeId,2, changeBtnFunc);
  });

  // Plan to Watch でリスト登録
  $("#js-btn-add-to-tmp-list-plan-to-watch").on('click', function(){
    const animeId = Number($(this).val());
    const changeBtnFunc = () => {
      $('.bottom-menu-btn-block').html(
        '<a href="/register.php" class="bottom-menu-btn btn-plan-to-watch">Plan to Watch</a>'
      );
    }
    addAnimeTempList(animeId, 6, changeBtnFunc);
  });

  let btnBottomActionAnime = true;
  const addAnimeTempList = function (animeId, status, changeBtnFunc) {
    if (btnBottomActionAnime === false) {
      return false;
    }
    btnBottomActionAnime = false;

    const url = new URL(location.href);
    if (animeId !== null) {
      if (Cookies.get('easy2add_disp_title') !== 'on' && $('.easy2add-child').length) {
        changeBtnFunc();
        setTmpList({id: animeId, status: status}, showEasy2addList);
      } else {
        setTmpList({id: animeId, status: status}, function(page, id){
          url.searchParams.set('tmp_list', status);
          location.href = url.toString();
        });
      }
    } else {
      url.searchParams.set('tmp_list', status);
      location.href = url.toString();
    }

  }

  $("#js-btn-add-to-tmp-list-manga-complete").on('click', function() {
    addMangaTempList('2')
  });

  $("#js-btn-add-to-tmp-list-i-read-this").on('click', function() {
    addMangaTempList('6')
  });

  let btnBottomActionManga = true;
  const addMangaTempList = function (status) {
    // 二重押下抑止（location更新するので、フラグは元に戻さない。）
    if (btnBottomActionManga === false) {
      return;
    }
    btnBottomActionManga = true;
    const url = new URL(location.href);
    url.searchParams.set('tmp_list', status);
    location.href = url.toString();
  }

  // show easy2add list
  const showEasy2addList = function(page, title_id) {
    overlay_dialog('easy2add', null);

    const template = _.template($("#dialog_easy2add_list").text());
    $("#dialog_pop").append(template({
      detail_info: $(".js-detail-easy2add-list").html()
    })).hide().fadeIn("fast");
    $("#fieldcontain").css("border-radius", "12px");

    // control background scrolling
    $(".js-easy2add-list-dialog").on("touchmove", function() {
      $("body").css('overflow-y', 'hidden');
    });

    // height adjustment
    const tmpListH = $(".js-easy2add-list-dialog").height();
    const winH     = window.innerHeight;
    const tmpH     = winH - 60;
    if (tmpH < tmpListH) {
      $(".js-easy2add-list-dialog").css({"height":tmpH, "overflow":"auto"});
    }

    $("#dialog_overlay").on('touchstart', function() {
      $("body").css('overflow-y', '');
    });

    // Finish BTN function
    $(".js-btn-close-easy2add").on("touchend",function() {
      $("body").css('overflow-y', '');
      closeDialog();
      getTmpList(function(tmp_list) {
        if (tmp_list.length > 0) {
          let url = new URL(location.href);
          url.searchParams.set('tmp_list','2');
          location.href = url;
        }
      });
      return false;
    });
    let checked_list = [];
    if (title_id) {
      checked_list.push(title_id);
    }
    getTmpList(
      function(tmp_list) {
        var easySlider = $('#fieldcontain').children(".common-dialog-content").children(".easy2add-list-dialog").children(".easy2add-slider");
        easySlider.easy2add({
          easing: 'easeOutQuad',
          handle_width: 30, //Prev next show width
          speed: 'slow',
          page: page,
          title_id: title_id,
          tmp_list: tmp_list,
          checked_list: checked_list
        });
      }
    );
  }

  // ---------------------------------
  // 20220127 sort menu popup
  // ---------------------------------
  if ($(".js-navi-company")[0]) {
    $(".js-navi-company .js-btn-show-sort").on("click", function(e) {
      e.preventDefault();
      const id = $(this).data("id");
      const dimention = getDimention();
      const docH = dimention.docH;
      const dialogObj = $("#dialog_overlay");
      // black background
      dialogObj.css({height: docH, display: "block", opacity: 0}).hide().fadeIn("fast", function() {
        // sort popup block
        $(`.js-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        dialogObj.on("touchend click", function() {
          closeDialog();
          $(".js-navi-company .js-sort-order-block").hide();
          $(".js-navi-company .js-btn-show-sort").removeClass("on");
          return false;
        });
      });

      dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
        e.preventDefault();
      });

      // $(window).on("touchmove.noScroll", function(e) {
      //   e.preventDefault();
      // });

      // close BTN function
      $(".js-sort-order-block .js-btn-close").on("touchend",function(){
        $(".js-navi-company .js-sort-order-block").hide();
        $(".js-navi-company .js-btn-show-sort").removeClass("on");
        closeDialog();
        return false;
      });

    });
  }

  // dialog for affiliate
  if ($(".js-shop-selecter")[0]) {
    $(".js-shop-selecter").on("click", function(e) {
      e.preventDefault();
      overlay_dialog();
      const template = _.template($("#dialog_common_info").text());

      $("#dialog_pop").append(template({
          detail_info : $(".js-dialog-affiliate").html(),
          add_class   : "affiliate"
      })).hide().fadeIn("fast");

      // anime詳細からのクリックログを出す
      $(".js-shop-anime").on("click", function () {
        const data = $(this).data("shop")
        Log.actionLog(data.shop, {
          user_id : data.user_id,
          type : data.type,
          id: data.id,
        })
      })

      //close BTN function
      $(".js-btn-close-dialog").on("touchend", function() {
        closeDialog();
        return false;
      });

    });
  }

  // delete cookie for manga store (in jumping from menu to pament method)
  const btnMenuPaymentMethod = document.querySelector(".js-btn-menu-payment-information")
  if (btnMenuPaymentMethod) {
    btnMenuPaymentMethod.addEventListener("click", (e) => {
      Cookies.remove("store_referer")
    })
  }
})
