<template>
  <span>
    {{ head }}:
    <span class="completed" v-if="isProgress">{{ completed }}</span><span v-if="isProgress">/</span>{{ totalAsString }}
  </span>
</template>

<script>
export default {
  props: {
    head: String, // 見出し
    completed: Number, // 視聴・購読済みの数
    total: Number, // 作品の総話・巻数
  },
  computed: {
    isProgress() {
      return this.completed !== undefined
    },
    totalAsString() {
      return this.total || "?"
    },
  }
}
</script>

<style lang="scss" scoped>
span.completed {
  font-weight: 700;
}
</style>
