var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-container"
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-header",
                on: {
                  touchmove: [
                    function($event) {
                      $event.preventDefault()
                    },
                    function($event) {
                      $event.stopPropagation()
                    }
                  ]
                }
              },
              [
                _c("div", { staticClass: "modal-header-title" }, [
                  _vm._v("\n          Confirm\n          "),
                  _c(
                    "a",
                    { staticClass: "modal-close", on: { click: _vm.cancel } },
                    [_vm._v("close")]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-list",
                on: {
                  touchmove: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-list-inner" },
                  _vm._l(_vm.items, function(item) {
                    return _c("div", { staticClass: "modal-list-item" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "modal-list-cover margin-r10",
                          attrs: {
                            src: _vm.coverImageUrl(item.coverImageBaseUrl),
                            width: "46",
                            height: "auto"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-list-info" }, [
                        _c("div", { staticClass: "modal-list-title" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal-list-volume",
                            class: { iconNew: !item.isPossessed && item.isNew }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.numberingText) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-list-price" },
                          [
                            item.salePrice
                              ? [
                                  _c("span", { staticClass: "is-sale" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currency.formatSymbolAndPrice(
                                          item.price
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v("\n                  Sale "),
                                  _c(
                                    "span",
                                    { staticClass: "modal-price-sale" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            item.salePrice
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.currency.formatSymbolAndPrice(
                                          item.price
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        )
                      ])
                    ])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-footer",
                on: {
                  touchmove: [
                    function($event) {
                      $event.preventDefault()
                    },
                    function($event) {
                      $event.stopPropagation()
                    }
                  ]
                }
              },
              [
                _c("div", { staticClass: "modal-price_wrap" }, [
                  _c("ul", [
                    _c("li", [
                      _c("div", [_vm._v("Price")]),
                      _vm._v(
                        _vm._s(
                          _vm.currency.formatSymbolAndPrice(
                            _vm.totalPriceWithoutTax
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("div", [_vm._v("Tax")]),
                      _vm._v(
                        _vm._s(_vm.currency.formatSymbolAndPrice(_vm.totalTax))
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "modal-price" }, [
                      _c("div", [_vm._v("Total")]),
                      _vm._v(_vm._s(_vm.currency.symbol)),
                      _c("span", [
                        _vm._v(_vm._s(_vm.currency.formatPrice(_vm.totalPrice)))
                      ]),
                      _vm._v(_vm._s(_vm.currency.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass: "modal-error"
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-table" },
                  [
                    !_vm.isRequesting
                      ? [
                          _c("div", {
                            staticStyle: { position: "absolute", left: "12px" }
                          }),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "modal-cancel-button margin-l10",
                                on: { click: _vm.cancel }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "modal-confirm-button margin-l10",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("confirm")
                                  }
                                }
                              },
                              [_vm._v("Confirm Purchase")]
                            )
                          ])
                        ]
                      : [_c("div", { staticClass: "modal-button-loading" })]
                  ],
                  2
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }