タイル表示用
<template>
  <div class="tile-unit" :style="backgroundImg">
    <span class="edit-icon" v-if="isOwner">
      <colored-edit-icon
        @edit-list-item="showModal"
        :id="id" :work-type="workType" :status-id="userStatusId"
      >
      </colored-edit-icon>
    </span>
    <div class="info">
      <information
        :title="title" :head="head" :completed="completed" :score="score"
      >
      </information>
    </div>
    <a :href="path"></a>
  </div><!-- .tile-unit -->
</template>

<script>
import ColoredEditIcon from "../atoms/ColoredEditIcon.vue"
import Information from "../atoms/Information.vue"
import AssetImage from "../../../../js/common/modules/AssetImage"
import {mapState, mapGetters} from "vuex"

export default {
  props: {
    id: Number, // anime or manga id
    path: String, // 作品詳細へのpth
    userStatusId: Number, // listについてのユーザのstatus
    score: Number,
    title: String, // 作品名
    image: String, // 作品画像へのpath
    watchedEpisodes: Number, // 視聴済みepisode数
    readChapters: Number, // 既読話数
  },
  computed: {
    ...mapState([
      "workType", "isWebp",
    ]),
    ...mapGetters([
      "isOwner",
    ]),
    head() {
      return this.workType === "anime" ? "Watched" : "Read"
    },
    completed() {
      return this.workType === "anime" ? this.watchedEpisodes : this.readChapters
    },
    backgroundImg() {
      const img = new AssetImage({path: this.image})
      return `background-image: url("${this.isWebp ? img.toWebp().toUrl() : img.toUrl()}")`
    },
  },
  methods: {
    showModal() {
      this.$emit("show-modal", this.id)
    },
  },
  components: {
    Information, ColoredEditIcon,
  },
}
</script>

<style lang="scss" scoped>
.tile-unit {
  position: relative;
  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  display: inline-block;
  width: calc((100% - 24px) / 2);
  height: 210px;
  margin: {
    top: 8px;
    left: 8px;
  }
  overflow: hidden;
  -ms-interpolation-mode: bicubic;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;

  .info {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
    width: calc(100% - 4px - 4px);
    padding: 4px;
  }

  .edit-icon {
    top: 5px;
    left: 5px;
    z-index: 20;
    display: block;
    position: absolute;
  }

  a {
    display: block;
    width: 100%;
    height: 209px;
    &:active {
      background-color: rgba(255,255,255,0.5);
    }
  }
}
</style>
