window.addEventListener("DOMContentLoaded", () => {

  const $vaSliderMore = document.querySelector(".va-slider-more")
  const $vaSliderItems = Array.apply(null, document.querySelectorAll(".va-slider-items"))
  
  if ($vaSliderMore) {
    $vaSliderMore.addEventListener("click", (e) => {

      // Hide view all button
      $vaSliderMore.parentNode.removeChild($vaSliderMore)

      // Show all items
      $vaSliderItems.forEach(function(element, index, array) {
        element.classList.remove("va-slider-items--hidden")
      })
    })
  }
})
