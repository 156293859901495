var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c(
        "label",
        { class: { disabled: !_vm.enabled } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-num", attrs: { total: _vm.totalStr } }, [
        _c("input", {
          attrs: {
            type: "number",
            size: "5",
            debounce: "500",
            number: "",
            disabled: !_vm.enabled
          },
          domProps: { value: _vm.current },
          on: {
            change: function($event) {
              return _vm.change($event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("increment-button", {
        attrs: { "is-active": _vm.canIncrement, className: "increment" },
        on: { increment: _vm.increment }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }