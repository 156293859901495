<template>
  <div class="root">
    <transition name="modal">
      <div v-show="show"
        class="tutorial-content"
        :style="{height: ( browserInnerHeight ? browserInnerHeight + 'px' : '100%' ) }">
        <div class="tutorial-mask" @click="maskClick"
        :style="{height: ( browserInnerHeight ? browserInnerHeight + 'px' : '100%' ) }">
          <div class="tutorial-mask-header" v-show="!isShowHowtoRead"><div class="tutorial-mask-header-text">Tool bar</div></div>
          <div class="tutorial-mask-gray"></div>
          <div class="tutorial-mask-footer" v-show="!isShowHowtoRead"><div class="tutorial-mask-footer-text">Tool bar</div></div>
        </div>
        <div class="tutorial-wrapper">
          <div class="tutorial-container">
            <div class="tutorial-howto-use" v-show="!isShowHowtoRead">
              <p>Tap the centre of the screen <br>to show the toolbar</p>
              <div><a class="button-howto-read" @click="showHowtoRead">How to Read</a></div>
            </div>
            <div class="tutorial-howto-read" v-show="isShowHowtoRead">
              <p>Unlike English comics, Japanese manga is read right to left, top to bottom. Pages are also turned in the opposite direction.</p>
              <div><a class="button-howto-use" @click="hideHowtoRead">How to Use</a></div>
            </div>
            <a class="button-tutorial-close" @click="hide">Got it!</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  $header-height: 40px;
  .tutorial-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    -webkit-text-size-adjust: none;
  }
  .tutorial-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: table;
    table-layout: auto;
  }
  .tutorial-mask-gray{
    display: table-row;
    &::after{
      content: ' ';
      display: table-cell;
      background-color: rgba(0, 0, 0, .8);
    }
  }
  .tutorial-mask-header,
  .tutorial-mask-footer{
    display: table-row;
    height: $header-height;
  }
  .tutorial-mask-header-text,
  .tutorial-mask-footer-text {
    vertical-align: middle;
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: table-cell;
    background-color: rgba(198, 232, 255, .95);
  }
  .tutorial-wrapper {
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
  }
  .tutorial-container {
    position: relative;
    margin: 0;
    display: block;
    transition: all .3s ease;
    color: #fff;

    text-align: center;
  }
  .tutorial-howto-use{
    p{
      font-weight: bold;
      pointer-events: none;
      font-size: 18px;
      margin: 0;
      padding-bottom: 10px;
      &::before {
        content: '';
        display: block;
        margin: 0 auto 15px;
        width: 67px;
        height: 103px;
        background: url(/images/manga_store/viewer/sp/tutorial_touch@2x.png?v=161115001) no-repeat center top;
        background-size: contain;
        vertical-align: middle;
        text-indent: -99999px;
      }
    }
  }
  .tutorial-howto-read{
    p{
      pointer-events: none;
      font-size: 16px;
      text-align: left;
      padding: 0 10px;
      margin: 0;
      &::after {
        content: '';
        display: block;
        margin: 10px auto 0;
        width: 100%;
        height: 0;
        padding-top: 72%;
        background: url(/images/manga_store/viewer/sp/tutorial_read@2x.png?v=161115001) no-repeat center top;
        background-size: contain;
        vertical-align: middle;
      }
    }
  }
  .button-tutorial-close{
    content: '';
    display: block;
    margin: 32px auto 0;
    width: 141px;
    height: 34px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    pointer-events: auto;
  }
  .button-howto{
    color: #fff;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    margin: 25px 0 0;
    pointer-events: auto;
  }
  .button-howto-use{
    @extend .button-howto;
    &::before{
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin: 0 2px 1px 0;
    }
  }
  .button-howto-read{
    @extend .button-howto;
    &::after{
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      margin: 0 0 1px 2px;
    }
  }
  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .tutorial-container,
  .modal-leave-active .tutorial-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  @media only screen and (max-width : 320px) and (max-height : 480px) {
    .tutorial-howto-use{
      p{
        font-size: 14px;
        padding-bottom: 5px;
        br{
          display: none;
        }
        &::before {
          width: 33px;
          height: 51px;
          margin: 0 auto 5px;
          background: url(/images/manga_store/viewer/sp/tutorial_touch@2x.png?v=161115001) no-repeat center top;
          background-size: contain;
        }
      }

    }
    .tutorial-howto-read{
      p{
        font-size: 14px;
        &::after {
          width: 100%;
          height: 116px;
          padding-top: 0;
          background: url(/images/manga_store/viewer/sp/tutorial_read@2x.png?v=161115001) no-repeat center top;
          background-size: contain;
        }
      }
    }
  }
  @media only screen and (orientation : landscape) {
    .tutorial-howto-use{
      p{
        font-size: 14px;
        padding-bottom: 5px;
        br{
          display: none;
        }
        &::before {
          width: 33px;
          height: 51px;
          margin: 0 auto 5px;
          background: url(/images/manga_store/viewer/sp/tutorial_touch@2x.png?v=161115001) no-repeat center top;
          background-size: contain;
        }
      }

    }
    .tutorial-howto-read{
      p{
        font-size: 14px;
        &::after {
          width: 100%;
          height: 116px;
          padding-top: 0;
          background: url(/images/manga_store/viewer/sp/tutorial_read@2x.png?v=161115001) no-repeat center top;
          background-size: contain;
        }
      }
    }
    .button-howto-use,
    .button-howto-read{
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
    }
    .button-tutorial-close{
      margin-top: 15px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
    }
  }
</style>
<script type="text/babel">
  import * as types from "../store/mutation-types"
  import { mapGetters } from "vuex"

  export default {
    props: {
      show: Boolean,
      browserInnerHeight: Number,
    },
    data() {
      return {
        isShowHowtoRead : false,
      }
    },
    methods: {
      maskClick() {
        if (this.isShowHowtoRead) {
          this.$store.commit(types.HIDE_TUTORIAL_MODAL)
        } else {
          this.isShowHowtoRead = true
        }

      },
      hide() {
        this.$store.commit(types.HIDE_TUTORIAL_MODAL)
      },
      showHowtoRead() {
        this.isShowHowtoRead = true
      },
      hideHowtoRead() {
        this.isShowHowtoRead = false
      },
    },
  }
</script>
