import $ from "jquery";
import _ from "lodash";
import MAL from "./namespace";
import localStorage from "../../common/modules/localStorage";

/**
 * @param {Object} event - googletag.events.SlotRenderEndedEvent
 *   https://developers.google.com/doubleclick-gpt/reference#googletag.events.SlotRenderEndedEvent
 */
function adjustBillboardPosition(event) {
  if (event.isEmpty) {
    return;
  }
  
  const id = event.slot.getSlotElementId();
  const width = event.size[0];
  _adjust(id, width);
  
  // orient action change
  // android chrome: orientationchange event -> (resize window) -> resize event
  // iOS 8: (resize window) -> resize event -> orientationchange event
  let timeoutId;
  $(window).on("resize orientationchange",function(e){
    if(e.type == "orientationchange"){
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function(){
        _adjust(id, width);
      });
    }
  });
}

function _adjust(id, width) {
  // 端末によって挙動が違うので横の場合は長いほうが横幅とする
  const screenWidth = (window.orientation % 180 == 0) ? screen.width
    : (screen.width > screen.height) ? screen.width : screen.height;
  const left = Math.floor((screenWidth - width)/2 );
  if (isFinite(left)) {
    $(`#${id}`).css({left: left});
  }
}

//---

const ANCHOR_AD_STORAGE_KEY = "anchor-ad";
const ANCHOR_AD_STORAGE_CURRENT_DATA_VERSION = 1;
const ANCHOR_AD_INITIAL_DATA = {
  version: ANCHOR_AD_STORAGE_CURRENT_DATA_VERSION,
  closedAt: 0
};

// 広告or閉じるボタンをおした後、しばらく非表示にする時間 [ms]
const ANCHOR_AD_SILENT_DURATION = 60 * 5 * 1000;

class AnchorAd {
  constructor(storage) {
    this.storage = storage;
    this.loadData();
  }

  loadData() {
    const rawData = this.storage[ANCHOR_AD_STORAGE_KEY];
    let data;
    if (!rawData) {
      data = _.assign({}, ANCHOR_AD_INITIAL_DATA);
    } else {
      data = JSON.parse(rawData);
      if (data.version < ANCHOR_AD_STORAGE_CURRENT_DATA_VERSION) {
        // migrate
      }
    }
    this.data = data;
  }

  persistData() {
    this.storage[ANCHOR_AD_STORAGE_KEY] = JSON.stringify(this.data);
  }

  /**
   * 非表示期間の判定
   * @return {boolean} trueの時は広告非表示にする
   */
  shouldSilence() {
    return Date.now() - this.data.closedAt < ANCHOR_AD_SILENT_DURATION;
  }

  /**
   * @param {Object} event - googletag.events.SlotRenderEndedEvent
   * @param {Boolean} is_otherad 
   */
  prepare(event, is_otherad = false) {
    if (!is_otherad) {
      if (event.isEmpty) {
        return; // 在庫がない
      }
    }

    const $ad = $(".js-anchor-ad");
    const $close = $ad.find(".js-close");

    $ad.show();

    $close.on("click", () => {
      $ad.hide();
      this.data.closedAt = Date.now();
      this.persistData();
      return false;
    });
  }
}

// ---

MAL.ad = {
  adjustBillboardPosition,
  anchor: new AnchorAd(localStorage)
};
