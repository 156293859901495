<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="$emit('close')"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <p class="modal-lead">Purchase completed.</p>
            <p class="modal-title">
              {{ title }}<br>
              <span class="modal-volume">{{ numberingText }}</span>
            </p>
            <div v-if="isSingle" class="modal-button-wrap">
              <a class="modal-read-button" @click="read">Read</a>
              <a class="modal-close-button" @click="close"><i class="fa-solid fa-xmark" aria-hidden="true"></i> Close</a>
            </div>
            <div v-else class="modal-button-wrap">
              <a class="modal-read-button" @click="read">Bookshelf</a>
              <a class="modal-close-button" @click="close"><i class="fa-solid fa-xmark" aria-hidden="true"></i> Close</a>
            </div>
            <a class="modal-close" @click="$emit('close')">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
$confirm-color:#ff9600;
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 83.6%;
    margin: 0 auto;
    padding: 30px 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-lead {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 24px;;
    line-height: 1.18;
    text-align: center;
  }
  .modal-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 27px;
    line-height: 1.14;
    word-wrap:break-word;
    text-align: left;
  }
  .modal-volume {
    color: #686A6F;
    font-size: 14px;
    font-weight: 700;
  }
  .modal-later-button,
  .modal-read-button {
    border: 2px solid #2E51A2;
    color: #2E51A2;
    font-size: 16px;
    text-align: center;
    display: block;
    height: 44px;
    line-height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 700;
    margin: 20px 0 0;
  }
  .modal-close-button {
    display: block;
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    color: #7a7a7a;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .modal-close {
    position: absolute;
    top: - 16px;
    right: - 20px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(/images/manga_store/sp/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  @media only screen and (orientation : landscape) {
    .modal-container {
      padding: 10px 10px;
    }
    .modal-lead {
      margin: 0 0 8px;
    }
    .modal-title {
      margin: 0 0 12px;
      text-align: center;
    }
    .modal-button-wrap {
      margin: 0 0 0;
      text-align: center;
    }
    .modal-later-button,
    .modal-read-button {
      margin: 0 4px 0;
      display: inline-block;
      width: 150px;
      height: inherit;
      padding: 8px 0;
      line-height: inherit;
    }
    .modal-close-button {
      margin-top: 16px;
    }
  }

</style>

<script type="text/babel">
  export default {
    props: {
      title: String,
      isShown: Boolean,
      isSingle: Boolean,
      numberingText: String,
    },
    methods: {
      read() {
        this.$emit('read')
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>
