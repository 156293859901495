listのstatus, score, 視聴・購読の進捗を変更できるUI
<template>
  <ul class="user-status">
    <li class="status">
      <user-status
        :status-id="userStatusId" :work-type="workType"
        @change="updateUserStatus" :is-owner="isOwner"
      >
      </user-status>
    </li>
    <li class="score" :class="{scored:(isOwner || score)}">
      <i class="fa-regular fa-star mr4"></i><user-score :score="score" :is-owner="isOwner" @change="updateScore"></user-score>
    </li>
    <li class="increment">
      <!-- レイアウトが崩れないようにtemplateにv-ifをつける -->
      <template v-if="canIncrement">
        <increment unit="ep" @increment="incrementEpisode" v-if="workType === 'anime'"
        ></increment>
        <increment unit="vol" @increment="incrementVolume" v-if="workType === 'manga'"
        ></increment>
        <increment unit="chp" @increment="incrementChapter" v-if="workType === 'manga'"
        ></increment>
      </template>
    </li>
  </ul>
</template>

<script>
import UserStatus from "../atoms/UserStatus.vue"
import UserScore from "../atoms/UserScore.vue"
import Increment from "../atoms/Increment.vue"

export default {
  props: {
    workType: String,
    userStatusId: Number,
    score: Number,
    reDoing: Boolean, // re-watching or re-readingかどうか
    isOwner: Boolean, // アクセスした人がlistのOwnerかどうか
    statusId: Number, // 作品のstatus
  },
  computed: {
    canIncrement() {
      return this.isOwner
      && (this.userStatusId === 1 || this.reDoing) // wathing, reading, or re-doing
      && (this.statusId !== 3) // not yet aired or published
    },
  },
  methods: {
    updateUserStatus(statusId) {
      this.$emit("update-user-status", statusId)
    },
    updateScore(score) {
      this.$emit("update-score", score)
    },
    incrementEpisode() {
      this.$emit("increment", "episode")
    },
    incrementVolume() {
      this.$emit("increment", "volume")
    },
    incrementChapter() {
      this.$emit("increment", "chapter")
    },
  },
  components: {
    UserStatus, UserScore, Increment,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/variables";

.user-status {
  background-color: #F6F6F6;
  width: 100%;
  display: table;
  padding: 8px 0;

  li {
    display: table-cell;
    width: calc(100% / 3);
    white-space: nowrap;
    &.status {
      padding-left: 8px;
      text-align: left;
    }
    &.score {
      position: relative;
      text-align: left;
    }
    &.increment {
      padding-right: 8px;
      text-align: right;
      span {
        margin-left: 12px;
      }
    }
  }
}
</style>
