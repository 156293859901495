import actions from "./actions"
import mutations from "./mutations"

const state = {
  id: null,
  userStatusId: null,
  statusId: null,
  score: null,
  title: null,
  watchedEpisodes: null,
  totalEpisodes: null,
  readVolumes: null,
  totalVolumes: null,
  readChapters: null,
  totalChapters: null,
  error: null,
  isLoading: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
