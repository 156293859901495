var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-unit" },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _vm._v(" "),
      _c("work-info", {
        attrs: {
          "work-type": _vm.workType,
          "is-owner": _vm.isOwner,
          id: _vm.id,
          score: _vm.score,
          title: _vm.title,
          "status-id": _vm.statusId,
          image: _vm.image,
          path: _vm.path,
          "user-status-id": _vm.userStatusId,
          "re-doing": _vm.reDoing,
          totalEpisodes: _vm.totalEpisodes,
          watchedEpisodes: _vm.watchedEpisodes,
          totalVolumes: _vm.totalVolumes,
          readVolumes: _vm.readVolumes,
          totalChapters: _vm.totalChapters,
          readChapters: _vm.readChapters
        },
        on: { "edit-list-item": _vm.showModal }
      }),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("status-controller", {
        attrs: {
          "work-type": _vm.workType,
          "is-owner": _vm.isOwner,
          score: _vm.score,
          "user-status-id": _vm.userStatusId,
          "re-doing": _vm.reDoing,
          "status-id": _vm.statusId
        },
        on: {
          "update-user-status": _vm.updateUserStatus,
          "update-score": _vm.updateScore,
          increment: _vm.increment
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }