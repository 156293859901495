<template>
  <div style="display:inline;">

    <preview-button
      v-if="!item.isPossessed && item.isPreviewable && !item.isFree"
      :previewUrl="item.previewUrl"
    ></preview-button>
    <read-button
      v-if="item.isPossessed || item.isFree"
      @read="readOrLimit"
    ></read-button>
    <purchase-button
      v-if="!item.isPossessed && !item.isFree"
      @purchase="purchase"
      :text="'Checkout'"
      style="margin-left:16px;"
    ></purchase-button>
    <icon-payment-method
      v-if="!hasDefaultPatmentMethod"
      style="margin-top: 4px;display: block;text-align: right;"
    ></icon-payment-method>

<!-- modal -->
    <tax-calculation-modal></tax-calculation-modal>
    <purchase-single-modal
      @confirm="confirm"
      @cancel="cancel"
      :is-shown="showConfirmationModal"
      :is-requesting="isRequesting"
      :is-delegated="isDelegated"
      :error="error"
      :item="item"
      :currency="currency"
    ></purchase-single-modal>
    <purchase-finish-modal
      @read="read"
      @close="close"
      :is-shown="showFinishModal"
      :is-single="true"
      :title="item.title"
      :numbering-text="item.numberingText"
    ></purchase-finish-modal>
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancelRead"
    ></access-limit-modal>
  </div>
</template>

<style scoped type="scss">
</style>

<script type="text/babel">
  import * as types from "./store/mutation-types"
  import {mapState, mapMutations} from "vuex"
  import PurchaseSingleModal from "../../molecules/PurchaseSingleModal.vue"
  import PurchaseFinishModal from "../../molecules/PurchaseFinishModal.vue"
  import SelectAddressModal from "../../molecules/SelectAddressModal.vue"
  import LoadingModal from "../../molecules/LoadingModal.vue"
  import PurchaseButton from "../../atoms/PurchaseButton.vue"
  import PreviewButton from "../../atoms/PreviewButton.vue"
  import ReadButton from "../../atoms/ReadButton.vue"
  import IconPaymentMethod from "../../atoms/IconPaymentMethod.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../tax_calculation_modal/TaxCalculationModal.vue"

  const axios = window.axios

  export default {
    components: {
      PurchaseSingleModal,
      PurchaseFinishModal,
      PurchaseButton,
      PreviewButton,
      ReadButton,
      IconPaymentMethod,
      TaxCalculationModal,
      AccessLimitModal,
    },
    mounted() {
      // check cookie
      const storeReferer = this.$cookie.get("store_referer")
      if (!storeReferer) {
        return
      }
      const successfullyRegistered = this.$cookie.get("successfully_registered")
      const storeRefererUrl = document.createElement('a')
      storeRefererUrl.href = decodeURIComponent(storeReferer)
      if (successfullyRegistered && location.pathname === storeRefererUrl.pathname) {
        this.purchase()
      }
    },
    data() {
      return {
        showConfirmationModal: false,
        showFinishModal: false,
        isRequesting: false,
        error: null,
        windowScrollY: Number,
        paymentMethod: "Stripe", //[TODO] 仮
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: null,
      }
    },
    computed: {
      ...mapState(["item", "loginPath", "currency", "payment"]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated
      }),
      hasDefaultPatmentMethod() {
        return this.payment.defaultPaymentMethod ? true : false
      },
    },
    methods: {
      async confirm() {
        this.error = null
        this.isRequesting = true
        try {
          await this.$store.dispatch(types.PURCHASE)
          this.showConfirmationModal= false
          this.showFinishModal = true
          this.item.isPossessed = true
        } catch (e) {
          this.isRequesting = false
          this.error = e.message
        }
      },
      read() {
        this.showFinishModal = false
        this.$store.commit(types.READ)
        this.toggleFixedWindow()
      },
      close() {
        this.showSelectAddressModal = false
        this.showFinishModal = false
        this.toggleFixedWindow()
      },
      cancel() {
        this.showSelectAddressModal = false
        this.showConfirmationModal = false
        this.toggleFixedWindow()
      },
      setCookie() {
        const successfullyRegistered = this.$cookie.get("successfully_registered")
        if (successfullyRegistered) {
          this.$cookie.delete("store_referer")
          this.$cookie.delete("successfully_registered")
        } else {
          this.$cookie.set("store_referer", location.href, 1)
        }
      },
      async purchase() {
        if (this.loginPath) {
          location.href = this.loginPath
        } else {
          this.isRequesting = true

          // CookieにURLを保存
          this.setCookie()

          // Paymentモーダルを使う or Paymentページにさせるか
          this.$store.commit(`${TAX_NAMESPACE}/${taxCalculationModalTypes.USE_PAYMENT_MODAL}`, false)

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: this.item.itemType,
            itemIds: [this.item.id],
            itemTotalAmount: this.item.salePrice || this.item.price,
            selectedCountry: null,
          })
          this.$store.commit(types.SET_TAX, taxInfo)

          this.isRequesting = false
          this.showConfirmationModal = true

          this.toggleFixedWindow()
        }
      },
      toggleFixedWindow() {
        if (this.windowScrollY > 0) {
          window.STATUS.fixed(true)
          window.scrollTo(0, this.windowScrollY)
          this.windowScrollY = 0
        } else {
          this.windowScrollY = window.pageYOffset
          window.STATUS.fixed()
          document.querySelector("body").style.cssText = `top:${-1 * this.windowScrollY}px;`
        }
      },

      moveToPurchase() {
        location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
      },
      cancelRead() {
        this.showAccessLimitModal = false
      },
      async readOrLimit(){
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.error = null
          this.showLoadingModal = true
          let newWindow = window.open(window.location.href)
          try {
            const res = await axios.get(`/store/manga_volume/${this.item.id}/access/count`)
            if (res.data.login_path) {
              this.showLoadingModal = false
              window.location.href = res.data.login_path
              newWindow.close()
            } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > this.item.accessLimit) {
              this.saleItem = this.item
              this.showLoadingModal = false
              this.showAccessLimitModal = true
              newWindow.close()
            } else {
              this.showLoadingModal = false
              newWindow.location.href = this.item.viewerUrl
            }
          } catch (e) {
            this.showLoadingModal = false
            // 握りつぶす
          }
        }
      },
    },
  }
</script>
