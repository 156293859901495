var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { class: _vm.statusClass, attrs: { href: _vm.path } }, [
    _c("picture", [
      _c("source", {
        attrs: {
          type: "image/webp",
          srcset:
            _vm.assetImg.toWebp().toUrl() +
            " 1x," +
            _vm.assetImg
              .toRetina()
              .toWebp()
              .toUrl() +
            " 2x"
        }
      }),
      _vm._v(" "),
      _c("source", {
        attrs: {
          srcset:
            _vm.assetImg.toJpeg().toUrl() +
            " 1x, " +
            _vm.assetImg
              .toRetina()
              .toJpeg()
              .toUrl() +
            " 2x"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: _vm.assetImg.toJpeg().toUrl(),
          alt: _vm.title,
          width: _vm.width,
          height: _vm.height
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }