var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("label", [_vm._v("Status")]),
    _vm._v(" "),
    _c(
      "select",
      {
        domProps: { value: _vm.statusId },
        on: {
          change: function($event) {
            return _vm.change($event.target.value)
          }
        }
      },
      _vm._l(_vm.statusList, function(status, id) {
        return _c("option", { domProps: { value: id } }, [
          _vm._v("\n    " + _vm._s(status.str) + "\n    ")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }