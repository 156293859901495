<template>
  <div>
    <div class="searchbox-wrap">
      <div class="genre-list-toggle-wrap">
        <a class="genre-list-toggle" @click="toggle" :class="{'is-open': isSeeAll}">{{ selectedGenreName }}</a>
      </div>
      <form class="searchbox-container" @submit.prevent="jump()">
        <input type="text" v-model="keyword" placeholder="Search Manga..." @keyup="inputText()">
        <input type="submit" value="" :class="{ on: isSubmitActive }">
      </form>
    </div>
    <div class="genre-list-wrap">
      <ul class="genre-list" v-show="isSeeAll">
        <li>
          <a class="genre-list-item" @click="clearSelection">All Genres</a>
        </li>
        <li v-for="genre in genres">
          <a class="genre-list-item" @click="select(genre.id)">{{ genre.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .searchbox-wrap {
    background-color: #e1e7f5;
    display: -webkit-box;
    display: flex;
    width: 100%;
  }
  .searchbox-container {
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    padding: 10px 10px 10px 0;
    input[type="text"] {
      -moz-appearance: none;
      -webkit-appearance: none;
      box-sizing: border-box;
      border-radius: 4px 0 0 4px;
      background-color: #FFF;
      border: 1px solid #A8AEB2;
      height: 36px;
      padding: 0 10px;
      width: calc(100% - 36px);
      color: #8E8E93;
      font-size: 14px;
    }
    input[type="submit"] {
      position: absolute;
      right: 11px;
      -moz-appearance: none;
      -webkit-appearance: none;
      box-sizing: border-box;
      border-radius: 0 4px 4px 0;
      background-position: -44px -58px;
      border: 1px solid #A8AEB2;
      background-color: #ced8ef;
      width: 36px;
      height: 36px;
      background-image: url(/img/sp/common/icon_search_set.png?v=160803001);
      background-repeat: no-repeat;
      background-size: 75px 98px;
      &.on {
        background-color: #8594B9;
        border: 1px solid #59637E;
      }
    }
  }
  .genre-list-wrap {
    position: relative;
  }
  a.genre-list-toggle {
    font-size: 14px;
    font-weight: bold;
    color: #2E51A2;
    height: 36px;
    box-sizing: border-box;
    margin: 10px 10px 10px 0;
    padding: 0 0 0 10px;
    line-height: 36px;
    height: 36px;
    display: block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    &:after {
      content: '';
      position: relative;
      display: inline-block;
      margin-left: 3px;
      width: 0;
    	height: 0;
    	border-right: 5px solid transparent;
    	border-left: 5px solid transparent;
      border-top: 7px solid #2E51A2;
      border-bottom: 7px solid transparent;
      top: 7px;
    }
    &.is-open:after {
      border-top: 7px solid transparent;
    	border-bottom: 7px solid #2E51A2;
      top: 0;
    }
  }
  .genre-list {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
    	height: 16px;
      border: 1px solid #EBEBEB;
      background-color: #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -9px;
      left: 48px;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      background-color: #fff;
      width: 30px;
      height: 14px;
      top: 0;
      left: 45px;
    }
  }
  a.genre-list-item {
    display: block;
    color: #333;
    border-bottom: 1px solid #EBEBEB;
    padding: 15px 10px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
  }
</style>

<script type="text/babel">
  import * as types from "./store/mutation-types"
  import {mapState, mapGetters} from "vuex"

  export default {
    data() {
      return {
        isSeeAll: false,
        isSubmitActive: false,
      }
    },
    computed: {
      ...mapState([
        "genres",
        "selectedGenreId"
      ]),
      ...mapGetters([
        "generatePath",
      ]),
      keyword: {
        get() {
          return this.$store.state.keyword
        },
        set(value) {
          this.$store.commit(types.UPDATE_KEYWORD, value)
        }
      },
      selectedGenreName() {
        if (this.selectedGenreId === 0) {
          return "All Genres"
        } else {
          return _.find(this.genres, g => g.id === this.selectedGenreId).name
        }
      },
    },
    methods: {
      toggle() {
        this.isSeeAll = !this.isSeeAll
      },
      clearSelection() {
        this.$store.commit(types.SELECT, 0)
        this.isSeeAll = false
        this.isSubmitActive = false
      },
      select(id) {
        this.$store.commit(types.SELECT, id)
        this.isSeeAll = false
        this.isSubmitActive = true
      },
      jump() {
        window.location.href = this.generatePath
      },
      inputText() {
        this.isSubmitActive = this.keyword.length > 0 ? true : false
      }
    },
  }
</script>
