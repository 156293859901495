
export const INITIALIZE = "INITIALIZE"

// チュートリアルを表示
export const SHOW_TUTORIAL = "SHOW_TUTORIAL"

// ページ遷移
export const MOVE_PAGE = "MOVE_PAGE"
export const JUMP_PAGE = "JUMP_PAGE"
export const TOGGLE_MARGINALS = "TOGGLE_MARGINALS"
export const CHANGE_DIRECTION = "CHANGE_DIRECTION"

// 端末の回転状態
export const CHANGE_ORIENTATION_STATE = "CHANGE_ORIENTATION_STATE"

// 画像のリクエストを送信したことを保存する
export const REQUESTED_IMAGE = "REQUESTED_IMAGE"

// 取得した画像データから画像を準備する
export const SETUP_IMAGE = "SETUP_IMAGE"

// 画像を読み込む(前後のページも先読みする)
export const LOAD_IMAGES = "LOAD_IMAGES"

// 読んだページを DB に保存する
export const BOOKMARK = "BOOKMARK"

// Viewer を開いている間の行動情報をサーバに送信する
export const LOG = "LOG"

// チュートリアルのモーダル
export const SHOW_TUTORIAL_MODAL = "SHOW_TUTORIAL_MODAL"
export const HIDE_TUTORIAL_MODAL = "HIDE_TUTORIAL_MODAL"

// ストーリー詳細のモーダル
export const SHOW_STORY_MODAL = "SHOW_STORY_MODAL"
export const HIDE_STORY_MODAL = "HIDE_STORY_MODAL"

// マンガが右開きであることを警告するモーダル
export const SHOW_NOPAGE_MODAL = "SHOW_NOPAGE_MODAL"
export const HIDE_NOPAGE_MODAL = "HIDE_NOPAGE_MODAL"

// 読了モーダル
export const HIDE_FINISH_MODAL = "HIDE_FINISH_MODAL"

// 署名付き URL のパラメータを再発行する
export const RENEW_TOKEN = "RENEW_TOKEN"

// マンガが左開きであることを注意するモーダル
export const SHOW_LEFTTORIGHT_MODAL = "SHOW_LEFTTORIGHT_MODAL"
export const HIDE_LEFTTORIGHT_MODAL = "HIDE_LEFTTORIGHT_MODAL"

export const SET_TAX = "SET_TAX"
export const PURCHASE = "PURCHASE"
export const PURCHASED = "PURCHASED"
