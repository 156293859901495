var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "searchbox-wrap" }, [
      _c("div", { staticClass: "genre-list-toggle-wrap" }, [
        _c(
          "a",
          {
            staticClass: "genre-list-toggle",
            class: { "is-open": _vm.isSeeAll },
            on: { click: _vm.toggle }
          },
          [_vm._v(_vm._s(_vm.selectedGenreName))]
        )
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "searchbox-container",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.jump()
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            attrs: { type: "text", placeholder: "Search Manga..." },
            domProps: { value: _vm.keyword },
            on: {
              keyup: function($event) {
                return _vm.inputText()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.keyword = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            class: { on: _vm.isSubmitActive },
            attrs: { type: "submit", value: "" }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "genre-list-wrap" }, [
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSeeAll,
              expression: "isSeeAll"
            }
          ],
          staticClass: "genre-list"
        },
        [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "genre-list-item",
                on: { click: _vm.clearSelection }
              },
              [_vm._v("All Genres")]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.genres, function(genre) {
            return _c("li", [
              _c(
                "a",
                {
                  staticClass: "genre-list-item",
                  on: {
                    click: function($event) {
                      return _vm.select(genre.id)
                    }
                  }
                },
                [_vm._v(_vm._s(genre.name))]
              )
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }