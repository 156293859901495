<template>
  <div class="item" :style="{ width : width ? ( width + 'px') : 'auto' }">
    <a :href="urlWithLocation(item.url, location)">
      <div class="cover-wrap"
        :class="{iconNew: !item.isPossessed && item.isNew}"
      >
        <img v-lazy="coverImage">
      </div>
    </a>
    <div class="title-wrap" :style="{ width : width ? ( (width - 8) + 'px') : 'auto' }">
      <div v-if="hideTitle !== true" class="title">{{ item.title }}</div>
      <div class="volume">{{ item.numberingText }}</div>
    </div>
    <div v-if="!item.isPossessed" class="price-wrap">
      <span class="price" :class="{'is-sale': item.salePrice >= 0}">{{ currency.formatSymbolAndPrice(item.price) }}</span>
      <span class="price-sale" v-show="item.salePrice >= 0">{{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
    </div>

    <a v-if="!item.isPossessed && item.isPreviewable && !item.isFree"
      :href="urlWithLocation(item.previewUrl, location)"
      class="button button-preview"
      target="_blank"
    >Preview</a>
    <a v-if="item.isPossessed || item.isFree"
      @click="click"
      class="button button-read"
      target="_blank"
    >Read</a>

  </div>
</template>
<style lang="scss" scoped>
  $title-color : #323232;
  $volume-color : #7a7a7a;
  $price-color : #C0392B;
  $price-sale-color : #FF0000;

  .item {
    position: relative;
    display: table-cell;
    text-align: center;
    padding: 0 4px 24px;
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    box-sizing: border-box;
  }
  .cover-wrap {
    position: relative;
    display: inline-block;
    height: 135px;
    text-decoration: none;
    &.iconNew:after {
      position: absolute;
      top: 1px;
      left: 1px;
      background-image: url('/images/manga_store/icon_new.png');
      background-size: 47px 47px;
      background-position: left top;
      background-repeat: no-repeat;
      display: inline-blockl;
      width: 47px;
      height: 47px;
      content: "";
    }
  }
  img {
    width: auto;
    min-width: 90px;
    height: 135px;
    border: 1px solid #E1E7F5;
    box-sizing: border-box;
    vertical-align: bottom;
    &:active {
      opacity: 0.8;
    }
  }
  img[lazy=loading] {
    background-image: url('/images/xmlhttp-loader.gif');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .title-wrap {
    margin: 4px 0 0 ;
  }
  .title {
    color: $title-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2em;
    text-align: left;
    text-decoration: none;
    // word-wrap:break-word;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // display: -webkit-box;
    display: block;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 2px;
  }
  .volume {
    margin: 0;
    color: $volume-color;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }
  .price-wrap {
    margin: 4px 0 0 ;
    font-size: 12px;
    text-decoration: none;
    font-weight: 300;
    text-align: left;
  }
  .price {
    color: $price-color;
    &.is-sale {
      font-size: 12px;
      color: #7a7a7a;
      text-decoration: line-through;
    }
  }
  .price-sale {
    color: $price-sale-color;
    font-weight: 700;
  }
  .button {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80px;
    margin-left: -40px;
    padding: 2px;
    text-align: center;
    border: #2e51a2 1px solid;
    border-radius: 2px;
    box-sizing: border-box;
    font-weight: 500;
    &:hover {
      color: #ffffff;
      background-color: #2e51a2;
    }
  }

  .button-preview {
    color: #2e51a2;
    background-color: #ffffff;
    &:hover {
      color: #2e51a2;
      background-color: rgba(#2e51a2, 0.3);
    }
  }
  .button-read {
      color: #ffffff;
      background-color: #2e51a2;
    &:hover {
      color: #ffffff;
      background-color: rgba(#2e51a2, 0.7);
    }
  }
</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"

  export default {
    props: {
      /**
       * @param {String} item.title
       * @param {String} item.numberingText
       * @param {String} item.url
       * @param {String} item.previewUrl
       * @param {String} item.coverImageBaseUrl
       * @param {Boolean} item.isPurchased
       * @param {Number} item.price
       * @param {Number} item.salePrice

       */
      item      : Object,
      currency  : Object,
      width     : Number,
      location: String,
      hideTitle : {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      coverImage() {
        const imgObj = {
          src: generateCoverImageUrl(this.item.coverImageBaseUrl)
        }
        return imgObj
      }
    },
    methods: {
      click() {
        if (this.item.isPossessed || !this.item.accessLimit) {
          window.open(this.item.viewerUrl)
        } else {
          this.$emit('accessLimit', this.item)
        }
      }
    },
  }
</script>
