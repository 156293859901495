/**
 * @see Mal\Constant\OwnList
 */
export const AnimeUserStatusMap = {
  1: {key: "watching", str: "Watching"},
  2: {key: "completed", str: "Completed"},
  3: {key: "on-hold", str: "On-Hold"},
  4: {key: "dropped", str: "Dropped"},
  6: {key: "plan-to-watch", str: "Plan to Watch"},
}
export const MangaUserStatusMap = {
  1: {key: "reading", str: "Reading"},
  2: {key: "completed", str: "Completed"},
  3: {key: "on-hold", str: "On-Hold"},
  4: {key: "dropped", str: "Dropped"},
  6: {key: "plan-to-read", str: "Plan to Read"},
}
export const getUserStatusMapByWorkType = workType => {
  return workType === "anime" ? AnimeUserStatusMap : MangaUserStatusMap
}

export const ScoreList = [
  {val: 10, str: "Masterpiece"},
  {val: 9, str: "Great"},
  {val: 8, str: "Very Good"},
  {val: 7, str: "Good"},
  {val: 6, str: "Fine"},
  {val: 5, str: "Average"},
  {val: 4, str: "Bad"},
  {val: 3, str: "Very Bad"},
  {val: 2, str: "Horrible"},
  {val: 1, str: "Appalling"},
]

/**
 * @see Mal\Consts
 */
export const AnimeStatusMap = {
  1: "Currently Airing",
  2: "Finished Airing",
  3: "Not yet aired",
}
export const MangaStatusMap = {
  1: "Publishing",
  2: "Finished",
  3: "Not yet published",
}
