const SVue = window.sVue;
const _ = window._;
const $ = window.$;

import WebpDetector from "../../../common/modules/WebpDetector"
import AssetImage from "../../../common/modules/AssetImage"

$(() => {

  (async () => {
    const supportsWebP = await WebpDetector.isSupported()

    const vNotification = new SVue({
      el: "#vm-notification",
      data: {
        notification: {},
        isLoading: true,
        friends: []
      },
      methods: {
        getNotificationCount: function (data) {
          if (this.notification !== {}) {
            return
          }

          this.notification = data
        },
        getFriendRequest: function () {
          if (this.friends.length > 0) {
            return
          }

          $.ajax({
            url: "/notification/api/request_and_check_friend_request_items.json",
            type: "POST",
            success: response => {
              this.friends = response
              this.isLoading = false
              // remove notification Dot icon from Header
              $("#btn-notification").removeClass("has-notification")
            }
          })
        },
        checkAllItemsAsReadAndViewAll: function (url) {
          $.ajax({
            url: "/notification/api/check_all_friend_request_items_as_read.json",
            type: "POST",
          })
          location.href = url
        },
        acceptFriendRequests: function (id, index) {
          $.ajax({
            url: "/notification/api/accept-friend-request.json",
            data: JSON.stringify({notification_ids: [id]}),
            contentType: "application/json",
            type: "POST",
            success: response => {
              // this.friends[index].isApproved = true
              //
              // It can happen that two requests from the same user appear.
              //   1. User A receives friend requests from B
              //   2. A accepts, the notification becomes approved
              //   3. B deletes A, the notification (2.) appears as "deleted" to A
              //   4. User A again receives friend requests from B
              //   5. Now there is one new notification plus the old "deleted" one becomes undeleted
              //
              //   The result is that we have two requests from the same user. We need to set both approved.
              //
              const name = this.friends[index].friendName
              this.friends.forEach((el) => {
                if (el.typeIdentifier === 'friend_request' && el.friendName === name) {
                  el.isApproved = true
                }
              })
            },
            error: error => {
              this.friends[index].error = error.responseJSON.errors[0].message
            }
          })
        },
        denyFriendRequests: function (id, index) {
          if (window.confirm("Are you sure you want to deny this request?")) { // eslint-disable-line no-alert
            $.ajax({
              url: "/notification/api/deny-friend-request.json",
              data: JSON.stringify({notification_ids: [id]}),
              contentType: "application/json",
              type: "POST",
              success: response => {
                // this.friends.splice(index, 1)
                //
                // See acceptFriendRequests...
                //
                const name = this.friends[index].friendName
                this.friends = this.friends.filter((el) => {
                  return el.typeIdentifier !== 'friend_request' || el.friendName !== name
                })
              },
              error: error => {
                this.friends[index].error = error.responseJSON.errors[0].message
              }
            })
          }
        }
      },
    })

    if ($(".js-btn-notification")[0]) {

      const resizeNotification = function() {
        const winH = $(window).height()
        const offset = $("#vm-notification").hasClass("supporter") ? 153 : 203
        $("#vm-notification").find(".notification-friend").css({"height": (winH - offset) + "px"})
      }

      $(".js-btn-notification").on("touchend", function (e) {
        e.preventDefault()
        window.STATUS.fixed()
        const $outer = $(".js-notification-outer")

        $outer.on("click", function(e) {
          if (!$(e.target).closest(".notification-container").length) {
            window.STATUS.fixed(true)
            $(this).removeClass("show")
          }
        })

        if ($outer.hasClass("show")) {
          $outer.removeClass("show")
        } else {
          // hide menu and search
          const $searchBoxOuter = $(".js-search-box-outer");
          if ($searchBoxOuter.hasClass("show")) {
            $searchBoxOuter.removeClass("show")
          }
          const $menu = $(".js-menu")
          const $faMenu = $(".js-fa-menu")
          const $faClose = $(".js-fa-close")
          if ($menu.hasClass("show")) {
            $menu.removeClass("show")
            $faMenu.addClass("show")
            $faClose.removeClass("show")
          }

          // show notification
          const notificationCount = $("#vm-notification").find(".notification-friend").data("notification")
          vNotification.getNotificationCount(notificationCount)
          vNotification.getFriendRequest()
          resizeNotification()
          const docH = $(document).height()
          $outer.addClass("show").css({"height": docH})
        }
      })

      $(window).on("resize", function() {
        resizeNotification()
      })
    }

  })()

})
