increment用のbutton
<template>
  <button @touchstart="increment" :class="classStr">
    <i class="fa-solid fa-circle-plus fa-2x"></i>
  </button>
</template>

<script>
export default {
  props: {
    className: String, // style適用するためのclass名
    isActive: Boolean, // buttonをactiveにするかどうか
  },
  computed: {
    classStr() {
      return this.isActive ? this.className : `not-active ${this.className}`
    },
  },
  methods: {
    increment() {
      if (this.isActive) {
        this.$emit("increment")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/variables";
button {
  color: $mal-blue;
  background-color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;

  &.not-active {
    opacity: .5;
  }
}
</style>
