var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tile-unit", style: _vm.backgroundImg }, [
    _vm.isOwner
      ? _c(
          "span",
          { staticClass: "edit-icon" },
          [
            _c("colored-edit-icon", {
              attrs: {
                id: _vm.id,
                "work-type": _vm.workType,
                "status-id": _vm.userStatusId
              },
              on: { "edit-list-item": _vm.showModal }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("information", {
          attrs: {
            title: _vm.title,
            head: _vm.head,
            completed: _vm.completed,
            score: _vm.score
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.path } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }