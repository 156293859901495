import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import taxStore, {NAMESPACE as TAX_NAMESPACE} from "../../../../common/stores/tax_calculation_modal"

export default () => {
  const state = {
    item: Object,
    currency: Object,
    loginPath: null,

    // Paymentの各種設定情報
    payment: {
      defaultPaymentMethod: null,
    },
  }

  return new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {
      [TAX_NAMESPACE]: taxStore(),
    }
  })
}
