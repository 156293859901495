const _ = window._
import * as types from "./mutation-types"
import axios from "axios"
import ModalNS from "../../../components/list_modal/store_module/namespace"
import {INIT_DATA} from "../../../components/list_modal/store_module/mutation-types"
import { load } from 'recaptcha-v3'

export default {
  initModalData({state, commit}, id) {
    const targetItem = state.items[id]
    commit(`${ModalNS}/${INIT_DATA}`, targetItem)
  },
  [types.UPDATE_USER_STATUS]({state, commit}, {id, userStatusId, done}) {
    postStatus({
      state,
      commit,
      id,
      next: {status: userStatusId},
      done,
      success() {
        commit(types.UPDATE_USER_STATUS, {id, userStatusId})
      },
    })
  },
  [types.UPDATE_SCORE]({state, commit}, {id, score, done}) {
    postStatus({
      state,
      commit,
      id,
      next: {score: score},
      done,
      success() {
        commit(types.UPDATE_SCORE, {id, score})
      },
    })
  },
  [types.INCREMENT_EPISODE]({state, commit}, {id, done}) {
    const targetItem = state.items[id]
    postStatus({
      state,
      commit,
      id,
      next: {num_watched_episodes: targetItem.watchedEpisodes + 1},
      done,
      success() {
        commit(types.INCREMENT_EPISODE, {id})
      },
    })
  },
  [types.INCREMENT_VOLUME]({state, commit}, {id, done}) {
    const targetItem = state.items[id]
    postStatus({
      state,
      commit,
      id,
      next: {num_read_volumes: targetItem.readVolumes + 1},
      done,
      success() {
        commit(types.INCREMENT_VOLUME, {id})
      },
    })
  },
  [types.INCREMENT_CHAPTER]({state, commit}, {id, done}) {
    const targetItem = state.items[id]
    postStatus({
      state,
      commit,
      id,
      next: {num_read_chapters: targetItem.readChapters + 1},
      done,
      success() {
        commit(types.INCREMENT_CHAPTER, {id})
      },
    })
  },
}

const postStatus = ({state, commit, id, next, done = () => {}, success = () => {}, error = () => {}}) => {
  const data = makeData({
    workType: state.workType,
    current: state.items[id],
    next
  })

  const request = async () => {
    await axios.post(`/ownlist/${state.workType}/edit.json`,
      JSON.stringify(data),
    ).then(({data}) => {
      return success(data)
    }).catch(err => {
      if (err.response) {
        commit(types.API_ERROR, {id, err: err.response.statusText})
      } else {
        commit(types.UNKNOWN_ERROR, {id, err: err.message})
      }
    })

    done()
  }
  request()
}

const makeData = ({workType, current, next}) => {
  const data = {
    status: current.userStatusId,
    score: current.score,
  }

  if (workType === "anime") {
    _.assign(data, {
      anime_id: current.id,
      num_watched_episodes: current.watchedEpisodes,
    })
  } else {
    _.assign(data, {
      manga_id: current.id,
      num_read_volumes: current.readVolumes,
      num_read_chapters: current.readChapters,
    })
  }

  _.assign(data, next)

  // to be completed
  if (_.has(next, "status") && next.status === 2) {
    if (current.totalEpisodes > 0) {
      data.num_watched_episodes = current.totalEpisodes
    } else {
      if (current.totalVolumes > 0) {
        data.num_read_volumes = current.totalVolumes
      }
      if (current.totalChapters > 0) {
        data.num_read_chapters = current.totalChapters
      }
    }
  }

  return data
}

