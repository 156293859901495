list編集アイコンを表示する、statusによって色が変わる
<template>
  <span @click="editListItem" :class="statusClass">
  </span>
</template>

<script>
import {getUserStatusMapByWorkType} from "../../../utils/list"
export default {
  props: {
    id: Number,
    workType: String, // anime or manga
    statusId: Number, // listについてのユーザのstatus
  },
  computed: {
    statusClass() {
      const statusMap = getUserStatusMapByWorkType(this.workType)
      return statusMap[this.statusId].key
    },
  },
  methods: {
    editListItem() {
      this.$emit("edit-list-item")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/variables";
@import "../../../../css/mixin";

span {
  width: 23px;
  height: 23px;
  display: block;
  @include vendor-prefixes(border-radius, 4px);

  &.watching {
    background-color: rgba($list-status-watching, 0.85);
  }
  &.reading {
    background-color: rgba($list-status-reading, 0.85);
  }
  &.completed {
    background-color: rgba($list-status-completed, 0.85);
  }
  &.on-hold {
    background-color: rgba($list-status-on-hold, 0.85);
  }
  &.dropped {
    background-color: rgba($list-status-dropped, 0.85);
  }
  &.plan-to-watch {
    background-color: rgba($list-status-plan-to-watch, 0.85);
  }
  &.plan-to-read {
    background-color: rgba($list-status-plan-to-read, 0.85);
  }

  &:before {
    top: 0;
    left: 0;
    font-family: 'Font Awesome 6 Pro', Avenir, "lucida grande", tahoma, verdana, arial, sans-serif;
    font-weight: 900;
    content: "\f044";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -5px;
    z-index: 30;
  }
}
</style>
