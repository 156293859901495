<template>
  <div class="root">
    <header class="header"
       :class="{'hide': !show}"
       :style="{width: marginalsWidth}">
      <a v-if="!isPreview && !isFree" class="button-story" @click="showStoryModal">Synopsis</a>
      <a v-if="isPreview" class="button-buy" @click="$emit('purchase')">Buy</a>
      <a v-if="customButton.type === 1" class="button-custom" :style="{ backgroundColor: customButton.backgroundColor, color: customButton.color, border: '1px solid' }" @click="custom(customButton.url)">{{ customButton.text }}</a>
      <a v-if="customButton.type === 2" class="button-custom" v-lazy:background-image="customButton.backgroundImage" style="height: 20px; background-size: contain; background-repeat: no-repeat;" @click="custom(customButton.url)"></a>
      <div class="title-wrap">
        <span class="title">{{ book.title }}</span>
        <span class="volume">{{ book.numberingText }}</span>
      </div>
        <a class="button-close" v-if="isPortrait" v-show="windowOpener" @click="close">Close</a>
    </header>
    <footer class="footer"
       :class="{'hide': !show}"
       :style="{bottom: (show ? bottomPosition : null), width: marginalsWidth}">
      <a class="button-tutorial" @click="showTutorialModal">Help</a>
      <div class="pagecount">
        <span class="pagecount-current">{{ currentPage }}</span>
        <span class="pagecount-total">{{ numTotalPages }}</span>
      </div>
      <seek-bar
        :num-total-pages="numTotalPages"
        :current-page="currentPage"
        :is-portrait="isPortrait"
        :manuscript="manuscript"
        :page-direction="pageDirection"
      ></seek-bar>
      <span class="button-direction"
         @click="changeDirection"
         :class="{opposite: switchDirection}">
        Direction
      </span>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
  .root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .header,.footer{
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
  }
  .title-wrap {
    margin: 10px 75px 0;
    line-height: 20px;
    display: block;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
  }
  .title {
    display: inline-block;
    max-width: calc(100% - 70px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .volume {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 5px;
  }
  .button-direction {
    position: absolute;
    top: 6px;
    right: 43px;
    content: '';
    display: block;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: url(/images/manga_store/viewer/sp/direction@2x.png?v=161130001) no-repeat left top;
    background-size: auto 28px;
    background-position: right center;
    text-indent: -99999px;
    &.opposite {
      background-position: left center;
    }
  }
  .button-story {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 55px;
    line-height: 18px;
    padding: 1px 0 0;
    vertical-align: middle;
    border: 1px solid #fff;
    border-radius: 2px;
    display: block;
    cursor: pointer;
    color: #fff;
    font-size: 11px;
    text-align: center;
  }
  .button-buy {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: #ffffff;
    width: 55px;
    line-height: 18px;
    padding: 1px 0 0;
    vertical-align: middle;
    border: 1px solid #ff9600;
    border-radius: 2px;
    display: block;
    color: #ff9600;
    font-size: 11px;
    text-align: center;
    &:active {
      background-color: #ffead3;
    }
    @media screen and (orientation: landscape) {
      text-shadow: none;
    }
  }
  .button-custom {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 55px;
    line-height: 18px;
    padding: 1px 0 0;
    vertical-align: middle;
    border-radius: 2px;
    display: block;
    font-size: 11px;
    text-align: center;
    @media screen and (orientation: landscape) {
      text-shadow: none;
    }
  }
  .button-close {
    position: absolute;
    top: 13px;
    right: 10px;
    content: '';
    display: block;
    cursor: pointer;
    width: 14px;
    height: 14px;
    background: url(/images/manga_store/viewer/sp/close@2x.png?v=161130001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: #2E51A2;
    color: #fff;
    transition: all .3s ease;
    &.hide {
      top: -40px;
    }
    @media screen and (orientation: landscape) {
      background-color: rgba(#2E51A2, 0.85);
      top: 45px;
      left: 30px;
      width: calc(100% - 60px);
      border-radius: 4px;
      text-shadow: #323232 -1px -1px 0;
    }
  }
  .pagecount{
    position: absolute;
    top: 8px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    margin-top: 3px;
    line-height: 17px;
  }
  .pagecount-current{
    font-size: 14px;
    &:after{
      content:'/';
      display: inline-block;
      margin: 0 0 0 1px;
    }
  }
  .pagecount-total{
    font-size: 10px;
    padding: 0 0 0 1px;
  }
  .button-tutorial{
    position: absolute;
    top: 6px;
    right: 10px;
    content: '';
    display: block;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: url(/images/manga_store/viewer/sp/tutorial@2x.png?v=161130001) no-repeat left top;
    background-size: auto 28px;
    vertical-align: top;
    text-indent: -99999px;
  }
  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #2E51A2;
    color: #fff;
    transition: all .3s ease;
    &.hide {
      bottom: -40px;
    }
    @media screen and (orientation: landscape) {
      background-color: rgba(#2E51A2, 0.85);
      bottom: 45px;
      left: 30px;
      width: calc(100% - 60px);
      border-radius: 4px;
      text-shadow: #323232 -1px -1px 0;
    }
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import SeekBar from "./SeekBar.vue"
  import { mapGetters } from "vuex"

  export default {
    mounted() {
      // orientationchangeだとandroidで効かない端末がある
      window.addEventListener("resize", this.resized)
      window.addEventListener("orientationchange", this.orientationchanged)
    },
    components: { SeekBar },
    data() {
      return {
        windowOpener : !!window.opener,
      }
    },
    props: {
      show: Boolean,
      book: Object,
      customButton: Object,
      manuscript: Object,
      currentPage: Number,
      numTotalPages: Number,
      isPortrait: Boolean,
      isPreview: Boolean,
      isFree: Boolean,
      isAdShown: Boolean,
      currentTimestamp: String,
      pageDirection: Number,
    },
    computed: {
      ...mapGetters([
        'isIos',
      ]),
      switchDirection() {
        return this.pageDirection === -1 ? true : false
      },
      osVersion() {
        if(this.isIos) {
          const appVersion = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
          const version = [parseInt(appVersion[1], 10), parseInt(appVersion[2], 10), parseInt(appVersion[3] || 0, 10)]
          return version[0]
        } else {
          return null
        }
      },
      bottomPosition() {
        if (!this.isPortrait && this.isIos && this.osVersion >= 11 ) {
          const offsetHeight = Math.round(window.innerHeight - window.parent.screen.height / window.devicePixelRatio)
          return `${(offsetHeight > 60) ? offsetHeight : 60}px`
        }
      },
      marginalsWidth() {
        if (!this.isPortrait && this.isIos && this.osVersion >= 11 ) {
          return `${window.innerWidth - 60}px`
        }
      }
      
    },
    methods: {
      close() {
        window.close()
      },
      showStoryModal() {
        this.$store.commit(types.SHOW_STORY_MODAL)
      },
      showTutorialModal() {
        this.$store.commit(types.SHOW_TUTORIAL_MODAL)
      },
      changeDirection() {
        this.$store.commit(types.CHANGE_DIRECTION)
      },
       showFinishModal() {
        this.$store.commit(types.SHOW_FINISH_MODAL)
      },
      custom(url) {
          location.href = url
      },
    },
  }
</script>
