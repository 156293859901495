<template>
  <div>
    <div class="slider-wrap">
      <div class="slider-container"
         :class="{noMargin: options.no_margin}">
        <div class="slider">
          <comic
            v-if="isTypeComic"
            v-for="item in items"
            :item="item"
            :width="itemWidth"
            :height="itemHeight"
            :no-margin="options.no_margin"
            :location="options.location"
            :isRecommendation="isRecommendation"
          ></comic>
          <book
            v-if="isTypeVolume"
            v-for="item in items"
            @accessLimit="accessLimit(item)"
            :item="item"
            :currency="currency"
            :width="itemWidth"
            :hide-title="isHideTitle"
            :location="options.location"
            :isRecommendation="isRecommendation"
          ></book>
          <chapter
            v-if="isTypeChapter"
            v-for="item in items"
            @accessLimit="accessLimit(item)"
            :item="item"
            :currency="currency"
            :width="itemWidth"
            :hide-title="isHideTitle"
            :login-path="loginPath"
            :location="options.location"
            :isRecommendation="isRecommendation"
            @purchase="purchase(item)"
          ></chapter>
          <view-more
            v-if="hasViewMore"
            :is-requesting="isLoadingItems"
            @viewMore="loadMoreItems"
          ></view-more>
          <view-more
            v-if="hasComicPath"
            @viewMore="jumpToComicPath"
          ></view-more>
        </div>
      </div>
    </div>

<!-- modal -->
    <tax-calculation-modal></tax-calculation-modal>
    <template v-if="showModal">
      <purchase-single-modal
        @confirm="confirm"
        @cancel="cancel"
        :is-shown="showConfirmationModal"
        :is-requesting="isPurchasing"
        :error="error"
        :item="modalItem"
        :currency="currency"
        :is-delegated="isDelegated"
      ></purchase-single-modal>
      <purchase-finish-modal
        @read="read"
        @close="close"
        :is-shown="showFinishModal"
        :is-single="true"
        :title="modalItem.title"
        :numbering-text="modalItem.numberingText"
      ></purchase-finish-modal>
    </template>
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancelRead"
    ></access-limit-modal>
  </div>
</template>

<style lang="scss" scoped>
  .slider-wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
  }

  .slider-container {
    width: 100%;
    box-sizing: border-box;
    padding-left: 4px;
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &.noMargin {
      padding-left: 0px;
    }
  }

  .slider {
    display: table;
  }
</style>

<script type="text/babel">
  import * as book from "../../../common/modules/book"
  import {showViewer} from "../../../common/modules/viewer"
  import {purchase} from "../../../common/modules/purchase"
  import * as types from "../../../common/stores/all_books/mutation-types"
  import Comic from "../../molecules/Comic.vue"
  import Book from "../../molecules/Book.vue"
  import Chapter from "../../molecules/Chapter.vue"
  import ViewMore from "../../molecules/ViewMore.vue"
  import PurchaseSingleModal from "../../molecules/PurchaseSingleModal.vue"
  import PurchaseFinishModal from "../../molecules/PurchaseFinishModal.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"
  import {mapState, mapMutations, mapGetters} from "vuex"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../tax_calculation_modal/TaxCalculationModal.vue"

  import appendQuery from "append-query"

  const axios = window.axios

  export default {
    components: {
      Comic,
      Book,
      Chapter,
      ViewMore,
      PurchaseSingleModal,
      PurchaseFinishModal,
      TaxCalculationModal,
      AccessLimitModal,
    },
    data() {
      return {
        numShowItemsPerPage: this.options.top_num_per_page ? 2.6 : 3.5,
        showModal: false,
        modalItem: {},
        showConfirmationModal: false,
        showFinishModal: false,
        isRequesting: false,
        // TODO ???
        error: null,
        windowScrollY: Number,
        sliderItems: this.items,
        paymentMethod: "Stripe", //[TODO] 仮
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: null,
      }
    },
    props: {
      options: Object,
    },
    computed: {
      ...mapState([
        "itemType",
        "loginPath",
        "currency",
        "numItemsPerPage",
        "numTotalItems",
        "loadItemsPath",
        "isPurchasing",
        "isLoadingItems",
        "currentPage",
      ]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated
      }),
      ...mapGetters([
        "numPages",
        "isTypeComic",
        "isTypeVolume",
        "isTypeChapter",
        "items",
        "hasMorePage",
      ]),

      isRecommendation() {
        return this.options.isRecommendation || false
      },

      hasComicPath() {
        // for Anime|Manga Detail
        return (this.options.view_more && this.options.store_comic_path) ? true : false
      },

      hasViewMore() {
        return this.options.view_more && this.hasMorePage
      },

      isHideTitle() {
        return this.options.hide_title || false
      },

      itemWidth() {
        if (screen.width > screen.height) {
          return screen.height / this.numShowItemsPerPage
        } else {
          if (screen.width >= 768) {
            return 120
          } else {
            return screen.width / this.numShowItemsPerPage
          }
        }
      },

      itemHeight() {
        if (this.options.top_num_per_page) {
          return (screen.width / this.numShowItemsPerPage) / 5 * 7
        } else {
          return 135
        }
      },
    },
    methods: {

      jumpToComicPath() {
        window.location.href = this.urlWithLocation(this.options.store_comic_path, this.options.location)
      },

      async loadMoreItems() {
        this.error = null
        try {
          await this.$store.dispatch(types.LOAD_MORE, this.currentPage + 1)
        } catch (e) {
          // 握りつぶす
        }
      },

      /**
       * 購入実行
       */
      async confirm() {
        this.error = null
        try {
          await this.$store.dispatch(types.PURCHASE, this.modalItem.id)
          this.showConfirmationModal = false
          this.showFinishModal = true
        } catch (e) {
          this.error = e.message
        }
      },

      read() {
        this.showFinishModal = false
        this.toggleFixedWindow()
        showViewer(this.modalItem)
      },

      /**
       * 買ったあとのモーダルを閉じる
       */
      close() {
        this.showSelectAddressModal = false
        this.showIncompletePaymentInfoModal = false
        this.showFinishModal = false
        this.toggleFixedWindow()
      },

      async purchase(item) {
        if (this.loginPath) {
          location.href = this.loginPath
        } else {
          this.isRequesting = true

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: item.itemType,
            itemIds: [item.id],
            itemTotalAmount: item.salePrice || item.price,
            selectedCountry: null
          })
          this.$store.commit(types.SET_TAX, {item, taxes: taxInfo})

          this.isRequesting = false
          this.showModal = true
          this.modalItem = item
          this.showConfirmationModal = true
        }
        this.toggleFixedWindow()
      },

      /**
       * 購入キャンセル
       */
      cancel() {
        this.showSelectAddressModal = false
        this.showIncompletePaymentInfoModal = false
        this.showConfirmationModal = false
        this.toggleFixedWindow()
      },

      toggleFixedWindow() {
        if (this.windowScrollY > 0) {
          window.STATUS.fixed(true)
          window.scrollTo(0, this.windowScrollY)
          this.windowScrollY = 0
        } else {
          this.windowScrollY = window.pageYOffset
          window.STATUS.fixed()
          document.querySelector("body").style.cssText = `top:${-1 * this.windowScrollY}px;`
        }
      },

      moveToPurchase() {
        if (this.isTypeVolume) {
          location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
        } else {
          location.href = `/store/manga/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
        }
      },
      cancelRead() {
        this.showAccessLimitModal = false
      },
      async accessLimit(item) {
        this.error = null
        this.showLoadingModal = true
        let newWindow = window.open(window.location.href)
        try {
          let res
          if (this.isTypeVolume) {
            res = await axios.get(`/store/manga_volume/${item.id}/access/count`)
          } else {
            res = await axios.get(`/store/manga/${item.id}/access/count`)
          }
          if (res.data.login_path) {
            this.showLoadingModal = false
            window.location.href = res.data.login_path
            newWindow.close()
          } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > item.accessLimit) {
            this.saleItem = item
            this.showLoadingModal = false
            this.showAccessLimitModal = true
            newWindow.close()
          } else {
            this.showLoadingModal = false
            newWindow.location.href = item.viewerUrl
          }
        } catch (e) {
          this.showLoadingModal = false
          // 握りつぶす
        }
      },
    }
  }
</script>
