
import * as types from "./mutation-types"

export default {
  [types.INITIALIZE](state, {genres, searchKeyword, searchGenreId}) {
    state.genres = genres
    if (searchKeyword) {
      state.keyword = searchKeyword
    }
    if (searchGenreId > 0) {
      state.selectedGenreId = searchGenreId
    }
  },
  [types.SELECT](state, id) {
    state.selectedGenreId = id
  },
  [types.UPDATE_KEYWORD](state, value) {
    state.keyword = value
  },
}
