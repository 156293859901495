const axios = window.axios
const _ = window._

window.addEventListener("DOMContentLoaded", () => {
  // Get by global
  const CURRENT_TUTORIAL_STEP_ID = window.MAL.CURRENT_TUTORIAL_STEP_ID
  const USER_NAME = window.MAL.USER_NAME

  // Get by template
  const step = CURRENT_TUTORIAL_STEP_ID

  // Tutorial messages and anchors
  const tutorial = [
    {
      anchor: "/topanime.php",
      message: "First, add anime to your list"
    },
    {
      anchor: `/animelist/${USER_NAME}`,
      message: "Next, check your anime list"
    },
    {
      anchor: "/anime/season",
      message: "Let’s check out the latest anime!"
    }
  ]

  const insertTutorial = () => {
    // .initialize-tutorial--disabled があったら実行させない
    if (document.querySelector(".initialize-tutorial--disabled") !== null) {
      return
    }
    // Create DOM contents
    const DOM = document.createElement("div")
    DOM.className = "initialize-tutorial"
    DOM.innerHTML = _.template(`
    <div class="initialize-tutorial__message">
      <i class="fa-regular fa-lightbulb" aria-hidden="true"></i> Hint: <a href="<%- anchor %>"><%- message %></a> (<%- step %>/3)
    </div>
    <div class="initialize-tutorial__close">
      <i class="fa-solid fa-xmark" aria-hidden="true"></i>
    </div>
    `)({anchor: tutorial[step - 1].anchor, message: tutorial[step - 1].message, step: step})

    // Create Style Tag
    /* チュートリアルは全ページ同じスタイルをあてたい
     * しかし、`/ownlist/:username`には共通のCSSが当たらない
     * そこで CSS in JS することにした
     */
    const STYLE = document.createElement("style")
    STYLE.innerHTML =
    `
    .initialize-tutorial {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      max-height: 80px;
      padding: 12px 8px;
      font-size: 1.4rem;
      line-height: 1.4rem;
      letter-spacing: 0.5;
      background-color: #fffefa;
      border-bottom: 1px solid #faebcc;
      box-sizing: border-box;
      opacity: 1.0;
      transition: all 0.45s ease;
    }

    .initialize-tutorial--is-hidden {
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .initialize-tutorial a {
      color: #8a6d3b;
    }
    
    .initialize-tutorial a:visited {
      color: #8a6d3b;
    }
    `
    
    // Insert DOM contents & Style tag
    const bodyTag = document.querySelector("body")  
    bodyTag.insertBefore(DOM, bodyTag.firstChild)
    document.querySelector("head").appendChild(STYLE)
  }
  
  const skipTutorial = () => {
    
    // Clickable
    const $tutorial = document.querySelector('.initialize-tutorial')
    const $closeBtn = document.querySelector('.initialize-tutorial__close')
    
    // Skip
    $closeBtn.addEventListener("click", (e) => {
      axios.delete(`/users/me/tutorials/${step}.json`)
        .catch(err => {
          console.error("[ERR]", err)
        }
      )
      $tutorial.classList.add("initialize-tutorial--is-hidden")
    }, false)
  }
  
  if (step) {
    insertTutorial()
    skipTutorial()
  }
})
