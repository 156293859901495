<template>
  <div class="root">
    <transition name="modal">
      <div v-show="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-manga-info">
              <div class="modal-cover">
                <img :src="coverImageUrl" width="90">
              </div>
              <div class="modal-title-wrap">
                <p class="modal-title">{{ item.title }}</p>
                <p class="modal-volume">{{ item.numberingText }}</p>
              </div>
            </div>
            <div class="modal-price-wrap">
              <div>
                <div class="modal-price-header">Price</div>
                <template v-if="item.salePrice">
                  <div class="modal-price">
                    <span class="is-sale">{{ currency.formatSymbolAndPrice(item.price) }}</span>
                    <span class="modal-price-sale">Sale {{ currency.formatSymbolAndPrice(item.salePrice) }}</span>
                  </div>
                </template>
                <template v-else>
                  <div>{{ currency.formatSymbolAndPrice(item.price) }}</div>
                </template>
              </div>
              <div class="modal-price-tax">
                <div class="modal-price-header">Tax</div>
                <div>{{ currency.formatSymbolAndPrice(item.tax) }}</div>
              </div>
              <div class="modal-price-total">
                <div class="modal-price-header">Total</div>
                <div>{{ currency.symbol }}<span>{{ currency.formatPrice(item.totalAmount) }}</span>{{ currency.name }}</div>
              </div>
            </div>
            <div class="modal-button-wrap">
              <template v-if="!isRequesting">
                <a class="modal-cancel-button" @click="cancel">Cancel</a>
                <a class="modal-confirm-button" v-show="!isRequesting" @click="confirm">Confirm Purchase</a>
              </template>
              <template v-else>
                <span class="modal-button-loading"></span>
              </template>
            </div>
            <div class="modal-error" v-show="error">{{ error }}</div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    /* ipone5でなぜかurlバーと下のメニューを高さに含めてしまうのでjavascriptで対応→結局だめ */
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 83.6%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
  }
  .modal-button-loading {
    display: block;
    width: 100%;
    height: 44px;
    background: url(/images/xmlhttp-loader.gif?v=161115001) no-repeat center center;
    background-size: 16px;
    vertical-align: middle;
    text-indent: -99999px;
  }
  .modal-manga-info {
    display: table;
    & > div{
      display: table-cell;
      vertical-align: top;
    }
  }
  .modal-cover {
    padding:0 9px 0 0;
    font-weight: 300;

    img {
      vertical-align: bottom;
      max-width: inherit;
    }
  }
  .modal-title-wrap {
    text-align: left;
  }
  .modal-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    line-height: 1.18;
    word-wrap:break-word;
  }
  .modal-volume {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.14;
    margin: 2px 0 0;
    color: #686A6F;
  }
  .modal-price-wrap {
    display: table;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6rem;
    margin: 8px auto 0;
    & > div {
      display: table-row;
      & > div {
        display: table-cell;
        padding-bottom: 7px;
        text-align: left;
        &:first-of-type {
          text-align: right;
        }
      }
    }
  }
  .modal-price-header {
    padding-right: 8px;
  }
  .modal-price {
    .is-sale {
      color: #7a7a7a;
      font-size: 12px;
      font-weight: 300;
      text-decoration: line-through;
    }
  }
  .modal-price-sale {
    color: #c0392b;
    font-weight: 700;
  }
  .modal-price-total {
    color: #C0392B;
    span {
      font-size: 24px;
    }
  }
  .modal-button-wrap {
    text-align: center;
    font-size: 0;
    margin: 4px 0 0;
  }
  .modal-cancel-button {
    border: 2px solid #2E51A2;
    color: #2E51A2;
    font-size: 16px;
    text-align: center;
    display: block;
    height: 38px;
    line-height: 34px;
    width: 200px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
    margin: 0 auto 10px;
    overflow: hidden;
    &:active {
      background-color: rgba(#2E51A2, 0.2);
    }
  }
  .modal-confirm-button {
    border: 2px solid #ff9600;
    color: #ff9600;
    font-size: 16px;
    text-align: center;
    display: block;
    height: 38px;
    line-height: 34px;
    width: 200px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
    margin: 0 auto 10px;
    white-space: nowrap;
    overflow: hidden;
    &:active {
      background-color: rgba(#ff9600, 0.2);
    }
  }
  .modal-taxamo {
    background: url(/images/manga_store/taxamo_logo@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    display: block;
    width: 52px;
    height: 10px;
    margin: 8px auto 0;
    text-indent: -9999px;
  }
  .modal-close {
    position: absolute;
    top: - 16px;
    right: - 20px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(/images/manga_store/sp/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -9999px;
    overflow: hidden;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .modal-error {
    display: block;
    margin-top: 10px;
    color: #ff6a86;
    text-indent: 0;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    line-height: 1.6rem;
  }

  @media only screen and (orientation : landscape) {
    .modal-container {
      padding: 10px;
    }
    .modal-cover {
      padding: 0;
      img {
        display: none;
      }
    }
    .modal-price-wrap {
      display: flex;
      margin-top: 0;
      font-size: 14px;
      & > div {
        align-self: baseline;
        & > div {
          padding-bottom: 3px;
        }
      }
    }
    .modal-price-tax {
      margin-left: 8px;
    }
    .modal-price-total {
      flex-grow: 2;
      text-align: right;
      text-align: -webkit-right;
      text-align: -moz-right;
      text-align: -o-right;
      text-align: -ms-right;
      margin-left: 8px;
    }
    .modal-button-wrap {
      margin-top: 10px;
    }
    .modal-cancel-button {
      display: inline-block;
      width: 100px;
      height: inherit;
      padding: 8px 0;
      margin: 0;
      line-height: inherit;
    }
    .modal-confirm-button {
      display: inline-block;
      width: inherit;
      min-width: 100px;
      height: inherit;
      padding: 8px 10px;
      margin: 0 0 0 10px;
      line-height: inherit;
    }
    .modal-taxamo {
      margin: 8px auto 0;
    }
    .modal-title {
      font-size: 14px;
    }
    .modal-volume {
      font-size: 12px;
    }
    .modal-price-total {
      span {
        font-size: 20px;
      }
    }
    .modal-button-loading {
      height: 32px;
      background-size: 14px;
    }
  }

</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"

  export default {
    props: {
      isShown: Boolean,
      isRequesting: Boolean,
      item: Object,
      currency: Object,
      error: String,
      paymentMethod: String,
      isDelegated: Boolean
    },
    computed: {
      coverImageUrl() {
        return generateCoverImageUrl(this.item.coverImageBaseUrl)
      },
    },
    methods: {
      cancel() {
        this.$emit('cancel')
      },
      confirm() {
        this.$emit('confirm')
      }
    }
  }
</script>
