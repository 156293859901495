import { render, staticRenderFns } from "./PreviewButton.vue?vue&type=template&id=5a2a2eb4&scoped=true&"
import script from "./PreviewButton.vue?vue&type=script&lang=js&"
export * from "./PreviewButton.vue?vue&type=script&lang=js&"
import style0 from "./PreviewButton.vue?vue&type=style&index=0&id=5a2a2eb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2a2eb4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a2a2eb4')) {
      api.createRecord('5a2a2eb4', component.options)
    } else {
      api.reload('5a2a2eb4', component.options)
    }
    module.hot.accept("./PreviewButton.vue?vue&type=template&id=5a2a2eb4&scoped=true&", function () {
      api.rerender('5a2a2eb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vue/components/manga_store/sp/atoms/PreviewButton.vue"
export default component.exports