import parse from "url-parse"
import crypto from "crypto"

const _ = window._

function parseUrlPath(path) {
  // httpじゃないかつ先頭'/'がついてない場合、自動付加
  if (!path.match(/^https?:\/\//) && path.substr(0, 1) !== "/") {
    path = `/${path}`
  }
  const tokens = parse(path)
  return tokens
}

// smalllightのsize指定を確認する
const regSize = new RegExp(/^\/r\/(\d+?)x(\d+?)\//)

export default class AssetImage {
  /**
   * Helper\Url::assetImageのJS実装
   *
   * @param {string} obj.path
   * @param {number} obj.width
   * @param {number} obj.height
   */
  constructor({path, width = null, height = null}) {
    const tokens = parseUrlPath(path)

    const pathname = tokens.pathname
    const matches = pathname.match(regSize)
    if (matches) {
      this.path = pathname.replace(regSize, "/")
      this.width = width || parseInt(matches[1])
      this.height = height || parseInt(matches[2])
      this._tokens = parseUrlPath(this.path)
    } else {
      this.path = path
      this.width = width
      this.height = height
      this._tokens = tokens
    }

    [, this._ext] = pathname.split(".")
  }

  toRetina() {
    if (!_.isNumber(this.width) || !_.isNumber(this.height)) {
      throw new Error(`width and height should be number: ${this.width}, ${this.height}`)
    }
    return new AssetImage({path: this.path, width: this.width * 2, height: this.height * 2})
  }

  toWebp() {
    const re = new RegExp(`\.${this._ext}`, "i")
    return new AssetImage({path: this.path.replace(re, ".webp"), width: this.width, height: this.height})
  }

  toJpeg() {
    const re = new RegExp(`\.${this._ext}`, "i")
    return new AssetImage({path: this.path.replace(re, ".jpg"), width: this.width, height: this.height})
  }

  resize({width, height}) {
    if (!_.isNumber(width) || !_.isNumber(height)) {
      throw new Error(`width and height should be number: ${this.width}, ${this.height}`)
    }
    return new AssetImage({path: this.path, width, height})
  }

  toUrl() {
    const cdnURL = window.MAL.CDN_URL
    const smallValidatorKey = window.MAL.SLVK
    let pathname = this._tokens.pathname

    if (_.isNumber(this.width) && _.isNumber(this.height)) {
      const p = `/r/${this.width}x${this.height}${pathname}`
      const md5 = crypto.createHash("md5").update(`${p}${smallValidatorKey}`).digest("hex")
      pathname = `${p}?s=${md5}`
    }

    return `${cdnURL}${pathname}`
  }
}

