var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root" }, [
    _c(
      "div",
      { staticClass: "seekbar-wrap", class: { opposite: _vm.switchDirection } },
      [
        _c("div", { staticClass: "seekbar-bg-wrap" }, [
          _c("div", {
            ref: "seekbar",
            staticClass: "seekbar-bg",
            style: _vm.backgroundStyle
          })
        ]),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "range", step: "1", min: "1", max: _vm.maxValue },
          domProps: { value: _vm.currentValue },
          on: {
            change: _vm.change,
            click: _vm.click,
            keydown: function($event) {
              $event.preventDefault()
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }