import incrementalSearch from "../../common/modules/incrementalSearch"
import $ from "jquery";

$(() => {
  const $searchBox = $("#search-box");
  if ($searchBox.length > 0) {
    incrementalSearch.initializeVueModel("#search-box", {
      type: $("#search-box").data("type"),
      query: "q",
      resultPosition : {
        left : 0,
        top  : 57,
        width: "100%",
      },
      generateResultPageParams: (type, keyword) => {
        if (type === "store") {
          return `keyword=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        } else {
          return `q=${encodeURIComponent(keyword)}&cat=${encodeURIComponent(type)}`
        }
      },
    });

    const $resultList = $(".incrementalSearchResultList");
    const resizeResultDOM = () => {
      $resultList.css({
        "max-height": $(window).height() - 142
      });
    }

    resizeResultDOM();
    $(window).on("resize orientationchange", () => {
      resizeResultDOM();
    });

    $resultList.on("touchstart", () => {
      $("#topSearchText").blur();
    });

    // TODO: 以下の処理をいれると、clickイベントが正常に発火せず、リンクが機能しない問題があったので暫定的にコメントアウト
    // 検索結果上でこれ以上スクロール出来ないときは、
    // preventDefaultを呼んでやって、body要素がスクロールされることを防ぐ。
    // 以下、touchstartとtouchmoveはその処理。
    //$resultList
    //  .on("touchstart", function (e) {
    //    $('#topSearchText').blur();
    //    this.allowUp   = (this.scrollTop > 0);
    //    this.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
    //    this.lastY = e.originalEvent.touches[0].pageY;
    //  })
    //  .on("touchmove", function (e) {
    //    this.eventCounter++;
    //    let up = (e.originalEvent.touches[0].pageY > this.lastY), down = !up;
    //    this.lastY = e.originalEvent.touches[0].pageY;
    //    if ((up && this.allowUp) || (down && this.allowDown)) {
    //      e.stopPropagation();
    //    } else {
    //      //e.preventDefault();
    //    }
    //  });
  }
});

