<template>
  <div class="loader">
    <img :src="loadingImage" width="32" height="32">
  </div>
</template>

<script>
export default {
  computed: {
    loadingImage() {
      return `${window.MAL.CDN_URL}/images/xmlhttp-loader.gif`
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, .8);
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  z-index: 100;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: {
      top: -16px;
      left: -16px;
    }
  }
}
</style>
