var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("label", [_vm._v("Score")]),
    _vm._v(" "),
    _c(
      "select",
      {
        domProps: { value: _vm.score },
        on: {
          change: function($event) {
            return _vm.change($event.target.value)
          }
        }
      },
      [
        _c("option", { attrs: { value: "0" } }, [_vm._v("Select")]),
        _vm._v(" "),
        _vm._l(_vm.scoreList, function(score) {
          return _c("option", { domProps: { value: score.val } }, [
            _vm._v(
              "\n      " +
                _vm._s(score.val) +
                " - " +
                _vm._s(score.str) +
                "\n    "
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }