const _ = window._

export const WEBP_LOSSY = "LOSSY"
export const WEBP_LOSSLESS = "LOSSLESS"
export const WEBP_ALPHA = "ALPHA"
export const WEBP_ANIMATION = "ANIMATION"

const supportedFeatures = {}

const testImages = {
  [WEBP_LOSSY]: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
  [WEBP_LOSSLESS]: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
  [WEBP_ALPHA]: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
  [WEBP_ANIMATION]: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
}

/**
 * @see {@link https://developers.google.com/speed/webp/faq#in_your_own_javascript}
 */
export default class WebpDetector {
  static isSupported(feature = WEBP_LOSSY) {
    if (_.has(supportedFeatures, feature)) {
      return new Promise((resolve, reject) => {
        resolve(supportedFeatures[feature])
      })
    }

    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        const res = (img.width > 0) && (img.height > 0)
        supportedFeatures[feature] = res
        resolve(res)
      }
      img.onerror = () => {
        supportedFeatures[feature] = false
        resolve(false)
      }
      img.src = `data:image/webp;base64,${testImages[feature]}`
    })
  }
}
