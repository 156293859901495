// Friend ListとPending ListのJS
// 以下で共用
// views/sp/profile/friends.html.twig
// views/sp/friends/pending.html.twig

const sVue = window.sVue
const $ = window.$
const _ = window._;

import WebpDetector from "../../../common/modules/WebpDetector"
import AssetImage from "../../../common/modules/AssetImage"

$(() => {

  const friendlistEl = $("#vm-friendlist")
  if (friendlistEl[0]) {
    (async() => {
      const supportsWebP = await WebpDetector.isSupported()
      const initialData = JSON.parse(friendlistEl.attr("data-initial-data"))
      const userId = friendlistEl.attr("data-user-id")
      const numFriends = friendlistEl.attr("data-num-friends")
      const numRequests = friendlistEl.attr("data-num-requests")

      const vFriendlist = new sVue({
        el: "#vm-friendlist",
        data: {
          friends: [],
          hasMore: false,
          isLoading: true,
          isLoadingId: 0,
          error: "",
          lastUserId: null,
          lastTimeAdded: null,
          numFriends: numFriends,
          numRequests: numRequests
        },
        created: function () {
          this.friends = initialData.items
          this.hasMore = initialData.has_more
          this.isLoading = false

          if (this.hasMore) {
            const lastFriend = _.last(this.friends)
            this.lastUserId = lastFriend.id
            this.lastTimeAdded = lastFriend.time_added
          }
        },
        updated: function () {
          if (this.hasMore) {
            const lastFriend = _.last(this.friends)
            this.lastUserId = lastFriend.id
            this.lastTimeAdded = lastFriend.time_added
          }
        },
        methods: {
          expandText: function (name) {
            //show message from requesting user
            $(`.friend[data-name="${name}"] .message`).toggleClass("line-clamp")
          },
          loadMoreFriend: function () {
            this.isLoading = true
            this.error = ""
            $.ajax({
              url: `/friends/api/list/${userId}.json`,
              contentType: "application/json",
              dataType: 'json',
              data: {
                last_friend_id: this.lastUserId,
                last_time_added: this.lastTimeAdded
              },
              success: response => {
                response.items.forEach((el) => {
                  this.friends.push(el)
                })
                this.hasMore = response.has_more
                this.isLoading = false
              },
              error: error => {
                this.isLoading = false
                this.error = error.responseJSON.errors[0].message
              }
            })
          },
          loadMorePending: function () {
            this.isLoading = true
            this.error = ""
            $.ajax({
              url: "/friends/api/requests.json",
              contentType: "application/json",
              dataType: 'json',
              data: {
                last_friend_id: this.lastUserId,
                last_time_added: this.lastTimeAdded
              },
              success: response => {
                response.items.forEach((el) => {
                  this.friends.push(el)
                })
                this.hasMore = response.has_more
                this.isLoading = false
              },
              error: error => {
                this.isLoading = false
                this.error = error.responseJSON.errors[0].message
              }
            })
          },
          removeFriend: function (id, index) {
            if (window.confirm("Are you sure you want to remove your current friendship?")) { // eslint-disable-line no-alert
              this.isLoadingId = id
              this.friends[index].error = ""
              $.ajax({
                url: "/friends/api/remove.json",
                data: JSON.stringify({"user_id": id}),
                contentType: "application/json",
                type: "POST",
                success: response => {
                  this.friends.splice(index, 1)
                  this.isLoadingId = 0
                  this.numFriends--
                },
                error: error => {
                  this.isLoadingId = 0
                  this.friends[index].error = error.responseJSON.errors[0].message
                }
              })
            }
          },
          acceptFriendRequests: function (id, index) {
            this.isLoadingId = id
            this.friends[index].error = ""
            $.ajax({
              url: "/friends/api/accept_request.json",
              data: JSON.stringify({"user_id": id}),
              contentType: "application/json",
              type: "POST",
              success: response => {
                this.friends[index].isApproved = true
                this.isLoadingId = 0
                this.numRequests--
                this.numFriends++
              },
              error: error => {
                this.isLoadingId = 0
                this.friends[index].error = error.responseJSON.errors[0].message
              }
            })
          },
          denyFriendRequests: function (id, index) {
            if (window.confirm("Are you sure you want to deny this request?")) { // eslint-disable-line no-alert
              this.isLoadingId = id
              this.error = ""
              $.ajax({
                url: "/friends/api/deny_request.json",
                data: JSON.stringify({"user_id": id}),
                contentType: "application/json",
                type: "POST",
                success: response => {
                  this.friends.splice(index, 1)
                  this.isLoadingId = 0
                  this.numRequests--
                },
                error: error => {
                  this.isLoadingId = 0
                  that.error = error.responseJSON.errors[0].message
                }
              })
            }
          },
        }
      })
    })()
  }

})
