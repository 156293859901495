
let hasDisabledInitialLoad = false;

/**
 * 無限スクロールで新規コンテンツを表示した際に、追加の広告を新しく表示する。
 * idにこのメソッドの引数nameを持つDIV要素を前もって作成しておかないといけない。
 *
 * @param {string} code     - ex.) /84947469/sp_anime_top_scroll_footer
 * @param {Array[]} sizes   - ex.) [[350, 50], [1, 1]]
 * @param {string} name     - ex.) sp_anime_top_scroll_footer
 * @param {json} key_values - ex.) {adult: 'gray'}
 */
function request(code, sizes, name, key_values) {
  // 無限スクロールで広告を表示する際は display -> refresh と呼びださなければならない。
  // display を読んだ際に広告の取得が走らないように、disableInitialLoad() を呼ぶ。
  if (!hasDisabledInitialLoad) {
    hasDisabledInitialLoad = true
    googletag.pubads().disableInitialLoad()
  }

  // 広告を入れるDIV要素が生成されているか確認
  if (document.getElementById(name) === null) {
    console.warn("A parent div should have been generated in advance.")
    return;
  }

  googletag.cmd.push(function() {
    const slot = googletag.defineSlot(code, sizes, name)
    for (const key in key_values) {
      slot.setTargeting(key, key_values[key])
    }
    slot.addService(googletag.pubads())

    googletag.display(name)
    googletag.pubads().refresh([slot])
  });
}

export default {
  request,
}
