const Vue = window.sVue
import * as types from "./mutation-types"
import ModalNS from "../../../components/list_modal/store_module/namespace"

export default {
  [types.INIT_DATA](state, {userName, loggedInUserName, workType, items, view, isWebp}) {
    state.userName = userName
    state.loggedInUserName = loggedInUserName
    state.workType = workType
    state.items = items
    state.view = view
    state.isWebp = isWebp
  },
  [types.UPDATE_USER_STATUS](state, {id, userStatusId}) {
    const targetItem = state.items[id]
    targetItem.userStatusId = userStatusId

    if (userStatusId !== 2) {
      return
    }

    // when completed
    if (targetItem.totalEpisodes > 0) {
      targetItem.watchedEpisodes = targetItem.totalEpisodes
    } else {
      if (targetItem.totalVolumes > 0) {
        targetItem.readVolumes = targetItem.totalVolumes
      }
      if (targetItem.totalChapters > 0) {
        targetItem.readChapters = targetItem.totalChapters
      }
    }
  },
  [types.UPDATE_SCORE](state, {id, score}) {
    const targetItem = state.items[id]
    targetItem.score = score
  },
  [types.INCREMENT_EPISODE](state, {id}) {
    const targetItem = state.items[id]
    targetItem.watchedEpisodes++
  },
  [types.INCREMENT_VOLUME](state, {id}) {
    const targetItem = state.items[id]
    targetItem.readVolumes++
  },
  [types.INCREMENT_CHAPTER](state, {id}) {
    const targetItem = state.items[id]
    targetItem.readChapters++
  },
  [types.API_ERROR](state, {id, err}) {
    const targetItem = state.items[id]
    Vue.set(targetItem, "error", err)
  },
  [types.UNKNOWN_ERROR](state, {id, err}) {
    const targetItem = state.items[id]
    Vue.set(targetItem, "error", err)
  },
  [types.CLEAR_ERROR](state, id) {
    const targetItem = state.items[id]
    Vue.set(targetItem, "error", null)
  },
  /**
   * Modalで編集したlistデータを一覧のstoreにも反映する
   */
  [types.FLUSH_UPDATED_ITEM_DATA](state) {
    const modal = state[ModalNS]
    const targetItem = state.items[modal.id]
    targetItem.userStatusId = modal.userStatusId
    targetItem.score = modal.score

    if (state.workType === "anime") {
      targetItem.watchedEpisodes = modal.watchedEpisodes
    } else {
      targetItem.readVolumes = modal.readVolumes
      targetItem.readChapters = modal.readChapters
    }
  }
}
