var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isOwner
      ? _c(
          "select",
          {
            staticClass: "select",
            class: _vm.statusClass,
            domProps: { value: _vm.statusId },
            on: {
              change: function($event) {
                return _vm.updateStatus($event.target.value)
              }
            }
          },
          _vm._l(_vm.statusList, function(status, id) {
            return _c("option", { domProps: { value: id } }, [
              _vm._v("\n    " + _vm._s(status.str) + "\n    ")
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isOwner
      ? _c("div", { class: _vm.statusClass }, [
          _vm._v("\n    " + _vm._s(_vm.currentStatus) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }