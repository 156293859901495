var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { on: { click: _vm.editListItem } }, [
    _c("i", { staticClass: "fa-solid fa-pencil-square-o fa-2x" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }