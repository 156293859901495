import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import taxStore, {NAMESPACE as TAX_NAMESPACE} from "../../../../common/stores/tax_calculation_modal"

export default () => {
  const state = {
    items: [],
    itemType: null,
    currency: Object,
    checkedItemIds: [],
    isPurchased: false,
    loginPath: null,
    bookshelfComicPath: null,

    // Paymentの各種設定情報
    payment: {
      defaultPaymentMethod: null,
    },
  }

  return new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
      [TAX_NAMESPACE]: taxStore(),
    }
  })
}
