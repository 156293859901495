<template>
  <p :class="className">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: {
    className: String, // style適用するためのclass名
  },
}
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.6rem;
}
</style>
