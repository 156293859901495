var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overlay",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.cancel($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "dialog" },
        [
          _vm.isLoading ? _c("loader") : _vm._e(),
          _vm._v(" "),
          _c("work-title", { attrs: { className: "title" } }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form" },
            [
              _c("user-status", {
                attrs: {
                  "work-type": _vm.workType,
                  "status-id": _vm.userStatusId,
                  className: "status"
                },
                on: { change: _vm.UPDATE_USER_STATUS }
              }),
              _vm._v(" "),
              _c("user-score", {
                attrs: {
                  "work-type": _vm.workType,
                  score: _vm.score,
                  className: "score"
                },
                on: { change: _vm.UPDATE_SCORE }
              }),
              _vm._v(" "),
              _vm.workType === "anime"
                ? _c(
                    "input-progress",
                    {
                      attrs: {
                        total: _vm.totalEpisodes,
                        current: _vm.watchedEpisodes,
                        className: "episode",
                        enabled: _vm.canProgress
                      },
                      on: { change: _vm.UPDATE_EPISODE }
                    },
                    [_vm._v("Episodes")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.workType === "manga"
                ? _c(
                    "input-progress",
                    {
                      attrs: {
                        total: _vm.totalVolumes,
                        current: _vm.readVolumes,
                        className: "volume",
                        enabled: _vm.canProgress
                      },
                      on: { change: _vm.UPDATE_VOLUME }
                    },
                    [_vm._v("Volumes")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.workType === "manga"
                ? _c(
                    "input-progress",
                    {
                      attrs: {
                        total: _vm.totalChapters,
                        current: _vm.readChapters,
                        className: "chapter",
                        enabled: _vm.canProgress
                      },
                      on: { change: _vm.UPDATE_CHAPTER }
                    },
                    [_vm._v("Chapters")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "detail" }, [
                _c("a", { attrs: { href: _vm.path } }, [
                  _vm._v(_vm._s(_vm.modeStr) + " Detailed Info")
                ])
              ]),
              _vm._v(" "),
              _vm.error
                ? _c("div", { staticClass: "error" }, [
                    _vm._v("\n        " + _vm._s(_vm.error) + "\n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status
                ? _c("div", { staticClass: "error" }, [
                    _vm._v("\n        " + _vm._s(_vm.status) + "\n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  { staticClass: "cancel" },
                  [
                    _c("modal-button", { on: { click: _vm.cancel } }, [
                      _vm._v("Cancel")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "submit" },
                  [
                    _c("modal-button", { on: { click: _vm.submit } }, [
                      _vm._v("Submit")
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notice_open_public pt8" }, [
                _vm._v("* Your list is public by default.")
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }