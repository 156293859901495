<template>
  <span @click="increment">
    +1 {{ unit }}
  </span>
</template>

<script>
export default {
  props: {
    unit: String,
  },
  methods: {
    increment() {
      this.$emit("increment")
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  color: #7a7a7a;
}
</style>
