const $ = window.$
const _ = window._
const SVue = window.sVue
import App from "./App.vue"
import store from "./store"
import {INIT_DATA} from "./store/mutation-types"
import WebpDetector from "../../../js/common/modules/WebpDetector"

$(() => {
  if ($("#list-items").exists()) {
    (async () => {
      const data = $("#app").data("list")
      const $loader = $(".js-customize-loader-overlay")
      const isWebp = await WebpDetector.isSupported()
      _.assign(data, {isWebp})
      new SVue({
        el: "#app",
        store,
        render: h => h(App),
        created() {
          this.$store.commit(INIT_DATA, data)
        },
        mounted() {
          $loader.hide()
        },
      })
    })()
  }
})
