// JavaScript Document
const SVue = window.sVue;
const _ = window._;
const $ = window.$;

$(() => {
  $(".js-profile-user-comment").off("click").on("click", (e) => {
    $(".js-profile-user-comment").prop("disabled", true);
    e.preventDefault()
    const profileMemId = $("input[name=profileMemId]").val()
    const profileUsername = $("input[name=profileUsername]").val()
    const comText = $("textarea[name=commentText]").val()
    $.post("/addcomment.php", {
      commentSubmit: 1,
      profileMemId: profileMemId,
      profileUsername: profileUsername,
      commentText: comText,
      area: 2
    }).done((data) => {
      $(".js-profile-user-comment").prop("disabled", false);
      const response = JSON.parse(data)
      if (response.error) {
        if (response.error == 'banned') {
          location.reload();
          return;
        }
        const $commentForm = $(".comment-form")
        if ($commentForm.children(".error-message").exists()) {
          $commentForm.children(".error-message").val(response.message)
        } else {
          const compiled = _.template(`<div class="error-message"><%- errorMessage %></div>`)
          $commentForm.append($(compiled({errorMessage: response.message})))
        }
        return
      }
      window.location.href = `/profile/${profileUsername}#lastcomment`
      window.location.reload(true)
    })
  });

  if ($(".user-profile-about")[0]) {
    window.addEventListener('load', function() {
      $('.app .profile-about-user .biography a').each(function(i,j){
        var dom = $(j);
        var href = dom.attr('href');
        if(href) {
          href = 'mal-action://open_browser?url=' + encodeURIComponent(href);
          dom.attr('href', href);
        }
      });

      const accordion = document.querySelector('.accordion');
      const limitedHeight = accordion.querySelector('.limited-height');
      const arrow = accordion.querySelector('.arrow');

      if (limitedHeight) {
        const max = limitedHeight.getAttribute('data-max-height');
        let innerHeight = limitedHeight.offsetHeight;

        if (max < innerHeight) {
          limitedHeight.style.maxHeight = `${max}px`;
          const headerHeight = document.querySelector('.header-inner').offsetHeight;
          const position = $('.profile-list-btn').offset().top;

          arrow.addEventListener('click', () => {
            if (max < limitedHeight.offsetHeight) {
              innerHeight = limitedHeight.offsetHeight;
              limitedHeight.style.maxHeight = `${max}px`;
              arrow.style.transform = '';
              if (position < $(window).scrollTop()) {
                $('html,body').animate({scrollTop: position - headerHeight}, 400);
              }
            } else {
              limitedHeight.style.maxHeight = `${innerHeight}px`;
              arrow.style.transform = 'rotate(180deg)';
            }
          }, false);
        } else {
          accordion.classList.remove('accordion');
          $('.biography-arrow').hide();
        }
      }
    });
  }

  const profileBadgeInfo = $(".js-profile-badge-info")
  if (profileBadgeInfo[0]) {
    const set_num = Math.floor(($(window).width() - 8) / (profileBadgeInfo.width() + 8))

    profileBadgeInfo.each(function(i) {
      if ((i+1)%set_num === 1) {
        $(this).find(".text").addClass("left")
      } else if ((i+1)%set_num === 0) {
        $(this).find(".text").addClass("right")
      }
    })

    profileBadgeInfo.on("touchstart", function() {
      $(this).find(".text").addClass("show-info")
    }).on("touchend", function() {
      $(this).find(".text").removeClass("show-info")
    })
  }

  const profileFriendRequestEl = $("#vm-profile-friend-request")
  if (profileFriendRequestEl[0]) {
    const status = profileFriendRequestEl.data("status")
    const friendStatusList = profileFriendRequestEl.data("friend-status-list")

    const vProfileFriend = new SVue({
      el: "#vm-profile-friend-request",
      data: {
        friendStatus: "",
        userRequesting: false,
        buttonDisabled: false,
        friendStatusList: {},
        initialize: true,
        isLoading: false,
      },
      mounted: function () {
        this.friendStatus = status.friendStatus
        this.userRequesting = status.userRequesting
        this.buttonDisabled = status.buttonDisabled === "disabled"
        this.friendStatusList = friendStatusList
        this.initialize = false
      },
      methods: {
        requestFriend: function (id, message) {
          this.isLoading = true
          this.error("")
          $.ajax({
            url: "/friends/api/send_request.json",
            data: JSON.stringify({"user_id": id, "message": message}),
            contentType: "application/json",
            type: "POST",
            success: response => {
              this.friendStatus = this.friendStatusList.pending
              this.isLoading = false
            },
            error: error => {
              this.isLoading = false
              this.error = error.responseJSON.errors[0].message
            }
          })
        },
        removeFriend: function (id) {
          if (window.confirm("Are you sure you want to remove this friend?")) { // eslint-disable-line no-alert
            this.isLoading = true
            this.error("")
            $.ajax({
              url: "/friends/api/remove.json",
              data: JSON.stringify({user_id: id}),
              contentType: "application/json",
              type: "POST",
              success: response => {
                this.friendStatus = this.friendStatusList.notFriend
                this.isLoading = false
              },
              error: error => {
                this.isLoading = false
                this.error(error.responseJSON.errors[0].message)
              }
            })
          }
        },
        popRequestDialog: function () {
          this.error("")
          this.overlayDialog();
          const template = _.template($("#dialog_common_info").text());
          const that = this
          $("#dialog_pop").append(template({
            detail_info: $(".js-friend-request-dialog").html(),
            add_class: "friend-request"
          })).hide().fadeIn("fast");

          $(".js-friend-request-submit").on("click", function () {
            const id = $(this).data("id")
            const message = $(this).parent().parent().find(".js-friend-request-textarea").val()
            vProfileFriend.requestFriend(id, message)
            that.closeDialog()
          })
          $(".js-friend-request-cancel").on("click", function () {
            that.closeDialog()
          })
        },
        overlayDialog: function () {
          const docH = $(document).height()
          const sclV = $(window).scrollTop()
          const $dialog_overlay = $("#dialog_overlay")

          $dialog_overlay.css({"height": docH, "display": "block"}).hide().fadeIn("fast",  () => {
            const dialogPop = $("#dialog_pop")

            const dlgH = dialogPop.height()
            const topPosV = ( dlgH < 150 ) ? 130 + sclV : 40 + sclV
            dialogPop.removeClass("dialog_edit_list")
            dialogPop.css({"top": `${topPosV}px`, "margin-top": "inherit", "display": "block"}).show()
            $dialog_overlay.on("click", () => {
              this.closeDialog()
            })
          })

          $(window).on("touchmove.noScroll", function (e) {
            e.preventDefault()
          });
        },
        closeDialog: function () {
          $("#loading .circleAnim").removeClass("circleAnim2")
          $("#loading").hide()
          $("#fieldcontain").remove();//remove lodash.js template
          $("#dialog_pop").empty()
          $(window).off(".noScroll")
          $("#dialog_overlay").css("display", "none")
          return false
        },
        error: function (text) {
          const $error = $(".js-user-function-error")
          $error.text(text)
          if (text) {
            $error.addClass("show")
          } else {
            $error.removeClass("show")
          }
        }
      }
    })
  }
  // -------------------------
  // Interest Stacks
  // -------------------------
  // get size
  const getDimention = function () {
    const data = {
      docW : $(document).width(),
      docH : $(document).height(),
      winW : $(window).width(),
      winH : $(window).height(),
      sclV : $(window).scrollTop()
    };
    return data;
  };

  // close Dialog
  const closeDialog = function () {
    $("#loading .circleAnim").removeClass("circleAnim2");
    $("#loading").hide();
    $("#fieldcontain").remove();// remove lodash.js template
    $("#dialog_pop").empty();
    $(window).off(".noScroll");
    $("#dialog_overlay").css("display", "none");
    return false;
  }

  // ---------------------------------
  // add stack popup
  // ---------------------------------
  if ($(".js-btn-new-stack")[0]) {
    $(".js-btn-new-stack").on("click", function(e) {
      e.preventDefault();
      const dimention = getDimention();
      const docH = dimention.docH;
      const sclV = dimention.sclV;
      const dialogObj = $("#dialog_overlay");
      const topPosV = 40 + sclV;
      // black background
      dialogObj.css({height: docH, display: "block", opacity: 0.8}).hide().fadeIn("fast", function() {
        // sort popup block
        $(".js-new-stack-block").css({"top": `${topPosV}px`, "margin-top": "inherit"}).show();

        dialogObj.on("touchend click", function() {
          closeDialog();
          $(".js-new-stack-block").hide();
          return false;
        });
      });

      dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
        e.preventDefault();
      });

    });
  }

  if ($(".js-stacks-navi")[0]) {
    $(".js-select-tab").on("change", function() {
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?tab=' + $(this).val();
    });

    // show dialog buttons
    $(".js-stacks-navi .js-btn-show-sort").on("click", function(e) {
      e.preventDefault();
      const id = $(this).data("id");
      const dimention = getDimention();
      const docH = dimention.docH;
      const dialogObj = $("#dialog_overlay");
      // black background
      dialogObj.css({height: docH, display: "block", opacity: 0}).hide().fadeIn("fast", function() {
        // sort popup block
        $(`.js-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        dialogObj.on("touchend click", function() {
          closeDialog();
          $(".js-stacks-navi .js-sort-order-block").hide();
          $(".js-stacks-navi .js-btn-show-sort").removeClass("on");
          return false;
        });
      });

      dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
        e.preventDefault();
      });

      // $(window).on("touchmove.noScroll", function(e) {
      //   e.preventDefault();
      // });

      // close BTN function
      $(".js-sort-order-block .js-btn-close").on("touchend", function() {
        $(".js-stacks-navi .js-sort-order-block").hide();
        $(".js-stacks-navi .js-btn-show-sort").removeClass("on");
        closeDialog();
        return false;
      });
    });

    // sort button
    $(".js-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      $(".js-sort-order-block.js-sort .js-ajax-loader").show();
      $(".js-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?sort=' + $(this).attr("id");
    });
  }
});

$(window).on('load',function(){
  // Modern About Me Expand
  const content = $('#modern-about-me');
  if (content[0]) {
    const foldHeight = 350;
    const originHeight = $('#modern-about-me-inner').height();
    if (foldHeight < originHeight) {
      const button = $('#modern-about-me-expand-button');
      content.data('origin-size', `${originHeight}px`);
      content.data('accordion', 'closed');
      content.css('max-height', `${foldHeight}px`);

      button.css('display', '');
      button.on('click', function () {
        if (content.data('accordion') === 'closed') {
          content.data('accordion', 'open');
          button.addClass('is-open');
          content.css('max-height', content.data('origin-size'));
        } else {
          content.data('accordion', 'closed');
          button.removeClass('is-open');
          content.css('max-height', content.data('fold-height'));
          $("html,body").animate({scrollTop: $(content).offset().top});
        }
      });
    }
  }
});
