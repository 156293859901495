var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item" }, [
    !_vm.isRequesting
      ? _c(
          "div",
          { staticClass: "btn-detail-view-all", on: { click: _vm.click } },
          [
            _c("span", { staticClass: "text" }, [_vm._v("View More")]),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isRequesting
      ? _c("div", { staticClass: "btn-detail-view-all" }, [
          _c("i", { staticClass: "loading fa-solid fa-spinner fa-spin fa-2x" })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-next" }, [
      _c("i", {
        staticClass: "fa-solid fa-chevron-right icon-chevron",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }