var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-container"
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-header",
                on: {
                  touchmove: [
                    function($event) {
                      $event.preventDefault()
                    },
                    function($event) {
                      $event.stopPropagation()
                    }
                  ]
                }
              },
              [
                _c("div", { staticClass: "modal-header-title" }, [
                  _vm._v("\n          Buy Series\n          "),
                  _c(
                    "a",
                    { staticClass: "modal-close", on: { click: _vm.cancel } },
                    [_vm._v("close")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-checkbox-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "checkbox-check-all-button",
                      on: { click: _vm.toggleAll }
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkedIdsNum === 0,
                              expression: "checkedIdsNum === 0"
                            }
                          ],
                          staticClass: "checkbox-wrap-text"
                        },
                        [_vm._v("Check all")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkedIdsNum > 0,
                              expression: "checkedIdsNum > 0"
                            }
                          ],
                          staticClass: "checkbox-wrap-text"
                        },
                        [_vm._v("Uncheck all")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "checkbox-wrap checkbox-s-wrap" },
                        [
                          _c("span", {
                            staticClass: "checkbox margin-l10",
                            class: _vm.checkAllClass
                          })
                        ]
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-list",
                on: {
                  touchmove: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-list-inner" },
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        staticClass: "modal-list-item",
                        class: { "is-hold": item.isPossessed }
                      },
                      [
                        _c("div", [
                          _c("img", {
                            staticClass: "modal-list-cover margin-r10",
                            class: { disabled: item.isPossessed },
                            attrs: {
                              width: "46",
                              height: "auto",
                              src: _vm.coverImageUrl(item.coverImageBaseUrl)
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-list-info" }, [
                          _c("div", { staticClass: "modal-list-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "modal-list-volume",
                              class: {
                                iconNew: !item.isPossessed && item.isNew
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.numberingText) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.isPossessed,
                                  expression: "!item.isPossessed"
                                }
                              ],
                              staticClass: "modal-list-price"
                            },
                            [
                              item.salePrice >= 0
                                ? [
                                    _c("span", { staticClass: "is-sale" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            item.price
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v("\n                  Sale "),
                                    _c(
                                      "span",
                                      { staticClass: "modal-price-sale" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currency.formatSymbolAndPrice(
                                              item.salePrice
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.currency.formatSymbolAndPrice(
                                            item.price
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "checkbox-wrap margin-l10" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedIds,
                                    expression: "checkedIds"
                                  }
                                ],
                                staticClass: "volumeCheckbox",
                                attrs: {
                                  type: "checkbox",
                                  disabled: item.isPossessed || item.isFree
                                },
                                domProps: {
                                  value: item.id,
                                  checked: Array.isArray(_vm.checkedIds)
                                    ? _vm._i(_vm.checkedIds, item.id) > -1
                                    : _vm.checkedIds
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.checkedIds,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkedIds = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkedIds = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkedIds = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkbox" })
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-footer",
                on: {
                  touchmove: [
                    function($event) {
                      $event.preventDefault()
                    },
                    function($event) {
                      $event.stopPropagation()
                    }
                  ]
                }
              },
              [
                _c("div", { staticClass: "modal-table" }, [
                  _c("div", { staticClass: "modal-price" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currency.formatSymbolAndPrice(
                            _vm.totalPriceWithoutTax
                          )
                        )
                      )
                    ]),
                    _vm._v(_vm._s(_vm.currency.name) + "\n          "),
                    _c("br"),
                    _c("span", { staticClass: "tax" }, [_vm._v("(Excl. Tax)")])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "modal-cancel-button margin-l10",
                        on: { click: _vm.cancel }
                      },
                      [_vm._v("Cancel")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "modal-buy-button margin-l10",
                        class: { "is-disable": _vm.checkedIdsNum === 0 },
                        on: { click: _vm.proceed }
                      },
                      [_vm._v("Buy")]
                    )
                  ])
                ])
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }