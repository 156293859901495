
import * as types from "./mutation-types"
import {createBook} from "../../../../common/modules/book"
import {appendTax} from "../../../../common/modules/tax"

export default {
  [types.INITIALIZE](state, { item, loginPath, currency, paymentSettings }) {
    state.loginPath = loginPath
    state.currency = currency

    state.item = createBook(item)

    // Paymentの各種設定情報
    state.payment = {
      defaultPaymentMethod: paymentSettings.defaultPaymentMethod,
    }
  },

  [types.READ](state) {
    window.open(state.item.viewerUrl)
  },

  [types.SET_TAX](state, taxes) {
    state.item = appendTax(state.item, taxes)
  },
}
