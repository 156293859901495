var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.id > 0 && _vm.sp
    ? _c("div", { staticClass: "root" }, [
        _c("div", { staticClass: "box" }, [
          _vm.is_buy
            ? _c(
                "button",
                {
                  staticClass:
                    "mal-btn outline secondary ga-click ga-impression",
                  attrs: {
                    "data-ga-click-type": _vm.gaType(),
                    "data-ga-impression-type": _vm.gaType(),
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam()
                  },
                  on: {
                    click: function($event) {
                      return _vm.showModal()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa-solid fa-cart-shopping ga-click",
                    attrs: {
                      "data-ga-click-type": _vm.gaType(),
                      "data-ga-click-param": _vm.gaParam()
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.is_manga && _vm.is_wish
            ? _c(
                "button",
                {
                  staticClass: "mal-btn outline ga-click ga-impression ",
                  class: [_vm.is_wishlist ? "secondary pressed" : ""],
                  attrs: {
                    "data-ga-click-type": _vm.gaType(),
                    "data-ga-impression-type": _vm.gaType(),
                    "data-ga-click-param": _vm.gaParam(),
                    "data-ga-impression-param": _vm.gaParam()
                  },
                  on: {
                    click: function($event) {
                      !_vm.is_wishlist && _vm.showModal()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa-solid fa-star-shooting ga-click",
                    attrs: {
                      "data-ga-click-type": _vm.gaType(),
                      "data-ga-click-param": _vm.gaParam()
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "modal-template" }, [
            _c("span", { ref: "modal_title", staticClass: "title" }, [
              _vm._v("Buy manga here:")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "modal_content", staticClass: "content" },
              [
                _c("affiliate-amazon-button", {
                  attrs: {
                    id: _vm.id,
                    type: _vm.type,
                    is_buy: _vm.is_buy,
                    link: _vm.link,
                    is_wish: _vm.is_wish,
                    sp: _vm.sp,
                    ga_type:
                      "buy_manga_" +
                      (_vm.is_buy ? "amazon" : "wish") +
                      "_" +
                      _vm.type +
                      "_ranking"
                  },
                  on: {
                    "click-link": function($event) {
                      return _vm.hideModal()
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }