
export default {
  generatePath: state => {
    let base = "/store/search"

    const params = []
    if (state.keyword !== "") {
      params.push(`keyword=${encodeURIComponent(state.keyword)}`)
    }
    if (state.selectedGenreId > 0) {
      params.push(`genre=${state.selectedGenreId}`)
    }

    if (params.length > 0) {
      base += "?"
      base += params.join("&")
    }

    return base
  },
}
