import GdprModalBottom from "./GdprModalBottom.vue"
import Cookies from "js-cookie"

const SVue = window.sVue
const MAL = window.MAL
const $ = window.$

$(() => {
  // cookieのチェックをする
  // if (Number(Cookies.get(MAL.GDPR_COOKIE_KEY)) === 1) {
  if (Number(Cookies.get(MAL.GDPR_COOKIE_KEY)) === 1) {
    return
  }

  // 無効にさせるページ
  const disabledPages = [
    "/about/privacy_policy",
    "/dialog/authorization",
    "/about/terms_of_use",
    "/about/cookie_policy",
    "/about/notice_at_collection",
    "/mhwc2023/votes",
  ]
  const locationPathname = location.pathname
  const locationSearch = decodeURIComponent(location.search)

  if (disabledPages.indexOf(locationPathname) > -1) {
    return
  }

  // アプリのOAuth認証のログイン画面で無効に
  if (locationPathname.indexOf("/login.php") > -1 && locationSearch.indexOf("?from=/dialog/authorization") > -1) {
    return
  }

  // modal表示
  const element = document.body
  const div = document.createElement("div")
  element.appendChild(div).setAttribute("class", "gdpr-modal")
  const el = document.querySelector(".gdpr-modal")
  new SVue({
    el,
    render: h => h(GdprModalBottom),
  })
})
