const $ = window.$;

// 共通で使う機能をまとめていく

$(() => {

  // anchor link
  if ($(".js-anchor-link")[0]) {
    $(".js-anchor-link").on("click", function() {
      const anchor_href = $(this).attr("href")
      const anchor_target_pos = $(anchor_href).offset().top- 100
      $('html,body').animate({ scrollTop: anchor_target_pos}, 500, 'swing')
      return false
    });
  }

  // FAQ
  if ($(".js-faq-question")[0]) {
    const $btnFaqQuestion = $(".js-faq-question")
    $btnFaqQuestion.on("click", function () {
      const faqId = $(this).attr("id")
      $(this).toggleClass("on")
      $(this).siblings(`.${faqId}`).toggleClass("show")
    })
  }

  if ($(".faq-lists .js-faq-img")[0]) {
    const $faqImg = $(".faq-lists .js-faq-img")
    $faqImg.on("touchstart", on)
  }

});
