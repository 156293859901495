var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("a", { on: { click: _vm.click } }, [
      _vm._v(_vm._s(_vm.text)),
      _vm.optionalText
        ? _c("span", [_vm._v(_vm._s(_vm.optionalText))])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }