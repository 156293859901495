var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "status" }, [
      _c("span", { staticClass: "progress" }, [
        _vm._v("\n      " + _vm._s(_vm.head) + ":\n      "),
        _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.completed))])
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "score score-label",
          class: "score-" + (_vm.score > 0 ? _vm.score : "na")
        },
        [
          _c("i", { staticClass: "fa-regular fa-star mr4" }),
          _vm._v(_vm._s(_vm.score) + "\n    ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }