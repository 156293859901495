import elVis from "../../../common/element-visibility"

const $ = window.$
const sVue = window.sVue

$(() => {

  // Auto recommendation
  $(".js-auto-recommendation").each(
    function () {

      const id = this.id
      const placement = this.getAttribute("data-placement")
      const initialData = this.getAttribute("data-initial-data")

      var timeoutId = null

      const autoRecs = new sVue({
        el: `#${id}`,
        data: {
          width: 90,
          margin: 1,
          isLoading: true,
          currentPos: 0,
          recs: [],
        },
        mounted: function() {
          let data = JSON.parse(initialData)
          if (data === null) {
            $.ajax({
              url: "auto_recommendation/personalized_suggestions.json",
              data: {
                placement: placement
              },
              success: response => {
                this.recs = response
                this.isLoading = false
              }
            })
          } else {
            this.recs = data
            this.isLoading = false
          }
        },
        updated: function () {
        },
        methods: {
          onClick: function (index) {
            const url = this.recs[index].path
            location.href = url
          },
          onContextMenu: function (index) {
          },
          scroll: function (direction) {
          }
        }
      })

    }
  )

})
