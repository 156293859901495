$(document).ready(function(){
     $('<div id="top_scroller" class="for-sp">')
         .append('<span class="fa-solid fa-arrow-up"></span>')
         .on('click', ()=>{ $('html,body').animate({scrollTop:0},500); })
         .appendTo('body');
     $(window).on('scroll', ()=>{
         if($(this).scrollTop() > 100){
            $('#top_scroller').fadeIn();
         } else {
            $('#top_scroller').fadeOut();
         }
    });
});
