import StacksModule from "../../../common/modules/stacks";

/* eslint-disable indent */
const $ = window.$;

$(function() {
  if ($(".js-show-spoilers")[0]) {
    $(".js-show-spoilers").on("click", function() {
      $(this).toggleClass("on");
      $(".js-spoiler").toggleClass("spoilers", !$(this).hasClass("on"));
    });
  }
  // tab button
  if ($(".js-navi-stacks")[0]) {
    $(".js-btn-type").on("click", function() {
      $(".js-btn-type").removeClass("on");
      $(this).addClass("on");
      // localStorage.setItem('stackTab', $(this).data("key"));
      reflectsStacks();
    });
    const reflectsStacks = function() {
      // tab
      const btnTab = $(".js-btn-type");
      btnTab.each(function() {
        const key = $(this).data("key");
        if (!$(this).hasClass("on")) {
          $(`.tab-${key}`).hide();
        } else {
          $(`.tab-${key}`).show();
        }
      });
    }
    const selectTab = '1';
    const btnTab = $(".js-btn-type");
    btnTab.each(function() {
      $(this).toggleClass("on", String($(this).data("key")) === selectTab);
    });
    reflectsStacks();
  }

  // Get screen size
  const getDimention = function () {
    const data = {
      docW : $(document).width(),
      docH : $(document).height(),
      winW : $(window).width(),
      winH : $(window).height(),
      sclV : $(window).scrollTop()
    };
    return data;
  };

  // close Dialog
  const closeDialog = function () {
    $("#loading .circleAnim").removeClass("circleAnim2");
    $("#loading").hide();
    $("#fieldcontain").remove();// remove lodash.js template
    $("#dialog_pop").empty();
    $(window).off(".noScroll");
    $("#dialog_overlay").css("display", "none");
    return false;
  }

  if ($(".js-navi-stacks")[0]) {
    $(".js-select-type").on("change", function() {
      const key = $(this).val();
      const url = location.href.replace(/\?.*$/, "");
      const q = $("input[name=q]").val();
      location.href = url + "?type=" + key + (q ? "&q=" + q : "");
      return false;
    });

    // show dialog buttons
    $(".js-navi-stacks .js-btn-show-sort").on("click", function(e) {
      e.preventDefault();
      const id = $(this).data("id");
      const dimention = getDimention();
      const docH = dimention.docH;
      const dialogObj = $("#dialog_overlay");
      // black background
      dialogObj.css({height: docH, display: "block", opacity: 0}).hide().fadeIn("fast", function() {
        // sort popup block
        $(`.js-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        dialogObj.on("touchend click", function() {
          closeDialog();
          $(".js-navi-stacks .js-sort-order-block").hide();
          $(".js-navi-stacks .js-btn-show-sort").removeClass("on");
          return false;
        });
      });

      dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
        e.preventDefault();
      });

      // $(window).on("touchmove.noScroll", function(e) {
      //   e.preventDefault();
      // });

      // close BTN function
      $(".js-sort-order-block .js-btn-close").on("touchend", function() {
        $(".js-navi-stacks .js-sort-order-block").hide();
        $(".js-navi-stacks .js-btn-show-sort").removeClass("on");
        closeDialog();
        return false;
      });
    });

    // sort button
    $(".js-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      $(".js-sort-order-block.js-sort .js-ajax-loader").show();
      $(".js-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?sort=' + $(this).attr("id");
    });
  }

  // open item modal
  $(".js-btn-add-item").on("click", function(e) {
    $(".stacks").css({"overflow-y": "hidden"})
    e.preventDefault();
    const dimention = getDimention();
    const docH = dimention.docH;
    const sclV = dimention.sclV;
    const dialogObj = $("#dialog_overlay");
    const topPosV = 40 + sclV;
    // black background
    dialogObj.css({height: docH, display: "block", opacity: 0.8}).hide().fadeIn("fast", function() {
      // sort popup block
      $(".add-item-popup").css({"top": `${topPosV}px`, "margin-top": "inherit"}).show();

      dialogObj.on("touchend click", function() {
        closeDialog();
        $(".add-item-popup").hide();
        $(".stacks").css({"overflow-y": "visible"})
        return false;
      });
    });

    dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
      e.preventDefault();
    });
    // close item modal
    $(".js-add-item-close").on("click", function(e) {
      $(".add-item-popup").hide();
      $(".stacks").css({"overflow-y": "visible"})
      closeDialog();
      // search item icon to be check
      $(".js-modal-btn i.fa-minus").each(function() {
        $(this).removeClass("fa-minus").addClass("fa-check").parents(".item").removeClass("just").addClass("added");
      });
      return false;
    });
  });
  // close item modal
  $(".js-modal-close").on("click", function(e) {
    $(".main-menu").show();
    $(".stacks-modal").hide();
  });

  // ---------------------------------
  // restack button
  // ---------------------------------
  let requestTimer;
  const setStackNum = function(context, num) {
    $(".balloon p", context).text(nFormatter(num, 1));
  }
  $(".js-btn-stack").on("click", function(e) {
    e.preventDefault();
    const num = parseInt($(this).data("num"));
    const $stackballoon = $(this).closest('.js-stack-balloon');
    const stat = $stackballoon.hasClass("on");
    if (stat) {
      $stackballoon.removeClass("on");
      $("i", this).removeClass("fa-layer-minus").addClass("fa-layer-plus");
      $(this).data("num", num - 1);
    } else {
      $stackballoon.addClass("on");
      $("i", this).removeClass("fa-layer-plus").addClass("fa-layer-minus");
      $(this).data("num", num + 1);
    }
    setStackNum($stackballoon, $(this).data("num"));
    $(".js-btn-stack").addClass("disabled");
    clearTimeout(requestTimer);
    requestTimer = setTimeout(function() {
      $(".js-btn-stack").removeClass("disabled");
    }, 300);
    $.ajax({
      url: "/stacks/restack.json",
      type: "POST",
      data: {
        mode: stat ? 'remove' : 'add',
        id: $(this).data("id")
      },
      dataType: "json"
    });
  });

  if ($(".js-stack-list")[0]) {
    // radio box
    $(".js-radio-box input[type=radio]").on("click", function() {
      changeSaveButton(this);
      const type = $(".js-form input[name=type]").val();
      const title = $("#stack_title").val();
      const visibility = $(".js-radio-box input[type=radio]:checked").val();
      requestSameStacks(type, title, visibility);
      $(".js-btn-type")[0].click();
    });
    const changeSaveButton = function(e) {
      $(".js-btn-save").prop("disabled",false);
      $(".js-radio-box").removeClass("on");
      $(e).parents(".js-radio-box").addClass("on");
      if ($(e).val() === "1") {
        $(".js-btn-save").html('<i class="fas fa-save"></i>Save Draft');
      } else {
        $(".js-btn-save").html('<i class="fas fa-paper-plane"></i>Publish');
      }
      if($(".js-stacks-warning-title ul").length !== 0) {
        $(".js-btn-save").prop("disabled",true);
      }
    }
    changeSaveButton($(".js-radio-box input[type=radio]:checked"));

    $(".js-btn-save").on("click", function(e) {
      e.preventDefault();
      $(this).prop('disabled', true);
      $('form[name="stack"]').submit();
    });

    // delete button
    $(".js-btn-delete-stacks").on("click", function(e) {
      if (confirm("Are you sure you want to delete this stacks?")) {
        $("input[name=mode]").val("delete");
      } else {
        return false;
      }
    });

    // item modal
    let stackModalInput, stackModalRequest, stackSearchText, stackOffset = 0;
    const mode = $("input[name=mode]").val();
    const stackItemParams = [
      'id',
      'type',
      'image_url',
      'swap_image',
      'title',
      'title_localized',
      'media_type',
      'episode',
      'published',
      'start_year',
      'start_date',
      'score',
      'members',
      'is_hentai',
      'list_status',
      'list_score',
    ];
    const funcStackModalRequest = function(type, q, offset) {
      $(".search-box i.fa-spinner").show();
      if (stackModalRequest &&
        stackModalRequest.readyState > 0 &&
        stackModalRequest.readyState < 4) {
        stackModalRequest.abort();
        stackModalRequest = null;
      }
      return $.ajax({
        url: "/stacks/search_item.json",
        type: "GET",
        data: {
          type: type,
          q: q,
          offset: offset
        },
        dataType: "json",
        cache: true,
        timeout: 10000,
      }).done(function(data) {
        $(".js-stacks-modal .next").remove();
        for (const item of data.item) {
          const added = $(".js-stack-list .item[data-id=" + item.id + "]").length > 0;
          let div = '<div class="di-box item'
          if (added) {
            div += ' added';
          }
          div += '"';
          for (const param of stackItemParams) {
            div += ' data-' + param + '="' + item[param] + '"';
          }
          div += '>';
          div += '<div class="image mr16"><img src="' + item.image_url + '" width="82" height="115"></div>';
          div += '<div class="data flex pt6 js-modal-btn"><div class="title"><span>' + item.title + '</span>';
          if (item.title_localized) {
            div += '<small>' + item.title_localized + '</small>';
          }
          div += '</div>';
          if (item.type === "anime") {
            div += '<div class="foot"><div class="stat"><span class="season">' + item.media_type + ', ' + item.episode + ', ' + item.start_year + '</span></div></div>';
          } else if (item.type === "manga") {
            div += '<div class="foot"><div class="stat"><span class="season">' + item.media_type + ', ' + item.published + '</span></div></div>';
          }
          if (item.list_status) {
            div += '<div class="modal-icon"><div class="' + STATUS[item.type][item.list_status]["class"] + '">' + STATUS[item.type][item.list_status]["name"] + '</div></div>';
          }
          div += '<div class="modal-btn"><i class="fas ';
          if (added) {
            div += 'fa-check';
          } else {
            div += 'fa-plus';
          }
          div += ' ga-click"';
          div += ' data-id="' + item.id + '"';
          div += ' data-ga-click-type="stacks-new-' + mode + '-item"';
          if (item.type == "anime") {
            div += ' data-ga-click-param="aid:' + item.id + '"';
          } else if (item.type == "manga") {
            div += ' data-ga-click-param="mid:' + item.id + '"';
          }
          div += '"></i></div>';
          div += '</div></div>';
          $(".js-modal-list").append(div);
        }
        stackOffset = $(".js-modal-list .item").length;
        if (data.item.length > 0) {
          const div_next = '<div class="next"><p><i class="fas fa-spinner fa-spin"></i>search next...</p></div>';
          $(".js-modal-list").append(div_next);
        }
        $(".search-box i.fa-spinner").hide();
      });
    }
    $(".js-stacks-modal input[name=q]").on("change keyup", function() {
      clearTimeout(stackModalInput);
      stackModalInput = setTimeout(function() {
        const type = $(".js-form input[name=type]").val();
        const q = $(".js-stacks-modal input[name=q]").val();
        if (type && q && q != stackSearchText) {
          stackSearchText = q;
          $(".js-modal-list").empty();
          stackModalRequest = funcStackModalRequest(type, q, 0);
        }
      }, 300);
    });
    $(".js-modal-list").scroll(function() {
      if ($(".js-stacks-modal .next")[0]) {
        if ($(".js-stacks-modal .next").offset().top - $(".js-stacks-modal .nextfoot").offset().top < 0) {
          const type = $(".js-form input[name=type]").val();
          const q = $(".js-stacks-modal input[name=q]").val();
          if (type && q && q == stackSearchText) {
            stackModalRequest = funcStackModalRequest(type, q, stackOffset);
          }
        }
      }
    });

    $(".js-stack-list").sortable({
      axis: 'y',
      handle: '.handle',
      activate: function(e, ui) {
        ui.item.addClass('dragging');
      },
      deactivate: function(e, ui) {
        ui.item.removeClass('dragging');
      },
      update: function() {
        itemCheck();
      }
    });

    $(document).on("click", ".js-stack-list .opener", function() {
      const item = $(this).parents(".item");
      stackItemOpen(item, $("i", this).hasClass("fa-angle-up"));
    });
    $(".js-stack-all-close").on("click", function(e) {
      e.preventDefault();
      $(".js-stack-list .item").each(function(i,e) {
        stackItemOpen($(e), true);
      });
    });
    $(".js-stack-all-open").on("click", function(e) {
      e.preventDefault();
      $(".js-stack-list .item").each(function(i,e) {
        stackItemOpen($(e), false);
      });
    });

    function stackItemOpen(item, flag) {
      const panel = item.find(".panel");
      if (flag) {
        $(".opener i", item).removeClass("fa-angle-up").addClass("fa-angle-down");
        item.removeClass("stop");
        item.find(".panel").hide();
      } else {
        $(".opener i", item).removeClass("fa-angle-down").addClass("fa-angle-up");
        item.addClass("stop");
        item.find(".panel").show();
        $(".js-auto-resize", item).change();
      }
    }

    // ---------------------------------
    // add stack popup
    // ---------------------------------
    if ($(".js-btn-new-stack")[0]) {
      $(".js-btn-new-stack").on("click", function(e) {
        e.preventDefault();
        const dimention = getDimention();
        const docH = dimention.docH;
        const sclV = dimention.sclV;
        const dialogObj = $("#dialog_overlay");
        const topPosV = 40 + sclV;
        // black background
        dialogObj.css({height: docH, display: "block", opacity: 0.8}).hide().fadeIn("fast", function() {
          // sort popup block
          $(".js-new-stack-block").css({"top": `${topPosV}px`, "margin-top": "inherit"}).show();

          dialogObj.on("touchend click", function() {
            closeDialog();
            $(".js-new-stack-block").hide();
            return false;
          });
        });

        dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
          e.preventDefault();
        });

      });
    }

    // ---------------------------------
    // Auto arrange stack popup
    // ---------------------------------
    if ($(".js-btn-auto-arrange")[0]) {
      $(".js-btn-auto-arrange").on("click", function(e) {
        e.preventDefault();
        const dimention = getDimention();
        const docH = dimention.docH;
        const sclV = dimention.sclV;
        const dialogObj = $("#dialog_overlay");
        const topPosV = 40 + sclV;
        // black background
        dialogObj.css({height: docH, display: "block", opacity: 0.8}).hide().fadeIn("fast", function() {
          // sort popup block
          $(".js-auto-arrange-block").css({"top": `${topPosV}px`, "margin-top": "inherit"}).show();

          dialogObj.on("touchend click", function() {
            closeDialog();
            $(".js-auto-arrange-block").hide();
            return false;
          });
        });

        dialogObj.on("touchmove.noScroll touchend.noScroll touchstart.noScroll", function(e) {
          e.preventDefault();
        });

        // auto arrange (sort)
        ["members", "score", "newest", "az", "myscore"].forEach(function(code) {
          $(".js-auto-arrange-block .js-arrange-" + code).on("click", function(e) {
            e.preventDefault();
            $(".tab-2 .js-stack-list .item").sort(blockSorter[code]).each(function() {
              $(".tab-2 .js-stack-list").append(this);
            });
            itemCheck();
            closeDialog();
            $(".js-auto-arrange-block").hide();
            return false;
          });
        });
      });
    }

    const blockSorter = {
      members: function(a, b) {
        const aMembers = $(a).data('members');
        const bMembers = $(b).data('members');
        return bMembers - aMembers;
      },
      score: function(a, b) {
        const aScore = $(a).data('score');
        const bScore = $(b).data('score');
        return bScore - aScore;
      },
      newest: function(a, b) {
        const aDate = $(a).data('start_date');
        const bDate = $(b).data('start_date');
        return bDate - aDate;
      },
      az: function(a, b) {
        const aTitle = $(a).data('title');
        const bTitle = $(b).data('title');
        return (aTitle > bTitle) ? 1 : (aTitle < bTitle) ? -1 : 0
      },
      myscore: function(a, b) {
        const aScore = $(a).data('list_score');
        const bScore = $(b).data('list_score');
        return bScore - aScore;
      }
    };

    // auto resize textarea
    $(document).on("input", ".js-auto-resize", function(){
      const $inputBox = $(this);
      const scrollHeight = $inputBox.get(0).scrollHeight;
      const paddingHeight = $inputBox.outerHeight() - $inputBox.height();
      const maxHeight = window.innerHeight - 160;

      // 高さを自動調整する
      $inputBox.height(scrollHeight - paddingHeight)
      // 上限を超えた場合は制限する
      if ($inputBox.outerHeight() > maxHeight) {
        $inputBox.height(maxHeight)
      }
    });

    // ---------------------------------
    // add/remove item
    // ---------------------------------
    $(document).on("click", ".js-modal-btn", function() {
      const icon_item = $(this).find(".modal-btn i");
      if (icon_item.hasClass("fa-plus")) {
        icon_item.removeClass("fa-plus").addClass("fa-minus");
        const itemDiv = $(this).parents(".item").addClass("just");
        let item = [];
        for (const param of stackItemParams) {
          if (param == 'swap_image') {
            item[param] = JSON.stringify((itemDiv.data(param)));
          } else {
            item[param] = itemDiv.data(param);
          }
        }
        addItem(item);
        itemCheck();
      } else if (icon_item.hasClass("fa-minus")) {
        icon_item.removeClass("fa-minus").addClass("fa-plus");
        const item = $(this).parents(".item").removeClass("just");

        // remove item to list
        $(".js-stack-list .item[data-id=" + item.data("id") + "]").remove();
        itemCheck();
      }
    });

    // ---------------------------------
    // delete item
    // ---------------------------------
    $(document).on("click", ".js-btn-delete-item", function(e) {
      e.preventDefault();
      const item = $(this).parents(".item");
      const modal_item = $(".js-modal-list .item[data-id=" + item.data("id") + "]");
      if (modal_item.length) {
        modal_item.removeClass("added");
        $(".modal-btn i", modal_item).removeClass("fa-check").addClass("fa-plus");
      }
      item.remove();
      itemCheck();
    });

    // ---------------------------------
    // add item to list
    // ---------------------------------
    const addItem = function(item) {
      let div = '<div class="item"';
      for (const param of stackItemParams) {
        div += ' data-' + param + '="' + item[param] + '"';
      }
      div += ' data-pic_id="0">';
      div += '<div class="bar">';
      div += '<div class="handle"><i class="far fa-grip-lines"></i></div>';
      div += '<div class="title"><span>' + item.title + '</span>';
      if (item.title_localized) {
        div += '<small>' + item.title_localized + '</small>';
      }
      div += '</div><div class="opener"><i class="fas fa-angle-down"></i></div></div>';
      div += '<div class="panel" style="display:none;">';
      // div += '<div class="img"><img src="' + item.image_url + '" width="90" height="124"></div>';
      div += '<div class="detail"><div class="notice_status notice">';
      // div += '<button class="btn btn-image"><i class="fas fa-exchange"></i>Swap Image</button>';
      div += '<div class="mr4">';
      if (item.type == 'anime') {
        div += '<span>' + item.media_type + ', ' + item.episode + ', ' + item.start_year + '</span>';
      } else if (item.type == 'manga') {
        div += '<span>' + item.media_type + ', ' + item.published + '</span>';
      }
      // div += '<span class="fl-r ml8"><i class="fas fa-user mr4"></i>' + nFormatter(item.members, 0) + '</span>';
      div += '<span class="ml8"><i class="far fa-star mr4"></i>' + item.score + '</span>';
      div += '</div></div>';
      div += '<textarea class="description auto-resize js-auto-resize" maxlength="' + MAXLENGTH + '" name="stack[item_descriptions][' + item.id + ']"></textarea>';
      div += '<div class="list_status">';
      div += '<button class="btn btn-trash js-btn-delete-item"><i class="fas fa-trash"></i>Delete Item</button>';
      if (item.list_status) {
        div += '<div class="status ' + STATUS[item.type][item.list_status]["class"] + '">' + STATUS[item.type][item.list_status]["desc"] + '</div>';
        let list_score = item.list_score ? item.list_score : "-";
        div += '<div class="balloon"><p><i class="fas fa-star"></i>' + list_score + '</p></div>';
      }
      div += '</div>';
      div += '</div></div></div>';
      $(".js-stack-list").append(div);
    }

    // ---------------------------------
    // expand additional & restore item description & expand item_pic_ids
    // ---------------------------------
    const expandAdditional = function() {
      if (Array.isArray(ADDITIONAL.item) && ADDITIONAL.item.length > 0) {
        for (const item of ADDITIONAL.item) {
          addItem(item);
        }
      }
      $(".js-stack-list .item").each(function() {
        const sel = '[name="stack[item_descriptions][' + $(this).data("id") + ']"]';
        const text = $(".js-item_descriptions " + sel).val();
        $(sel, this).val(text);
      });
      $(".js-item_descriptions").empty();
      $(".js-auto-resize").trigger('input');
      const item_ids = $("#stack_item_pic_ids").val().split(',');
      if (item_ids) {
        let idx = 0;
        for (let key in item_ids) {
          const item = $(".js-stack-list .item").eq(idx);
          item.data("pic_id", item_ids[key]);
          if (item_ids[key] > 0) {
            const swap_image = item.data("swap_image");
            item.data("image_url", swap_image[item_ids[key]]);
            $(".img img", item).attr("src", swap_image[item_ids[key]]);
          }
          idx++;
        }
      }
    }
    expandAdditional();

    // ---------------------------------
    // item check
    // ---------------------------------
    const itemCheck = function() {
      const hentaiCount = $(".js-stack-list .item[data-is_hentai=true]").length;
      $(".js-contains-hentai").toggle(hentaiCount > 0);
      $('[name="stack[is_hentai]"]').val(hentaiCount > 0 ? '1' : '0')
      const c = $(".js-stack-list .item").length;
      $(".js-number-of-items").text(c + " item" + (c > 1 ? "s" : ""));
      let list = [], pic_list = [];
      $(".js-stack-list .item").each(function() {
        list.push($(this).data('id'));
        pic_list.push($(this).data('pic_id'));
      });
      $("#stack_item_ids").val(list.join(","));
      $("#stack_item_pic_ids").val(pic_list.join(","));
    }
    itemCheck();

    // ---------------------------------
    // preview button
    // ---------------------------------
    $(".js-btn-preview").on("click", function(e) {
      $(".main-menu").hide();
      $(".stacks-modal").show();
      const p = $(".js-stacks-preview");
      const type = $(".js-form input[name=type]").val();
      $(".js-title", p).text($("#stack_title").val());
      ["anime", "manga", "characters", "challenge"].forEach(function(t) {
        $(".tag-" + t, p).toggle(t == type);
      });
      $(".js-owner", p).text($(".username").text());
      $(".js-introduction").html(nl2br($("#stack_description").val()));
      const list = $(".js-stacks-preview .list-anime-list").empty();
      $(".tab-2 .js-stack-list .item").each(function() {
        let div = '<div class="seasonal-anime">';
        div += '<div class="top-detail di-box">';
        div += '<div class="image mr16">';
        div += '<img src="' + $(this).data("image_url") + '" width="87">';
        div += '</div>';
        div += '<div class="data flex pt6">';
        div += '<div class="title"><a href="#" class="link-title">' + $(this).data("title") + '</a></div>';
        div += '<div class="foot">';
        if ($(this).data("type") == 'anime') {
          div += '<div class="info">' + $(this).data("media_type") + ', ' + $(this).data("episode") + ', ' + $(this).data("start_year");
        } else if ($(this).data("type") == 'manga') {
          div += '<div class="info">' + $(this).data("media_type") + ', ' + $(this).data("published");
        }
        div += '</div>';
        div += '</div>';
        const desc = $(".tab-2 .js-stack-list .item[data-id=" + $(this).data("id") + "] .description").val();
        div += '</div>';
        div += '</div>';
        div += '<div class="detail pt12 pr8 pl8">';
        div += '<div class="info di-b">' + nl2br(desc) + '</div>';
        div += '</div>';
        div += '</div>';
        list.append(div);
      });
      $(".js-stacks-preview").fadeIn();
      // $('body').css('overflow-y', 'hidden');
    });
  }

  // ---------------------------------
  // Upload Image
  // ---------------------------------
  if ($(".js-droparea")[0]) {
    const enableDrop = function(){
      const div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FileReader' in window;
    }();
    if (enableDrop) {
      const reader = new FileReader();
      reader.onload = function(e){
        $('.js-droparea').css('background-image', 'url(' + e.target.result + ')').addClass("on");
      }
      $(".js-droparea").on('drag dragstart dragend dragover dragenter dragleave drop', function(e){
        e.preventDefault();
        e.stopPropagation();
      })
      .on('dragover dragenter', function(){
        $(".js-droparea").addClass('is-dragover');
      })
      .on('dragleave dragend drop', function(){
        $(".js-droparea").removeClass('is-dragover');
      })
      .on('drop', function(e){
        document.getElementById("stack_header_picture").files = e.originalEvent.dataTransfer.files;
        $(".js-droparea input[type=file]").change();
      });
      $(".js-droparea input[type=file]").on("change", function(e){
        const image = $(this).prop('files')[0];
        reader.readAsDataURL(image);
        $(".js-unset-image").show();
      });
      $(".js-unset-image a").on("click", function(e){
        e.preventDefault();
        document.getElementById("stack_header_picture").files = null;
        $('.js-droparea').css('background-image', 'none').removeClass("on");
        $(".js-unset-image").hide();
      });
    }
  }

  // ---------------------------------
  // enable search button & quick clear button
  // ---------------------------------
  if ($(".js-search-area")[0]) {
    const p = $(".js-search-area");
    $("input[type=text]", p).on("change keyup", function(){
      $("button", p).toggleClass("notActive", $("input[type=text]", p).val() == "");
      $(".js-quick-clear", p).toggle($("input[type=text]", p).val() != "");
    }).change();
    $(".js-quick-clear", p).on("click", function(){
      $(this).hide();
      $("input[type=text]", p).val("");
      $("button", p).addClass("notActive");
    });
  }
  // ---------------------------------
  // opener
  // ---------------------------------
  if ($(".js-opener")[0]) {
    $(".js-opener").on("click", function(){
      const p = $("i", this).hasClass("fa-angle-up");
      $("i", this).toggleClass("fa-angle-up", !p).toggleClass("fa-angle-down", p);
      $(this).parent().find(".js-panel").toggle(!p);
    });
  }
  // ---------------------------------
  // add/edit title check
  // ---------------------------------
  let stackTitleInput, sameStackSearchText;
  $("#stack_title").on("change keyup", function() {
    clearTimeout(stackTitleInput);
    stackTitleInput = setTimeout(function(){
      const type = $(".js-form input[name=type]").val();
      const title = $("#stack_title").val();
      const visibility = $(".js-radio-box input[type=radio]:checked").val();
      if (type && title && title != sameStackSearchText) {
        sameStackSearchText = title;
        requestSameStacks(type, title, visibility);
      }
    }, 500);
  });
  const stackItemParams = [
    'stack_id',
    'type',
    'title',
    'count_item',
    'owner_mem_username',
    'num_stack'
  ];
  const requestSameStacks = function(type, title, visibility) {
    $(".js-stacks-warning-title").empty();
    $(".js-title-ok").hide();
    $(".same-stack-list-block").hide();
    $(".js-same-list").empty();
    // 3-20の結果リストをresetするのため
    $(".js-same-list-hidden").empty();
    if (!$(".js-same-list-hidden").hasClass('off')){
      $(".js-same-list-hidden").addClass('off');
    }
    $(".js-btn-more").hide();
    $(".js-stacks-warning-title").hide();
    $(".js-btn-save").prop('disabled',false);
    $(".title-box i.fa-spinner").show();

    // unlistedの場合、publicに公開するわけではないので重複チェックは行わない
    // 未入力状態でdraftからpublicに変えた時などに意図せず動作することを防ぐ。
    if(visibility === "2" || !title) {
      $(".title-box i.fa-spinner").hide();
      return;
    }

    // Ajax通信前にFormエラーを消さないと、エラーが二重に表示されてしまう。
    $(".js-stack-title-badresult-text").hide();

    return $.ajax({
      url: "/stacks/search_title.json",
      type: "GET",
      data: {
        type: type,
        title: title,
        status: visibility
      },
      dataType: "json",
      cache: false,
      timeout: 10000
    }).done(function(data) {
      const stacks = data.filter((row) => row.stack_id !== $("#stack_id").val())
      if (stacks.length === 0) {
        $(".js-title-ok").show();
      } else {
        $(".same-stack-list-block").fadeIn();
        for (const [index, item] of stacks.entries()) {
          let div = '<div class="item"';
          for (const param of stackItemParams) {
            div += ' data-' + param + '="' + escape_html(item[param]) + '"';
          }
          div += '>';
          const url = location.href
          const url_search = url.indexOf('stacks/');
          const url_txt = url.substring(url_search);
          const stacks_top = url.replace(url_txt, "stacks/");
          div += '<a class="title" href="' + stacks_top + item.stack_id + '"><span>' + escape_html(item.title) + '</span></a>';
          div += '<span class="by">by ' + item.owner_mem_username + '</span>';
          div += '<span class="stat">'+ item.count_item + ' Entrie';
          if (item.count_item > 1) {
            div += 's';
          }
          div += '&nbsp;&middot;&nbsp;' + item.num_stack + ' Restack';
          if (item.num_stack > 1) {
            div += 's';
          }
          div += '</span>';
          div += '</div>';
          if (index >= 2){
            $(".js-same-list-hidden").append(div);
          } else {
            $(".js-same-list").append(div);
          }
        }
        if (stacks.length > 2) {
          if ($('i', ".js-btn-more").hasClass('fa-angle-up')){
            $('i', ".js-btn-more").removeClass('fa-angle-up')
            $('i', ".js-btn-more").addClass('fa-angle-down')
          }
          $(".js-btn-more").show();
        } else {
          $(".js-btn-more").hide();
        }
      }
      $(".title-box i.fa-spinner").hide();
    }).fail(function(xhr) {
      if(Array.isArray(xhr.responseJSON.errors)) {
        $(".js-stacks-warning-title").show();
        $(".js-stacks-warning-title").append(xhr.responseJSON.errors[0].message);
        $(".js-btn-save").prop('disabled',true);
      }
      $(".title-box i.fa-spinner").hide();
    });
  }

  if ($(".js-btn-more").exists()) {
    $(".js-btn-more").on("click", function(e) {
      e.preventDefault()
      const button = $(this);
      const more_area = $(this).siblings(".js-same-list-hidden");
      if (more_area.hasClass('off')) {
        more_area.removeClass('off');
        $('i', button).removeClass('fa-angle-down');
        $('i', button).addClass('fa-angle-up');
      } else {
        more_area.addClass('off');
        $('i', button).addClass('fa-angle-down');
        $('i', button).removeClass('fa-angle-up');
      }
    });
  }

  if ($(".js-num-stack")[0]) {
    $(".js-num-stack").on("click", (e) => {
      const $self = $(e.currentTarget)
      const pagename = $self.data("pagename")
      const stackId = $self.data("id")
      StacksModule.generatRestacksModal('#content', 'sp', pagename)
      StacksModule.openRestacksModal(stackId)
      return false
    })
  }
});

function nl2br(str) {
  str = str.replace(/\r\n/g, "<br />");
  str = str.replace(/(\n|\r)/g, "<br />");
  return str;
}

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

function escape_html(str) {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/[&'`"<>]/g, function(match) {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    }[match]
  });
}
