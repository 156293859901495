export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const UPDATE_SCORE = "UPDATE_SCORE"
export const INCREMENT_EPISODE = "INCREMENT_EPISODE"
export const INCREMENT_VOLUME = "INCREMENT_VOLUME"
export const INCREMENT_CHAPTER = "INCREMENT_CHAPTER"
export const INIT_DATA = "INIT_DATA"
export const INIT_DATA_ITEM = "INIT_DATA_ITEM"
export const API_ERROR = "API_ERROR"
export const UNKNOWN_ERROR = "UNKNOWN_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const FLUSH_UPDATED_ITEM_DATA = "FLUSH_UPDATED_ITEM_DATA"
