status編集用のselect box
<template>
  <div :class="className">
    <label>Status</label>
    <select :value="statusId" @change="change($event.target.value)">
      <option v-for="(status, id) in statusList" :value="id">
      {{ status.str }}
      </option>
    </select>
  </div>
</template>

<script>
import {getUserStatusMapByWorkType} from "../../../utils/list"

export default {
  props: {
    workType: String, // anime or manga
    statusId: Number,
    className: String, // style適用するためのclass名
  },
  computed: {
    statusList() {
      return getUserStatusMapByWorkType(this.workType)
    },
  },
  methods: {
    change(val) {
      this.$emit("change", parseInt(val))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
@import "../../../../css/variables";

label {
  float: left;
  display: inline-block;
  width: 80px;
  margin-top: 12px;
  font-size: 1.4rem;
  color: #323232;
  font-weight: 700;
}

select {
  @include vendor-prefixes(appearance, none);
  background: {
    image: url('/img/sp/common/icon-dropdown.png?v='+ $version);
    repeat: no-repeat;
    size: 16px 16px;
    position: right 8px center;
    color: #fff;
  }
  width: calc(100% - 100px);
  height: 33px;
  border: 1px solid #979797;
  color: #4a4a4a;
  @include vendor-prefixes(border-radius, 4px);
  font-size: 1.4rem;
  font-weight: 400;
  text-indent: .4em;
  cursor: pointer;
}
</style>
