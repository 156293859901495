<template>
  <div class="root" v-if="id > 0 && sp">
    <div class="box">
      <button v-if="is_buy" v-on:click="showModal()" class="mal-btn outline secondary ga-click ga-impression"
              :data-ga-click-type="gaType()"
              :data-ga-impression-type="gaType()"
              :data-ga-click-param="gaParam()"
              :data-ga-impression-param="gaParam()"><i
        class="fa-solid fa-cart-shopping ga-click"
        :data-ga-click-type="gaType()"
        :data-ga-click-param="gaParam()"></i></button>
      <button v-if="is_manga && is_wish" v-on:click="!is_wishlist && showModal()"
              class="mal-btn outline ga-click ga-impression "
              v-bind:class="[is_wishlist ?  'secondary pressed' : '']"
              :data-ga-click-type="gaType()"
              :data-ga-impression-type="gaType()"
              :data-ga-click-param="gaParam()"
              :data-ga-impression-param="gaParam()">
        <i class="fa-solid fa-star-shooting ga-click"
           :data-ga-click-type="gaType()"
           :data-ga-click-param="gaParam()"></i></button>
      <div class="modal-template">
        <span class="title" ref="modal_title">Buy manga here:</span>
        <div class="content" ref="modal_content">
          <affiliate-amazon-button :id="id" :type="type" :is_buy="is_buy" :link="link" :is_wish="is_wish"
                                   :sp="sp" v-on:click-link="hideModal()"
                                   :ga_type="'buy_manga_'+(is_buy ? 'amazon' : 'wish')+'_'+type+'_ranking'"></affiliate-amazon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../../../../js/common/modules/modal';
import AffiliateAmazonButton from "./AffiliateAmazonButton.vue";
import AffiliateAmazon from "./AffiliateAmazon";

export default {
  name: "AffiliateAmazonButtonPreviewModal",
  components: {
    AffiliateAmazonButton
  },
  props: {
    type: String,
    id: Number,
    link: String,
    image_url: String,
    title: String,
    volume: String,
    is_wish: Boolean,
    is_buy: Boolean,
    sp: Boolean,
    ga_type: String
  },
  mounted() {
    this.is_wishlist = this.affiliate.isWishList(this.id)
  },
  methods: {
    showModal: function () {
      Modal.generate();
      Modal.buildCommonDialog(this.$refs.modal_title, this.$refs.modal_content)
      Modal.show();
    },
    hideModal: function () {
      Modal.hide();
      this.is_wishlist = this.affiliate.isWishList(this.id)
    },
    addWishList: function () {
      if (!this.is_wishlist) {
        this.affiliate.addWishList(this.id)
        this.is_wishlist = true;
      }
    },
    gaType: function () {
      return 'buy_manga_popup_' + (this.is_buy ? 'amazon' : 'wish') + '_' + (this.is_manga ? 'manga' : 'anime') + '_ranking';
    },
    gaParam: function () {
      return (this.is_manga ? 'mid' : 'aid') + ':' + this.id;
    }
  },
  data() {
    return {
      is_manga: this.type === 'manga',
      is_anime: this.type === 'anime',
      is_wishlist: false,
      affiliate: AffiliateAmazon(this.$cookie)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../../css/_variables.scss";

.root > div.box {
  right: 0;
  top: .75rem;
  position: absolute;
  background-color: $color-pc-bg-ffffff;
  border-radius: var(--mal-btn-border-radius);

  & > button {
    margin: 0;
    padding: 0.5rem;
    font-size: 2rem;

    &.secondary {
      border-color: $color-pc-text-323232;
      color: $color-pc-text-323232;
    }

    &.pressed {
      color: $color-pc-text-888888;
    }
  }

  & > .modal-template {
    display: none;
  }
}

.content {
  margin: 2rem;
}
</style>
