var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "canvas-root" }, [
    _c("canvas", {
      ref: "canvas",
      style: {
        width: _vm.displayWidth + "px",
        height: _vm.displayHeight + "px"
      },
      attrs: {
        width: _vm.displayWidth * _vm.pixelDensity,
        height: _vm.displayHeight * _vm.pixelDensity
      }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "loading-image",
      style: _vm.loadingImageLeftStyle
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "loading-image",
      style: _vm.loadingImageRightStyle
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "hover-area",
      on: {
        click: _vm.canvasClick,
        touchstart: _vm.startDrag,
        touchend: _vm.stopDrag
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }