const _ = window._
import * as types from "./mutation-types"

export default {
  [types.INIT_DATA](state, item) {
    _.forEach(_.keys(state), k => {
      if (_.has(item, k)) {
        state[k] = item[k]
      }
    })
  },
  [types.UPDATE_USER_STATUS](state, userStatusId) {
    state.userStatusId = userStatusId

    if (userStatusId !== 2) {
      return
    }

    // when completed
    if (state.totalEpisodes > 0) {
      state.watchedEpisodes = state.totalEpisodes
    } else {
      if (state.totalVolumes > 0) {
        state.readVolumes = state.totalVolumes
      }
      if (state.totalChapters > 0) {
        state.readChapters = state.totalChapters
      }
    }
  },
  [types.UPDATE_SCORE](state, score) {
    state.score = score
  },
  [types.UPDATE_EPISODE](state, val) {
    if (state.totalEpisodes > 0 && state.totalEpisodes < val) {
      state.watchedEpisodes = state.totalEpisodes
    } else {
      state.watchedEpisodes = val
    }
  },
  [types.UPDATE_VOLUME](state, val) {
    if (state.totalVolumes > 0 && state.totalVolumes < val) {
      state.readVolumes = state.totalVolumes
    } else {
      state.readVolumes = val
    }
  },
  [types.UPDATE_CHAPTER](state, val) {
    if (state.totalChapters > 0 && state.totalChapters < val) {
      state.readChapters = state.totalChapters
    } else {
      state.readChapters = val
    }
  },
  [types.API_ERROR](state, err) {
    state.error = err
  },
  [types.UNKNOWN_ERROR](state, err) {
    state.error = err
  },
  [types.CLEAR_ERROR](state) {
    state.error = null
  },
  [types.LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
}
