<template>
  <div>
    <a @click="click">{{ text }}<span v-if="optionalText">{{ optionalText }}</span></a>
  </div>
</template>

<style lang="scss" scoped>
  div {
    display: inline-block;
  }
  a {
    border: 1px solid #ff9600;
    background-color: #ff9600;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 16px;
    min-width: 92px;
    padding: 8px 8px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
    &:active {
      color: #ffffff;
      text-decoration: none;
      opacity: 0.7;
    }
  }
  @media screen and (max-width:374px) {
    a {
      padding: 8px 4px;
    }
  }
  span {
    font-size: 12px;
    font-weight: 300;
  }
</style>

<script type="text/babel">
  export default {
    props: {
      text: String,
      optionalText: String,
    },
    methods: {
      click() {
        this.$emit('purchase')
      }
    }
  }
</script>
