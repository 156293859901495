// JavaScript Document
import Cookies from "js-cookie"
import $ from "jquery"
import _ from "lodash"
import axios from "axios"
import Vuex from "vuex"
import InterstitialAd from "../../modules/ad/Interstitial"
import ListModal from "../../../../vue/components/list_modal/ListModal.vue"
import modalStore from "../../../../vue/components/list_modal/store_module"
import ModalNS from "../../../../vue/components/list_modal/store_module/namespace"
import {LOADING} from "../../../../vue/components/list_modal/store_module/mutation-types"
const SVue = window.sVue

$(function() {
  // ------------------------------
  // List Viewを設定する
  // ------------------------------
  if ($(".js-btn-ownlist-view")[0]) {
    $(".js-btn-ownlist-view").on("click", function() {
      const view = $(this).data("view")
      Cookies.set("ownlist_view", view, {expires: 157680000, path: "/"})
    })
  }

  const store = new Vuex.Store({
    state: {
      type: null,
    },
    modules: {
      [ModalNS]: modalStore,
    },
    actions: {
      fetchData({commit, state}, {id, category, statusId}) {
        return axios.get("/ownlist/get_list_item", {params: {id, list: category} })
          .then(res => {
            commit("initData", _.assign(res.data, {id, category, statusId}))
          })
      },
    },
    mutations: {
      initData(state, data) {
        state.type = (data.in_ownlist ? "edit" : "add")

        // for modal store module
        const modal = state[ModalNS]
        modal.id = parseInt(data.id)
        modal.title = data.title
        modal.statusId = parseInt(data.statusId)
        modal.userStatusId = parseInt(data.status) || 1
        modal.score = parseInt(data.score) || 0
        modal.watchedEpisodes = parseInt(data.completed_episode_num) || 0
        modal.totalEpisodes = parseInt(data.episode_num) || 0
        modal.readChapters = parseInt(data.completed_chapter_num) || 0
        modal.totalChapters = parseInt(data.chapter_num) || 0
        modal.readVolumes = parseInt(data.completed_volume_num) || 0
        modal.totalVolumes = parseInt(data.volume_num) || 0
      },
    }
  })
  SVue.use(Vuex)

  // ----------------------------------
  // Add/Edit の dialogue
  // ----------------------------------
  const vDialog = new SVue({
    el: "#vm-entry-edit-dialog",
    store,
    data() {
      return {
        rating: "gray",
        category: null,
        scrollTop: Number,
      }
    },
    computed: {
      ...Vuex.mapState([
        "type",
      ]),
      ...Vuex.mapState(ModalNS, {
        id: state => state.id,
        userStatusId: state => state.userStatusId,
        isLoading: state => state.isLoading,
      }),
    },
    methods: {
      display(params) {
        (async() => {
          this.$store.commit(`${ModalNS}/${LOADING}`, true)
          $(this.$el).show()
          const statusId = params.series_status
          await this.$store.dispatch("fetchData", _.assign(params, {statusId}))
          this.category = params.category
          this.scrollTop = $(window).scrollTop()
          this.$store.commit(`${ModalNS}/${LOADING}`, false)
        })().catch(err => {
          this.close()
          // error msg
          let message
          if (err.response) {
            message = err.response.statusText
          } else {
            message = "Connection Failed."
          }

          const docH = $(document).height()
          const sclV = $(window).scrollTop()
          $("#dialog_overlay").css({height: docH, display: "block"}).hide().fadeIn("fast", () => {
            const dialogH = $("#dialog_pop").height()
            const topPosV = (dialogH < 150) ? 130 + sclV : 40 + sclV
            $("#dialog_pop").removeClass("dialog_edit_list")
            $("#dialog_pop").css({"top": `${topPosV}px`, "margin-top": "inherit", "display": "block"}).show()
          })

          $(window).on("touchmove.noScroll", (e) => {
            e.preventDefault()
          })
          const template = _.template($("#dialog_error_template").text())
          $("#dialog_pop").append(template({
            error_message: message
          }))

          // close BTN function
          $(".js-btn-close-dialog").on("touchend", (e) => {
            e.preventDefault()
            $("#fieldcontain").remove()
            $("#dialog_pop").empty()
            $(window).off(".noScroll")
            $("#dialog_overlay").css("display", "none")
          })
        })
      },
      close() {
        this.$store.commit(`${ModalNS}/${LOADING}`, false)
        $(this.$el).hide()
        $(window).scrollTop(this.scrollTop)
      },
      saveItem() {
        // ------------------------------
        // vueの外にいるのをupdateするとこ
        // ------------------------------
        // change color of triangle
        $(`.js-anime-watch-status[data-btnid="${this.category}${this.id}"]`).attr("data-class", this.userStatusId)
        if ($(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"] i`).hasClass("malicon malicon-ic_list_add")) {
          $(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"] i`).removeClass("malicon malicon-ic_list_add").addClass("malicon malicon-ic_list_edit")
        }
        $(`.js-manga-watch-status[data-btnid="${this.category}${this.id}"]`).attr("data-class", this.userStatusId)
        if ($(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"] i`).hasClass("malicon malicon-ic_list_add")) {
          $(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"] i`).removeClass("malicon malicon-ic_list_add").addClass("malicon malicon-ic_list_edit")
        }
        // change button in Anime/Manga Detail
        if (this.type === "add") {
          $(`.js-btn-text-add[data-btnid="${this.category}${this.id}"]`).attr("data-add", "Edit")
        }
        // anime detail
        if ($(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"]`).hasClass("detail")) {
          const statusName = $(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"]`).data("status")[this.userStatusId]
          $(`.js-btn-dialog-add[data-btnid="${this.category}${this.id}"]`).attr("data-class", this.userStatusId).text(statusName)
        }
        this.close()

        const interstitial = new InterstitialAd("sp_list_interstitial", this.rating)
        interstitial.renderAd()
      },
    },
    components: {
      ListModal,
    },
  })

  $(document).on("click", ".js-btn-dialog-add", (e) => {
    vDialog.display($(e.currentTarget).data("param"))
  })

})
