<template>
  <div id="list-items">
    <list-modal
      v-if="show"
      :work-type="workType"
      @submitted="saveItem" @cancel="closeModal"
      ></list-modal>
    <list-view
      v-if="view === 'list'"
      v-for="item in sortedItems"
      :id="item.id" :score="item.score" :error="item.error"
      :title="item.title" :status-id="item.statusId" :image="item.image" :path="item.path"
      :user-status-id="item.userStatusId" :re-doing="item.reDoing"
      :total-episodes="item.totalEpisodes" :watched-episodes="item.watchedEpisodes"
      :total-volumes="item.totalVolumes" :read-volumes="item.readVolumes"
      :total-chapters="item.totalChapters" :read-chapters="item.readChapters"
      @show-modal="showModal"
    >
    </list-view>
    <tile-view
      v-if="view === 'tile'"
      v-for="item in sortedItems"
      :id="item.id" :score="item.score"
      :title="item.title" :image="item.image" :path="item.path"
      :user-status-id="item.userStatusId"
      :watched-episodes="item.watchedEpisodes" :read-chapters="item.readChapters"
      @show-modal="showModal"
    >
    </tile-view>
  </div>
</template>

<script>
import ListView from "./organisms/ListView.vue"
import TileView from "./organisms/TileView.vue"
import ListModal from "../../components/list_modal/ListModal.vue"
import {FLUSH_UPDATED_ITEM_DATA} from "./store/mutation-types"
import {mapState, mapGetters} from "vuex"

export default {
  name: "OwnList",
  data() {
    return {
      show: false,
      scrollTop: Number,
    }
  },
  computed: {
    ...mapState([
      "view", "workType",
    ]),
    ...mapGetters([
      "sortedItems",
    ]),
  },
  methods: {
    showModal(id) {
      this.$store.dispatch("initModalData", id)
      this.show = true
      this.scrollTop = $(window).scrollTop()
    },
    closeModal() {
      this.show = false
      $(window).scrollTop(this.scrollTop)
    },
    saveItem() {
      this.$store.commit(FLUSH_UPDATED_ITEM_DATA)
      this.closeModal()
    },
  },
  components: {
    ListView, TileView, ListModal,
  },
}
</script>

<style lang="scss" scoped>
#list-items {
  padding-bottom: 16px;
  margin-bottom: 16px;
}
</style>
