var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("status-colored-image", {
          attrs: {
            title: _vm.title,
            "work-type": _vm.workType,
            image: _vm.image,
            path: _vm.path,
            statusId: _vm.userStatusId
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "info" }, [
      _vm.isOwner
        ? _c(
            "div",
            { staticClass: "edit-icon" },
            [_c("edit-icon", { on: { "edit-list-item": _vm.showModal } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("a", { staticClass: "title", attrs: { href: _vm.path } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stats" },
        [
          _vm.workType === "anime"
            ? _c("progress-stats", {
                attrs: {
                  head: "Episodes",
                  total: _vm.totalEpisodes,
                  completed: _vm.watchedEpisodes
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.workType === "manga"
            ? _c("progress-stats", {
                attrs: {
                  head: "Volumes",
                  total: _vm.totalVolumes,
                  completed: _vm.readVolumes
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.workType === "manga"
            ? _c("progress-stats", {
                attrs: {
                  head: "Chapters",
                  total: _vm.totalChapters,
                  completed: _vm.readChapters
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.reDoing
            ? _c("span", { staticClass: "re-doing" }, [
                _vm._v("\n        " + _vm._s(_vm.doing) + "\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.status
            ? _c("span", { staticClass: "status" }, [
                _vm._v("\n        " + _vm._s(_vm.status) + "\n      ")
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }