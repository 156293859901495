import * as types from "./mutation-types"
import {purchase} from "../../../../common/modules/purchase"
import {NAMESPACE as TAX_NAMESPACE} from "../../../../common/stores/tax_calculation_modal"

export default {
  async [types.PURCHASE]({state}) {
    await purchase({
      itemType: state.item.itemType,
      itemIds: [state.item.id],
      itemTotalAmount: state.item.totalAmount,
      selectedCountry: state[TAX_NAMESPACE].selectedCountry
    })
  },
}
