var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v("\n  " + _vm._s(_vm.head) + ":\n  "),
    _vm.isProgress
      ? _c("span", { staticClass: "completed" }, [
          _vm._v(_vm._s(_vm.completed))
        ])
      : _vm._e(),
    _vm.isProgress ? _c("span", [_vm._v("/")]) : _vm._e(),
    _vm._v(_vm._s(_vm.totalAsString) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }