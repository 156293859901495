import Cookies from "js-cookie"

(function($) {

  function isArray(value) {
    if (typeof value == "undefined") return false;

    if (value instanceof Array ||
      (!(value instanceof Object) && (Object.prototype.toString.call((value)) == '[object Array]') ||
      typeof value.length == 'number' &&
      typeof value.splice != 'undefined' &&
      typeof value.propertyIsEnumerable != 'undefined' &&
      !value.propertyIsEnumerable('splice'))) {
      return true;
    }

    return false;
  }

  $.easy2add = function(node, settings) {
    var lNode = $(node);
    if (!lNode.data("easy2add")) lNode.data("easy2add", new easy2add(node, settings));

    return lNode.data("easy2add");
  };

  $.fn.easy2add = function(action, opt_value) {

    var returnValue, args = arguments;

    function isDef(val) {
      return val !== undefined;
    };

    function isDefAndNotNull(val) {
      return val != null;
    };
    this.each(function() {
      var self = $.easy2add(this, action);

      if (typeof action == "string") {
        switch (action) {
          case "resize":
            self.reset();
            break;
        };

      } else if (!action && !opt_value) {
        if (!isArray(returnValue)) returnValue = [];

        returnValue.push(self);
      }
    });

    if (isArray(returnValue) && returnValue.length == 1) returnValue = returnValue[0];

    return returnValue || this;
  };

  var OPTIONS = {
    settings: {
      easing: 'easeOutQuad',
      handle_width: 30,
      speed: 'slow'
    },
  };

  function easy2add() {
    return this.init.apply(this, arguments);
  };

  easy2add.prototype.init = function(node, settings) {
    var caller = this;
    caller.settings = $.extend(true, {}, OPTIONS.settings, settings ? settings : {});
    caller.current = 0;
    caller.padding = $(window).height() * 0.12;
    caller.window_height = $('#easy2add-dialog').height();
    caller.window_width =  $('#easy2add-dialog').width();
    caller.child_count = $(node).children('.easy2add-child').length;
    caller.node = $(node);
    caller.image_height = 305;
    caller.image_width = 216;
    caller.load_count = 0;
    caller.next_click_count = 0;
    caller.recommend_time = 0;
    caller.recommend_limit = 1;

    caller.node.swipe({
      swipeLeft: function(event, direction, distance, duration, fingerCount) {
        caller.forward();
      },
    });

    caller.node.children('.easy2add-child').children('.easy2add-image').children('img').each(function(){
      var img = $('<img>').on('load', function(){
        caller.load_count ++;
        caller.reset();
      });
      img.attr('src',$(this).attr('src'));
    });

    caller.next_click_count = 1;
    $('.easy2add-next-icon').on('click', function(event) {
      caller.forward();
      caller.next_click_count++;
      caller.setGaTagOnNextIcon();
    });

    $('.easy2add-button1').on('click', function(event) {caller.button1Clicked()});
    if (caller.settings.page == 'seasonal') {
      $('.easy2add-button2').on('click', function(event) {caller.forward()});
    } else {
      $('.easy2add-button2').on('click', function(event) {caller.button2Clicked()});
    }

    // 上部文言
    if (caller.settings.tmp_list.length > 1) {
      $('.easy2add-list-text-upper').text("You add " + caller.settings.tmp_list.length + " animes to your list!");
    } else if (caller.settings.tmp_list.length == 1) {
      if (caller.settings.page != 'title_page') {
        $('.easy2add-list-text-upper').text("You add " + caller.settings.tmp_list.length + " anime to your list!");
      }
    }
  };

  easy2add.prototype.reset = function() {
    var caller = this;
    var node = caller.node;
    caller.window_height = node.height(); // $(window).height()
    caller.window_width = node.width(); // $(window).width();
    var window_width = caller.window_width;
    var window_height = caller.window_height;
    caller.padding = window_height * 0.05;
    var image_height = 305;
    var image_width = 216;

    caller.image_width = image_width;
    caller.image_height = image_height;

    node.children('.easy2add-child').children('.easy2add-image').children('img');
    var caption = node.children('.easy2add-child').children('.easy2add-caption');
    caption.each(function() {
      $(this).css('margin-top', 4);
    });
    node.children('.easy2add-child').hide();

    var current_child = node.children('.easy2add-child').eq(caller.current);
    current_child.width(image_width).height(image_height);
    current_child.css({
      "top": caller.padding,
      "left": (window_width - image_width) / 2
    }).show();

    var prev_child = current_child.prev();
    prev_child.width(image_width - 30).height(image_height);
    var prev_left = (-image_width + caller.settings.handle_width);
    prev_child.css({
      "top": caller.padding,
      "left": prev_left
    }).show();

    var next_child = current_child.next();
    next_child.width(image_width - 30).height(image_height);
    var next_left = window_width - caller.settings.handle_width;
    next_child.css({
      "top": caller.padding,
      "left": next_left
    }).show();

    var first_child = prev_child.prev();
    first_child.width(image_width - 30).height(image_height);
    var first_left = (-window_width + caller.settings.handle_width) * 2;
    first_child.css({
      "top": caller.padding,
      "left": first_left
    }).show();

    var last_child = next_child.next();
    last_child.width(image_width - 30).height(image_height);
    var last_left = (window_width - caller.settings.handle_width) * 2;
    last_child.css({
      "top": caller.padding,
      "left": last_left
    }).show();

    node.children('.easy2add-child').children('.easy2add-caption').hide();
    current_child.children('.easy2add-caption').show();
  }

  easy2add.prototype.remove = function() {
      var caller = this;
      var current_child = caller.node.children('.easy2add-child').eq(caller.current);
      current_child.remove();
      caller.child_count--;

      if (caller.child_count > 0 && caller.current < caller.child_count) {
        caller.reset();

        var next_child = current_child.next();
        next_child.animate({
          left: (caller.window_width - next_child.children('.easy2add-image').children('img').width()) / 2
        }, caller.settings.speed, caller.settings.easing);

        var last_child = next_child.next();
        last_child.animate({
          left: caller.window_width - caller.settings.handle_width
        }, caller.settings.speed, caller.settings.easing);
      } else {
        caller.current--;
        // APIで再取得
        caller.getRecommend();
      }
  };

  easy2add.prototype.getRecommend = function() {
    const caller = this;

    if (caller.recommend_time >= caller.recommend_limit) {
      caller.finish();
      return;
    }
    caller.recommend_time++;

    const page = caller.settings.page;
    const title_id = caller.settings.title_id || 1;
    const api_url = '/anime/easy2add/recommend';
    const ga = Cookies.get('_ga');
    const user_id = ga.substring(6);

    const param = {
      user_pseudo_id: user_id,
      page_type : page,
      title_anime_id : title_id,
      max_anime: 20
    };

    $.ajax({
        url: api_url,
        type: "GET",
        data    : param,
        dataType: "json",
        success : function (data) {
            if (data.message === "OK") {
              const recommended_anime = data.result.recommended_anime
                .filter((item) => caller.settings.checked_list.includes(item.anime_id) === false);
              if(recommended_anime.length > 0) {
                caller.addList(recommended_anime);
              } else {
                // 終了処理
                caller.finish();
              }
            } else {
              // 終了処理
              caller.finish();
            }
        },
        error : function (err) {
            // 終了処理
            caller.finish();
        }
    });
  };

  easy2add.prototype.addList = function(recommended_anime) {
    const caller = this;

    recommended_anime
      .forEach((item) => {
        const img_path = item.img_path || "/images/qm_50.gif";
        let html = "<div class=\"easy2add-child\">";
        html += "<div class=\"easy2add-id\">" + item.anime_id + "</div>";
        html += "<div class=\"easy2add-image\">";
        html += "<img src=\"" + img_path + "\" />";
        html += "</div>";
        html += "<div class=\"easy2add-caption\">" + item.title + "</div>";
        html += "</div>";
        caller.node.append(html);
        caller.child_count++;
        caller.reset();
      })
    caller.current++;
    caller.reset();

    const current_child = caller.node.children('.easy2add-child').eq(caller.current);
    const anime_id = current_child.children('.easy2add-id');
    caller.settings.checked_list.push(anime_id);

    $('.easy2add-next-icon').show();
}

  easy2add.prototype.setTmpList = function(item) {
    var caller = this;
    const api_url = '/anime/easy2add/set_tmp_list';
    $.ajax({
      url: api_url,
      type: "GET",
      data    : item,
      dataType: "json",
      success : function (data) {
          if (data.message == 'OK') {
            caller.settings.tmp_list = data.tmp_list;
            // $('.easy2add-tmplist-num').text(caller.settings.tmp_list.length);
            if (caller.settings.tmp_list.length > 1) {
              $('.easy2add-list-text-upper').text("You add " + caller.settings.tmp_list.length + " animes to your list!");
            } else if (caller.settings.tmp_list.length == 1) {
              $('.easy2add-list-text-upper').text("You add " + caller.settings.tmp_list.length + " anime to your list!");
            }
          }
      },
      error : function (err) {
      }
    });
  };

  easy2add.prototype.button1Clicked = function() {
      var caller = this;
      var current_child = caller.node.children('.easy2add-child').eq(caller.current);
      var anime_id = current_child.children('.easy2add-id');
      var status = caller.settings.page == 'seasonal' ? '6' : '2';
      var item = {'id': anime_id.text(), 'status': status};

      caller.settings.tmp_list.push(item);
      caller.setTmpList(item);

      // caller.remove();
      caller.forward();
  };
  easy2add.prototype.button2Clicked = function() {
      var caller = this;

      var current_child = caller.node.children('.easy2add-child').eq(caller.current);
      var anime_id = current_child.children('.easy2add-id');
      var item = {'id': anime_id.text(), 'status': '6'};

      caller.settings.tmp_list.push(item);
      caller.setTmpList(item);
      // caller.remove();
      caller.forward()
  };

  easy2add.prototype.finish = function() {
    $("#dialog_overlay").click();
  };

  easy2add.prototype.forward = function() {
    $('.easy2add-next-icon').hide();

    var caller = this;
    var current_child = caller.node.children('.easy2add-child').eq(caller.current);
    var anime_id = current_child.children('.easy2add-id');
    caller.settings.checked_list.push(anime_id);

    if (caller.current >= caller.child_count - 1) {
      caller.getRecommend();
      return false;
    }

    current_child.animate({
      left: (-current_child.children('.easy2add-image').children('img').width() + caller.settings.handle_width)
    }, caller.settings.speed, caller.settings.easing, function() {
      caller.current++;
      caller.reset();
      $('.easy2add-next-icon').show();
    });

    var prev_child = current_child.prev();
    prev_child.animate({
      left: (-caller.window_width)
    }, caller.settings.speed, caller.settings.easing);

    var next_child = current_child.next();
    next_child.animate({
      left: (caller.window_width - next_child.children('.easy2add-image').children('img').width()) / 2
    }, caller.settings.speed, caller.settings.easing);
    var next_anime_id = next_child.children('.easy2add-id');
    caller.setGaTagOnButton1(next_anime_id.text());
    caller.setGaTagOnButton2(next_anime_id.text());

    var last_child = next_child.next();
    last_child.animate({
      left: caller.window_width - caller.settings.handle_width
    }, caller.settings.speed, caller.settings.easing);
  };

  // GA 計測タグ
  easy2add.prototype.setGaTagOnNextIcon = function() {
    const caller = this;
    const click_param = 'titlecount:' + caller.next_click_count;
    $('.easy2add-next-icon').attr("data-ga-click-param", click_param);
  };

  easy2add.prototype.setGaTagOnButton1 = function(anime_id) {
    const click_param = 'aid:' + anime_id;
    $('.easy2add-button1').attr("data-ga-click-param", click_param);
  };

  easy2add.prototype.setGaTagOnButton2 = function(anime_id) {
    const click_param = 'aid:' + anime_id;
    $('.easy2add-button2').attr("data-ga-click-param", click_param);
  };

})(jQuery);
