<template>
  <div class="viewer-wrap">
    <main-canvas
      :current-page="currentPage"
      :num-total-pages="numTotalPages"
      :is-portrait="isPortrait"
      :is-show-marginals="showMarginals"
      :newest-loaded-image-page="newestLoadedImagePage"
      :manuscript="manuscript"
      :images="images"
      :page-direction="pageDirection"
    ></main-canvas>
    <marginals
      :show="isMenuVisible"
      :is-portrait="isPortrait"
      :is-preview="isPreview"
      :is-free="isFree"
      :is-ad-shown="isAdShown"
      :current-timestamp="currentTimestamp"
      :custom-button="customButton"
      :current-page="currentPage"
      :num-total-pages="numTotalPages"
      :book="book"
      :manuscript="manuscript"
      :images="images"
      :page-direction="pageDirection"
      @purchase="purchase"
    ></marginals>
    <story-modal
      :is-portrait="isPortrait"
      :show="modals.showStoryModal"
      :book="book"
      :authors="authors"
      :browser-inner-height="browserInnerHeight"
    ></story-modal>
    <tutorial-modal
      :show="modals.showTutorialModal"
      :browser-inner-height="browserInnerHeight"
    ></tutorial-modal>
    <nopage-modal
      :show="modals.showNopageModal"
    ></nopage-modal>
    <lefttoright-modal
      :show="modals.showLefttorightModal"
    ></lefttoright-modal>
    <finish-modal
      :show="modals.showFinishModal"
      :book="book"
      :next-book="nextBook"
      :currency="currency"
      :is-preview="isPreview"
      :is-free="isFree"
      :is-ad-shown="isAdShown"
      :current-timestamp="currentTimestamp"
      :custom-finish="customFinish"
      :browser-inner-height="browserInnerHeight"
      @purchase="purchase"
      @backstore="backstore"
      @backmanga="backmanga"
      @read="read"
      @preview="preview"
      :banned="banned"
    ></finish-modal>

    <template v-if="nextBook">
      <tax-calculation-modal></tax-calculation-modal>
      <purchase-single-modal
        @confirm="confirm"
        @cancel="showConfirmationModal = false"
        :is-shown="showConfirmationModal"
        :is-requesting="isRequesting"
        :error="error"
        :item="nextBook"
        :currency="currency"
        :is-delegated="isDelegated"
      ></purchase-single-modal>
      <purchase-finish-modal
        @read="read"
        @close="showPurchaseFinishModal = false"
        :is-shown="showPurchaseFinishModal"
        :is-single="true"
        :title="nextBook.title"
        :numberingText="nextBook.numberingText"
        :onSaleAt="nextBook.onSaleAt"
        :closeSaleAt="nextBook.closeSaleAt"
        :currentTimestamp="currentTimestamp"
      ></purchase-finish-modal>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .viewer-wrap {
    // iOS11.2未満はenv()
    // iOS11.2以降はconstant()
    padding-top: constant(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-right: constant(safe-area-inset-left);
    padding-left: constant(safe-area-inset-left);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-right: env(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
  }
</style>

<script type="text/babel">
  import * as types from "./store/mutation-types"
  import { mapGetters, mapState, mapActions } from "vuex"
  import MainCanvas from "./organisms/MainCanvas.vue"
  import Marginals from "./organisms/Marginals.vue"
  import StoryModal from "./organisms/StoryModal.vue"
  import FinishModal from "./organisms/FinishModal.vue"
  import TutorialModal from "./organisms/TutorialModal.vue"
  import NopageModal from "./organisms/NopageModal.vue"
  import LefttorightModal from "./organisms/LefttorightModal.vue"

  import PurchaseSingleModal from "../../../../components/manga_store/sp/molecules/PurchaseSingleModal.vue"
  import PurchaseFinishModal from "../../../../components/manga_store/sp/molecules/PurchaseFinishModal.vue"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../../components/manga_store/common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../../components/manga_store/common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../../../../components/manga_store/sp/organisms/tax_calculation_modal/TaxCalculationModal.vue"

  const _ = window._

  export default {
    components: {
      MainCanvas,
      Marginals,
      StoryModal,
      FinishModal,
      TutorialModal,
      NopageModal,
      PurchaseSingleModal,
      PurchaseFinishModal,
      TaxCalculationModal,
      LefttorightModal,
    },
    data() {
      return {
        showConfirmationModal: false,
        showPurchaseFinishModal: false,
        isRequesting: false,
        error: null,
      }
    },
    mounted() {
      if (this.isIos) {
        window.addEventListener("touchmove", e => {
          if (e.touches[0].target.tagName.toLowerCase() === "input") {
            // seekbarはスクロールが必要
            return
          } else if (e.touches[0].target.className === "modal-description") {
            // モーダルのsynopsis部分はスクロールが必要
            return
          }
          e.preventDefault()
        })
      }

      // check cookie
      const storeReferer = this.$cookie.get("store_referer")
      if (!storeReferer) {
        return
      }
      const successfullyRegistered = this.$cookie.get("successfully_registered")
      const storeRefererUrl = document.createElement('a')
      storeRefererUrl.href = decodeURIComponent(storeReferer)
      if (successfullyRegistered && location.pathname === storeRefererUrl.pathname) {
        const page = parseInt(this.$cookie.get("store_viewer_page"))
        setTimeout(() => {
          this.$store.commit(types.JUMP_PAGE, {page})
          this.bookmark(this.$store)
          this.purchase()
        }, 350)
      }
    },
    computed: {
      ...mapGetters(["isIos"]),
      ...mapState([
        "banned",

        "loginUrl",

        "currentPage",
        "numTotalPages",
        "showMarginals",
        "pageDirection",
        "isPortrait",
        "browserInnerHeight",
        "newestLoadedImagePage",

        "modals",

        "book",
        "nextBook",
        "authors",
        "manuscript",
        "images",
        "isPreview",
        "isFree",
        "isAdShown",
        "currentTimestamp",
        "customButton",
        "customFinish",
        "currency",
      ]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated
      }),
      isMenuVisible() {
        return this.isFinished || this.showMarginals || this.modals.showTutorialModal
      }
    },
    created() {
      // 45 秒ごとに署名付き URL のパラメータを更新する
      setInterval(() => {
        this.$store.dispatch(types.RENEW_TOKEN)
      }, 45000)
    },
    watch: {
      currentPage() {
        // 事前に数ページ前後の画像をロードする
        this.loadImages(this.$store)

        // 読んだページを保存
        // 試し読みでは読んだページを保存しない
        if (!this.isPreview) {
          this.bookmark(this.$store)
        }
      }
    },
    methods: {
      // 画像をロードする
      // ページを短い間隔で移動した時に都度リクエストを送らないように debounce を使用
      loadImages: _.debounce((store) => {
        store.dispatch(types.LOAD_IMAGES)
      }, 300),

      // 読んだページを保存するリクエストを送信する
      // ページを短い間隔で移動した時に都度リクエストを送らないように debounce を使用
      bookmark: _.debounce((store) => {
        store.dispatch(types.BOOKMARK)
      }, 1000),

      setCookie() {
        const successfullyRegistered = this.$cookie.get("successfully_registered")
        if (successfullyRegistered) {
          this.$cookie.delete("store_viewer_page")
          this.$cookie.delete("store_referer")
          this.$cookie.delete("successfully_registered")
        } else {
          this.$cookie.set("store_referer", location.href , 1)
          this.$cookie.set("store_viewer_page", this.currentPage , 1)
        }
      },

      /**
       * 最後のページで購入する
       */
      async purchase() {
        this.$store.commit(types.HIDE_FINISH_MODAL)

        if (this.loginUrl) {
          location.href = this.loginUrl
        } else {
          this.isRequesting = true

          // CookieにURLを保存
          this.setCookie()

          // Paymentモーダルを使う or Paymentページにさせるか
          this.$store.commit(`${TAX_NAMESPACE}/${taxCalculationModalTypes.USE_PAYMENT_MODAL}`, false)

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: this.nextBook.itemType,
            itemIds: [this.nextBook.id],
            itemTotalAmount: this.nextBook.salePrice || this.nextBook.price,
            selectedCountry: null,
          })
          this.$store.commit(types.SET_TAX, taxInfo)

          this.isRequesting = false
          this.showConfirmationModal = true
        }
      },

      /**
       * 購入確定する
       */
      async confirm() {
        this.error = null
        this.isRequesting = true
        try {
          await this.$store.dispatch(types.PURCHASE)
          this.showConfirmationModal= false
          this.showPurchaseFinishModal = true
          // 購入時にPreviewで読んだページを保存
          this.bookmark(this.$store)
        } catch (e) {
          this.isRequesting = false
          this.error = e.message
        }
      },

      backstore() {
        location.href = this.nextBook.url
      },
      backmanga() {
        location.href = this.nextBook.url + '?goto=manga'
      },
      read() {
        location.href = this.nextBook.viewerUrl
      },
      preview() {
        location.href = this.nextBook.previewUrl
      },
    },
  }
</script>
