const SVue = window.sVue

import Vuex from "vuex"

SVue.use(Vuex)

import * as getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import modal from "../../../components/list_modal/store_module"
import ModalNS from "../../../components/list_modal/store_module/namespace"

const state = {
  items: {
    /*
     id: {
      id: Number,
      statusId: Number,
      userStatusId: Number,
      score: Number,
      title: String,
      path: String,
      image: String,
      watchedEpisodes: Number,
      totalEpisodes: Number,
      readVolumes: Number,
      totalVolumes: Number,
      readChapters: Number,
      totalChapters: Number,
      reDoing: Boolean,
      orderKey: Number,
      error: String,
     }
    */
  },
  workType: null,
  userName: null,
  loggedInUserName: null,
  view: null,
  isWebp: null,
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    [ModalNS]: modal,
  },
})
