<template>
  <div class="root">
    <transition name="modal">
        <div class="modal-nopage" v-show="show">No page</div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-nopage{
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    z-index: 99999;
    display: block;
    width: 202px;
    padding: 15px;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"

  export default {
    props: {
      show: Boolean,
    },
    watch: {
      show() {
        if (this.show) {
          const modalShowTime = 1500
          setTimeout(() => {
            this.$store.commit(types.HIDE_NOPAGE_MODAL)
          }, modalShowTime)
        }
      }
    },
  }
</script>
