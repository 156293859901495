// JavaScript Document
const Swiper = require("../../common/swiper")

const storeTopSwiper = new Swiper(".swiper-container", {
  // Optional parameters
  autoplay: 5000,
  loop: true,

  // If we need pagination
  pagination: ".swiper-pagination",

  // Navigation arrows
  nextButton: ".swiper-button-next",
  prevButton: ".swiper-button-prev",

  paginationClickable: true,
})

Array.from(document.querySelectorAll(".swiper-container")).forEach(el => {
  const dataCount = parseInt(el.getAttribute("data-swiper"))
  if (dataCount <= 1) {
    storeTopSwiper.params = {
      autoplay: false,
      loop: false,
      loopAdditionalSlides: 0,
      loopedSlides: 0,
      allowTouchMove: false,
    }
  }
  el.addEventListener("touchstart", (e) => {
    storeTopSwiper.stopAutoplay()
  })
  el.addEventListener("touchend", (e) => {
    storeTopSwiper.startAutoplay()
  })
})