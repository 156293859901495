status更新用のselect box
<template>
  <div>
    <select @change="updateStatus($event.target.value)" :value="statusId" :class="statusClass" v-if="isOwner" class="select">
      <option v-for="(status, id) in statusList" :value="id">
      {{ status.str }}
      </option>
    </select>
    <div v-if="!isOwner" :class="statusClass">
      {{ currentStatus }}
    </div>
  </div>
</template>

<script>
import {getUserStatusMapByWorkType} from "../../../utils/list"

export default {
  props: {
    workType: String, // anime or manga
    statusId: Number,
    isOwner: Boolean, // アクセスした人がlistのOwnerかどうか
  },
  computed: {
    statusList() {
      return getUserStatusMapByWorkType(this.workType)
    },
    statusClass() {
      return this.statusList[this.statusId].key
    },
    currentStatus() {
      return this.statusList[this.statusId].str
    },
  },
  methods: {
    updateStatus(val) {
      this.$emit("change", parseInt(val))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
@import "../../../../css/variables";

.select {
  @include vendor-prefixes(appearance, none);
  background-color: inherit;
  border: 0 solid #979797;
  @include vendor-prefixes(border-radius, 0);
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.watching {
  color: $list-status-watching;
}
.reading {
  color: $list-status-reading;
}
.completed {
  color: $list-status-completed;
}
.on-hold {
  color: $list-status-on-hold;
}
.dropped {
  color: $list-status-dropped;
}
.plan-to-watch {
  color: $list-status-plan-to-watch;
}
.plan-to-read {
  color: $list-status-plan-to-read;
}
</style>
