var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "list-items" } },
    [
      _vm.show
        ? _c("list-modal", {
            attrs: { "work-type": _vm.workType },
            on: { submitted: _vm.saveItem, cancel: _vm.closeModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.sortedItems, function(item) {
        return _vm.view === "list"
          ? _c("list-view", {
              attrs: {
                id: item.id,
                score: item.score,
                error: item.error,
                title: item.title,
                "status-id": item.statusId,
                image: item.image,
                path: item.path,
                "user-status-id": item.userStatusId,
                "re-doing": item.reDoing,
                "total-episodes": item.totalEpisodes,
                "watched-episodes": item.watchedEpisodes,
                "total-volumes": item.totalVolumes,
                "read-volumes": item.readVolumes,
                "total-chapters": item.totalChapters,
                "read-chapters": item.readChapters
              },
              on: { "show-modal": _vm.showModal }
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm._l(_vm.sortedItems, function(item) {
        return _vm.view === "tile"
          ? _c("tile-view", {
              attrs: {
                id: item.id,
                score: item.score,
                title: item.title,
                image: item.image,
                path: item.path,
                "user-status-id": item.userStatusId,
                "watched-episodes": item.watchedEpisodes,
                "read-chapters": item.readChapters
              },
              on: { "show-modal": _vm.showModal }
            })
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }