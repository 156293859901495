import PurchaseSingleButton from "./PurchaseSingleButton.vue"
import store from "./store/index"
import * as types from "./store/mutation-types"
import {getCurrency} from "../../../common/modules/currency"

const $ = window.$
const SVue = window.sVue

const selector = ".v-manga-store-purchase-single-button"

$(() => {
  Array.from(document.querySelectorAll(selector)).forEach(el => {
    const item      = JSON.parse(el.getAttribute("data-item"))
    const loginPath = el.getAttribute("data-loginPath")
    const currency  = getCurrency(JSON.parse(el.getAttribute("data-currency")))
    const paymentSettings = JSON.parse(el.getAttribute("data-settings"))

    new SVue({
      el,
      store: store(),
      render: h => h(PurchaseSingleButton),
      created() {
        this.$store.commit(types.INITIALIZE, { item, loginPath, currency, paymentSettings })
      },
    })
  })
})
