<template>
  <div class="root">
    <transition name="modal">
      <div v-if="show"
        class="modal-content"
        :style="{height: ( browserInnerHeight ? browserInnerHeight + 'px' : '100%' ) }">
        <div class="modal-mask" @click="hide"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-inner">
              <div class="modal-comic-info">
                <img class="modal-cover" :src="coverImageUrl" :height="( browserInnerHeight < 300 ? '75' : '135' )">
                <p class="modal-title is-landscape-none">{{ book.title }}</p>
                <p class="modal-volume">{{ book.numberingText }}</p>
              </div>
              <div>
                <p class="modal-title is-portrait-none">{{ book.title }}</p>
                <p class="modal-author is-portrait-none">
                  <span v-for="(names, role) in authors">
                    {{ role }}<br>
                    <span v-for="name in names">{{ name }}<br></span><br>
                  </span>
                </p>
                <div class="modal-header">Synopsis</div>
                <div class="modal-description-wrap" :style='{"max-height": getSynopsisHeight() + "px"}'>
                  <p class="modal-description">{{ book.synopsis }}</p>
                </div>
              </div>
            </div>
            <a class="modal-close-text" @click="hide">Close</a>
            <a class="modal-close" @click="hide">Close</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    position: relative;
    width: 76%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    transition: all .3s ease;
    text-align: center;
  }
  .modal-comic-info {
    &::after {
      content: '';
      clear: both;
      display: block;
    }
  }
  .modal-cover {
    float: left;
    margin: 0 15px 10px 0;
    width: auto;
  }
  .modal-title {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    line-height: 1.18;
    text-align: left;
  }
  .modal-volume {
    font-size: 14px;
    font-weight: 700;
    color: #7a7a7a;
    text-align: left;
    margin: 4px 0 0;
  }
  .modal-author {
    font-size: 12px;
    line-height: 1.14;
    margin: 8px 0 0;
    color: #686A6F;
    text-align: left;
  }
  .modal-header {
    line-height: 1.0;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 10px;
  }
  .modal-description-wrap {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-description {
    display: block;
    height: calc(100% + 1px);
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
    text-align: left;
  }
  .modal-close-text {
    margin-top: 15px;
    color: #2E51A2;
    display: inline-block;
    text-decoration: underline;
    font-weight: 700;
  }
  .modal-close {
    position: absolute;
    top: - 20px;
    right: - 25px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(/images/manga_store/viewer/sp/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -99999px;
    cursor: pointer;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .is-portrait-none {
    display: none;
  }
  @media only screen and (orientation : landscape) {
    .is-portrait-none{
      display: block;
    }
    .is-landscape-none {
      display: none;
    }
    .modal-container {
      padding: 15px;
    }
    .modal-cover {
      float: none;
      margin: 0;
    }
    .modal-title {
      font-size: 12px;
    }
    .modal-volume {
      font-size: 12px;
      font-weight: 300;
    }
    .modal-header {
      margin-top: 10px;
      font-size: 12px;
    }
    .modal-description {
      font-size: 12px;
    }
    .modal-inner {
      display: table;
      & > div{
        display: table-cell;
        vertical-align: top;
      }
      & > div:first-child{
        padding:0 10px 0 0;
      }
      img{
        vertical-align: bottom;
      }
    }
    .modal-close-text {
      margin-top: 5px;
    }
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import {generateCoverImageUrl} from "../../../../../components/manga_store/common/modules/book"
  import { mapGetters } from "vuex"

  export default {
    mounted() {
      window.addEventListener("resize", this.resized)
      window.addEventListener("orientationchange", this.orientationchanged)
      this.resized()
    },
    props: {
      isPortrait: Boolean,
      show: Boolean,
      book: Object,
      authors: Object,
      browserInnerHeight: Number,
    },
    data() {
      return {
        isOrientationchanged : true,
      }
    },
    computed: {
      coverImageUrl() {
        return generateCoverImageUrl(this.book.coverImageBaseUrl)
      }
    },
    methods: {
      getSynopsisHeight() {
        const winH = window.innerHeight
        return this.isPortrait ? winH - 325 : 90
      },
      orientationchanged(e) {
        this.isOrientationchanged = true
      },
      resized() {
        const orientationChangeTime = 350
        setTimeout(() => {
          if (this.isOrientationchanged) {
            this.getSynopsisHeight()
            this.isOrientationchanged = false
          }
        }, orientationChangeTime)
      },
      hide() {
        this.$store.commit(types.HIDE_STORY_MODAL)
      },
    },
  }
</script>
