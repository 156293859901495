リスト表示用
<template>
  <div class="list-unit">
    <loader v-if="loading"></loader>
    <work-info
      :work-type="workType" :is-owner="isOwner"
      :id="id" :score="score"
      :title="title" :status-id="statusId" :image="image" :path="path"
      :user-status-id="userStatusId" :re-doing="reDoing"
      :totalEpisodes="totalEpisodes" :watchedEpisodes="watchedEpisodes"
      :totalVolumes="totalVolumes" :readVolumes="readVolumes"
      :totalChapters="totalChapters" :readChapters="readChapters"
      @edit-list-item="showModal"
    >
    </work-info>

    <div v-if="error" class="error">
      {{ error }}
    </div>

    <status-controller
      :work-type="workType" :is-owner="isOwner"
      :score="score" :user-status-id="userStatusId" :re-doing="reDoing" :status-id="statusId"
      @update-user-status="updateUserStatus" @update-score="updateScore" @increment="increment"
    >
    </status-controller>
  </div><!-- .list-unit -->
</template>

<script>
import * as types from "../store/mutation-types"
import Loader from "../atoms/Loader.vue"
import WorkInfo from "../molecules/WorkInfo.vue"
import StatusController from "../molecules/StatusController.vue"
import {mapState, mapGetters} from "vuex"

export default {
  props: {
    id: Number, // anime or manga id
    path: String, // 作品詳細へのpth
    userStatusId: Number, // listについてのユーザのstatus
    statusId: Number, // 作品のstatus
    score: Number,
    title: String, // 作品名
    image: String, // 作品画像へのpath
    watchedEpisodes: Number, // 視聴済みepisode数
    totalEpisodes: Number, // 作品の総episode数
    readVolumes: Number, // 既読巻数
    totalVolumes: Number, // 作品の総巻数
    readChapters: Number, // 既読話数
    totalChapters: Number, // 作品の総話数
    reDoing: Boolean, // re-watching or re-readingかどうか
    error: String, // エラー文言
  },
  data() {
    return {loading: false}
  },
  computed: {
    ...mapState([
      "workType",
    ]),
    ...mapGetters([
      "isOwner",
    ]),
  },
  methods: {
    showModal() {
      this.$emit("show-modal", this.id)
    },
    updateUserStatus(userStatusId) {
      this.loading = true
      this.$store.commit(types.CLEAR_ERROR, this.id)
      const self = this
      this.$store.dispatch(types.UPDATE_USER_STATUS, {
        id: this.id,
        userStatusId,
        done() {
          self.loading = false
        },
      })
    },
    updateScore(score) {
      this.loading = true
      this.$store.commit(types.CLEAR_ERROR, this.id)
      const self = this
      this.$store.dispatch(types.UPDATE_SCORE, {
        id: this.id,
        score,
        done() {
          self.loading = false
        },
      })
    },
    increment(target) {
      let total, current, action

      switch (target) {
        case "episode":
          total = this.totalEpisodes
          current = this.watchedEpisodes
          action = types.INCREMENT_EPISODE
          break
        case "volume":
          total = this.totalVolumes
          current = this.readVolumes
          action = types.INCREMENT_VOLUME
          break
        case "chapter":
          total = this.totalChapters
          current = this.readChapters
          action = types.INCREMENT_CHAPTER
          break
        default:
          throw new Error(`${target} is unknown target to increment`)
      }

      // totalより多くなる場合はなにもしない
      if (total > 0 && total <= current) {
        return
      }

      this.loading = true
      this.$store.commit(types.CLEAR_ERROR, this.id)
      const self = this
      this.$store.dispatch(action, {
        id: this.id,
        done() {
          self.loading = false
        }
      })
    },
  },
  components: {
    Loader, WorkInfo, StatusController,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";

.list-unit {
  border-bottom: #BEBEBE 1px solid;
  padding: 8px 0 0;
  position: relative;
  display: block;

  &:first-of-type {
    border-top: #BEBEBE 1px solid;
  }

  .error {
    border: 2px solid #ff6a86;
    @include vendor-prefixes(border-radius, 3px);
    padding: 12px;
    color: #ff6a86;
  }
}
</style>
