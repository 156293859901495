<template>
  <div class="item">
    <div v-if="!isRequesting"
        @click="click"
        class="btn-detail-view-all">
      <span class="text">View More</span>
      <span class="icon-next"><i class="fa-solid fa-chevron-right icon-chevron" aria-hidden="true"></i></span>
    </div>
    <div v-if="isRequesting"
        class="btn-detail-view-all">
      <i class="loading fa-solid fa-spinner fa-spin fa-2x"></i>
    </div>
  </div>

</template>

<style lang="scss" scoped>
  .item {
    display: table-cell;
    text-align: center;
    padding: 0 4px 24px;
    box-sizing: border-box;
    vertical-align: top;
  }
  .btn-detail-view-all {
    position: relative;
    background-color: #ebebeb;
    display: inline-block;
    height: 135px;
    width: 90px;
    opacity: 1.0;
    .text {
      color: #545454;
      display: inline-block;
      font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
      font-size: 1.0em;
      font-weight: 700;
      line-height: 1.4em;
      padding-top: 40px;
      text-align: center;
      width: 100%;
    }
    .icon-next {
      background-color: #545454;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      left: 50%;
      margin-left: -10px;
      position: absolute;
      top: 65px;
      width: 20px;
    }
    &:active {
      opacity: 0.7;
    }
    i.loading {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }
</style>

<script type="text/babel">
  export default {
    props: {
      isRequesting: Boolean,
    },
    methods: {
      click() {
        this.$emit('viewMore')
      }
    },
  }
</script>
