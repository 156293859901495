<template>
  <div>
    <preview-button
      v-if="firstItem && firstItem.isPreviewable && !firstItem.isPossessed && !firstItem.isFree"
      :preview-url="firstItem && firstItem.previewUrl"
      style="margin-right:16px;"
    ></preview-button>
    <read-button
      v-if="firstItem && (firstItem.isPossessed || firstItem.isFree)"
      @read="readOrLimit"
      style="margin-right:16px;"
    ></read-button>
    <purchase-button
      v-if="showBulkPurchaseButton = (purchasableItems.length > 0)"
      @purchase="purchase"
      :text="'Checkout - All Volumes'"
    ></purchase-button>
    <icon-payment-method
      v-if="!hasDefaultPatmentMethod"
      style="margin-top: 4px;display: block;text-align: right;"
    ></icon-payment-method>

<!-- modal -->
    <tax-calculation-modal></tax-calculation-modal>
    <purchase-bulk-selection-modal
      @proceed="proceed"
      @updateCheckedItemIds="updateCheckedItemIds"
      @updateIsPurchased="updateIsPurchased"
      @cancel="cancel"
      :is-shown="showSelectionModal"
      :items="items"
      :currency="currency"
      :title="title"
      :checked-item-ids="checkedItemIds"
      :is-purchased="isPurchased"
      :purchasable-items="purchasableItems"
      :total-price-without-tax="totalPriceWithoutTax"
    ></purchase-bulk-selection-modal>
    <purchase-bulk-confirmation-modal
      @confirm="confirm"
      @cancel="back"
      :is-shown="showConfirmationModal"
      :error="error"
      :is-requesting="isRequesting"
      :title="title"
      :items="checkedItems"
      :currency="currency"
      :total-price="totalPrice"
      :total-price-without-tax="totalPriceWithoutTax"
      :total-tax="totalTax"
      :is-delegated="isDelegated"
    ></purchase-bulk-confirmation-modal>
    <purchase-finish-modal
      @read="read"
      @close="close"
      :is-single="false"
      :is-shown="showFinishModal"
      :title="title"
    ></purchase-finish-modal>
    <access-limit-modal
      :is-shown="showAccessLimitModal"
      :item="saleItem"
      :show-loading-modal="showLoadingModal"
      @purchase="moveToPurchase"
      @cancel="cancelRead"
    ></access-limit-modal>
  </div>
</template>

<script type="text/babel">
  import * as types from "./store/mutation-types"
  import {mapState, mapMutations, mapGetters} from "vuex"
  import PurchaseBulkSelectionModal from "../../molecules/PurchaseBulkSelectionModal.vue"
  import PurchaseBulkConfirmationModal from "../../molecules/PurchaseBulkConfirmationModal.vue"
  import PurchaseFinishModal from "../../molecules/PurchaseFinishModal.vue"
  import PurchaseButton from "../../atoms/PurchaseButton.vue"
  import PreviewButton from "../../atoms/PreviewButton.vue"
  import ReadButton from "../../atoms/ReadButton.vue"
  import IconPaymentMethod from "../../atoms/IconPaymentMethod.vue"
  import AccessLimitModal from "../../molecules/AccessLimitModal.vue"

  import {NAMESPACE as TAX_NAMESPACE} from "../../../common/stores/tax_calculation_modal"
  import * as taxCalculationModalTypes from "../../../common/stores/tax_calculation_modal/mutation-types"
  import TaxCalculationModal from "../tax_calculation_modal/TaxCalculationModal.vue"

  const axios = window.axios

  export default {
    components: {
      PurchaseBulkSelectionModal,
      PurchaseBulkConfirmationModal,
      PurchaseFinishModal,
      PurchaseButton,
      PreviewButton,
      ReadButton,
      IconPaymentMethod,
      TaxCalculationModal,
      AccessLimitModal,
    },
    mounted() {
      // check cookie
      const storeReferer = this.$cookie.get("store_referer")
      if (!storeReferer) {
        return
      }
      const successfullyRegistered = this.$cookie.get("successfully_registered")
      const storeRefererUrl = document.createElement('a')
      storeRefererUrl.href = decodeURIComponent(storeReferer)
      if (successfullyRegistered && location.pathname === storeRefererUrl.pathname) {
        this.purchase()
      }
    },
    data() {
      return {
        showSelectionModal: false,
        showConfirmationModal: false,
        showFinishModal: false,
        showBulkPurchaseButton: false,
        isRequesting: false,
        error: null,
        windowScrollY: Number,
        paymentMethod: "Stripe",
        showAccessLimitModal: false,
        saleItem: null,
        showLoadingModal: null,
      }
    },
    computed: {
      ...mapState(["firstItem", "items", "itemType", "checkedItemIds", "loginPath", "currency", "bookshelfComicPath", "isPurchased", "payment"]),
      ...mapGetters(["purchasableItems", "checkedItems", "totalPrice", "totalPriceWithoutTax", "totalTax"]),
      ...mapState(`${TAX_NAMESPACE}`, {
        isDelegated: state => state.isDelegated
      }),
      title() {
        return this.firstItem ? this.firstItem.title : null
      },
      hasDefaultPatmentMethod() {
        return this.payment.defaultPaymentMethod ? true : false
      },
    },
    methods: {
      updateIsPurchased(status) {
        this.$store.commit(types.IS_PURCHASED, status)
      },
      updateCheckedItemIds(checkedItemIds) {
        this.$store.commit(types.UPDATE_CHECKED_IDS, checkedItemIds)
      },
      proceed() {
        this.showSelectionModal = false
        this.showConfirmationModal = true
      },
      back() {
        this.showSelectionModal = true
        this.showConfirmationModal = false
      },
      async confirm() {
        this.error = null
        this.isRequesting = true
        try {
          await this.$store.dispatch(types.PURCHASE)
          this.showConfirmationModal= false
          this.showFinishModal = true
          this.$store.commit(types.UPDATE_ITEMS, this.checkedItemIds)
          this.updateCheckedItemIds(this.purchasableItems.map(i => i.id))
          this.showBulkPurchaseButton = (this.purchasableItems.length > 0)
        } catch (e) {
          this.isRequesting = false
          this.error = e.message
        }
      },
      read() {
        this.showFinishModal = false
        this.toggleFixedWindow()
        window.location.href = this.bookshelfComicPath
      },
      close() {
        this.showSelectAddressModal = false
        this.showIncompletePaymentInfoModal = false
        this.showFinishModal = false
        this.toggleFixedWindow()
      },
      cancel() {
        this.showSelectAddressModal = false
        this.showIncompletePaymentInfoModal = false
        this.showSelectionModal = false
        this.toggleFixedWindow()
      },
      setCookie() {
        const successfullyRegistered = this.$cookie.get("successfully_registered")
        if (successfullyRegistered) {
          this.$cookie.delete("store_referer")
          this.$cookie.delete("successfully_registered")
        } else {
          this.$cookie.set("store_referer", location.href, 1)
        }
      },
      async purchase() {
        if (this.loginPath) {
          location.href = this.loginPath
        } else {
          this.isRequesting = true

          // CookieにURLを保存
          this.setCookie()

          // Paymentモーダルを使う or Paymentページにさせるか
          this.$store.commit(`${TAX_NAMESPACE}/${taxCalculationModalTypes.USE_PAYMENT_MODAL}`, false)

          const taxInfo = await this.$store.dispatch(`${TAX_NAMESPACE}/${taxCalculationModalTypes.GET_TAX}`, {
            itemType: this.itemType,
            itemIds: this.purchasableItems.map(i => i.id),
            itemTotalAmount: this.purchasableItems.reduce((sum, item) => sum + (item.salePrice || item.price), 0),
            selectedCountry: null
          })
          this.$store.commit(types.SET_TAX, taxInfo)

          this.isRequesting = false
          this.showSelectionModal = true
          this.toggleFixedWindow()
        }
      },
      toggleFixedWindow() {
        if (this.windowScrollY > 0) {
          window.STATUS.fixed(true)
          window.scrollTo(0, this.windowScrollY)
          this.windowScrollY = 0
        } else {
          this.windowScrollY = window.pageYOffset
          window.STATUS.fixed()
        }
      },

      moveToPurchase() {
        location.href = `/store/manga_volume/${this.saleItem.saleBookId}/${this.saleItem.saleBookTitle}`
      },
      cancelRead() {
        this.showAccessLimitModal = false
      },
      async readOrLimit(){
        if (this.firstItem.isPossessed || !this.firstItem.accessLimit) {
          window.open(this.firstItem.viewerUrl)
        } else {
          this.error = null
          this.showLoadingModal = true
          let newWindow = window.open(window.location.href)
          try {
            const res = await axios.get(`/store/manga_volume/${this.firstItem.id}/access/count`)
            if (res.data.login_path) {
              this.showLoadingModal = false
              window.location.href = res.data.login_path
              newWindow.close()
            } else if (!(res.data.user_access_count > 0) && res.data.total_access_count > this.firstItem.accessLimit) {
              this.saleItem = this.firstItem
              this.showLoadingModal = false
              this.showAccessLimitModal = true
              newWindow.close()
            } else {
              this.showLoadingModal = false
              newWindow.location.href = this.firstItem.viewerUrl
            }
          } catch (e) {
            this.showLoadingModal = false
            // 握りつぶす
          }
        }
      },
    },
  }
</script>
