var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-outer" }, [
    _vm.isOwner
      ? _c(
          "select",
          {
            staticClass: "select",
            domProps: { value: _vm.score },
            on: {
              change: function($event) {
                return _vm.updateScore($event.target.value)
              }
            }
          },
          [
            _c("option", { attrs: { value: "0" } }, [_vm._v("Select")]),
            _vm._v(" "),
            _vm._l(_vm.scoreList, function(score) {
              return _c("option", { domProps: { value: score.val } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(score.val) +
                    " - " +
                    _vm._s(score.str) +
                    "\n    "
                )
              ])
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isOwner
      ? _c("div", [_vm._v("\n    " + _vm._s(_vm.currentStatus) + "\n  ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }