// JavaScript Document
import $ from "jquery";
import _ from "lodash";

$(function () {

  //seasonal sort
  $(".js-seasonal-filter-sort").on("change", function (){
    const key = $(this).val();
    //Submit
    const data = {
      id   : key,
      name : "sort_by",
      type : "sort",
      url  : "/anime/season/config/sort.json",
      dataType : "html",
    }
    $(".js-ajax-loader").toggleClass("on").on("touchstart", function(e) {
      return false;
    });
    submitConfig(data);
  });

  // crossed > none > crossed ...
  let toggleCheckboxR18kids = function(e) {
    if ($(e).hasClass("crossed")) {
      $(e).removeClass("crossed");
      $(e).children(".fa-xmark").hide();
    } else {
      $(e).addClass("crossed");
      $(e).children(".fa-xmark").show();
    }
  }

  // R18 - Kids
  const checkR18kids = function () {
    let hideIds = [];

    //R18
    const $r18 = $(".js-seasonal-filter-button .js-r18");
    const is_crossed_r18 = $r18.hasClass("crossed");

    if (is_crossed_r18) {
      hideIds = hideIds.concat($r18.data("id").split(",").map(function (el){return Number(el)}));
      $r18.children(".fa-xmark").show();
    } else {
      $r18.children(".fa-xmark").hide();
    }

    //kids
    const $kids = $(".js-seasonal-filter-button .js-kids");
    const is_crossed_kids = $kids.hasClass("crossed");

    if (is_crossed_kids) {
      hideIds.push(parseInt($kids.data("id")));
      $kids.children(".fa-xmark").show();
    } else {
      $kids.children(".fa-xmark").hide();
    }

    // status -> 0:none, 1:selected, 2:crossed
    const hide_list = [
      {
        name    : "js-r18",
        status  : (is_crossed_r18) ? 0 : 1
      },
      {
        name    : "js-kids",
        status  : (is_crossed_kids) ? 0 : 1
      }
    ];

    // 上のhide_listのソート
    // statusが 1 のものをdisplay:blockしたあとに、
    // statusが 0 のものをdisplay:noneにする
    const sorted_hide_list = _.sortBy(hide_list, function(list) {
      return -Number(list.status);
    });

    const $seasonalAnime = $(".js-seasonal-unit");
    $seasonalAnime.show();
    $seasonalAnime.each(function() {
      const $item = $(this);
      _.each(sorted_hide_list, function (k){
        if (k.status === 0 && $item.hasClass(k.name)) {
          $item.hide();
        }
      });
    });

    //submit R18&kids
    const data = {
      id   : {hide_ids: hideIds},
      name : "genre_filter",
      type : "r18kids",
      url  : "/anime/season/config/filter.json",
    }
    submitConfig(data);
  }

  // btn R18
  $(".js-seasonal-filter-button .js-r18").on("click", function() {
    toggleCheckboxR18kids(this);
    checkR18kids();
  });
  // btn Kids
  $(".js-seasonal-filter-button .js-kids").on("click", function() {
    toggleCheckboxR18kids(this);
    checkR18kids();
  });

  //ajax action for filter
  const submitConfig = function(obj) {
    const type     = obj.type;
    const id       = obj.id;
    const url      = obj.url;
    const name     = obj.name;
    const dataType = obj.dataType;

    let data = {};
    data[name] = id;
    data = JSON.stringify(data);

    //error message 削除
    if ($(`.js-seasonal-sort-order-block.js-${type} .js-error-message`)[0]) {
      $(`.js-seasonal-sort-order-block.js-${type} .js-error-message`).html("").hide();
    }

    $.ajax({
      url     : url,
      type    : "PUT",
      dataType: dataType,
      data    : data,
      timeout : 10000,
      cache   : false
    })
    .done( function(data){
      //success
      if (type === "sort") {
        window.location.reload();
      }
    })
    .fail( function(jqXHR) {
      const errorMessages = $.parseJSON(jqXHR.responseText).errors;
      const message = errorMessages[0].message ;
      const messageHtml = `<span class=\"di-ib pb8\">Error: ${message}</span>`;
    });

  }

  // on load page
  if ($(".content-main.seasonal")[0]) {
    checkR18kids();
  }
});
