import $ from "jquery"
import AdInfiniteScroll from "../../../common/ad-infinite-scroll"
const sVue = window.sVue;

$(() => {
  if ($("body.anime-ranking").length <= 0 && $("body.manga-ranking").length <= 0) {
    return;
  }

  const $ranking = $("#ranking-list")
  const $spinner = $("#loading-spinner").hide()
  const type     = $ranking.data("type")
  const category = $ranking.data("category")

  let offset = $ranking.data("offset")
  let isLoading = false, isLoadFinished = false

  const VueRanking = new sVue({
    el: "#ranking-list",
    data: {
      pages: []
    },
    methods: {
      appendItems(items) {
        this.pages.push({
          items: items
        })
      },
      getDigitClass(rank) {
        return `d${rank.toString().length}`
      },
      getDataParam(id, category, status) {
        return JSON.stringify({
          id            : id,
          category      : category,
          series_status : status,
        })
      },
      showAd(code, sizes, name, keyValues) {
        sVue.nextTick(() => {
          AdInfiniteScroll.request(code, sizes, name, keyValues)
        })
      }
    },
  })

  $(window).on("scroll", () => {
    // ページの下の方まで行ったら追加ロード
    if ($(window).scrollTop() > $(document).height() - window.innerHeight * 1.5) {
      // ロード中、または全件表示ずみなら何もしない
      if (!isLoading && !isLoadFinished) {
        // ローディングのアイコンを表示
        $spinner.show()
        isLoading = true

        $.get(`/${category}/ranking.json`, {
            offset: offset,
            type  : type
          })
          .done((data) => {
            if (data.length > 0) {
              // Vueの要素追加
              VueRanking.appendItems(data)

              // offset更新
              offset += data.length
            }
          })
          .fail((xhr) => {
            if (xhr.status === 404) {
              // 最終ページまで行ったのでロード終了フラグを立てる
              isLoadFinished = true
            }
          })
          .always(() => {
            isLoading = false
            $spinner.hide()
          })
      }
    }
  })
})
