視聴・既読数入力用のtext box
<template>
  <div :class="className">
    <label :class="{disabled: !enabled}"><slot></slot></label>
    <div class="input-num" :total="totalStr">
      <input type="number" size="5" debounce="500" number :value="current" @change="change($event.target.value)" :disabled="!enabled">
    </div>
    <increment-button :is-active="canIncrement" className="increment" @increment="increment"></increment-button>
  </div>
</template>

<script>
import IncrementButton from "../atoms/IncrementButton.vue"

export default {
  props: {
    total: Number, // 総数
    current: Number, // 現在の進捗
    className: String, // style適用するためのclass名
    enabled: Boolean, // 機能を有効にするかどうか
  },
  computed: {
    totalStr() {
      return this.total ? this.total : "?"
    },
    canIncrement() {
      if (!this.enabled) {
        return false
      }
      if (this.total > 0) {
        return this.total > this.current
      } else {
        return true
      }
    }
  },
  methods: {
    increment() {
      this.change(this.current + 1)
    },
    change(val) {
      this.$emit("change", parseInt(val))
    },
  },
  components: {
    IncrementButton,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";

label {
  float: left;
  display: inline-block;
  width: 80px;
  margin-top: 12px;
  font-size: 1.4rem;
  color: #323232;
  font-weight: 700;

  &.disabled {
    color: #9b9b9b;
  }
}

.input-num {
  position: relative;
  width: calc(100% - 12px - 130px);
  height: 30px;
  border: 1px solid #979797;
  @include vendor-prefixes(border-radius, 4px);
  overflow: hidden;
  float: left;
  display: inline-block;

  input {
    @include vendor-prefixes(appearance, none);
    border: none;
    width: 100%;
    color: #4a4a4a;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 6px 8px;
    &[disabled] {
      color: #9b9b9b;
    }
  }

  &:after {
    position: absolute;
    bottom: -1px;
    right: 8px;
    display: block;
    width: 40px;
    height: 20px;
    color: #989898;
    font-size: 1.4rem;
    text-align: right;
    content: "/" attr(total) "";
  }

  .increment {
    margin-left: 8px;
  }
}
</style>
