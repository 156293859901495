const _ = window._
import {CLEAR_ERROR} from "./mutation-types"
import axios from "axios"

export default {
  saveItem({state, commit}, {workType, mode}) {
    commit(CLEAR_ERROR)

    const data = {
      status: state.userStatusId,
      score: state.score,
    }

    if (workType === "anime") {
      _.assign(data, {
        anime_id: state.id,
        num_watched_episodes: state.watchedEpisodes,
      })
    } else {
      _.assign(data, {
        manga_id: state.id,
        num_read_volumes: state.readVolumes,
        num_read_chapters: state.readChapters,
      })
    }

    return axios.post(`/ownlist/${workType}/${mode}.json`,
      JSON.stringify(data)
    )
  },
}

