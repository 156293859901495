import Cookies from "js-cookie"
import * as types from "./mutation-types"
import * as cookieNames from "./cookie-names"
import {purchase} from "../../../../../components/manga_store/common/modules/purchase"
import {NAMESPACE as TAX_NAMESPACE} from "../../../../../components/manga_store/common/stores/tax_calculation_modal"

const axios = window.axios

export default {
  /**
   * 初回のみチュートリアルを表示する
   * @param state
   * @param commit
   */
  [types.SHOW_TUTORIAL]({state, commit}) {
    const showedTutorial = Cookies.get(cookieNames.SHOWED_TUTORIAL)
    commit(types.SHOW_TUTORIAL, {isNotShown: showedTutorial === undefined})
    axios.post("/cookie/set", {key: cookieNames.SHOWED_TUTORIAL, value: 1, expires: 3650})
  },

  /**
   * 画像をロードする
   * 事前に現在のページの数ページ前後の画像も先読みする
   *
   * @param state
   * @param commit
   */
  [types.LOAD_IMAGES]({state, commit}) {
    const _load = i => {
      if (i >= 1 && i <= state.numTotalPages) {
        if (!state.images[i].isRequested) {
          commit(types.REQUESTED_IMAGE, {page: i})

          const urlWithParams = `${state.images[i].url}?${state.manuscript.queryParamsPart}`
          const requestedTime = Date.now()
          axios
            .get(urlWithParams, {
              responseType: "arraybuffer",
              withCredentials: false,
            })
            .then(response => {
              return commit(types.SETUP_IMAGE, {
                page: i,
                data: response.data,
                contentType: response.headers["content-type"],
                responseTime: Date.now() - requestedTime,
              })
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    }

    // 最初に現在のページをロードして、その後は前後に1ページずつ範囲を広げながらロードする
    _load(state.currentPage)
    for (let d = 1; d <= 6; d++) {
      _load(state.currentPage + d)
      _load(state.currentPage - d)
    }
  },

  /**
   * 読んだところまでブックマークする
   * @param state
   */
  [types.BOOKMARK]({state}) {
    axios.put(state.bookmarkUrl, {page: state.currentPage})
  },

  /**
   * 署名付き URL のパラメータを再発行する
   * @param state
   */
  async [types.RENEW_TOKEN]({state, commit}) {
    // 試し読みではトークンの再発行は行わない
    if (!state.isPreview) {
      const response = await axios.put(state.renewTokenUrl)
      commit(types.RENEW_TOKEN, {json: response.data})
    }
  },

  /**
   * Viewer を開いている間の行動情報をサーバに送信する
   * @param state
   */
  [types.LOG]({state, commit}, {type, payload}) {
    axios.put(state.analytics.logUrl, _.assign(payload, {
      _t: type,
      _sid: state.analytics.sessionId,
      _i: state.analytics.i,
      _du: parseInt((Date.now() - state.analytics.openedTime) / 1000),
      _p: state.currentPage,
      _tp: state.numTotalPages,
    }))
    commit(types.LOG)
  },

  /**
   * 購入
   */
  async [types.PURCHASE]({state, commit}) {
    await purchase({
      itemType: state.nextBook.itemType,
      itemIds: [state.nextBook.id],
      itemTotalAmount: state.nextBook.totalAmount,
      selectedCountry: state[TAX_NAMESPACE].selectedCountry
    })

    commit(types.PURCHASED)
  },
}
