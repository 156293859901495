export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const UPDATE_SCORE = "UPDATE_SCORE"
export const UPDATE_EPISODE = "UPDATE_EPISODE"
export const UPDATE_VOLUME = "UPDATE_VOLUME"
export const UPDATE_CHAPTER = "UPDATE_CHAPTER"
export const INIT_DATA = "INIT_DATA"
export const API_ERROR = "API_ERROR"
export const UNKNOWN_ERROR = "UNKNOWN_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const LOADING = "LOADING"
