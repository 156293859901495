var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "slider-wrap" }, [
        _c(
          "div",
          {
            staticClass: "slider-container",
            class: { noMargin: _vm.options.no_margin }
          },
          [
            _c(
              "div",
              { staticClass: "slider" },
              [
                _vm._l(_vm.items, function(item) {
                  return _vm.isTypeComic
                    ? _c("comic", {
                        attrs: {
                          item: item,
                          width: _vm.itemWidth,
                          height: _vm.itemHeight,
                          "no-margin": _vm.options.no_margin,
                          location: _vm.options.location,
                          isRecommendation: _vm.isRecommendation
                        }
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.items, function(item) {
                  return _vm.isTypeVolume
                    ? _c("book", {
                        attrs: {
                          item: item,
                          currency: _vm.currency,
                          width: _vm.itemWidth,
                          "hide-title": _vm.isHideTitle,
                          location: _vm.options.location,
                          isRecommendation: _vm.isRecommendation
                        },
                        on: {
                          accessLimit: function($event) {
                            return _vm.accessLimit(item)
                          }
                        }
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.items, function(item) {
                  return _vm.isTypeChapter
                    ? _c("chapter", {
                        attrs: {
                          item: item,
                          currency: _vm.currency,
                          width: _vm.itemWidth,
                          "hide-title": _vm.isHideTitle,
                          "login-path": _vm.loginPath,
                          location: _vm.options.location,
                          isRecommendation: _vm.isRecommendation
                        },
                        on: {
                          accessLimit: function($event) {
                            return _vm.accessLimit(item)
                          },
                          purchase: function($event) {
                            return _vm.purchase(item)
                          }
                        }
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.hasViewMore
                  ? _c("view-more", {
                      attrs: { "is-requesting": _vm.isLoadingItems },
                      on: { viewMore: _vm.loadMoreItems }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasComicPath
                  ? _c("view-more", { on: { viewMore: _vm.jumpToComicPath } })
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("tax-calculation-modal"),
      _vm._v(" "),
      _vm.showModal
        ? [
            _c("purchase-single-modal", {
              attrs: {
                "is-shown": _vm.showConfirmationModal,
                "is-requesting": _vm.isPurchasing,
                error: _vm.error,
                item: _vm.modalItem,
                currency: _vm.currency,
                "is-delegated": _vm.isDelegated
              },
              on: { confirm: _vm.confirm, cancel: _vm.cancel }
            }),
            _vm._v(" "),
            _c("purchase-finish-modal", {
              attrs: {
                "is-shown": _vm.showFinishModal,
                "is-single": true,
                title: _vm.modalItem.title,
                "numbering-text": _vm.modalItem.numberingText
              },
              on: { read: _vm.read, close: _vm.close }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("access-limit-modal", {
        attrs: {
          "is-shown": _vm.showAccessLimitModal,
          item: _vm.saleItem,
          "show-loading-modal": _vm.showLoadingModal
        },
        on: { purchase: _vm.moveToPurchase, cancel: _vm.cancelRead }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }