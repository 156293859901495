import ReviewModule from "../../../common/modules/review"

const $=require("jquery");
import Modal from "../../../common/modules/modal"
const MAL = window.MAL

$(function() {
  const redirectReview = function(opt, href = "") {
    const params = new URLSearchParams(window.location.search)
    for (const [key] of params) {
      if (!["t", "sort", "filter_check", "filter_hide", "preliminary", "spoiler", "friends_only"].includes(key)) {
        params.delete(key)
      }
    }
    for (const key in opt) {
      params.set(key, opt[key])
    }
    const url = href || window.location.pathname
    window.location.href = `${url}?${params.toString()}`
  }

  if ($(".js-review-sort-menu")[0]) {
    $(".js-review-sort-menu").on("click", function() {
      $(".js-review-sort-list").toggle();
    });
    $(".js-btn-review-sort-list").on("click", function(e) {
      const id = $(this).attr("id");
      $(".js-btn-review-sort-list").find(".text").removeClass("selected");
      $(`.js-btn-review-sort-list#${id}`).find(".text").addClass("selected");
      e.stopPropagation();
      const url = $(this).data("url");
      location.href = url;
    });
  }
  if ($(".js-review-element").exists()) {
    // touch hover
    $(".js-review-element.js-touch-anime").on("touchstart", function() {
      $(this).addClass("on")
    }).on("touchend", function() {
      $(this).removeClass("on")
    })

    // Reaction button
    const GIVE_FEEDBACK = 'Give Feedback!'
    let requestTimer
    $(".js-btn-reaction").on("click", (e) => {
      clearTimeout(requestTimer)
      $(".js-btn-reaction").addClass("disabled")
      const t = $(e.currentTarget)
      const r = t.parents(".js-review-element")
      const sel = t.hasClass("selected")
      const num = parseInt(t.find(".num").html()) + (sel ? -1 : 1)
      if (sel) {
        t.removeClass("selected")
      } else {
        t.siblings(".selected").each(function(i, elem) {
          $(elem).removeClass("selected")
          const num2 = parseInt($(elem).find(".num").html()) - 1
          $(elem).find(".num").html(num2 < 0 ? 0 : num2)
        })
        t.addClass("selected")
        r.find(".js-btn-showall").hide()
        r.find(".js-btn-reaction").show()
        if (t.data("more") == true) {
          r.find(".js-more_reviews").slideDown()
        }
      }
      t.find(".num").html(num < 0 ? 0 : num)
      const data = {
        id: t.data("id"),
        val: sel ? 0 : t.data("val")
      }
      $.post("/includes/ajax.inc.php?t=72", data, (res) => {
        if (res.redirect) {
          window.location.href = res.redirect
        } else {
          let div = ''
          if (res.num > 0) {
            for (const id of res.icon) {
              div += '<img src="' + MAL.REACTIONS_IMAGE_PATH + MAL.REACTIONS_EMOTICON[id] + '">'
            }
            div += '<span class="num js-num">' + res.num + '</span>'
          } else {
            div = GIVE_FEEDBACK
          }
          r.find(".js-icon-reaction").html(div)
          $(".js-votes-modal").data("id", "")
          if (res.num > 0) {
            $(".js-icon-reaction .js-num", r).on("click", clickNum)
          } else {
            $(".js-give-feedback", r).on("click", clickGiveFeedback)
          }
        }
      })
      requestTimer = setTimeout(function() {
        $(".js-btn-reaction").removeClass("disabled")
      }, 300)
    })
    $(".js-review-element").each(function(e) {
      const res = $(this).data("reactions")
      let div = ''
      if (res.num > 0) {
        for (const id of res.icon) {
          div += '<img src="' + MAL.REACTIONS_IMAGE_PATH + MAL.REACTIONS_EMOTICON[id] + '">'
        }
        div += '<span class="num js-num">' + res.num + '</span>'
      } else {
        div = GIVE_FEEDBACK
      }
      $(this).find(".js-icon-reaction").html(div)
      if (res.count) {
        let id = 1
        for (const num of res.count) {
          $(this).find(".js-btn-reaction[data-val=" + id + "] .num").html(num)
          id++
        }
      }
      $(".js-btn-reaction", this).each(function() {
        if ($(this).hasClass("selected") && parseInt($(".num", this).html()) === 0) {
          $(".num", this).html("1")
        }
      })
    })

    // Votes list
    const clickNum = function(e) {
      const $self = $(e.currentTarget).parents(".js-icon-reaction")
      const pagename = $self.data("pagename")
      const reviewId = $self.data("id")
      ReviewModule.generateVotesModal('#content', 'sp', pagename)
      ReviewModule.openVotesModal(reviewId)
      window.dataLayer.push({'event':'ga-js-event','ga-js-event-type':'review-votes-modal-open-sp-' + pagename})
      return false
    }
    $(".js-icon-reaction .js-num").on("click", clickNum)
  }
  // tab button
  if ($(".js-navi-reviews")[0]) {
    $(".js-btn-type").on("click", function() {
      $(".js-btn-type").removeClass("on");
      $(this).addClass("on");
      reflectsReviews();
    });
    const reflectsReviews = function() {
      // tab
      const btnTab = $(".js-btn-type");
      btnTab.each(function() {
        const key = $(this).data("key");
        if (!$(this).hasClass("on")) {
          $(`.tab-${key}`).hide();
        } else {
          $(`.tab-${key}`).show();
        }
      });
    }
    const selectTab = '1';
    const btnTab = $(".js-btn-type");
    btnTab.each(function() {
      $(this).toggleClass("on", String($(this).data("key")) === selectTab);
    });
    reflectsReviews();
  }
  if ($(".js-form-reviews")[0]) {
    $(".js-scores .score").on("click", function() {
      $(".js-scores .score").removeClass("selected")
      $(this).addClass("selected")
      const id = $(this).data("id")
      $("#review_overall_score").val(id)
      $(".js-scores .js-score-message").html('<i class="fas fa-circle-check"></i><span class="js-score-id">' + id + '</span> - <span class="js-score-str">' + $(this).data("str") + '</span>')
    });
    $(".js-scores .score.selected").click();

    // radio box
    if ($(".js-radio-box")[0]) {
      $(".js-radio-box input[type=radio]").on("click", function() {
        changeRadioBox(this)
      });
      const changeRadioBox = function(e) {
        $(".js-radio-box").removeClass("on")
        $(e).parents(".js-radio-box").addClass("on")
      }
      changeRadioBox($(".js-radio-box input[type=radio]:checked"))
    }

    // anime episodes alert (TV 3 eps, other 1 eps)
    $("#myinfo_watchedeps").on("change", function() {
      const threshold = $("#myinfo_media_type").val() == 1 ? 3 : 1;
      const v = parseInt($(this).val())
      const f = v < threshold
      $("#review_message").toggle(f)
      $("#review_text").prop("disabled", f)
      $("#review_num_seen_episodes").val(v)
    }).trigger("change");

    // manga chapters alert
    $("#myinfo_chapters").on("change", function() {
      const v = parseInt($(this).val())
      const f = v < 1
      $("#review_message").toggle(f)
      $("#review_text").prop("disabled", f)
      $("#review_num_seen_episodes").val(v)
    }).trigger("change");

    $(".js-btn-save").on("click", function(e) {
      // overall score
      let episodeShow = true
      if ($("input[name=mode]").val() === "add") {
        if ($("#review_message").is(":visible")) {
          episodeShow = false
        }
      }

      // overall score
      const score = $("#review_overall_score").val();
      const scoreShow = !(score === "" || isNaN(Number(score)) || score <= 0 || score > 10);
      $(".js-error-overall").toggle(!scoreShow);

      // レビューの最低文字数を増やす
      const matches = $("#review_text").val().match(/(\s\w)/g);
      let warningShow = false;
      if ((matches == null) || ((matches.length + 1) < 100) || ($("#review_text").val().length < 500)) {
        $(".js-warning-box").slideDown();
      } else {
        $(".js-warning-box").hide();
        warningShow = true
      }

      // is_spoiler
      const spoilerShow = ($("input[name='review[is_spoiler]']:checked").val() !== undefined);
      $(".js-error-spoiler").toggle(!spoilerShow);

      // feelings
      const feelingsShow = ($("input[name='review[feelings]']:checked").val() !== undefined);
      $(".js-error-feeling").toggle(!feelingsShow);

      if (scoreShow && warningShow && spoilerShow && feelingsShow && episodeShow) {
        e.preventDefault();
        $(this).prop('disabled', true);
        $('form[name="review"]').submit();
      } else if (!episodeShow) {
        $(".js-btn-type")[0].click();
      } else if (!warningShow) {
        $(".js-btn-type")[1].click();
      } else {
        $(".js-btn-type")[2].click();
      }
    });

    $(".js-btn-preview").on("click", function(e) {
      const $targetItem = $(this).closest(".js-form-reviews");
      const workType = $("input[name='type']").val();
      const feeling = $("input[name='review[feelings]']:checked").val();
      let feelingTag = "";
      const spoiler = $("input[name='review[is_spoiler]']:checked").val();
      let spoilerTag = "";
      if (feeling !== undefined) {
        if (feeling === "1") {
          feelingTag = '<div class="tag recommended"><i class="fas fa-star"></i>Recommended</div>';
        } else if (feeling === "2") {
          feelingTag = '<div class="tag mixed-feelings"><i class="fas fa-star-half-stroke"></i>Mixed Feelings</div>';
        } else if (feeling === "3") {
          feelingTag = '<div class="tag not-recommended"><i class="far fa-star"></i>Not Recommended</div>';
        }
      }
      let preliminaryTag = "";
      if ($('div').hasClass("js-user-status-block")) {
        if ($("#myinfo_status").data("class") !== "completed") {
          if (workType === "anime") {
            const v = parseInt($("#myinfo_watchedeps").val());
            const total = $("#curEps").data("num") === 0 ? "?" : $("#curEps").data("num");
            preliminaryTag = '<div id="preliminary_tag" class="tag preliminary">Preliminary<span>(' + v + '/' + total + ' eps)</span>'
          } else {
            const v = parseInt($("#myinfo_chapters").val());
            const total = $("#totalChaps").data("num") === 0 ? "?" : $("#totalChaps").data("num");
            preliminaryTag = '<div id="preliminary_tag" class="tag preliminary">Preliminary<span>(' + v + '/' + total + ' chp)</span>'
          }
        }
      }
      if (spoiler === "1") {
        spoilerTag = '<div class="tag spoiler">Spoiler</div>';
      }
      Modal.generate();
      Modal.buildSpReviewPreviewDialog(
        $targetItem.attr("data-user-name"),
        $targetItem.attr("data-user-img"),
        nl2br($("#review_text").val()),
        feelingTag,
        preliminaryTag,
        spoilerTag,
      );
      Modal.show();
    })

    // delete button
    $(".js-btn-delete-review").on("click", function(e) {
      if (confirm("Are you sure you want to delete this review?")) {
        $("input[name=mode]").val("delete");
        $('form[name="review"]').submit();
      } else {
        return false;
      }
    })
  }

  // reviews sort
  if ($(".js-reviews-sort")[0]) {
    const $reviews_sort = $(".js-reviews-sort")
    $reviews_sort.on("change", function() {
      const type = $(this).val()
      window.dataLayer.push({'event':'ga-js-event','ga-js-event-type':'review-sp-sort-' + type})
      redirectReview({sort: type})
    })
  }
  // reviews filter-check
  $(".js-reviews-filter-check").on("change", function() {
    const type = $(this).val()
    window.dataLayer.push({"event": "ga-js-event", "ga-js-event-type": `review-sp-filter-check-${type}`})
    // eslint-disable-next-line camelcase
    redirectReview({filter_check: type})
  })

  // sort & filter
  if ($(".js-reviews-horiznav-nav-sort-block").exists()) {
    // Preliminary のチェックボックス
    $(".js-reviews-chk-preliminary").on("click", (e) => {
      const checked = $(e.currentTarget).hasClass("on") ? 1 : 0
      redirectReview({preliminary: (checked ? "off" : "on")})
    })

    // Spoiler のチェックボックス
    $(".js-reviews-chk-spoiler").on("click", (e) => {
      const checked = $(e.currentTarget).hasClass("on") ? 1 : 0
      redirectReview({spoiler: (checked ? "off" : "on")})
    })
  }
});

function nl2br(str) {
  str = str.replace(/\r\n/g, "<br />");
  str = str.replace(/(\n|\r)/g, "<br />");
  return str;
}
