<template>
  <div class="item" :style="{ width : width ? ( width + 'px') : 'auto' }" :class="{noMargin: noMargin}">
    <a :href="urlWithLocation(item.url, location)">
      <div class="cover-wrap" :style="{ height : height ? ( height + 'px') : 'auto' }">
        <img v-lazy="coverImage" :style="{ height : height ? ( height + 'px') : 'auto' }">
      </div>
      <recommendation-gauge
        v-if="isRecommendation === true"
        :score="item.score"
      ></recommendation-gauge>
      <div class="title-wrap" :style="{ width : width ? ( (width - 8) + 'px') : 'auto' }">
        <div class="title">{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>
<style lang="scss" scoped>
  $title-color : #333;
  $price-color : #d93f32;
  $price-sale-color : #FF2851;

  .item {
    display: table-cell;
    text-align: center;
    padding: 0 4px;
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
    box-sizing: border-box;
    &.noMargin {
      padding: 0 0;
    }
  }
  .cover-wrap {
    position: relative;
    display: inline-block;
    height: 135px;
    text-decoration: none;
    cursor: pointer;
  }
  img {
    width: auto;
    min-width: 80px;
    height: 135px;
    border: 1px solid #E1E7F5;
    box-sizing: border-box;
    vertical-align: bottom;
  }
  img[lazy=loading] {
    background-image: url('/images/xmlhttp-loader.gif');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .title-wrap {
    margin: 4px 0 8px;
  }
  .title {
    color: $title-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    text-decoration: none;
    word-wrap:break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-family: Avenir,"lucida grande",tahoma,verdana,arial,sans-serif;
  }
</style>

<script type="text/babel">
  import {generateCoverImageUrl} from "../../common/modules/book"
  import RecommendationGauge from "../atoms/RecommendationGauge.vue"

  export default {
    components: {
      RecommendationGauge,
    },
    props: {
      /**
       * @param {String} item.title
       * @param {String} item.url
       * @param {String} item.coverImageBaseUrl
       */
      item : Object,
      width: Number,
      height: Number,
      noMargin: Boolean,
      location: String,
      isRecommendation: Boolean,
    },
    computed: {
      coverImage() {
        const imgObj = {
          src: generateCoverImageUrl(this.item.coverImageBaseUrl)
        }
        return imgObj
      }
    },
  }
</script>
