var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShown,
                expression: "isShown"
              }
            ],
            staticClass: "modal-content"
          },
          [
            _c("div", { staticClass: "modal-mask", on: { click: _vm.cancel } }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "modal-manga-info" }, [
                  _c("div", { staticClass: "modal-cover" }, [
                    _c("img", {
                      attrs: { src: _vm.coverImageUrl, width: "90" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-title-wrap" }, [
                    _c("p", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.item.title))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "modal-volume" }, [
                      _vm._v(_vm._s(_vm.item.numberingText))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-price-wrap" }, [
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "modal-price-header" }, [
                        _vm._v("Price")
                      ]),
                      _vm._v(" "),
                      _vm.item.salePrice
                        ? [
                            _c("div", { staticClass: "modal-price" }, [
                              _c("span", { staticClass: "is-sale" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currency.formatSymbolAndPrice(
                                      _vm.item.price
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "modal-price-sale" }, [
                                _vm._v(
                                  "Sale " +
                                    _vm._s(
                                      _vm.currency.formatSymbolAndPrice(
                                        _vm.item.salePrice
                                      )
                                    )
                                )
                              ])
                            ])
                          ]
                        : [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.currency.formatSymbolAndPrice(
                                    _vm.item.price
                                  )
                                )
                              )
                            ])
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-price-tax" }, [
                    _c("div", { staticClass: "modal-price-header" }, [
                      _vm._v("Tax")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.currency.formatSymbolAndPrice(_vm.item.tax))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-price-total" }, [
                    _c("div", { staticClass: "modal-price-header" }, [
                      _vm._v("Total")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.currency.symbol)),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.currency.formatPrice(_vm.item.totalAmount))
                        )
                      ]),
                      _vm._v(_vm._s(_vm.currency.name))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-button-wrap" },
                  [
                    !_vm.isRequesting
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "modal-cancel-button",
                              on: { click: _vm.cancel }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isRequesting,
                                  expression: "!isRequesting"
                                }
                              ],
                              staticClass: "modal-confirm-button",
                              on: { click: _vm.confirm }
                            },
                            [_vm._v("Confirm Purchase")]
                          )
                        ]
                      : [_c("span", { staticClass: "modal-button-loading" })]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error"
                      }
                    ],
                    staticClass: "modal-error"
                  },
                  [_vm._v(_vm._s(_vm.error))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "modal-close", on: { click: _vm.cancel } },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }