list編集アイコンを表示する
<template>
  <span @click="editListItem">
    <i class="fa-solid fa-pencil-square-o fa-2x"></i>
  </span>
</template>

<script>
export default {
  methods: {
    editListItem() {
      this.$emit("edit-list-item")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../css/variables";
span {
  color: $mal-blue;
}
</style>
