<template>
  <div class="canvas-root">
    <canvas ref="canvas"
      :width="displayWidth * pixelDensity"
      :height="displayHeight * pixelDensity"
      :style="{width: displayWidth + 'px', height: displayHeight + 'px'}"
    ></canvas>
    <div :style="loadingImageLeftStyle" class="loading-image"></div>
    <div :style="loadingImageRightStyle" class="loading-image"></div>
    <div class="hover-area"
         @click="canvasClick"
         @touchstart="startDrag"
         @touchend="stopDrag"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
  .canvas-root {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: #000;
  }
  .hover-area {
    display: block;
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
  }
  canvas {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
  }
  .loading-image {
    position: absolute;
    background: url(/images/manga_store/viewer/loader.gif) no-repeat center center;
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import { mapGetters } from "vuex"
  import * as consts from "../../common/constants"

  export default {
    mounted() {
      // canvas の context を保存しておく
      this.context = this.$refs.canvas.getContext("2d")
      window.addEventListener("resize", this.resized)

      const gesturestart  = (this.isIos) ? "gesturestart"  : "touchstart"
      const gestureend    = (this.isIos) ? "gestureend"    : "touchend"
      const gesturechange = (this.isIos) ? "gesturechange" : "touchmove"
      const touchmove     = (this.isIos) ? "touchmove"     : "touchmove"

      window.addEventListener(gesturestart, this.gestureStart)
      window.addEventListener(gestureend, this.gestureEnd)
      window.addEventListener(gesturechange, this.gestureChange)
      window.addEventListener(touchmove, this.touchmove)

      this.displayWidth = ( this.isIos ? document.documentElement.clientWidth : window.outerWidth )
      this.displayHeight = ( this.isIos ? window.innerHeight : window.outerHeight )

      if(this.isIos){
        const displayHeightCookie = this.$cookie.get("display_height")
        if (!displayHeightCookie) {
          this.$cookie.set("display_height", this.displayHeight)
        }else if(this.displayHeight < displayHeightCookie){
          this.$cookie.set("display_height", this.displayHeight)
        }else if(this.displayHeight > displayHeightCookie){
          this.displayHeight = displayHeightCookie
        }
      }

      this.startSendingStats()
    },
    props: {
      manuscript: Object,
      images: Object,
      currentPage: Number,
      pageDirection: Number,
      isPortrait: Boolean,
      showMarginals: Boolean,
      newestLoadedImagePage: Number,
    },
    data() {
      return {
        context              : null,
        displayHeight        : 0,
        displayWidth         : 0,
        didFirstTap          : false,
        dragStart            : {x: 0, y: 0},
        gestureStartPos      : {x: 0, y: 0},
        pixelDensity         : ( window.devicePixelRatio ? window.devicePixelRatio : 1 ),
        isGesture            : false,
        zoomScale            : 1,
        baseImageScale       : {x: 0, y: 0, w: 0, h: 0},
        beforeImageScale     : {x: 0, y: 0, w: 0, h: 0},
        beforeDragDistance   : 0,
        translate            : {x: 0, y: 0},
        isTouchMove          : false,
        isZoomIn             : false,
        clickCount           : 0,
      }
    },
    computed: {
      ...mapGetters([
        'isIos',
      ]),

      isLoadingImageLeft() {
        return this.currentImages.left && !this.currentImages.left.isLoaded
      },
      isLoadingImageRight() {
        return this.currentImages.right && !this.currentImages.right.isLoaded
      },

      loadingImageLeftStyle() {
        if (this.isLoadingImageLeft) {
          const rect = this.imageRenderingRects.left
          return {
            left: `${rect.x / this.pixelDensity}px`,
            top: `${rect.y / this.pixelDensity}px`,
            width: `${rect.w / this.pixelDensity}px`,
            height: `${rect.h / this.pixelDensity}px`,
          }
        } else {
          return {display: "none"}
        }
      },

      loadingImageRightStyle() {
        if (this.isLoadingImageRight) {
          const rect = this.imageRenderingRects.right
          return {
            left: `${rect.x / this.pixelDensity}px`,
            top: `${rect.y / this.pixelDensity}px`,
            width: `${rect.w / this.pixelDensity}px`,
            height: `${rect.h / this.pixelDensity}px`,
          }
        } else {
          return {display: "none"}
        }
      },

      currentImages() {
        if (!this.isPortrait) {
          let leftPage = this.manuscript.isLeftFirst
            ? this.currentPage + 1 - this.currentPage % 2
            : this.currentPage + this.currentPage % 2

          if (this.pageDirection === 1) {//右から左の場合
            return {
              left: this.images[leftPage],
              right: this.images[leftPage - 1]
            }
          }else{//左から右の場合
            return {
              left: this.images[leftPage - 1],
              right: this.images[leftPage]
            }
          }
        } else {
          return {
            left: this.images[this.currentPage],
            right: undefined,
          }
        }
      },

      // 画像を描画する矩形領域を計算する
      imageRenderingRects() {
        if (!this.isPortrait) {
          let imageWidth, imageHeight
          if ((2 / this.manuscript.aspectRatio) > (this.displayWidth / this.displayHeight)) {
            imageWidth = this.displayWidth / 2
            imageHeight = (this.displayWidth / 2) * this.manuscript.aspectRatio
          } else {
            imageWidth = this.displayHeight / this.manuscript.aspectRatio
            imageHeight = this.displayHeight
          }

          const leftX  = (this.displayWidth / 2 - imageWidth) * this.pixelDensity
          const leftY  = (this.displayHeight - imageHeight) * this.pixelDensity / 2
          const rightX = (this.displayWidth / 2) * this.pixelDensity
          const rightY = (this.displayHeight - imageHeight) * this.pixelDensity / 2

          this.baseImageScale = {
            x: leftX || rightX,
            y: leftY || rightY,
            w: imageWidth * this.pixelDensity,
            h: imageHeight * this.pixelDensity,
          }
          this.beforeImageScale = {
            x: leftX || rightX,
            y: leftY || rightY,
            w: imageWidth * this.pixelDensity,
            h: imageHeight * this.pixelDensity,
          }
          return {
            left  : {
              x: leftX,
              y: leftY,
              w: imageWidth * this.pixelDensity,
              h: imageHeight * this.pixelDensity,
            },
            right : {
              x: rightX,
              y: rightY,
              w: imageWidth * this.pixelDensity,
              h: imageHeight * this.pixelDensity,
            },
          }
        } else {
          let imageWidth, imageHeight
          if (this.manuscript.aspectRatio > (this.displayHeight / this.displayWidth)) {
            imageWidth = this.displayHeight / this.manuscript.aspectRatio * this.pixelDensity
            imageHeight = this.displayHeight * this.pixelDensity
          } else {
            imageWidth = this.displayWidth * this.pixelDensity
            imageHeight = this.displayWidth * this.manuscript.aspectRatio * this.pixelDensity
          }

          const leftX = (this.displayWidth * this.pixelDensity - imageWidth) / 2
          const leftY = (this.displayHeight * this.pixelDensity - imageHeight) / 2

          this.baseImageScale = {
            x: leftX,
            y: leftY,
            w: imageWidth,
            h: imageHeight,
          }
          this.beforeImageScale = {
            x: leftX,
            y: leftY,
            w: imageWidth,
            h: imageHeight,
          }
          return {
            left : {
              x: leftX,
              y: leftY,
              w: imageWidth,
              h: imageHeight,
            },
            right : undefined,
          }
        }
      },

      // Zoomのときの画像を描画する矩形領域を計算する
      imageZoomedRenderingRects() {
        if (!this.isPortrait) {

          /* landscape */
          const imageWidth = this.baseImageScale.w * this.zoomScale
          const imageHeight = this.baseImageScale.h * this.zoomScale

          // 拡大画像のxyの座標
          const imageX = this.beforeImageScale.w - imageWidth + this.beforeImageScale.x
          const imageY = (this.beforeImageScale.h - imageHeight) / 2 + this.beforeImageScale.y

          // タップした座標の画面の中心からの距離
          const zoomStep = (this.zoomScale - this.getZoomRate(this.clickCount - 1)) //前のZoomとの差
          const imageDragDistance = {
            x: (this.displayWidth / 2 - this.dragStart.x) * this.pixelDensity * zoomStep,
            y: (this.displayHeight / 2 - this.dragStart.y) * this.pixelDensity * zoomStep,
          }

          // はみ出ないように
          // Landscapeは50pxずつマージンを与えることにより拡大時にブラウザのアドレスバーに隠れている部分も読めるように配慮している
          const landscapeViewMargin = 50 * this.pixelDensity
          const offsetLimit = {
            x: this.displayWidth * this.pixelDensity - imageWidth * 2 - landscapeViewMargin,
            y: this.displayHeight * this.pixelDensity - imageHeight * 2 - landscapeViewMargin,
          }

          // クリックした場所を中心に拡大
          let offsetX, offsetY
          offsetX = imageX + imageDragDistance.x
          offsetY = imageY + imageDragDistance.y

          let leftX, leftY, rightX, rightY
          if (this.isTouchMove) {
            // translate
            leftX  = this.beforeImageScale.x + this.translate.x
            leftY  = this.beforeImageScale.y + this.translate.y
            rightX = this.beforeImageScale.x + this.translate.x + imageWidth
            rightY = this.beforeImageScale.y + this.translate.y
          } else {
            // zoom
            const limitedOffsetX = (offsetLimit.x > offsetX) ? offsetLimit.x : (landscapeViewMargin < offsetX) ? landscapeViewMargin : offsetX
            const limitedOffsetY = (offsetLimit.y > offsetY) ? offsetLimit.y : (landscapeViewMargin < offsetY) ? landscapeViewMargin : offsetY

            leftX  = limitedOffsetX
            leftY  = limitedOffsetY
            rightX = limitedOffsetX + imageWidth
            rightY = limitedOffsetY
          }

          this.beforeImageScale = {
            x: leftX,
            y: leftY,
            w: imageWidth,
            h: imageHeight,
          }

          this.isZoomIn = this.zoomScale > 1

          return {
            left: {
              x: leftX,
              y: leftY,
              w: imageWidth,
              h: imageHeight,
            },
            right: {
              x: rightX,
              y: rightY,
              w: imageWidth,
              h: imageHeight,
            },
          }
        } else {

          /* Portrait */
          const imageWidth = this.baseImageScale.w * this.zoomScale
          const imageHeight = this.baseImageScale.h * this.zoomScale

          // 拡大画像のxyの座標
          const imageX = (this.beforeImageScale.w - imageWidth) / 2 + this.beforeImageScale.x
          const imageY = (this.beforeImageScale.h - imageHeight) / 2 + this.beforeImageScale.y

          // タップした座標の画面の中心からの距離
          const zoomStep = (this.zoomScale - this.getZoomRate(this.clickCount - 1)) //前のZoomとの差
          const imageDragDistance = {
            x: (this.displayWidth / 2 - this.dragStart.x) * this.pixelDensity * zoomStep,
            y: (this.displayHeight / 2 - this.dragStart.y) * this.pixelDensity * zoomStep,
          }

          // はみ出ないように
          const offsetLimit = {
            x: this.displayWidth * this.pixelDensity - imageWidth,
            y: this.displayHeight * this.pixelDensity - imageHeight,
          }

          // クリックした場所を中心に拡大
          let offsetX, offsetY
          offsetX = imageX + imageDragDistance.x
          offsetY = imageY + imageDragDistance.y


          let leftX, leftY
          if (this.isTouchMove) {
            leftX = this.beforeImageScale.x + this.translate.x
            leftY = this.beforeImageScale.y + this.translate.y
          } else {
            leftX = (offsetLimit.x > offsetX) ? offsetLimit.x : (0 < offsetX) ? 0 : offsetX
            leftY = (offsetLimit.y > offsetY) ? offsetLimit.y : (0 < offsetY) ? 0 : offsetY
          }

          this.beforeImageScale = {
            x: leftX,
            y: leftY,
            w: imageWidth,
            h: imageHeight,
          }

          this.isZoomIn = this.zoomScale > 1

          return {
            left  : {
              x: leftX,
              y: leftY,
              w: imageWidth,
              h: imageHeight,
            },
            right : undefined,
          }
        }
      },
    },
    watch: {
      currentPage() {
        this.initPaint()
      },

      // 新しく画像のロードが完了した時に更新される変数
      // ロード完了した画像が現在のページのものであれば、再描画を行う
      newestLoadedImagePage() {
        const {left, right} = this.currentImages
        if (
          (left && this.newestLoadedImagePage === left.page) ||
          (right && this.newestLoadedImagePage === right.page)
        ) {
          this.initPaint()
        }
      },
    },
    methods: {
      getZoomScale(touches) {
        // calicurating scale for Android
        const x1 = touches[0].pageX
        const y1 = touches[0].pageY
        const x2 = touches[1].pageX
        const y2 = touches[1].pageY
        const distance = Math.sqrt( Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) )
        if (this.beforeDragDistance === 0) {
          this.beforeDragDistance = distance
        }
        const scale = Math.round((distance / this.beforeDragDistance) * 10) / 10 || 1
        return scale
      },
      getZoomRate(clickCount) {
        const scale = {
          portrait  : [1, 2, 3, 4],
          landscape : [1, 2, 4, 6],
        }
        return this.isPortrait ? scale.portrait[clickCount] : scale.landscape[clickCount]
      },
      translateX(translateX) {
        // Xの可動範囲
        translateX = translateX * this.pixelDensity
        const rangeX = (this.displayWidth * this.pixelDensity - this.beforeImageScale.w * (this.isPortrait ? 1 : 2))
        const offset = this.isPortrait ? 0 : 50 * this.pixelDensity
        if (rangeX < 0) {
          return (this.beforeImageScale.x + translateX >= rangeX - offset && this.beforeImageScale.x + translateX <= 0 + offset) ? translateX : 0
        } else {
          return 0
        }
      },
      translateY(translateY) {
        // Yの可動範囲
        translateY = translateY * this.pixelDensity
        const rangeY = (this.displayHeight * this.pixelDensity - this.beforeImageScale.h)
        const offset = this.isPortrait ? 0 : 50 * this.pixelDensity
        if (rangeY < 0) {
          return (this.beforeImageScale.y + translateY >= rangeY - offset && this.beforeImageScale.y + translateY <= 0 + offset ) ? translateY : 0
        } else {
          return 0
        }
      },
      touchmove(e) {
        // 画像の横移動
        if ((this.isIos || e.touches.length === 1) && this.isZoomIn && !this.isGesture) {
          this.isTouchMove = true
          const touches = e.touches[0]
          this.translate = {
            x: this.translateX(touches.pageX - this.dragStart.x),
            y: this.translateY(touches.pageY - this.dragStart.y),
          }
          this.dragStart = {
            x: touches.pageX,
            y: touches.pageY,
          }
          this.repaint()
        }
      },
      gestureStart(e) {
        if (this.isIos) {
          this.gestureStartPos = {
            x: e.pageX,
            y: e.pageY,
          }
          this.isGesture = true
        } else if (e.touches.length > 1) {
          this.gestureStartPos = {
            x: (e.touches[0].pageX + e.touches[1].pageX) / 2,
            y: (e.touches[0].pageX + e.touches[1].pageY) / 2,
          }
          this.isGesture = true
        }
      },
      gestureChange(e) {
        if (this.isIos && this.isGesture) {
          if (e.scale < 0.8) {
            this.isZoomIn = false
            this.initPaint()
          }
        } else if (e.touches.length > 1 && this.isGesture) {
          // android
          if (this.getZoomScale(e.touches) < 0.8) {
            this.isZoomIn = false
            this.initPaint()
          }
        }
        if (this.isGesture) {
          this.$store.commit(types.TOGGLE_MARGINALS, {show: false})
        }
      },
      gestureEnd(e){
        this.isTouchMove = false
        // タッチが2本から1本に減ったとき対策
        if (!this.isIos && e.touches.length > 0) {
          return
        }
        const gestureTime = 250
        setTimeout( () => {
          this.beforeDragDistance = 0
          this.translate = {x: 0, y: 0}
          this.isGesture = false
        }, gestureTime )
      },
      startDrag(e) {
        if (this.isGesture) {
          return
        }
        this.isTouchMove = false //toucheMoveをキャンセル
        e = e.changedTouches ? e.changedTouches[0] : e
        this.translate = {x: 0, y: 0}
        this.dragStart.x = e.pageX
        this.dragStart.y = e.pageY
      },
      stopDrag(e) {
        if (this.isGesture || this.dragStart.x == 0 || this.isZoomIn) {
          return
        }
        e = e.changedTouches ? e.changedTouches[0] : e
        const dx = e.pageX - this.dragStart.x
        if (( this.isIos ? document.documentElement.clientWidth : window.outerWidth ) == window.innerWidth) {
          if (dx > 50) {
            const dir = 1 * this.pageDirection
            this.$store.commit(types.MOVE_PAGE, {dir})
          } else if (dx < -50) {
            const dir = -1 * this.pageDirection
            this.$store.commit(types.MOVE_PAGE, {dir})
          }
          return
        }
        if (dx === 0) {
          //シングルタップの場合
          if( !this.didFirstTap ) {
            // 1回目のタップ判定を真にする
            this.didFirstTap = true
            // 350ミリ秒だけ、1回目のタップ判定を残す
            const doubleTapTime = 350
            setTimeout( () => {
              if (this.didFirstTap ) {
                this.singleTapAction(e)
              }
              this.didFirstTap = false
            }, doubleTapTime )
          } else {
            // ダブルタップの場合
            // 1回目のタップ判定を解除
            this.didFirstTap = false
          }
        }
      },
      singleTapAction(e) {
        const touchAreaRatio = (this.isPortrait) ? 3 : 5
        const dir = ((window.innerWidth / touchAreaRatio > e.clientX) ? 1 : ((window.innerWidth - window.innerWidth / touchAreaRatio) > e.clientX) ? 0 : -1) * this.pageDirection
        if (dir !== 0) {
          this.$store.commit(types.MOVE_PAGE, {dir})
          this.$store.commit(types.TOGGLE_MARGINALS, {show: false})
        } else {
          this.$store.commit(types.TOGGLE_MARGINALS, {})
        }
      },
      doubleTapAction() {
        // 4th click : reset size
        if (this.clickCount < 3) {
          this.clickCount += 1
          this.zoomScale = this.getZoomRate(this.clickCount)
          this.$store.commit(types.TOGGLE_MARGINALS, {show: false})
          this.repaint()
        } else {
          this.clickCount = 0
          this.isZoomIn = false
          this.zoomScale = 1
          this.translate = {x: 0, y: 0}
          this.dragStart = {x: 0, y: 0}
          this.beforeImageScale = this.baseImageScale
          this.initPaint()
        }
      },
      canvasClick(e) {
        if (((this.isIos ? document.documentElement.clientWidth : window.outerWidth) === window.innerWidth) ) {
          //シングルタップの場合
          if( !this.didFirstTap ) {
            // 1回目のタップ判定を真にする
            this.didFirstTap = true
            // 350ミリ秒だけ、1回目のタップ判定を残す
            const doubleTapTime = 350
            setTimeout( () => {
              if (this.didFirstTap && !this.isZoomIn ) {
                // Zoomsしてるときはページ送りをしない
                this.singleTapAction(e)
              }
              this.didFirstTap = false
            }, doubleTapTime )
          } else {
            // ダブルタップの場合
            this.doubleTapAction()
            // 1回目のタップ判定を解除
            this.didFirstTap = false
          }
        }
      },
      drawImage(rect, imageInfo) {
        //Canvasに画像をロードさせる処理
        if ( imageInfo.imageObject.naturalHeight > rect.h * 2) {
          let oc = document.createElement('canvas'),
          octx = oc.getContext('2d');
          oc.width  = imageInfo.imageObject.naturalWidth * 0.5
          oc.height = imageInfo.imageObject.naturalHeight * 0.5
          octx.drawImage(imageInfo.imageObject, 0, 0, oc.width, oc.height)
          if ( oc.width > rect.h * 4 ) {
            this.context.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, rect.x, rect.y, rect.w, rect.h)
          } else {
            this.context.drawImage(oc, rect.x, rect.y, rect.w, rect.h)
          }
        } else {
          this.context.drawImage(imageInfo.imageObject, rect.x, rect.y, rect.w, rect.h)
        }
      },
      resized() {
        const orientationChangeTime = 500
        //iosで最初から横向き場合のonload直後だと高さがurlバーの高さを含めてしまう時がある
        //回転が完了しないうちにresizeやorientationchangeが走ってしまうのsetTimeout使う
        setTimeout(() => {
          //ピンチインの時完全にほぼscale=1に近ければ1にしたいができない・・・javascriptで動的にviewportのscaleを変えられないinitial-scaleではだめ
          // const diffScale =  ( this.isIos ? document.documentElement.clientWidth : window.outerWidth ) - window.innerWidth
          // if ( 0 < diffScale && diffScale < 20 ) {
          //   //document.querySelector("[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1,maximum-scale=1.0")
          //   // alert( "clientWidth:" +  document.documentElement.clientWidth + " innerWidth:" +  window.innerWidth)
          //   // document.documentElement.style.width =  window.innerWidth
          //   // alert( document.documentElement.clientWidth)
          // }
          window.scrollTo(0,-1)
          this.zoomScale = 1
          this.displayWidth = ( this.isIos ? document.documentElement.clientWidth : window.outerWidth )
          const zoomLevel = this.displayWidth / window.innerWidth
          this.displayHeight = ( this.isIos ? window.innerHeight * zoomLevel : window.outerHeight )
          this.$store.commit(types.CHANGE_ORIENTATION_STATE, {
            isPortrait: (this.displayHeight > this.displayWidth),
            browserInnerHeight: this.displayHeight
          })

          this.$nextTick(() => {
            this.initPaint()
          })
        }, orientationChangeTime)
      },
      resetRectSize() {
        this.zoomScale = 1
        this.beforeImageScale = this.baseImageScale
      },
      initPaint() {
        // 毎回リフレッシュ必要（拡大率変更や見開き切り替えがあるため）
        this.context.clearRect(0, 0, this.displayWidth * this.pixelDensity, this.displayHeight * this.pixelDensity)

        // init
        this.clickCount = 0
        this.isZoomIn = false
        this.zoomScale = 1
        this.translate = {x: 0, y: 0}
        this.dragStart = {x: 0, y: 0}
        this.beforeImageScale = this.baseImageScale

        // 単一ページ表示のときは right は undefined になるため、 left のみレンダリングされる
        const leftImageInfo  = this.currentImages.left
        const rightImageInfo = this.currentImages.right

        // 見開きで変にズレないように小数を切り上げる
        const mathCeil = (rect) => {
          rect.x = Math.ceil(rect.x)
          rect.y = Math.ceil(rect.y)
          rect.w = Math.ceil(rect.w)
          rect.h = Math.ceil(rect.h)
          return rect
        }

        // left image
        if (leftImageInfo && leftImageInfo.isLoaded) {
          const rect = mathCeil(this.imageRenderingRects.left)
          this.drawImage(rect, leftImageInfo)
        }
        // right image
        if (rightImageInfo && rightImageInfo.isLoaded) {
          const rect = mathCeil(this.imageRenderingRects.right)
          this.drawImage(rect, rightImageInfo)
        }
      },
      repaint() {
        // 毎回リフレッシュ必要（拡大率変更や見開き切り替えがあるため）
        this.context.clearRect(0, 0, this.displayWidth * this.pixelDensity, this.displayHeight * this.pixelDensity)

        // 単一ページ表示のときは right は undefined になるため、 left のみレンダリングされる
        const leftImageInfo  = this.currentImages.left
        const rightImageInfo = this.currentImages.right

        const mathCeil = (rect) => {
          rect.x = Math.ceil(rect.x)
          rect.y = Math.ceil(rect.y)
          rect.w = Math.ceil(rect.w)
          rect.h = Math.ceil(rect.h)
          return rect
        }

        // left image
        if (leftImageInfo && leftImageInfo.isLoaded) {
          const rect = mathCeil(this.imageZoomedRenderingRects.left)
          this.drawImage(rect, leftImageInfo)
        }

        // right image
        if (rightImageInfo && rightImageInfo.isLoaded) {
          const rect = mathCeil(this.imageZoomedRenderingRects.right)
          this.drawImage(rect, rightImageInfo)
        }
      },

      startSendingStats() {
        // 画像が完全に出ていたフレーム数と、ローディング中のフレーム数をカウントする
        // これで、どのくらいの間ユーザがローディング中のくるくるを見ているのかが分かるようになる
        let totalFrames = 0, loadingFrames = 0
        setInterval(() => {
          totalFrames ++
          if (this.isLoadingImageLeft || this.isLoadingImageRight) {
            loadingFrames ++
          }
        }, 100)

        // 5秒ごとに統計を送信する
        setInterval(() => {
          // ロードが完了している画像の平均レスポンスタイムを計算
          let loadedImages = 0, totalResponseTime = 0
          for (let p in this.images) {
            let image = this.images[p]
            if (image.isLoaded) {
              loadedImages ++
              totalResponseTime += image.responseTime
            }
          }

          // ログ送信
          this.$store.dispatch(types.LOG, {
            type: consts.LOG_TYPE_STATS,
            payload: {
              // 直近5秒で何％ローディングが出ていたか
              lr: Number((loadingFrames / totalFrames).toFixed(3)),

              // 画像の平均レスポンスタイム
              ar: parseInt(totalResponseTime / loadedImages),
            },
          })

          // フレーム数のカウントをリセット
          totalFrames = loadingFrames = 0
        }, 5000)
      },
    }
  }
</script>
