import Slider from "./Slider.vue"
import {createStore} from "../../../common/stores/all_books/factory"

const $ = window.$
const SVue = window.sVue

const selectorBook = ".v-manga-store-book-slider"
const selectorComic = ".v-manga-store-comic-slider"
const selectorChapter = ".v-manga-store-chapter-slider"

$(() => {
  const _initSlider = (el) => {
    const options = JSON.parse(el.getAttribute("data-options"))

    new SVue({
      el,
      store: createStore(el),
      render(h) {
        return h(Slider, {
          props: {
            options: options || {},
          },
        })
      },
    })
  }

  // volume
  Array.from(document.querySelectorAll(selectorBook)).forEach(el => {
    _initSlider(el)
  })

  // comics
  Array.from(document.querySelectorAll(selectorComic)).forEach(el => {
    _initSlider(el)
  })

  // chapter
  Array.from(document.querySelectorAll(selectorChapter)).forEach(el => {
    _initSlider(el)
  })
})
