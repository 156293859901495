// JavaScript Document
import $ from "jquery"

$(() => {

  $(window)
    .one('load', function () {
      setTimeout(function() {
        $('#apply-submit-modal').addClass('fade-in');
      },0);

      const banner = $('#top-banner-img-closed');
      if (banner) {
        let height = banner.height();
        let width = banner.width();
        const msg = $('#top-banner-closed-msg');
        msg.height(height);
        msg.width(width);
        msg.addClass('visible');
      }
    });
  $(document).one('click', '.apply-submit-modal-close', function () {
    $('#apply-submit-modal')
      .removeClass('fade-in').addClass('fade-out')
      .one('animationend', function () { $(this).remove(); });
    window.location.href = '/15th_anniversary';
  });

})
