import $ from "jquery"
import _ from "lodash"

$(function () {

  const aToast=function(){"use strict";function t(){this.speed=4e3,this.position="top"}return t.prototype.setOption=function(t,e){this.speed=t,this.position=e},t.prototype.show=function(t,e){let o=void 0===e||""===e?"":"a-toast-"+e,s="a-toast-pos-"+this.position;this.hide(),clearTimeout(this.timer);let i=document.createElement("div");i.className="a-toast "+o+" "+s,i.appendChild(document.createTextNode(t)),document.body.appendChild(i);let n=document.body.clientWidth/2-i.clientWidth/2;i.style.left=n+"px",i.classList.add("a-toast-show"),this.timer=setTimeout(function(){i.classList.remove("a-toast-show")},this.speed)},t.prototype.success=function(t){this.show(t,"success")},t.prototype.warn=function(t){this.show(t,"warn")},t.prototype.danger=function(t){this.show(t,"danger")},t.prototype.hide=function(){let t=document.getElementsByClassName("a-toast");for(let e=0;e<t.length;++e)t[e].parentNode.removeChild(t[e])},t}();

  if ($(".mhwc_votes_2023")[0]) {

    const render = function (id, params) {
      const template = _.template($(id).html())
      return template(params);
    };

    let voting = false;
    const finished = function () {
      $(".vote_button").not(".disabled2").on("click", function () {
        if (voting) return;

        const is_unvote = $(this).hasClass("voted")
        const url = '/polls/' + $(this).data("code") + "/" + $(this).data("question_id") + "/" + $(this).data("option_id") + ".json";

        if (is_unvote) return;

        voting = true;
        $.ajax({
          url: url + window.location.search,
          contentType: "application/json",
          type: is_unvote ? "DELETE" : "POST",
          dataType: 'json',
          data: JSON.stringify({
            number: 1
          }),
          success: data => {
            if (is_unvote) {
              $(this).removeClass("voted");
              $(this).text("Vote");
              let number = Number($(this).next().text());
              $(this).next().text(number - 1);
            } else {
              $(this).addClass("voted");
              $(this).text("Voted");
              let number = Number($(this).next().text());
              $(this).next().text(number + 1);
            }
          },
          error: error => {
            if(error.status == 400 && error.responseJSON.errors[0].message == "not login"){
              //alert("Please login and open the vote page.");
              var toast = new aToast();
              toast.setOption(4000,"top");
              toast.show("Please login and open the vote page.", "");
            }
            console.log(error)
          },
          complete: data => {
            voting = false;
          }
        });
      });
      $("div.round").show();
    };

    const fetch_poll = function (round) {
      $.ajax({
        url: "/polls/mhwc2023_" + round + ".json" + window.location.search,
        contentType: "application/json",
        type: "GET",
        success: data => {
          data.questions[0].options = _.shuffle(data.questions[0].options);
          let html = render("#round-template", {round: round, data: data});
          $("#round-" + round).html(html);
          finished();
        },
        error: error => {
          finished();
        },
        complete: data => {
        }
      });
    }
    fetch_poll(1);
  }
});
