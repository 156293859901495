var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item",
      style: { width: _vm.width ? _vm.width + "px" : "auto" }
    },
    [
      _c(
        "a",
        { attrs: { href: _vm.urlWithLocation(_vm.item.url, _vm.location) } },
        [
          _c(
            "div",
            {
              staticClass: "cover-wrap",
              class: { iconNew: !_vm.item.isPossessed && _vm.item.isNew }
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.coverImage,
                    expression: "coverImage"
                  }
                ]
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "title-wrap",
          style: { width: _vm.width ? _vm.width - 8 + "px" : "auto" }
        },
        [
          _vm.hideTitle !== true
            ? _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.item.title))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "volume" }, [
            _vm._v(_vm._s(_vm.item.numberingText))
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.item.isPossessed
        ? _c("div", { staticClass: "price-wrap" }, [
            _c(
              "span",
              {
                staticClass: "price",
                class: { "is-sale": _vm.item.salePrice >= 0 }
              },
              [
                _vm._v(
                  _vm._s(_vm.currency.formatSymbolAndPrice(_vm.item.price))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item.salePrice >= 0,
                    expression: "item.salePrice >= 0"
                  }
                ],
                staticClass: "price-sale"
              },
              [
                _vm._v(
                  _vm._s(_vm.currency.formatSymbolAndPrice(_vm.item.salePrice))
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isPossessed && _vm.item.isPreviewable && !_vm.item.isFree
        ? _c(
            "a",
            {
              staticClass: "button button-preview",
              attrs: {
                href: _vm.urlWithLocation(_vm.item.previewUrl, _vm.location),
                target: "_blank"
              }
            },
            [_vm._v("Preview")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.isPossessed || _vm.item.isFree
        ? _c(
            "a",
            {
              staticClass: "button button-read",
              attrs: { target: "_blank" },
              on: { click: _vm.click }
            },
            [_vm._v("Read")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }