<template>
  <div class="root">
    <div class="seekbar-wrap" :class="{opposite: switchDirection}">
      <div class="seekbar-bg-wrap">
        <div ref="seekbar" class="seekbar-bg" :style="backgroundStyle"></div>
      </div>
      <input type="range" step="1" min="1"
             :max="maxValue"
             :value="currentValue"
             @change="change"
             @click="click"
             @keydown.prevent>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  $seekbar-width: 100%;
  $seekbar-height: 4px;
  /*動かしやすくするため実際の見ためよりhandleは大きい*/
  $seekbar-handle-width: 30px;
  $seekbar-color: #fff;
  $seekbar-lower-color: #50E3C2;
  $seekbar-handle-color:#fff;
  .seekbar-wrap {
    display: block;
    position: relative;
    width: 62%;
    margin: 13px auto 0;
    transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    @media screen and (max-width: 370px) {
      width: 55%;
    }
    &.opposite {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }
  .seekbar-bg-wrap{
    display: block;
    position: relative;
    margin: 0 $seekbar-handle-width/2;
  }
  /* for webkit browser seekbar-lower-color  */
  .seekbar-bg{
    position: absolute;
    width: $seekbar-width;
    height: $seekbar-height;
    display: block;
    border-radius: 3px;
    margin-top: 4px;
  }
  input[type=range] {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
    position: absolute;
    top: 0;
    -webkit-appearance: none;
    width: $seekbar-width;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: $seekbar-width;
    height: $seekbar-height;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    margin-top: 4px;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: $seekbar-handle-width;
    width: $seekbar-handle-width;
    background: url(/images/manga_store/viewer/sp/seekbar_thumb@2x.png?v=161130001) no-repeat center center;
    background-size: contain;
    margin-top: - $seekbar-handle-width/2 + $seekbar-height/2;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background-color: transparent;
  }
  input[type=range]::-moz-range-track {
    width: $seekbar-width;
    height: $seekbar-height;
    background-color: transparent;
    border: none;
    border-radius: 3px;
  }
  input[type=range]::-moz-range-progress {
    background-color: transparent;
    height: $seekbar-height;
  }
  input[type=range]::-moz-range-thumb {
    border: none;
    /*firefoxで微妙に縦ずれる対策で4px追加*/
    height: $seekbar-handle-width + 4px;
    width: $seekbar-handle-width;
    background: url(/images/manga_store/viewer/sp/seekbar_thumb@2x.png?v=161130001) no-repeat center top;
    background-size: contain;
    margin-top: 10px;
  }
  /*hide the outline behind the border*/
  input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }
</style>

<script type="text/babel">
  import * as types from "../store/mutation-types"
  import { mapGetters } from "vuex"

  export default {
    props: {
      numTotalPages: Number,
      currentPage: Number,
      isPortrait: Boolean,
      manuscript: Object,
      pageDirection: Number,
    },
    computed: {
      switchDirection() {
        return this.pageDirection === -1 ? true : false
      },
      maxValue() {
        if (!this.isPortrait) {
          const pseudoPages = this.numTotalPages + (this.manuscript.isLeftFirst ? 1 : 0)
          return Math.ceil(pseudoPages / 2)
        } else {
          return this.numTotalPages
        }
      },
      currentValue() {
        if (!this.isPortrait) {
          const pseudoCurrentPage = this.currentPage + (this.manuscript.isLeftFirst ? 1 : 0)
          return Math.floor((pseudoCurrentPage + 1) / 2)
        } else {
          return this.currentPage
        }
      },
      backgroundStyle() {
        const percentage = (this.currentValue - 1) / (this.maxValue - 1) * 100
        return {
          background: `linear-gradient(to right, #50E3C2, #50E3C2 ${percentage}%, #fff ${percentage}%, #fff)`
        }
      },
      ...mapGetters([
        'isIos',
      ]),
    },
    methods: {
      change(e) {
        let v = parseInt(e.target.value)
        let page
        if (v === 1) {
          page = 1
        } else if (v === this.maxValue) {
          page = this.numTotalPages
        } else {
          if (!this.isPortrait) {
            page = (v - 1) * 2 + (this.manuscript.isLeftFirst ? 0 : 1)
          } else {
            page = v
          }
        }
        this.$store.commit(types.JUMP_PAGE, {page})
      },
      click(e) {
        if (this.isIos) {
          e = e.changedTouches ? e.changedTouches[0] : e
          const seekbar = this.$refs.seekbar
          const dx = e.pageX - seekbar.getBoundingClientRect().left
          let page = Math.round((1 - dx/seekbar.offsetWidth) * (this.numTotalPages - 1) + 1)
          page = (this.pageDirection === 1) ? page : this.numTotalPages - page
          this.$store.commit(types.JUMP_PAGE, {page})
        }
      },
    },
  }
</script>
