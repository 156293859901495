<template>
  <div class="overlay" @click.self="cancel">
    <div class="dialog">
      <loader v-if="isLoading"></loader>
      <work-title className="title">{{ title }}</work-title>
      <div class="form">
        <user-status
          :work-type="workType" :status-id="userStatusId" className="status"
          @change="UPDATE_USER_STATUS"
          ></user-status>
        <user-score
          :work-type="workType" :score="score" className="score"
          @change="UPDATE_SCORE"
          ></user-score>
        <input-progress v-if="workType === 'anime'"
          :total="totalEpisodes" :current="watchedEpisodes" className="episode"
          @change="UPDATE_EPISODE" :enabled="canProgress"
          >Episodes</input-progress>
        <input-progress v-if="workType === 'manga'"
          :total="totalVolumes" :current="readVolumes" className="volume"
          @change="UPDATE_VOLUME" :enabled="canProgress"
          >Volumes</input-progress>
        <input-progress v-if="workType === 'manga'"
          :total="totalChapters" :current="readChapters" className="chapter"
          @change="UPDATE_CHAPTER" :enabled="canProgress"
          >Chapters</input-progress>

        <div class="detail">
          <a :href="path">{{ modeStr }} Detailed Info</a>
        </div>

        <div v-if="error" class="error">
          {{ error }}
        </div>
        <div v-if="status" class="error">
          {{ status }}
        </div>

        <ul>
          <li class="cancel">
            <modal-button @click="cancel">Cancel</modal-button>
          </li>
          <li class="submit">
            <modal-button @click="submit">Submit</modal-button>
          </li>
        </ul>
        <div class="notice_open_public pt8">* Your list is public by default.</div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = window._
import namespace from "./store_module/namespace"
import * as types from "./store_module/mutation-types"
import {mapState, mapMutations} from "vuex"
import {AnimeStatusMap, MangaStatusMap} from "../../utils/list"

import Loader from "./atoms/Loader.vue"
import ModalButton from "./atoms/ModalButton.vue"
import WorkTitle from "./atoms/WorkTitle.vue"
import UserScore from "./atoms/UserScore.vue"
import UserStatus from "./atoms/UserStatus.vue"
import InputProgress from "./molecules/InputProgress.vue"

// store_module内から使用するstate
const states = [
  "id", "userStatusId", "score", "title", "watchedEpisodes", "totalEpisodes", "isLoading",
  "readVolumes", "totalVolumes", "readChapters", "totalChapters", "error", "statusId",
]
// store_module内から使用するmutation
const mutations = [
  "UPDATE_USER_STATUS", "UPDATE_SCORE",
  "UPDATE_EPISODE", "UPDATE_VOLUME", "UPDATE_CHAPTER",
]

export default {
  props: {
    workType: String,
    mode: {
      type: String,
      default: "edit",
    },
  },
  computed: {
    ...mapState(namespace,
      _.zipObject(
        states, _.map(states, s => {
          return state => state[s]
        })
      )
    ),
    path() {
      if (this.mode === "add") {
        return (this.workType === "anime")
          ? `/ownlist/anime/add?selected_series_id=${this.id}`
          : `/ownlist/manga/add?selected_manga_id=${this.id}`
      } else {
        // edit
        return `/ownlist/${this.workType}/${this.id}/edit`
      }
    },
    modeStr() {
      return _.capitalize(this.mode)
    },
    canProgress() {
      // not yet aired or publishedのときは進捗を進められない
      return this.statusId !== 3
    },
    status() {
      if (this.canProgress) {
        return
      }
      return this.workType === "anime" ? AnimeStatusMap[this.statusId] : MangaStatusMap[this.statusId]
    },
  },
  methods: {
    ...mapMutations(namespace,
      _.map(mutations, m => types[m])
    ),
    cancel() {
      this.$store.commit(`${namespace}/${types.LOADING}`, false)
      this.$store.commit(`${namespace}/${types.CLEAR_ERROR}`)
      this.$emit("cancel")
    },
    submit() {
      (async () => {
        this.$store.commit(`${namespace}/${types.LOADING}`, true)
        await this.$store.dispatch(
          `${namespace}/saveItem`, {workType: this.workType, mode: this.mode}
        )
        this.$store.commit(`${namespace}/${types.LOADING}`, false)
        this.$emit("submitted")
      })()
      .catch(err => {
        if (err.response) {
          this.$store.commit(`${namespace}/${types.API_ERROR}`, err.response.data.errors[0].message)
          this.$store.commit(`${namespace}/${types.LOADING}`, false)
        } else {
          this.$store.commit(`${namespace}/${types.UNKNOWN_ERROR}`, err.message)
        }
      })
    },
  },
  components: {
    Loader, ModalButton, WorkTitle, UserScore, UserStatus, InputProgress,
  },
}
</script>

<style lang="scss" scoped>

.overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .8);
  height: 100%;
  display: block;

  .dialog {
    position: relative;
    background-color: #fff;
    width: calc(100% - 24px - 24px);
    padding: 16px 24px;

    .title {
      display: block;
      margin: 0;
      padding-bottom: 16px;
    }

    .form {
      background-color: #fff;
      width: 100%;
      overflow-y: scroll;

      .status, .score {
        padding-bottom: 12px;
      }
      .chapter {
        padding-top: 12px;
      }

      .detail {
        display: block;
        padding: 16px 0;
        text-align: center;
        a {
          color: #8594b9;
          font-size: 1.6rem;
          font-weight: 700;
          text-decoration: underline;
        }
      }

      .error {
        display: block;
        padding-bottom: 16px;
        text-align: center;
        color: #ff6a86;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 700;
      }

      ul {
        width: 100%;
        display: table;
        li {
          text-align: center;
          display: table-cell;
        }
      }
    }
  }
}
</style>
