<template>
  <div class="root">
    <transition name="modal">
      <div v-if="isShown" class="modal-content">
        <div class="modal-mask" @click="cancel"></div>
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="text">
              <b>Thanks for choosing this manga.</b>
              <p>
                This content can only be viewed by the first {{ item.accessLimit }} customers.<br>
                Please try again next time.
              </p>
            </div>
            <div class="modal-button-wrap">
              <template v-if="!isRequesting && item.saleBookId">
                <a class="modal-select-button" @click="purchase">Purchase to continue reading</a>
              </template>
            </div>
            <div class="modal-error" v-show="error">{{ error }}</div>
            <a class="modal-close" @click="cancel">Close</a>
          </div>
        </div>
      </div>
    </transition>
    <loading-modal
      :is-shown="showLoadingModal"
    ></loading-modal>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    /* ipone5でなぜかurlバーと下のメニューを高さに含めてしまうのでjavascriptで対応→結局だめ */
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity .3s ease;
  }
  .modal-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    font-family: Verdana,Arial;
    position: relative;
    width: 83.6%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #353535;
    border-radius: 4px;
    text-align: left;
    transition: all .3s ease;
  }
  .modal-message {
    display: inline-block;
    font-size: 14px;
    line-height: 2.0rem;
    font-weight: 300;
    text-align: left;
  }
  .modal-button-wrap {
    text-align: center;
    font-size: 0;
    margin: 20px 0 0;
  }
  .modal-cancel-button {
    border: 2px solid #2E51A2;
    color: #2E51A2;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    height: 44px;
    line-height: 40px;
    width: 100px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
    margin: 0 5px;
    &:active {
      background-color: rgba(#2E51A2, 0.2);
    }
  }
  .modal-select-button {
    border: 2px solid #2E51A2;
    background-color: #2E51A2;
    color: #ffffff;
    font-size: 1.6rem;
    text-align: center;
    display: inline-block;
    height: 44px;
    line-height: 40px;
    width: 200px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: bold;
    margin: 0 5px;
    &:active {
      background-color: rgba(#4f74c8, 0.2);
    }
  }
  .modal-close {
    position: absolute;
    top: - 20px;
    right: - 20px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(/images/manga_store/sp/modal_close@2x.png?v=161115001) no-repeat left top;
    background-size: contain;
    vertical-align: top;
    text-indent: -9999px;
    overflow: hidden;
  }

  /* Transition Effect */
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .modal-error {
    display: block;
    margin-top: 20px;
    color: #ff6a86;
    text-indent: 0;
    font-size: 14px;
    line-height: 2.0rem;
    font-weight: 700;
    text-align: center;
  }
  .text {
    font-size: 1.4rem;
    line-height: 2.0rem;
    margin-bottom: 15px;
    word-wrap: break-word;
    text-align: center;
    p {
      padding-bottom: 8px;
    }
    b {
      font-size: 16px;
      text-align: center;
      display: block;
      border-bottom: #bebebe 1px solid;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    a {
      text-decoration: underline;
      &:active {
        text-decoration: none;
      }
    }
  }

  @media only screen and (orientation : landscape) {
    .modal-container {
      padding: 10px;
    }
    .modal-close {
      top: -10px;
    }
    .modal-cancel-button {
      height: inherit;
      padding: 8px 0;
      line-height: 1.0rem;
    }
    .modal-select-button {
      height: inherit;
      padding: 8px 0;
      line-height: 1.0rem;
    }
  }

</style>

<script type="text/babel">
  import LoadingModal from "./LoadingModal.vue"

  export default {
    components: {
      LoadingModal,
    },
    data() {
      return {
      }
    },
    props: {
      isShown: Boolean,
      showLoadingModal: Boolean,
      error: String,
      residentialArea: Object,
      item: Object,
    },
    methods: {
      cancel() {
        this.$emit('cancel')
      },
      purchase() {
        this.$emit('purchase')
      },
    }
  }
</script>
