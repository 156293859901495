import Vuex from "vuex"
import mutations from "./mutations"
import getters from "./getters"

export default () => {
  const state = {
    selectedGenreId: 0,
    genres: [],
    keyword: "",
  }

  return new Vuex.Store({
    state,
    mutations,
    getters,
  })
}
