
import SearchBox from "./SearchBox.vue"
import Store from "./store/index"
import * as types from "./store/mutation-types"

const SVue = window.sVue

const $ = window.$
const selector = ".v-manga-store-search-box"

$(() => {
  Array.from(document.querySelectorAll(selector)).forEach(el => {
    const genres = JSON.parse(el.getAttribute("data-genres"))
    const searchKeyword = el.getAttribute("data-search-keyword")
    const searchGenreId = parseInt(el.getAttribute("data-search-genre-id")) || 0
    new SVue({
      el,
      store: Store(),
      render: h => h(SearchBox),
      created() {
        this.$store.commit(types.INITIALIZE, {genres, searchKeyword, searchGenreId})
      }
    })
  })
})
